import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GET_PRODUCT_ATTRS } from '@product/product/services/graphql';
import { AsyncSelect } from '@base/components/form';
import { graphQLApi } from '@base/utils/axios/graphql';

const orderOptions = (values: any[]) => {
  return values
    .filter((v: { isFixed: any }) => v.isFixed)
    .concat(values.filter((v: { isFixed: any }) => !v.isFixed));
};

const styles = {
  // multiValue: (base: any, state: { data: { isFixed: any } }) => {
  //   return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  // },
  // multiValueLabel: (base: any, state: { data: { isFixed: any } }) => {
  //   return state.data.isFixed
  //     ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
  //     : base;
  // },
  // multiValueRemove: (base: any, state: { data: { isFixed: any } }) => {
  //   return state.data.isFixed ? { ...base, display: 'none' } : base;
  // },
};

interface IProps {
  className?: string;
  single?: boolean;
  value?: any | any[];
  onChange?: any;
  onAdd?: any;
  addLabel?: string;
  options?: any[];
}

const ProductAttributeAutoComplete: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    single = false,
    value, //single={}, multi=[]
    onChange,
    onAdd,
    options: initOptions = [],
  } = props;

  // state
  const [selectedValue, setSelectedValue] = useState<any>([]); //single={}, multi=[]

  // init data
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  // fetch data
  const fetchOptions = async (inputValue: string | any[]) => {
    let newOptions: any[] = [];
    if (initOptions && initOptions?.length > 0) {
      newOptions = initOptions?.map((item) => ({ ...item, value: item.id, label: item.name }));
    } else {
      let params: any = {
        filter: {
          filters: {},
          keyword: inputValue,
          paging: {
            page: 1,
            size: 100,
          },
        },
      };

      const data = await graphQLApi<any>('product_attributes', GET_PRODUCT_ATTRS, params);
      newOptions = data?.results?.map((_item: any) => {
        return {
          ..._item,
          label: _item.name,
          value: _item.id,
        };
      });
    }
    return newOptions;
  };

  // value change
  const handleSelectChange = (value: any, { action, removedValue }: any) => {
    setSelectedValue(value);
    if (!single) {
      switch (action) {
        case 'remove-value':
        case 'pop-value':
          if (removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          value = value.filter((v: { isFixed: any }) => v.isFixed);
          break;
      }
      value = orderOptions(value ? value : []);
    }

    //callback
    onChange && onChange(value);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <AsyncSelect
        styles={styles}
        isMulti={!single}
        placeholder="Select a attribute..."
        menuPlacement="auto"
        cacheOptions
        value={selectedValue}
        loadOptions={fetchOptions}
        onChange={handleSelectChange}
        isClearable={!single && value && value?.some((v: { isFixed: any }) => !v.isFixed)}
        addLabel="Add new attribute"
        onAdd={onAdd}
      />
    </div>
  );
};

export default ProductAttributeAutoComplete;
