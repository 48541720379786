import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { WARRANTY_PERIOD_OPTIONS } from '@product/main/config/constants';

/**
 *
 * @param {*} props
 * @returns
 */
const WarrantyPeriod: React.FC<any> = (props: any) => {
  const {
    className,
    value, // { period: 10, unit: 'd' }
    onChange,
  } = props;

  //state
  const [number, setNumber] = useState(0);
  const [option, setOption] = useState<any>(null);

  //set selected items
  useEffect(() => {
    // console.log('[Warranty] >>> value', value);
    if (value && value?.period) {
      setNumber(value.period);
    }
    if (value && value?.unit) {
      const idx = WARRANTY_PERIOD_OPTIONS.findIndex((el: any) => el.value === value?.unit);
      if (idx > -1) {
        setOption(WARRANTY_PERIOD_OPTIONS[idx]);
      }
    } else {
      setOption(WARRANTY_PERIOD_OPTIONS[0]);
    }
  }, [value]);

  //number
  const handleNumberChange = (e: any) => {
    setNumber(e.target.value);
    //callback
    onChange && onChange({ period: parseInt(e.target.value), unit: option?.value });
  };

  //option
  const handleOptionChange = (newOption: any) => {
    setOption(newOption);
    //callback
    onChange && onChange({ period: number, unit: newOption?.value });
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <div className="d-flex align-items-center">
        <input
          type="number"
          className="form-control ht-38 mg-r-5"
          value={number}
          onChange={handleNumberChange}
          min={0}
        />
        <Select value={option} options={WARRANTY_PERIOD_OPTIONS} onChange={handleOptionChange} />
      </div>
    </div>
  );
};

export default WarrantyPeriod;
