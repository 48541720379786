import React, { useEffect, useState } from 'react';
import View from './view';

const ImagesViewField: React.FC = (props: any) => {
  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    if (props?.value?.length > 0) {
      setValues(props?.value?.map((item: any) => ({ ...item, url: item?.name ?? '' })));
    }
    return () => {
      setValues([]);
    };
  }, [props?.value]);

  return <View {...props} value={values} />;
};

export default ImagesViewField;
