import { lazy } from 'react';

export const CountryOriginView = lazy(
  () => import('@product/item/containers/view-field/country-origin'),
);
export const WarrantyView = lazy(
  () => import('@product/product/containers/view-field/product-warranty'),
);
export const WeightView = lazy(() => import('@product/item/containers/view-field/weight'));
export const CustomerView = lazy(
  () => import('@customer/customer/containers/view-field/customer-view'),
);
export const DimensionView = lazy(() => import('@product/item/containers/view-field/dimension'));
export const ItemBarCodeView = lazy(
  () => import('@product/item/containers/view-field/item-barcode'),
);
export const ItemSkuView = lazy(() => import('@product/item/containers/view-field/item-sku'));
export const TrackingTypeView = lazy(
  () => import('@product/item/containers/view-field/tracking-type'),
);
export const SalesCommissionView = lazy(
  () => import('@product/item/containers/view-field/sales-commission'),
);
export const ProductView = lazy(
  () => import('@product/product/containers/view-field/product-view'),
);
