import React from 'react';
import { Input } from '@base/components/form';
import { Settings } from 'react-feather';

const BarCodeInput: React.FC<any> = (props: any) => {
  const { value = '', onChange } = props;

  return (
    <div className="pos-relative">
      <Input
        type="text"
        value={value}
        // disabled={generator === GENERATOR_AUTO}
        rightIcon={<Settings className="wd-20" />}
        // isLoading={isLoading}
        onChange={(_value: string) => onChange && onChange({ barCode: _value })}
        // onRightIconClick={() => setShowGenerate(true)}
      />
    </div>
  );
};
export default BarCodeInput;
