/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import _, { uniqueId } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { ArrowRight, ArrowLeft, Plus } from 'react-feather';
import Button from '@base/components/form/button';
import {
  KEY_ITEM_CATEGORY,
  KEY_ITEM_TYPE,
  KEY_ITEM_BASE_UNIT,
  KEY_ITEM_ATTRIBUTES,
  KEY_PRICING_TYPE,
  KEY_USE_PREPAID,
  KEY_USE_SUBSCRIBE,
  KEY_ITEM_BASIC_INFO,
  KEY_ITEM_INVENTORY_INFO,
  KEY_ITEM_REORDER_POINT,
  KEY_ITEM_OPEN_STOCK,
  KEY_ITEM_LOT_SIZE,
  KEY_ITEM_LEAD_TIME,
  KEY_ITEM_INVENTORIES,
  KEY_ITEM_BATCH_INFO,
  KEY_ITEM_PREPAID_INFO,
  KEY_ASSOCIATED_ITEMS,
  KEY_PREPAID_ITEM,
  KEY_ITEM_COUNTRY_ORIGIN,
  KEY_PREPAID_TYPE,
  KEY_ITEM_UNITS,
} from '@product/item/config/key-names';
import {
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_BUNDLE,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_CATEGORY_NON_INVENTORY,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
  PRODUCT_PRICE_TYPE_STATIC,
  PRODUCT_PREPAID_TYPE_AMOUNT,
} from '@product/main/config/constants';
import { ADD_NEW_PRODUCT_ITEM } from '@product/item/services/graphql';

import validators from '@base/utils/validation/field-validator';
import {
  unitIsRequired,
  attributeIsRequired,
  basicIsRequired,
  inventoryIsRequired,
  batchIsRequired,
  prepaidIsRequired,
  prepaidItemIsRequired,
  associatedIsRequired,
} from './validation';

import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';

import { useRecoilValue } from 'recoil';
import { defaultCurrencySelector, defaultCountrySelector } from '@base/recoil/selectors';

import { generateProductItems } from './utils';
import { finalizeParams } from './payload';

import ItemCategory from '@product/item/containers/item-category';
import ItemType from '@product/item/containers/item-type';
import PrepaidType from '@product/item/containers/prepaid-type';
import AssociatedItems from '@product/item/containers/associated-items';
import ProductAddAttribute from '@product/product/containers/attributes-select/add-attribute';

import BaseUnitName from './base-unit-name';
import ItemAttributes from './item-attributes';
import BasicInfo from './basic-info';
import PrepaidItems from './prepaid-items';
import ProductAutoComplete from '@product/product/containers/product-auto-complete';
import classNames from 'classnames';
import {
  KEY_BASE_UNIT,
  KEY_PRODUCT_ATTRIBUTE,
  KEY_PRODUCT_TYPE,
  KEY_USE_ATTR,
} from '@product/product/config/key-names';

const STEPS_INVENTORY = [
  {
    value: 1,
    label: 'Step 1',
  },
  {
    value: 2,
    label: 'Step 2',
  },
];

const defaultValues = {
  [KEY_ITEM_CATEGORY]: PRODUCT_ITEM_CATEGORY_INVENTORY,
  [KEY_ITEM_TYPE]: PRODUCT_ITEM_TYPE_GENERAL,
  [KEY_ITEM_BASE_UNIT]: { id: '', unitValues: [], unitNames: [] },
  [KEY_ITEM_ATTRIBUTES]: { applyItemAttribute: true, attributes: [] },
  [KEY_PRICING_TYPE]: PRODUCT_PRICE_TYPE_STATIC,
  [KEY_USE_SUBSCRIBE]: 'n',
  [KEY_USE_PREPAID]: 'n',
  [KEY_ITEM_BASIC_INFO]: [],
  [KEY_PREPAID_TYPE]: PRODUCT_PREPAID_TYPE_AMOUNT,
  [KEY_ITEM_INVENTORY_INFO]: [],
  [KEY_ITEM_PREPAID_INFO]: [],
  [KEY_ITEM_BATCH_INFO]: [],
  [KEY_ASSOCIATED_ITEMS]: [],
  [KEY_PREPAID_ITEM]: [],
};

/**
 *
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<any> = (props: any) => {
  const {
    showCanvas = false,
    fullScreen = false,
    withinProduct = false,
    product, //both
    value, //both
    onValid,
    onChange: onFormChange, //for write product
    onClose,
    onReload,
    itemStep,
    setItemStep,
  } = props;

  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);
  const defaultCountry = useRecoilValue<any>(defaultCountrySelector);

  let MAX_STEP = 2; //default
  let STEPS = STEPS_INVENTORY; //default

  //state
  const [showAddAttribute, setShowAddAttribute] = useState(false); //add attribute
  const [formStep, setFormStep] = useState(1);
  const [prodSelected, setProdSelected] = useState<any>(null);

  const {
    watch,
    reset,
    //register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({ defaultValues, criteriaMode: 'firstError', mode: 'onChange' });

  //init base unit attributes
  const setBaseUnitAttributes = (baseUnitValue: any, attrValue: any) => {
    //item base unit
    let newBaseUnitValue = { id: '', unitValues: [], unitNames: [] };
    if (baseUnitValue) {
      newBaseUnitValue = {
        ...baseUnitValue,
        unitNames: baseUnitValue?.unitNames
          ? baseUnitValue?.unitNames
          : baseUnitValue?.unitValues?.map((_i: any) => ({
              ..._i,
              value: _i.id,
              label: _i.name,
            })),
      };
    }
    setValue(KEY_ITEM_BASE_UNIT, newBaseUnitValue, { shouldValidate: true });

    //item attributes
    const newAttributeValue = {
      ...attrValue,
      attributes:
        attrValue?.attributes?.length > 0
          ? attrValue?.attributes?.map((_attr: any) => ({
              ..._attr,
              values: _attr?.values?.map((_child: any) => ({
                ..._child,
                value: _child.id,
                label: _child.name,
              })),
              activeValue: _attr.activeValue
                ? _attr.activeValue
                : _attr?.values?.map((_child: any) => ({
                    ..._child,
                    value: _child.id,
                    label: _child.name,
                  })),
            }))
          : [],
    };
    setValue(KEY_ITEM_ATTRIBUTES, newAttributeValue);
  };

  //for product success
  useEffect(() => {
    // if (product?.isSubmitSuccessful && !product?.id) {
    //   reset(); //form default values
    //   setFormStep(1);
    // }
    // if (product && product?.id != '') {
    //   setProdSelected({ ...product, value: product?.id, label: product?.name });
    // }

    if (product) {
      setProdSelected({ ...product, value: product.id, label: product.name });
    }
  }, [product]);

  // for with product
  useEffect(() => {
    if (itemStep && itemStep != formStep) {
      if (itemStep > formStep) {
        goNextStep();
      } else {
        goPreviousStep();
      }
    }
  }, [itemStep]);

  //init base unit, attributes when product change
  useEffect(() => {
    // console.log('prodSelected', prodSelected);
    if (prodSelected) {
      //set base unit + attributes
      let newAttribute = { applyItemAttribute: false, attributes: [] };
      if (prodSelected?.id) {
        newAttribute = {
          applyItemAttribute: prodSelected?.[KEY_USE_ATTR],
          attributes: prodSelected?.[KEY_PRODUCT_ATTRIBUTE],
        };
      } else {
        newAttribute = prodSelected?.[KEY_PRODUCT_ATTRIBUTE];
      }
      setBaseUnitAttributes(prodSelected[KEY_BASE_UNIT], newAttribute);
    }
  }, [prodSelected]);

  // EDIT: init item data
  useEffect(() => {
    if (value) {
      //set base unit + attributes
      setBaseUnitAttributes(value?.[KEY_ITEM_BASE_UNIT], value?.[KEY_ITEM_ATTRIBUTES]);
      // set itemType value
      setValue(KEY_ITEM_TYPE, PRODUCT_ITEM_TYPE_GENERAL);
    } else {
      //setItemData(defaultValues);
    }
  }, [value]);

  //on valid change
  useEffect(() => {
    if (onValid) {
      onValid(isValid);
    }
  }, [isValid]);

  //form values change
  watch((data: any) => {
    //// console.log('watch data', data);
    onFormChange && onFormChange(data);
  });

  /**================================== FORM PROCESSING ==================================== */

  // item option watch
  const itemCategory = watch(KEY_ITEM_CATEGORY);
  const itemType = watch(KEY_ITEM_TYPE);
  const itemPrepaidOn = watch(KEY_USE_PREPAID);
  useEffect(() => {
    if (itemType === PRODUCT_ITEM_TYPE_PREPAID) {
      setValue(KEY_ITEM_CATEGORY, PRODUCT_ITEM_CATEGORY_NON_INVENTORY);
    } else {
    }
  }, [itemType]);

  // base unit names and attribute watch
  const itemBaseUnit = watch(KEY_ITEM_BASE_UNIT);
  const itemAttribute = watch(KEY_ITEM_ATTRIBUTES);
  const prepaidType = watch(KEY_PREPAID_TYPE);

  if (itemType === PRODUCT_ITEM_TYPE_PREPAID) {
    MAX_STEP = 1;
  }

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_NEW_PRODUCT_ITEM,
    'product_createItems',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        // toast.success('Create product items successfully!');
        // refecth data
        // onReload && onReload();
        // onClose && onClose();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Create product items failed: ' + JSON.parse(error).message);
        // refecth data
      },
    },
  );

  //mutate success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      toast.success('Create product items successfully!');
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //generate params and save
  const onSubmit = (formData: any) => {
    if (prodSelected && prodSelected?.id) {
      let item: any = finalizeParams(formData, prodSelected, itemCategory, itemType, prepaidType);
      let itemParams: any = {
        items: item?.[KEY_ITEM_UNITS],
      };
      // console.log('[Item] product_createItems', itemParams);
      mutationAdd.mutate(itemParams);
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log(errors, e);
    toast.error('Created product items failed: ' + JSON.stringify(errors));
  };

  /**========================================== INIT STEP ==================================================== */

  //next step
  const goNextStep = async () => {
    if (itemType !== PRODUCT_ITEM_TYPE_PREPAID) {
      // init data for STEP Basic
      if (formStep + 1 === 2) {
        // TODO set defaultValues with react-hook-form
        // generate items
        let newItems: any = generateProductItems(
          itemBaseUnit.unitNames,
          itemType === PRODUCT_ITEM_TYPE_BUNDLE ? [] : itemAttribute.attributes,
          {},
        );
        // init prices - for product.type === 'both'
        const newCurrency: any = {
          ...defaultCurrency,
          label: defaultCurrency.code,
          value: defaultCurrency.code,
        };
        let defaultPrices: any = [
          {
            purchase_price: {
              value: 0,
              currency: newCurrency,
            },
            base_price: {
              value: 0,
              currency: newCurrency,
            },
            cost_price: {
              value: 0,
              currency: newCurrency,
              cost_fields: [],
            },
            best_price: {
              value: 0,
              currency: newCurrency,
            },
          },
        ];
        if (prodSelected?.type === PRODUCT_TYPE_PURCHASE && !prodSelected?.canBeSold) {
          defaultPrices = [
            {
              purchase_price: {
                value: 0,
                currency: newCurrency,
              },
            },
          ];
        } else {
          defaultPrices = [
            {
              base_price: {
                value: 0,
                currency: newCurrency,
              },
              cost_price: {
                value: 0,
                currency: newCurrency,
                cost_fields: [],
              },
              best_price: {
                value: 0,
                currency: newCurrency,
              },
            },
          ];
        }
        newItems = newItems.map((_item: any) => ({
          ..._item,
          name: [prodSelected?.name, _item?.name].join(' '), // itemName = productName + unitName + attribute/attribute
          prices: _.cloneDeep(defaultPrices),
          sku: [],
          moreInfo: {
            ..._item.moreInfo,
            [KEY_ITEM_COUNTRY_ORIGIN]: {
              value: defaultCountry?.isoCode2 ?? '',
              label: defaultCountry?.country ?? '',
            },
          },
          idLocal: uniqueId(),
        }));

        // console.log('STEP 2...', itemBaseUnit, itemType);

        //set form value
        setValue(KEY_ITEM_BASIC_INFO, newItems, { shouldValidate: true });
      }
    }
    // next
    setFormStep((cur) => cur + 1);
  };

  // previous step
  const goPreviousStep = async () => {
    if (formStep - 1 === 1) {
      const isStepValid = await trigger(KEY_ITEM_BASE_UNIT); //re-validate when back to general step
      setValue(KEY_ITEM_BASIC_INFO, []);
      //onChange to product form
      // if (!prodSelected?.id) {
      //   onFormChange && onFormChange({ ...getValues(), [KEY_ITEM_BASIC_INFO]: [] });
      // }
    }
    setFormStep((cur) => cur - 1);
  };

  /**=================================== HANDLE EVENT ====================================================== */

  //set new attribute
  const handleAddNewAttribute = (newAttribute: any) => {
    const newAttributeValue: any = getValues(KEY_ITEM_ATTRIBUTES);
    //more info
    newAttribute.productAttribute = false;
    if (newAttribute?.values) {
      const newValue: any = [];
      newAttribute.values.map((_child: any) => {
        newValue.push({
          ..._child,
          value: _child.id,
          label: _child.name,
        });
      });
      newAttribute.values = newValue;
      newAttribute.activeValue = newValue;
    }
    newAttributeValue.attributes.push(newAttribute);
    setValue(KEY_ITEM_ATTRIBUTES, newAttributeValue, { shouldValidate: true });
  };

  //product selected
  const handleProductChange = (newProduct: any) => {
    setProdSelected(newProduct);
  };

  /**========================================== RENDER ==================================================== */

  //general info
  const GeneralStep = useMemo(() => {
    return (
      <div className={classnames('pd-15', { 'd-none': formStep !== 1 })}>
        <div className="form-row">
          {/* {(!product || product?.id == '') && ( */}
          <div className="form-group col-12">
            <label className="form-item-title">
              Product<span className="tx-danger">*</span>
            </label>
            <ProductAutoComplete
              outSide={true}
              single={true}
              disabled={withinProduct}
              showAllOption={false}
              value={prodSelected}
              onChange={handleProductChange}
            />
          </div>
          {/* )} */}
          {/* {prodSelected && prodSelected?.id != '' && ( */}
          <>
            {/* Item type */}
            <div className="form-group col-12">
              <label className="form-item-title">
                Item Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={KEY_ITEM_TYPE}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ItemType
                    value={value}
                    onChange={(newValue: any) => {
                      onChange(newValue);
                    }}
                    productData={
                      withinProduct
                        ? {
                            ...prodSelected,
                            [KEY_PRODUCT_TYPE]: prodSelected?.[KEY_PRODUCT_TYPE]?.value,
                          }
                        : prodSelected
                    }
                    disabled={!prodSelected}
                  />
                )}
              />
            </div>
            {/* Inventory Type */}
            <div className="form-group col-12">
              <label className="form-item-title">
                Inventory Type<span className="tx-danger">*</span>
              </label>
              <Controller
                name={KEY_ITEM_CATEGORY}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ItemCategory
                    value={value}
                    onChange={(newValue: any) => {
                      onChange(newValue);
                    }}
                    disabled={itemType === PRODUCT_ITEM_TYPE_PREPAID}
                  />
                )}
              />
            </div>
            {/* Base Unit */}
            {itemType !== PRODUCT_ITEM_TYPE_PREPAID && (
              <div className="form-group col-12">
                <label className="form-item-title">
                  Base Unit<span className="tx-danger">*</span>
                </label>
                <Controller
                  name={KEY_ITEM_BASE_UNIT}
                  control={control}
                  rules={{ validate: unitIsRequired }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <BaseUnitName
                      value={value}
                      onChange={(newValue: any) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                />
              </div>
            )}
            {/* Attributes */}
            {itemType === PRODUCT_ITEM_TYPE_GENERAL && itemAttribute.applyItemAttribute && (
              <div className="form-group col-12">
                <div className="d-flex align-items-center mg-b-5">
                  <label className="form-item-title">Attributes</label>
                </div>
                <div className="pos-relative pd-15 bd rounded">
                  <Controller
                    name={KEY_ITEM_ATTRIBUTES}
                    control={control}
                    rules={{ validate: (data) => attributeIsRequired(data, itemType) }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ItemAttributes
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue);
                        }}
                        attributes={
                          withinProduct
                            ? prodSelected?.attributes?.attributes
                            : prodSelected?.attributes ?? []
                        }
                      />
                    )}
                  />
                </div>
                {showAddAttribute && (
                  <ProductAddAttribute
                    isShow={showAddAttribute}
                    onClose={() => setShowAddAttribute(false)}
                    onAdd={handleAddNewAttribute}
                  />
                )}
              </div>
            )}
            {/* Associated Items */}
            {itemType === PRODUCT_ITEM_TYPE_BUNDLE && (
              <div className="form-group col-12">
                <label className="form-item-title">Associated Items</label>
                <Controller
                  name={KEY_ASSOCIATED_ITEMS}
                  control={control}
                  rules={{ validate: associatedIsRequired }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <AssociatedItems
                      value={value}
                      onChange={(newValue: any) => {
                        onChange(newValue);
                      }}
                    />
                  )}
                />
              </div>
            )}
            {/* Prepaid Type */}
            {itemType === PRODUCT_ITEM_TYPE_PREPAID && (
              <>
                <div className="form-group col-12">
                  <label className="form-item-title">Prepaid Type</label>
                  <Controller
                    name={KEY_PREPAID_TYPE}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PrepaidType
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                </div>
                {/* Prepaid Items */}
                <div className="form-group col-12">
                  <label className="form-item-title">Prepaid Items</label>
                  <Controller
                    name={KEY_PREPAID_ITEM}
                    control={control}
                    rules={{ validate: prepaidItemIsRequired }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PrepaidItems
                        product={prodSelected}
                        category={itemCategory}
                        type={itemType}
                        prepaidType={prepaidType}
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue);
                        }}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </>
          {/* )} */}
        </div>
      </div>
    );
  }, [prodSelected, itemType, formStep, prepaidType, showAddAttribute, itemCategory]);

  //basic information
  const BasicStep = useMemo(() => {
    return (
      <div className={classnames('pd-15', { 'd-none': formStep !== 2 })}>
        <Controller
          name={KEY_ITEM_BASIC_INFO}
          control={control}
          rules={{
            validate: (data) =>
              basicIsRequired(data, prodSelected, itemCategory, itemType, prepaidType),
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <BasicInfo
              product={prodSelected}
              category={itemCategory}
              type={itemType}
              prepaidType={prepaidType}
              usePrepaid={itemPrepaidOn}
              useSubscribe={getValues(KEY_USE_SUBSCRIBE)} //item
              value={value}
              onChange={(newValue: any) => {
                onChange(newValue);
              }}
            />
          )}
        />
      </div>
    );
  }, [prodSelected, itemCategory, itemPrepaidOn, formStep, prepaidType]);

  //render steps
  const FormSteps = useMemo(() => {
    return (
      <ul className="steps steps-justified steps-tab">
        {STEPS.map((_item: any, _index: number) => (
          <li
            key={_index}
            className={classnames('step-item', {
              complete: _item.value < formStep,
              active: _item.value === formStep,
              disabed: _item.value > formStep,
            })}
          >
            <button
              type="button"
              className="btn step-link rounded-0"
              //onClick={(e) => setFormStep(_item.value)}
            >
              <span className="step-number">{_item.value}</span>
              <span className="step-title">{_item.label}</span>
            </button>
          </li>
        ))}
      </ul>
    );
  }, [prodSelected?.id, formStep]);

  //render footer without product
  const FormFooter = useMemo(() => {
    return (
      <div className="d-flex pd-15 bd-t">
        {/* Previous */}
        {formStep > 1 && (
          <button type="button" className="btn btn-light" onClick={goPreviousStep}>
            <ArrowLeft />
            Previous
          </button>
        )}

        {/* Close */}
        <button type="button" className="btn btn-light mg-r-5 mg-l-auto" onClick={onClose}>
          Close
        </button>

        {itemType !== PRODUCT_ITEM_TYPE_PREPAID && (
          <>
            {formStep < MAX_STEP && (
              <button
                disabled={!isValid || !prodSelected?.id}
                type="button"
                className="btn btn-primary"
                onClick={goNextStep}
              >
                Next
                <ArrowRight />
              </button>
            )}
          </>
        )}

        {/* for write item separatedly */}
        {formStep === MAX_STEP && prodSelected?.id && (
          <>
            <Button
              color="success"
              className=""
              onClick={() => {
                handleSubmit((data) => onSubmit(data), onError)().then(() => {});
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save"
            />
          </>
        )}
      </div>
    );
  }, [
    prodSelected,
    itemType,
    formStep,
    itemBaseUnit,
    itemAttribute,
    itemPrepaidOn,
    isValid,
    mutationAdd.isLoading,
  ]);

  //======================== Debug ========================//
  // // console.log('[Item] watch', watch());
  // // console.log('[Item] form errors', errors);
  // // console.log('[Item] form isValid', isValid);
  //======================== End Debug ========================//

  let formHeight = 'calc(100vh - 340px)';
  if (fullScreen) {
    formHeight = withinProduct ? 'calc(100vh - 270px)' : 'calc(100vh - 210px)';
  } else if (showCanvas) {
    formHeight = withinProduct ? 'calc(100vh - 270px)' : 'calc(100vh - 170px)';
  }

  // // console.log('WRITE ITEM ...', prodSelected);
  //render
  return (
    <div className="pos-relative">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className={classNames('form', { 'wd-800': showCanvas && withinProduct })}
      >
        <div className={classNames('pos-relative', { 'mg-10 bd rounded': withinProduct })}>
          {itemType !== PRODUCT_ITEM_TYPE_PREPAID && (withinProduct ? FormSteps : FormSteps)}
          <div
            className={classnames('pos-relative scroll-box')}
            style={{ height: formHeight, overflowX: 'hidden' }}
          >
            {formStep >= 1 && GeneralStep}
            {itemType !== PRODUCT_ITEM_TYPE_PREPAID && formStep === MAX_STEP && BasicStep}
          </div>
          {!withinProduct && FormFooter}
        </div>
      </form>
    </div>
  );
};

export default WriteForm;
