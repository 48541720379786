import React, { useMemo, useState } from 'react';
import Icon from '@base/assets/icons/svg-icons';
import { ChevronDown, ChevronLeft, ChevronRight, Plus, Trash, X } from 'react-feather';
import usePosts from '@base/hooks/usePosts';
import { GET_PRODUCT_ITEM_UNITS } from '@product/product/services/graphql';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Checkbox, SpanLang } from '@base/components/form';

// write item
import WriteItem from '@product/item/pages/write';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import {
  KEY_PRODUCT_NAME,
  KEY_PRODUCT_GROUP,
  KEY_PRODUCT_TYPE,
  KEY_BASE_UNIT,
  KEY_PRODUCT_ATTRIBUTE,
  KEY_USE_ATTR,
  KEY_PRODUCT_TYPE_BE_SOLD,
} from '@product/product/config/key-names';
import { Portal } from '@base/components';
import { MENU_PRODUCT_ITEM, MENU_PRODUCT_PRODUCT } from '@base/config/menus';
import { ASC, DESC } from '@base/config/constant';
import { ColumnDef } from '@tanstack/react-table';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { getAttributesByValues, moneyFormat } from '@base/utils/helpers';
import { PRODUCT_ITEM_TYPE_OPTIONS } from '@product/main/config/constants';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { ProductItemTextView } from '@base/containers/quick-view';

const ProductItems: React.FC<any> = (props: any) => {
  //for write
  const [showItemWrite, setShowItemWrite] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const productData = useRecoilValue(viewDataByMenuAtom(MENU_PRODUCT_PRODUCT));
  const [sort, setSort] = useState<any>({ field: 'createdAt', orderBy: DESC });

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const { id: prodId } = useParams();

  const params = {
    filter: {
      query: `prodId=${prodId}`,
      sort: {
        field: 'createdAt',
        orderBy: 0,
      },
      paging: {
        page: currentPage,
        size: 15,
      },
    },
  };

  const {
    isLoading,
    data,
    refetch: reloadItemUnits,
  } = usePosts<any[]>(['product_items', JSON.stringify(params)], GET_PRODUCT_ITEM_UNITS, params);

  const items = data?.data ?? [];
  const paging = data?.paging ?? undefined;

  const goToPage = (page: number | undefined) => {
    if (page && page != currentPage) {
      setCurrentPage(page);
    }
  };

  const columnsV8 = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'itemType',
        header: () => <SpanLang keyLang={'Item Type'} />,
        accessorKey: 'itemType',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const itemType = row?.original?.itemType ?? '';
          return PRODUCT_ITEM_TYPE_OPTIONS?.find((v: any) => v.value === itemType)?.label ?? '';
        },
        // width: 'auto',
      },
      {
        id: 'name',
        header: () => <SpanLang keyLang={'Item Name'} />,
        accessorKey: 'name',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <ProductItemTextView value={{ id: row?.original?.id, name: row?.original?.name }} />
          );
        },
        // width: 'auto',
      },
      {
        id: 'unitVal',
        header: () => <SpanLang keyLang={'Unit Name'} />,
        accessorKey: 'unitVal',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return row?.original?.unitVal?.name ?? '';
        },
        // width: 'auto',
      },
      {
        id: 'unitValQty',
        header: () => <SpanLang keyLang={'Unit Qty'} />,
        // accessorKey: 'unitValQty',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return row?.original?.unitVal?.qty ?? '';
        },
        // width: 'auto',
      },
      {
        id: 'attrValues',
        header: () => <SpanLang keyLang={'Attributes'} />,
        // accessorKey: 'attrValues',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const items: any[] =
            row?.original?.attrValues?.map((item: any) => ({
              id: item.id,
              name: [item?.attr?.name, item?.name].join(':'),
            })) ?? [];
          return items.length > 0 ? (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={items}
            />
          ) : (
            ''
          );
        },
        // width: 'auto',
      },
      {
        id: 'rate',
        header: () => <SpanLang keyLang={'Rate'} />,
        // accessorKey: 'attrValues',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          let items: any[] = row?.original?.prices ?? [];

          items = items
            ? items?.map((price: any) => ({
                id: price?.currency,
                name: [price?.currency, moneyFormat(price?.amount ?? 0)].join(':'),
              }))
            : [];

          return items.length > 0 ? (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={items}
            />
          ) : (
            ''
          );
        },
        // width: 'auto',
      },
    ],
    [items],
  );

  // add default values for write item
  let product: any = {};
  if (productData && productData.data) {
    product = {
      ...productData,
      [KEY_PRODUCT_NAME]: productData.data[KEY_PRODUCT_NAME] || '',
      [KEY_PRODUCT_GROUP]: productData.data[KEY_PRODUCT_GROUP] || null,
      [KEY_PRODUCT_TYPE]: productData.data[KEY_PRODUCT_TYPE] || '', //"PRODUCT_TYPE_PRODUCED"
      [KEY_BASE_UNIT]: productData.data.unit || {}, //TODO: write and view must be the same key
      [KEY_PRODUCT_ATTRIBUTE]: productData.data[KEY_PRODUCT_ATTRIBUTE] || [],
      [KEY_USE_ATTR]: productData?.data[KEY_USE_ATTR] || false,
      [KEY_PRODUCT_TYPE_BE_SOLD]: productData?.data[KEY_PRODUCT_TYPE_BE_SOLD] || false,
      ['isSubmitSuccessful']: false,
      ['id']: prodId,
    };
  }

  // React-table v8
  const listTable8Props = {
    className: 'product-detail-tb',
    tableClassName: 'mg-b-0',
    style: { maxHeight: 'calc(100vh - 290px)' },
    columns: columnsV8,
    data: items ?? [],
    initialState: {
      // pageCount: paging?.totalItems || 0,
      pageSize: data?.paging?.itemPerPage || 15,
      // pageIndex: paging?.currentPage || 1,
      sorts: [sort],
    },
    // onRowSelect: handleCheckItem,
    onSortBy: (clName: any, isSorted: any) => {
      // isSorted from react-table-v8 that is false, 'asc' or 'desc'
      // // console.log('isSorted', isSorted);
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    rowSelected: [],
    isLoading: isLoading,
  };

  return (
    <>
      <div className="detail-view scroll-box pd-15">
        <div className="mg-b-10 text-right">
          <button
            type="button"
            className="btn btn-primary rounded-30"
            onClick={() => setShowItemWrite(true)}
          >
            <Plus className="mg-r-5" />
            New
          </button>
        </div>
        <div className="card bd-0">
          {/* React-table-v8 */}
          <ListReactTable8 {...listTable8Props} />
          {/* pagination */}
          <div className="card-footer pd-y-6-f pagination bd-0">
            <button
              type="button"
              className={classNames('btn btn-link link-02 btn-icon', {
                disabled: paging && currentPage <= 1,
              })}
              onClick={() => {
                if (paging && currentPage > 1) {
                  goToPage(1);
                }
              }}
              aria-label="move to first"
            >
              {Icon('move_first')}
              <span className="sr-only">처음으로</span>
            </button>
            <button
              type="button"
              className={classNames('btn btn-link link-02 btn-icon', {
                disabled: paging && currentPage <= 1,
              })}
              onClick={() => {
                if (paging && currentPage > 1) {
                  goToPage(currentPage - 1);
                }
              }}
              aria-label="previous"
            >
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </button>
            <button
              type="button"
              className={classNames('btn btn-link link-02 btn-icon', {
                disabled: paging && currentPage >= paging?.totalPage,
              })}
              onClick={() => {
                if (paging && currentPage < paging?.totalPage) {
                  goToPage(currentPage + 1);
                }
              }}
              aria-label="next"
            >
              <ChevronRight />
              <span className="sr-only">다음</span>
            </button>
            <button
              type="button"
              className={classNames('btn btn-link link-02 btn-icon', {
                disabled: paging && currentPage >= paging?.totalPage,
              })}
              onClick={() => {
                if (paging && currentPage < paging?.totalPage) {
                  goToPage(paging?.totalPage);
                }
              }}
              aria-label="move to last"
            >
              {Icon('move_last')}
              <span className="sr-only">마지막으로</span>
            </button>
            <input
              type="number"
              className="paging-input"
              value={currentPage}
              min={1}
              max={paging?.totalPage}
              onChange={(e: any) => {
                goToPage(parseInt(e?.target?.value));
              }}
            />
            / {paging?.totalPage}
          </div>
        </div>
      </div>

      {showItemWrite && (
        <Portal>
          <WriteItem
            title={<SpanLang keyLang={'Create Item'} />}
            size="xl"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={showItemWrite}
            onClose={() => setShowItemWrite(false)}
            onReload={reloadItemUnits}
            product={product}
            menuApi={MENU_PRODUCT_ITEM}
            showCanvas={false}
          />
        </Portal>
      )}
    </>
  );
};

export default ProductItems;
