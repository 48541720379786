import React from 'react';
import { useParams } from 'react-router-dom';

import ProductViewPage from '@product/product/pages/view';
import ItemViewPage from '@product/item/pages/view';

const View: React.FC<any> = (props: any) => {
  const params = useParams();
  const type = params['type'];
  return <>{type == 'product' ? <ProductViewPage /> : <ItemViewPage />}</>;
};
export default View;
