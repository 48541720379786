import * as keyNames from '@product/product/config/key-names';
import * as components from './components';
import validators from '@base/utils/validation/field-validator';
import { PRODUCT_TYPE_PRODUCED } from '@product/main/config/constants';

export default {
  [keyNames.KEY_PRODUCT_ID]: {
    component: components.InputCodeGenerator,
    componentProps: {
      menu: 'product',
      showType: 'canvas',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_NAME]: {
    component: components.Input,
    componentProps: {
      type: 'text',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_PRODUCT_TYPE]: {
    component: components.ProductType,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: { value: PRODUCT_TYPE_PRODUCED, label: 'Produced' },
    parseValue: (valueApi: any) => valueApi || { value: PRODUCT_TYPE_PRODUCED, label: 'Produced' },
    parseParam: (value: any) => value?.value,
  },
  [keyNames.KEY_PRODUCT_GROUP]: {
    component: components.ProductGroupAutoComplete,
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_COST_OF_GOODS]: {
    component: components.ProductCostOfGoods,
    validate: {},
    showFullRow: true,
    defaultValue: 0,
    parseValue: (valueApi: any) => valueApi || 0,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_ACTIVE]: {
    component: components.Switch,
    componentProps: {
      value: true,
    },
    validate: {},
    defaultValue: true,
    parseValue: (valueApi: any) => valueApi || true,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SALE_START_DATE]: {
    component: components.DatePicker,
    validate: {},
    defaultValue: new Date().toISOString(),
    parseValue: (valueApi: any) => valueApi || new Date().toISOString(),
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_SALE_END_DATE]: {
    component: components.UnLimitDatePicker,
    validate: {},
    defaultValue: { dataTime: new Date(), isUnlimit: true },
    parseValue: (valueApi: any) => valueApi || { dataTime: new Date(), isUnlimit: true },
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_ASSIGN_TO]: {
    component: components.UserAutoComplete,
    componentProps: {
      single: false,
      showAvatar: true,
      placement: 'top',
    },
    validate: {},
    showFullRow: true,
    defaultValue: [],
    parseValue: (valueApi: any) => valueApi || [],
    //parseParam: (value: any) => value,
  },
  [keyNames.KEY_BASE_UNIT]: {
    component: components.BaseUnit,
    validate: {
      required: validators.required,
    },
    showFullRow: true,
    defaultValue: null,
    //parseValue: (valueApi) => valueApi || null,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_ATTRIBUTE]: {
    component: components.ProductAttributes,
    validate: {
      //attributes: validators.attribute,
    },
    showFullRow: true,
    defaultValue: { applyItemAttribute: true, attributes: [] },
    parseValue: (valueApi: any) => valueApi || { applyItemAttribute: true, attributes: [] },
    // parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_TYPE_BE_SOLD]: {
    component: components.CanBeSold,
    defaultValue: true,
    showFullRow: true,
    parseValue: (valueApi: any) => valueApi || true,
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_PRODUCT_DESCRIPTION]: {
    component: components.TextArea,
    showFullRow: true,
    parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
};
