import { gql } from 'graphql-request';

export const UPDATE_PRODUCT_UNIT_VALUES = gql`
  mutation m($unitId: string!, $unitValues: [UnitValue!]) {
    product_updateUnitValues(unitId: $unitId, unitValues: $unitValues) {
      unitId
      unitValues {
        id
        name
        qty
      }
    }
  }
`;

export const ADD_PRODUCT_UNIT = gql`
  mutation m($unit: Unit!) {
    product_createUnit(unit: $unit) {
      id
      name
      unitValues {
        id
        name
      }
    }
  }
`;

export const DELETE_PRODUCT_UNIT = gql`
  mutation m($ids: [string]!) {
    product_deleteUnit(ids: $ids) {
      ids
    }
  }
`;

export const GET_UNIT_VALUES = gql`
  query q($filter: SearchFilter) {
    product_unitValues(filter: $filter) {
      results {
        id
        name
        qty
        unit {
          id
          name
        }
        createdBy {
          id
          name
        }
        createdAt
        updatedBy {
          id
          name
        }
        updatedAt
        deletedBy {
          id
          name
        }
        deletedAt
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;
