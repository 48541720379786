import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Button } from '@base/components/form';
import { Plus } from 'react-feather';
import RowItem from './row-item';
import { generateBarCode } from '@base/utils/helpers';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = '', errors = {}, onChange } = props;

  const [barCodes, setBarCodes] = useState<string[]>([]);

  useEffect(() => {
    if (value && JSON.stringify(value) != JSON.stringify(barCodes)) {
      setBarCodes(value);
    }
  }, [value]);

  const handleAddBarCode = () => {
    const newBarCodes = [...barCodes];
    newBarCodes.push(generateBarCode());
    setBarCodes(newBarCodes);

    onChange && onChange(newBarCodes);
  };

  const handleRemoveBarCode = (index: number) => {
    const newBarCodes = [...barCodes];
    newBarCodes.splice(index, 1);
    setBarCodes(newBarCodes);

    onChange && onChange(newBarCodes);
  };

  const handleOnChangeRow = (newItem: any, index: number) => {
    const newBarCodes = [...barCodes];
    newBarCodes[index] = newItem?.barCode ?? '';
    setBarCodes(newBarCodes);

    onChange && onChange(newBarCodes);
  };

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <ul className="list-group list-group-flush">
          {barCodes &&
            barCodes.map((barCode: string, index: number) => {
              return (
                <RowItem
                  key={index}
                  value={barCode}
                  rowIndex={index}
                  onRemove={handleRemoveBarCode}
                  onChange={handleOnChangeRow}
                />
              );
            })}
        </ul>
        <div className="d-flex flex-grow-1 justify-content-between">
          <Button color="link" className="tx-nowrap" onClick={() => handleAddBarCode()}>
            <Plus className="mg-r-5" />
            Add BarCode
          </Button>
        </div>
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
