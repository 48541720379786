import React, { useEffect, useState } from 'react';
import { Select, Switch } from '@base/components/form';
// import { SC_TYPE_OPTIONS } from '@settings/sales-commission/config/constant';
import {
  KEY_ITEM_SALES_COMMISSION,
  KEY_ITEM_SALES_COMMISSION_TYPE,
} from '@product/item/config/key-names';
import classnames from 'classnames';
// import { ESalesCommissionType } from '@settings/sales-commission/types/enums';

const SalesCommission: React.FC<any> = (props: any) => {
  const { value = null, onChange } = props;

  const [options, setOptions] = useState<any[]>(
    // SC_TYPE_OPTIONS?.map((v: any) => ({ ...v, value: v.keyName, label: v.languageKey })),
    []
  );
  const [data, setData] = useState<any>(value);

  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) != JSON.stringify(data)) {
        setData(value);
      }
    } else {
      setData(null);
    }
  }, [value]);

  const onChangeSalesCommission = (e: any) => {
    const newData = { ...data };
    newData[KEY_ITEM_SALES_COMMISSION] = e?.target?.checked;
    setData(newData);
    onChange && onChange(newData);
  };

  const onChangeSalesCommissionType = (newValue: any) => {
    const newData = { ...data };
    newData[KEY_ITEM_SALES_COMMISSION_TYPE] = newValue?.value;
    setData(newData);
    onChange && onChange(newData);
  };

  return (
    <div className={classnames('')}>
      <Switch value={data?.[KEY_ITEM_SALES_COMMISSION]} onChange={onChangeSalesCommission} />
      {data?.[KEY_ITEM_SALES_COMMISSION] && (
        <Select
          className="pd-t-10"
          value={
            // data?.[KEY_ITEM_SALES_COMMISSION_TYPE] === ESalesCommissionType.RESIDUAL
            //   ? {
            //       ...SC_TYPE_OPTIONS[1],
            //       value: SC_TYPE_OPTIONS[1].keyName,
            //       label: SC_TYPE_OPTIONS[1].languageKey,
            //     }
            //   : {
            //       ...SC_TYPE_OPTIONS[0],
            //       value: SC_TYPE_OPTIONS[0].keyName,
            //       label: SC_TYPE_OPTIONS[0].languageKey,
            //     }
            null
          }
          options={options}
          onChange={onChangeSalesCommissionType}
          menuPlacement="auto"
        />
      )}
    </div>
  );
};

export default SalesCommission;
