import { useState, useEffect, useCallback, useMemo } from 'react';
import { Move, Plus, Trash2 } from 'react-feather';
import React from 'react';
import styled from '@emotion/styled';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { makeReactTableColumns } from '@base/components/utils/helpers/react-table';

const Styles = styled.div`
  table {
    table-layout: fixed;
  }
`;

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

/**
 *
 * @param {*} props
 * @returns
 */
const UnitDetail: React.FC<any> = (props: any) => {
  const { baseUnit, value, onChange } = props;

  // state
  const [items, setItems] = useState<any[]>([]);

  // init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(items)) {
        setItems(value);
      }
    } else {
      // setItems([]);
    }
  }, [value]);

  // add new unit name
  const handleAddNew = () => {
    if (baseUnit && baseUnit?.name != '') {
      const newItems = [...items];
      newItems.push({
        baseUnit: baseUnit?.name,
        unitName: '',
        unitQty: '',
        isBase: false,
      });
      setItems(newItems);
      onChange && onChange(newItems);
    }
  };

  //remove
  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //drag start
  const handleDragStart = useCallback((event: any) => {
    //event = item dragging
  }, []);

  //drag end, update new position
  const handleDragEnd = useCallback(
    (result: any) => {
      const { source, destination } = result;
      if (!destination || destination?.index === 0) {
        return;
      }
      // re-order the items
      const newItems = [...items];
      const [removedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removedItem);
      setItems(newItems);

      onChange && onChange(newItems);
    },
    [items, setItems],
  );

  // react-table-v8
  const InputCellv8 = ({ getValue, row: { index }, column: { id }, table }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        className="form-control"
        defaultValue={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        disabled={index === 0}
      />
    );
  };

  const NumberCellv8 = ({ getValue, row: { index }, column: { id }, table }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, parseInt(value));
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        type={'number'}
        className="form-control"
        defaultValue={value as number}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        min={1}
      />
    );
  };

  const DeleteCellv8 = ({ row: { index }, onDelete = () => {} }: any) => {
    if (index === 0) {
      return null;
    }
    return (
      <button
        onClick={() => onDelete(index)}
        type="button"
        className="btn btn-link btn-icon pd-y-0"
      >
        <Trash2 className="tx-danger" />
      </button>
    );
  };

  const columnsTable = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'unitName',
        accessorKey: 'unitName',
        header: () => <span>Unit Name</span>,
        cell: (cell) => {
          return (
            <div className="d-flex flex-1 align-items-center">
              {/* <button
                type="button"
                className="btn btn-xs mg-r-5 pd-0 tx-color-03"
                style={{ cursor: 'grab' }}
              >
                {Icon('move')}
              </button>{' '} */}
              <InputCellv8 {...cell} />
            </div>
          );
        },
      },
      {
        id: 'unitQty',
        accessorKey: 'unitQty',
        header: () => <span>Unit Qty</span>,
        cell: (cell) => {
          return <NumberCellv8 {...cell} />;
        },
      },
      {
        id: 'delete',
        header: () => <span></span>,
        cell: (cell) => {
          return <DeleteCellv8 {...cell} onDelete={handleRemove} />;
        },
        size: 70,
      },
    ],
    [items],
  );

  const table = useReactTable({
    data: items,
    columns: makeReactTableColumns(columnsTable),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        const newItems = [...items];
        newItems[rowIndex][columnId] = value;
        setItems(newItems);
        onChange && onChange(newItems);
      },
    },
    debugTable: true,
  });

  return (
    <Styles>
      <div className="table-responsive bg-white bd mg-b-0">
        <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    style={{
                      width: header?.column?.columnDef?.width ?? header?.column?.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button type="button" className="btn btn-link mg-y-10" onClick={handleAddNew}>
        <Plus className="mg-r-5" />
        Add Unit Name
      </button>
    </Styles>
  );
};

export default UnitDetail;
