import React from 'react';
import { Canvas, Portal } from '@base/components';
import SKUGeneratorFrom from '../sku-generator-form';
/**
 *
 * @param {*} props
 * @returns
 */
const SKUGenerator: React.FC<any> = (props: any) => {
  const {
    isShow,
    data, //{}, input data
    onClose,
    onChange,
    setting,
  } = props;

  //render
  return (
    <Portal>
      <Canvas
        isVisible={isShow}
        onCloseSideBar={onClose}
        className={'page-sidebar-container-wrap wd-800'}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={'SKU Generator'} />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)', overflow: 'auto' }}>
          <SKUGeneratorFrom
            className="pd-10"
            data={data}
            //setting={setting}
            onChange={onChange}
          />
        </Canvas.Body>
        <Canvas.Footer
          closeLabel="Close"
          onClose={onClose}
        //onSave={() => onAdd()}
        //saveLoading={isAdding}
        //saveBtn={renderSaveButton()}
        />
      </Canvas>
    </Portal>
  );
};

export default SKUGenerator;
