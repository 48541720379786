import React, { useEffect, useMemo, useState } from 'react';
import { ViewFields } from '@base/components/hook-form/view';

const TabDetail: React.FC<any> = (props: any) => {
  const { fields, menuSource, menuSourceId, data } = props;

  const View = useMemo(() => {
    return (
      <div className="form-row">
        <ViewFields
          fields={fields}
          column={2}
          ignoreFields={[]}
          // menu={'product'}
          menuSource={menuSource}
          menuSourceId={menuSourceId}
        />
      </div>
    );
  }, [data, fields]);

  return <div className="detail-view scroll-box">{View}</div>;
};

export default TabDetail;
