import { PRODUCT_TYPE_OPTIONS } from '@product/main/config/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

const View: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <div className="d-flex">{PRODUCT_TYPE_OPTIONS?.find((v: any) => v.value === value)?.label}</div>
  );
};

export default View;
