import React, { useEffect, useState } from 'react';
import ProductGroupAutoComplete from '../product-group-auto-complete';

/**
 *
 * @param {*} props
 * @returns
 */
const ProductGroup: React.FC<any> = (props: any) => {
  return <ProductGroupAutoComplete {...props} />;
};

export default ProductGroup;
