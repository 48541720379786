import React, { useEffect, useState } from 'react';
import { ProductAttributeAutoComplete, ProductAddAttribute } from '@product/product/containers';
import classNames from 'classnames';

/**
 *
 * @param {*} props
 * @returns
 */
const ProductAttributes: React.FC = (props: any) => {
  const { className = '', isShowItem = true, value = null, onChange = null } = props;

  //state
  const [attributes, setAttributes] = useState<any[]>([]);
  const [applyItemAttribute, setApplyItemAttribute] = useState<boolean>(true);
  const [showAdd, setShowAdd] = useState(false);

  //set data
  useEffect(() => {
    if (value) {
      const tempAttributes = value?.attributes ?? [];
      const tempApplyItemAttribute = value?.applyItemAttribute ?? true;
      const valueString =
        tempAttributes
          .map((item: any) => item?.value)
          ?.sort()
          ?.join('') ?? '';
      const attributesString =
        attributes
          ?.map((item: any) => item?.value)
          ?.sort()
          ?.join('') ?? '';
      if (valueString !== attributesString) {
        setAttributes(tempAttributes);
      }
      if (applyItemAttribute !== tempApplyItemAttribute) {
        setApplyItemAttribute(tempApplyItemAttribute);
      }
    }
  }, [value]);

  //add new
  const handleAddNew = (attribute: any) => {
    setAttributes((attributes) => attributes.concat([attribute]));
    //callback
    onChange &&
      onChange({
        attributes: attributes.concat([attribute]),
        applyItemAttribute,
      });
  };

  //select new attributes
  const handleSelectChange = (attributes: any) => {
    setAttributes(attributes);
    //callback
    onChange &&
      onChange({
        attributes,
        applyItemAttribute,
      });
  };

  //checked or unchecked
  const handleApplyAttr = (_value: any) => {
    setApplyItemAttribute(_value);
    //callback
    onChange &&
      onChange({
        attributes: _value ? attributes : [],
        applyItemAttribute: _value,
      });
  };

  //render
  return (
    <>
      <div className={classNames("d-flex flex-column", className)}>
        <div className="d-flex justify-content-start align-items-center">
          {isShowItem && (
            <div className="d-flex mg-b-5">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="productAttribute"
                  checked={applyItemAttribute}
                  onChange={(e) => handleApplyAttr(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="productAttribute">
                  Create Item with Attributes
                  {applyItemAttribute && <span className="tx-danger">*</span>}
                </label>
              </div>
            </div>
          )}
        </div>
        {applyItemAttribute && (
          <>
            <ProductAttributeAutoComplete
              {...props}
              value={attributes}
              onChange={handleSelectChange}
              addLabel="Add Attribute"
              onAdd={() => setShowAdd(true)}
            />
          </>
        )}
      </div>
      {showAdd && applyItemAttribute && (
        <ProductAddAttribute
          isShow={showAdd}
          onClose={() => setShowAdd(false)}
          onAdd={(item: any) => handleAddNew(item)}
        />
      )}
    </>
  );
};

export default ProductAttributes;
