import { lazy } from 'react';

const UnitMain = lazy(() => import('./index'));

export default [
  {
    path: '/product/unit/*',
    component: UnitMain,
  },
];
