import { useState, useEffect } from 'react';

/**
 *
 * @param {*} props
 * @returns
 */
const ProductCostOfGoods = (props: any) => {
  const { value = 0, onChange } = props;
  //state
  const [percentage, setPercentage] = useState(0);

  //init data
  useEffect(() => {
    if (value !== percentage) {
      setPercentage(value);
    }
  }, []);

  //value change
  const handleValueChange = (e: any) => {
    setPercentage(e.target.value);
    //callback
    onChange && onChange(e.target.value);
  };

  //render
  return (
    <>
      <div className="d-flex flex-wrap align-items-center">
        Approximatley
        <div className="input-group wd-150 mg-x-10">
          <input
            type="number"
            className="form-control"
            value={percentage}
            onChange={handleValueChange}
          />
          <div className="input-group-append">
            <span className="input-group-text lh-1">%</span>
          </div>
        </div>
        of Base Price
      </div>
      <div className="mg-t-5 tx-orange">
        The Cost to physically produce/purchase/service/delivery
      </div>
    </>
  );
};

export default ProductCostOfGoods;
