import { lazy } from 'react';

const ItemMain = lazy(() => import('.'));

export default [
  {
    path: '/product/item/*',
    component: ItemMain,
  },
];
