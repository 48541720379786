import { KEY_UNIT_NAME } from '../key-names';

export const groupByOptions = [{ label: 'All Units', value: 'all' }];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
];

export const filterByOptions = [];

export const searchFields = [
  {
    name: KEY_UNIT_NAME,
    type: 'text',
    label: 'Base Unit',
    defaultValue: '',
  },
];

export const sortsBy = [
  // { label: 'Created At', value: 'createdAt' },
  // { label: 'Updated At', value: 'updatedAt' },
];
