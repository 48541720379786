import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { WEIGHT_UNIT_OPTIONS } from '@product/main/config/constants';
import usePost from '@base/hooks/usePost';
import { GET_MENU_SETTING } from '@settings/general-setting/services/graphql/format';

/**
 *
 * @param {*} props
 * @returns
 */
const Weight: React.FC<any> = (props: any) => {
  const {
    className,
    value, //{value: 10, option: 1}
    onChange,
  } = props;

  //state
  const [number, setNumber] = useState(0);
  const [option, setOption] = useState<any>(null);

  // GET setting
  const params = {
    menu: 'product',
    key: 'item_measurement',
  };
  const { isLoading, data: dimensionConfig } = usePost<any>(
    ['setting_menuSetting', JSON.stringify(params)],
    GET_MENU_SETTING,
    params,
    { enabled: true },
  );

  useEffect(() => {
    if (dimensionConfig) {
      const valueConfig = JSON.parse(dimensionConfig?.value);
      setOption(
        WEIGHT_UNIT_OPTIONS?.find((v: any) => v.value === valueConfig.weight) ??
          WEIGHT_UNIT_OPTIONS[0],
      );
    }
  }, [dimensionConfig]);

  //set selected items
  useEffect(() => {
    if (value && value?.val) {
      setNumber(value.val);
    }
    if (value && value?.unit) {
      const idx = WEIGHT_UNIT_OPTIONS.findIndex((el: any) => el.value === value?.unit);
      if (idx > -1) {
        setOption(WEIGHT_UNIT_OPTIONS[idx]);
      }
    } else {
      // setOption(WEIGHT_UNIT_OPTIONS[0]);
    }
  }, [value]);

  //number
  const handleNumberChange = (e: any) => {
    setNumber(e.target.value);
    //callback
    onChange && onChange({ val: parseInt(e.target.value), unit: option?.value });
  };

  //option
  const handleOptionChange = (newOption: any) => {
    setOption(newOption);
    //callback
    onChange && onChange({ val: number, unit: newOption?.value });
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <div className="d-flex align-items-center">
        <input
          type="number"
          className="form-control ht-38 mg-r-5"
          value={number}
          onChange={handleNumberChange}
          min={0}
        />
        <Select value={option} options={WEIGHT_UNIT_OPTIONS} onChange={handleOptionChange} />
      </div>
    </div>
  );
};

export default Weight;
