import { PRODUCT_MENUS } from '@base/config/route-menus';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { buildViewSchema } from '@base/utils/helpers/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { TABS } from './tabs';
import { useGetView } from '@base/services/view';
import { default as viewConfig } from '@product/unit/config/view-field';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@product/unit/config/display-layout';
import { useTranslation } from 'react-i18next';
import ViewLeft from '@product/unit/layouts/desktop/layout1/view/view-left';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '@base/containers/view-field/title';
import { PRODUCT_ADD_OPTIONS } from '@product/main/config';
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';

import WritePageProduct from '@product/product/pages/write';
// Write Item
import WritePageItem from '@product/item/pages/write';
// Write Component
// import WritePageComponent from '@product/component/pages/write';
// Write Unit
import WritePageUnit from '@product/unit/pages/write';
// Write Price List
// import WritePagePriceList from '@product/price-list/pages/write';
// Write Discount
// import WritePageDiscount from '@product/discount/pages/write';
import { SpanLang } from '@base/components';
import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import Loading from '@base/components/loading';
import NotFound from '@base/components/errors/notFound';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_PRODUCT_UNIT } from '@product/unit/services/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device,  init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { t } = useTranslation();
  const { isSplitMode } = props;
  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'unit';

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const pageData = useRecoilValue(pageDataByMenuAtom(MENU_PRODUCT_UNIT));
  const { activeMenu, activeId, activeTab } = pageData;

  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';

  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_PRODUCT_UNIT));

  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  /** Get data */
  const layoutMenu = 'product_units';
  const { data: layoutView, isLoading: layoutLoading } = usePageLayoutByMenu(layoutMenu, 'view');
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    error,
    data: unit,
    isFetching: dataLoading,
    isPreviousData,
    refetch,
  } = useGetView<any>({ menu: 'product_unit', schemas: viewSchema, params: { id: id } });

  useEffect(() => {
    if (unit && !layoutLoading && !isLoading) {
      const layoutData = mergeLayoutData(layoutView, unit, viewConfig);

      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menuSource = MENU_PRODUCT_UNIT;
      newLayoutData.data = unit;
      setViewData(newLayoutData);
    }

    return () => {};
  }, [unit, layoutView, layoutLoading, isLoading]);

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT_UNIT,
    'product_deleteUnit',
    {
      onMutate: () => {},
      onSuccess: (data: any, variables: any, context: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['product_units']);
        }, 500);
        return navigate(`/${menu}/${category}`);
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete unit failed: ' + JSON.parse(error).message);
      },
    },
  );

  const View = useMemo(() => {
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: category, //Write form - MENU_PRODUCT_UNIT, list categories - category
        menus: PRODUCT_MENUS,
        onMenuChange: (newCategory: string) => {
          return navigate(`/${menu}/${newCategory}`);
        },
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        // onClone: () => {},
        // addOptions: PRODUCT_ADD_OPTIONS,
        onNew: (category: string | undefined) => {
          setWriteOption((filter: any) => ({
            ...filter,
            isOpenWrite: true,
            writeType: category === undefined ? MENU_PRODUCT_UNIT : category,
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName="name"
              value={unit?.name}
              userPermission={{
                isEdit: false,
              }}
              onSave={(params: any) => {}}
              menuSourceId={id}
              menuSource={MENU_PRODUCT_UNIT}
            />
          </>
        ),
      },
      centerProps: {
        menuSource: MENU_PRODUCT_UNIT,
        menuSourceId: id,
        tabs: TABS,
        currentTab: currentTab,
      },
      componentLeft: <ViewLeft id={id} category={MENU_PRODUCT_UNIT} />,
      leftProps: {},
      componentRight: null,
      rightProps: {},
    };

    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
          <WritePageProduct
            title={<SpanLang keyLang={'Create Product'} />}
            size="md"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            menuApi={MENU_PRODUCT_PRODUCT}
          />
        )}

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
          <WritePageItem
            title={<SpanLang keyLang={'Create Item'} />}
            size="xl"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={reloadItemUnits}
            product={null}
            menuApi={MENU_PRODUCT_ITEM}
          />
        )}

        {/* {writeOption.isOpenWrite && writeOption.writeType === 'component' && (
          <WritePageComponent
            title={<SpanLang keyLang={'Create Component'} />}
            size="md"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={reloadItemUnits}
            menuApi={MENU_PRODUCT_COMPONENT}
          />
        )} */}

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_UNIT && (
          <WritePageUnit
            title={<SpanLang keyLang={'Create Unit'} />}
            size="md"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            menuApi={MENU_PRODUCT_UNIT}
          />
        )}

        {/* {writeOption.isOpenWrite && writeOption.writeType === 'price-list' && (
          <WritePagePriceList
            title={<SpanLang keyLang={'Create Price List'} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            menuApi={MENU_PRICELIST}
          />
        )} */}

        {/* {writeOption.isOpenWrite && writeOption.writeType === 'discount' && (
          <WritePageDiscount
            title={<SpanLang keyLang={'Create Discount'} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            menuApi={MENU_DISCOUNT}
          />
        )} */}
      </>
    );
  }, [id, category, unit, currentTab, writeOption, fullScreen]);

  return <>{isLoading || layoutLoading ? <Loading /> : unit ? View : <NotFound />}</>;
};
export default ViewPage;
