import * as keyNames from '@product/product/config/key-names';
import * as commonConfig from '@base/config/view-field';
import * as components from './components';
import * as commonComponents from '@base/config/view-field/components';
import { t } from 'i18next';
import validators from '@base/utils/validation/field-validator'; //all validate functions
import { IFieldConfig } from '@base/types/interfaces/common';

const Config: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_PRODUCT_TYPE]: {
    component: components.ProductTypeView,
    componentProps: {},
    schema: keyNames.KEY_PRODUCT_TYPE,
  },
  [keyNames.KEY_PRODUCT_GROUP]: {
    schema: `group {
      id
      name
    }`,
    component: components.ProductGroupView,
    getValue: (value: any) => {
      return { ...value?.group, value: value?.group?.id, label: value?.group?.name };
    },
    // getValueView: (value: any) => {
    //   return t(value?.name ?? '');
    // },
    getValueEdit: (value: any) => {
      return value;
    },
    getMutationValue: (value: any) => {
      return {
        group: {
          id: value?.id,
          name: value?.name,
        },
      };
    },
    getChangedValue: (value: any) => {
      return {
        id: value?.id,
        name: value?.name,
      };
    },
  },
  [keyNames.KEY_PRODUCT_ACTIVE]: {
    component: commonComponents.Switch,
    schema: keyNames.KEY_PRODUCT_ACTIVE,
  },
  [keyNames.KEY_PRODUCT_TYPE_BE_SOLD]: {
    component: commonComponents.Switch,
    schema: keyNames.KEY_PRODUCT_TYPE_BE_SOLD,
    viewProps: {
      userPermission: { isEdit: false, isShow: true },
    },
  },
  [keyNames.KEY_SALE_START_DATE]: {
    component: commonComponents.DateView,
    schema: keyNames.KEY_SALE_START_DATE,
  },
  [keyNames.KEY_SALE_END_DATE]: {
    component: commonComponents.UnLimitDateTimeView,
    schema: `noEndDate
    endDate
    `,
    getValue: (value: any) => {
      return {
        isUnlimit: value?.noEndDate,
        dataTime: value?.noEndDate ? new Date() : new Date(value?.endDate),
      };
    },
    getMutationValue: (value: any) => {
      return {
        noEndDate: value?.isUnlimit,
        endDate: value?.isUnlimit ? '0001-01-01T00:00:00Z' : value?.dataTime,
      };
    },
  },
  [keyNames.KEY_BASE_UNIT]: {
    component: components.ProductBaseUnitView,
    schema: `unit {
      id
      name
      unitValues {
        id
        name
        qty
      }
    }`,
    getValue: (value: any) => {
      return value?.unit;
    },
    getValueView: (value: any) => {
      return value?.name ?? '';
    },
    getValueEdit: (value: any) => {
      return { ...value, value: value.id, label: value.name };
    },
  },
  [keyNames.KEY_PRODUCT_ATTRIBUTE]: {
    component: components.ProductAttributesView,
    componentProps: {
      single: false,
    },
    schema: `useAttr
    attributes {
      id
      name
      values {
        id
        name
      }
    }`,
    getValue: (value: any) => {
      return {
        useAttr: value?.useAttr,
        attributes:
          value?.attributes?.length > 0
            ? value?.attributes?.map((_ele: any) => ({
                id: _ele?.id,
                name: _ele?.name,
                value: _ele?.id,
                label: _ele?.name,
              }))
            : [],
      };
    },
    getValueView: (value: any) => {
      return value && value?.useAttr
        ? value?.attributes?.length > 0
          ? value?.attributes?.map((item: any) => item?.name ?? '').join(', ')
          : ''
        : 'No use attribute';
    },
    getValueEdit: (value: any) => {
      return {
        attributes: value?.attributes
          ? value?.attributes?.map((item: any) => ({ ...item, value: item.id, label: item.name }))
          : [],
        applyItemAttribute: value?.useAttr,
      };
    },
    getMutationValue: (value: any) => {
      return {
        useAttr: value?.applyItemAttribute,
        attributes:
          value?.attributes?.length > 0
            ? value?.attributes?.map((item: any) => ({
                id: item?.value,
                name: item?.label,
              }))
            : [],
      };
    },
    getChangedValue: (value: any) => {
      return {
        useAttr: value?.applyItemAttribute,
        attributes:
          value?.attributes?.length > 0
            ? value?.attributes?.map((item: any) => ({
                id: item?.value,
                name: item?.label,
              }))
            : [],
      };
    },
  },
  [keyNames.KEY_ASSIGN_TO]: {
    component: commonComponents?.AssignRepView,
    componentProps: {
      placement: 'top',
    },
    schema: `assignTo {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        assignTo:
          value && value?.length > 0
            ? value?.map((item: any) => ({ user: { id: item.id, name: item.name }, group: null }))
            : [],
      };
    },
  },
  [keyNames.KEY_VENDOR]: {
    component: components.CustomerView,
    componentProps: {
      single: false,
    },
    schema: `vendors {
      id
      code
      name
    }`,
    getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value?.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        vendors:
          value && value?.length > 0
            ? value?.map((item: any) => ({ linkId: item.id, linkName: item.name }))
            : [],
      };
    },
    getChangedValue: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_PRODUCT_MANUFACTURERS]: {
    component: components.CustomerView,
    componentProps: {
      single: false,
    },
    schema: `manufacturers {
      id
      code
      name
    }`,
    getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value?.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        manufacturers:
          value && value?.length > 0
            ? value?.map((item: any) => ({ linkId: item.id, linkName: item.name }))
            : [],
      };
    },
    getChangedValue: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_PRODUCT_COUNTRY_ORIGIN]: {
    component: components.CountryOriginView,
    schema: keyNames.KEY_PRODUCT_COUNTRY_ORIGIN,
    getMutationValue: (value: any) => {
      return {
        countryOrigin: value?.value,
      };
    },
    getChangedValue: (value: any) => {
      return value?.value;
    },
  },
  [keyNames.KEY_PRODUCT_WARRANTY_PERIOD]: {
    schema: `warrantyPeriod {
      unit
      period
    }`,
    component: components.ProductWarrantyView,
    getValue: (value: any) => {
      return value?.warranty;
    },
    getMutationValue: (value: any) => {
      return {
        warranty: value,
      };
    },
  },
  [keyNames.KEY_PRODUCT_ITEMS]: {
    schema: `items {
      id
      name
    }`,
    component: null,
  },
  [keyNames.KEY_PRODUCT_DESCRIPTION]: {
    schema: keyNames.KEY_PRODUCT_DESCRIPTION,
    component: commonComponents.TextAreaView,
  },
  [keyNames.KEY_PRODUCT_COST_OF_GOODS]: {
    schema: keyNames.KEY_PRODUCT_COST_OF_GOODS,
    component: components.ProductCostOfGoodsView,
  },
};

export default Config;
