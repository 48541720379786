import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import AttributeAutoComplete from '@product/product/containers/attributes-select/auto-complete';
import ProductAddAttribute from '@product/product/containers/attributes-select/add-attribute';
import { Plus, Minus } from 'react-feather';
import { Select } from '@base/components/form';

/**
 *
 * @param {*} props
 * @returns
 */
const ItemAttributes: React.FC<any> = (props: any) => {
  const { value, onChange, attributes = [] } = props;

  //default item
  const defaultAttribute = {
    id: '',
    name: '',
    values: [], //attribute values
    activeValue: [], //selected values
    productAttribute: false,
  };

  //state
  const [editAttribute, setEditAttribute] = useState<any>(null);
  const [editRowIndex, setEditRowIndex] = useState(-1);
  const [attributeValue, setAttributeValue] = useState<any>({
    applyItemAttribute: false,
    attributes: [],
  });
  const [showAdd, setShowAdd] = useState(false); //add attribute
  //const [cacheOptions, setCacheOptions] = useState(null);

  //init data
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(attributeValue)) {
        setAttributeValue(value);
      }
    } else {
      setAttributeValue({ applyItemAttribute: false, attributes: [] });
    }
  }, [JSON.stringify(value.attributes)]);

  //// console.log('attributeValue', attributeValue);

  //add empty attribute
  const handleAddItemAttribute = () => {
    const newAttributeValue = { ...attributeValue };
    newAttributeValue.attributes.push(defaultAttribute);
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //remove an attribute
  const handleRemoveItemAttribute = (index: number) => {
    const newAttributeValue = { ...attributeValue };
    newAttributeValue.attributes.splice(index, 1);
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //attribute change
  const handleAttributeChange = (newAttribute: any, index: number) => {
    const newAttributeValue = { ...attributeValue };
    //set attribute values
    setEditAttribute(newAttribute);
    //more info
    newAttribute.productAttribute = false;
    let newValues: any = [];
    if (newAttribute?.values) {
      newAttribute.values.map((_child: any) => {
        newValues.push({
          ..._child,
          value: _child.id,
          label: _child.name,
        });
      });
      newAttribute.values = newValues;
    }
    //check exist
    const existIdx = newAttributeValue.attributes.findIndex((el: any) => el.id === newAttribute.id);
    if (existIdx === -1) {
      newAttribute.activeValue = newValues;
    } else {
      //update to current list
      newAttribute.is_existed = true;
      newAttribute.activeValue = [];
    }
    newAttributeValue.attributes[index] = newAttribute;
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //selected attribute values
  const handleAttributeValueChange = (newValue: any, index: number) => {
    const newAttributeValue = { ...attributeValue };
    newAttributeValue.attributes[index].activeValue = newValue || [];
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //show add attribute canvas
  const handleShowAddAttribute = (index: number) => {
    setEditRowIndex(index);
    setEditAttribute(null);
    setShowAdd(true);
  };

  //add new value to attribute
  const handleShowAddValue = (curAttribute: any) => {
    setEditAttribute(curAttribute);
    setShowAdd(true);
  };

  //set new attribute
  const handleAddNewAttribute = (newAttribute: any) => {
    const newAttributeValue = { ...attributeValue };
    //more info
    newAttribute.productAttribute = false;
    if (newAttribute?.values) {
      const newValue: any = [];
      newAttribute.values.map((_child: any) => {
        newValue.push({
          ..._child,
          value: _child.id,
          label: _child.name,
        });
      });
      newAttribute.values = newValue;
      newAttribute.activeValue = newValue;
    }
    newAttributeValue.attributes[editRowIndex] = newAttribute;
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //add new value to current attribute
  const handleAddNewAttributeValue = (newValue: any, isApply: boolean) => {
    const newAttributeValue = { ...attributeValue };
    const idx = newAttributeValue.attributes.findIndex((el: any) => el.id === newValue.id);
    //update value
    if (newValue?.values) {
      //reset value options and active value
      const newChildValues: any = [];
      const newActiveValues = newValue.activeValue ? [...newValue.activeValue] : [];
      newValue.values.map((_child: any) => {
        //check new value
        const valIdx = newActiveValues.findIndex((_ele) => _ele.id === _child.id);
        if (valIdx === -1) {
          //add active value
          newActiveValues.push({
            ..._child,
            value: _child.id,
            label: _child.name,
          });
        }
        //add value, label for values
        newChildValues.push({
          ..._child,
          value: _child.id,
          label: _child.name,
        });
      });
      //apply selected value
      if (isApply) {
        newValue.activeValue = newActiveValues;
      }
      newValue.values = newChildValues;
    }
    newAttributeValue.attributes[idx] = newValue;
    setAttributeValue(newAttributeValue);
    //callback
    onChange && onChange(newAttributeValue);
  };

  //render attribute and its' options
  const renderAttributes = () => {
    return attributeValue.attributes.map((_item: any, index: number) => {
      _item.value = _item.id;
      _item.label = _item.name;
      return (
        <div key={index} className="row row-xs">
          {/* attribute name */}
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-item-title">Attribute Name</label>
              <div
                className={classNames('', {
                  'mg-t-5': !_item.productAttribute,
                  'mg-t-10': _item.productAttribute,
                })}
              >
                {_item.productAttribute && <span>{_item.name}</span>}
                {/* for single select */}
                {!_item.productAttribute && (
                  <>
                    <AttributeAutoComplete
                      single={true}
                      value={_item}
                      onChange={(newValue: any) => handleAttributeChange(newValue, index)}
                      onAdd={() => handleShowAddAttribute(index)}
                      options={attributes}
                    />
                    {_item.is_existed && (
                      <p className="tx-orange">This attribute name is selected.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* attribute values */}
          <div className="col-lg-6">
            <div className="form-group">
              <div className="d-flex">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="d-flex mg-b-5">
                      <label className="form-item-title">
                        Value<span className="tx-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Select
                      key={_item.id}
                      isMulti
                      outSide={true}
                      isDisabled={_item.is_existed} //cannot select values
                      options={_item.values ?? []}
                      value={_item.activeValue}
                      onChange={(newValue: any) => handleAttributeValueChange(newValue, index)}
                      addLabel="Add Attribute Value"
                      onAdd={() => handleShowAddValue(_item)}
                    />
                  </div>
                </div>
                {!_item.productAttribute && (
                  <div className="d-flex mg-t-30">
                    <button
                      type="button"
                      className="btn btn-icon tx-danger"
                      aria-label="delete"
                      onClick={() => handleRemoveItemAttribute(index)}
                    >
                      <Minus />
                      <span className="sr-only">삭제</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  //render
  return (
    <>
      <div className="form-col">
        <div className="pos-relative mg-b-20">
          {attributeValue.attributes.length === 0 ? (
            <div className={'tx-danger'}>Pls choose Product Attributes first</div>
          ) : (
            renderAttributes()
          )}
          {/* add button */}
          {attributeValue.attributes.length > 0 && (
            <div className="col-12">
              <button
                type="button"
                className="btn btn-link"
                aria-label="add"
                onClick={handleAddItemAttribute}
              >
                <Plus className="mg-r-5" />
                Add a attribute
              </button>
              <div className="mg-t-5 tx-orange">
                상품의 Attributes가 자동으로 적용됩니다. Attribute 추가 시 상품 정보에 자동
                적용됩니다
              </div>
            </div>
          )}
        </div>
      </div>
      {showAdd && (
        <ProductAddAttribute
          isShow={showAdd}
          onClose={() => setShowAdd(false)}
          defaultValue={editAttribute}
          onAdd={handleAddNewAttribute}
          onAddValue={handleAddNewAttributeValue}
        />
      )}
    </>
  );
};

export default ItemAttributes;
