import { lazy } from 'react';

const MainPage = lazy(() => import('@product/main/pages'));

export default [
  {
    path: '/product/*',
    component: MainPage,
  },
];
