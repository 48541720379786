import React, { useEffect, useMemo, useState } from 'react';
import { Switch } from '@base/components/form';
import { KEY_ITEM_SALES_COMMISSION } from '@product/item/config/key-names';
// import { SC_TYPE_OPTIONS } from '@settings/sales-commission/config/constant';
import { t } from 'i18next';

const View: React.FC = (props: any) => {
  const { value } = props;

  return (
    <div className="d-flex flex-column">
      <Switch value={value?.[KEY_ITEM_SALES_COMMISSION]} />
      {value?.[KEY_ITEM_SALES_COMMISSION] && (
        <span className="pd-t-10">
          Sales commission Type:<b>{''}</b>
        </span>
      )}
    </div>
  );
};

export default View;
