import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Minus, Plus, Copy } from 'react-feather';
import { Button, Input, Select } from '@base/components/form';
import { moneyFormat } from '@base/utils/helpers/general.utils';

import { useRecoilValue } from 'recoil';
import { currenciesAtom } from '@base/recoil/atoms';
import { defaultCurrencySelector } from '@base/recoil/selectors';

const BaseCurrency: React.FC<any> = (props: any) => {
  const { rowIndex, removable, value = null, onChange, onCopyAll } = props;

  const currencies: any = useRecoilValue(currenciesAtom);
  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);

  //state
  const [currencyOptions, setCurrencyOptions] = useState<any>([]);
  const [prices, setPrices] = useState<any>([]);
  //default value
  const defaultPrice = {
    value: 0,
    currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
  };

  //init currency options
  const initOptions = (currencyList: any) => {
    let newOptions: any = [];
    currencyList.map((item: any) => {
      const newItem = {
        label: item.code, //item.currency_name,
        value: item.code,
        ...item,
      };
      newOptions.push(newItem);
    });
    setCurrencyOptions(newOptions);
  };

  //init currency
  useEffect(() => {
    //init currency
    if (currencies && currencies.length) {
      initOptions(currencies);
    } else {
      //loadCurrency();
    }
  }, []);

  //init default value
  useEffect(() => {
    if (value) {
      if (value.length > 0) {
        if (JSON.stringify(value) !== JSON.stringify(prices)) {
          setPrices(value);
        }
      }
    } else {
      setPrices([_.cloneDeep(defaultPrice)]);
    }
  }, [value]);

  //value change
  const handleValueChange = (index: number, keyName: string, value: any) => {
    const newPrices = [...prices];
    newPrices[index][keyName] = value;
    setPrices(newPrices);
    //callback
    onChange && onChange(newPrices);
  };

  //remove item
  const handleRemovePrice = (index: number) => {
    const newPrices = [...prices];
    newPrices.splice(index, 1);
    setPrices(newPrices);
    //callback
    onChange && onChange(newPrices);
  };

  //add new price
  const handleAddPrice = () => {
    const newPrices = [...prices];
    newPrices.push(_.cloneDeep(defaultPrice));
    setPrices(newPrices);
    //callback
    onChange && onChange(newPrices);
  };

  //render
  return (
    <ul className="list-group list-group-flush">
      {prices?.map((ele: any, index: number) => (
        <li
          key={index}
          className={classNames(
            'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
            { 'pd-t-0': index === 0 },
          )}
        >
          <div className="d-flex flex-column">
            <div className="d-flex">
              <div className="wd-200 mg-r-5">
                <Input
                  type="money"
                  value={moneyFormat(ele.value || 0)}
                  onChange={(value: string) => handleValueChange(index, 'value', value)}
                />
              </div>
              <Select
                className="wd-150-f flex-shrink-0 mg-r-5"
                outSide={true}
                value={ele.currency}
                options={currencyOptions}
                onChange={(option: any) => handleValueChange(index, 'currency', option)}
              />
              {prices?.length > 1 && (
                <Button
                  color="link"
                  className="tx-danger mg-l-5"
                  onClick={() => handleRemovePrice(index)}
                >
                  <Minus className="mg-r-5" />
                </Button>
              )}
            </div>
            {index === prices?.length - 1 && (
              <div className="d-flex justify-content-between mg-t-5">
                <Button color="link" onClick={() => handleAddPrice()}>
                  <Plus className="mg-r-5" />
                  Add amount
                </Button>
                {rowIndex === 0 && removable && (
                  <Button
                    color="link"
                    onClick={() => {
                      onCopyAll && onCopyAll();
                    }}
                  >
                    <Copy className="mg-r-5" />
                    Copy All
                  </Button>
                )}
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default BaseCurrency;
