import React, { useEffect, useState } from 'react';
import { PRODUCT_TYPE_PRODUCED, PRODUCT_TYPE_PURCHASE } from '@product/main/config/constants';
import { Select } from '@base/components/form';

/**
 *
 * @param {*} props
 * @returns
 */
const ProductType: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  return (
    <>
      <Select
        isSearchable={false}
        options={[
          {
            value: PRODUCT_TYPE_PRODUCED,
            label: 'Produced',
          },
          { value: PRODUCT_TYPE_PURCHASE, label: 'Purchased' },
        ]}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default ProductType;
