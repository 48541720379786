import { identity } from 'lodash';
import { gql } from 'graphql-request';

export const GET_PRODUCTS_LIST = gql`
  query q($filter: SearchFilter) {
    product_products(filter: $filter) {
      results {
        id
        type
        canBeSold
        name
        group {
          id
          name
        }
        code
        # startDate
        # noEndDate
        # endDate
        createdAt
        active
        updatedAt
        unit {
          id
          name
          unitValues {
            id
            name
            qty
          }
        }
        useAttr
        attributes {
          id
          name
          values {
            id
            name
          }
        }
        countItems
        createdBy {
          id
          name
        }
        # costOfGoods
        updatedBy {
          id
          name
        }
        assignTo {
          id
          name
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PUBLIC_PRODUCTS_LIST = gql`
  query q($filter: SearchFilter, $token: String) {
    tracking_products(filter: $filter, token: $token) {
      results {
        id
        type
        canBeSold
        name
        group {
          id
          name
        }
        code
        # startDate
        # noEndDate
        # endDate
        createdAt
        active
        updatedAt
        unit {
          id
          name
          unitValues {
            id
            name
            qty
          }
        }
        useAttr
        attributes {
          id
          name
          values {
            id
            name
          }
        }
        countItems
        createdBy {
          id
          name
        }
        # costOfGoods
        updatedBy {
          id
          name
        }
        assignTo {
          id
          name
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PRODUCT_ATTRS = gql`
  query q($filter: SearchFilter) {
    product_attributes(filter: $filter) {
      results {
        id
        name
        values {
          id
          name
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PRODUCT_STAFFS = gql`
  query q($prodId: String!) {
    product_assignTo(prodId: $prodId) {
      results {
        id
        name
      }
    }
  }
`;

export const CREATE_PRODUCT_ASSIGN_REP = gql`
  mutation m($ids: [String], $assignTo: AssignToInput) {
    product_createAssignTo(ids: $ids, assignTo: $assignTo) {
      ids
      assignTo {
        user {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_ASSIGN_REP = gql`
  mutation m($ids: [String!], $refIds: [String!]) {
    product_deleteAssignTo(ids: $ids, refIds: $refIds) {
      ids
      refIds
    }
  }
`;

export const CHANGE_PRODUCT_ASSIGN_REP = gql`
  mutation m($ids: [String!], $from: String!, $to: String!) {
    product_changeAssignTo(ids: $ids, from: $from, to: $to) {
      ids
    }
  }
`;

export const GET_PRODUCT_BASE_UNIT = gql`
  query q($filter: SearchFilter) {
    product_units(filter: $filter) {
      results {
        id
        name
        active
        createdBy {
          id
          name
        }
        createdAt
        updatedBy {
          id
          name
        }
        updatedAt
        deletedBy {
          id
          name
        }
        deletedAt
        deleted
        unitValues {
          id
          unit {
            id
            name
          }
          name
          qty
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PRODUCT_ITEM_UNITS = gql`
  query q($filter: SearchFilter) {
    product_items(filter: $filter) {
      results {
        id
        code
        name
        sku
        prod {
          id
          name
          type
          canBeSold
        }
        unit {
          id
          name
        }
        unitVal {
          id
          name
          qty
        }
        attrValues {
          id
          name
          attr {
            id
            name
          }
        }
        weight {
          unit
          val
        }
        warrantyPeriod {
          unit
          period
        }
        dimension {
          unit
          val {
            x
            y
            z
          }
        }
        inventoryType
        itemType
        prices {
          amount
          currency
        }
        basePrices {
          amount
          currency
        }
        costPrices {
          amount
          currency
        }
        bestPrices {
          amount
          currency
        }
        purchasePrices {
          amount
          currency
        }
        createdAt
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const PRODUCT_ATTRIBUTE_CREATE = gql`
  mutation m($attribute: Attribute) {
    product_createAttribute(attribute: $attribute) {
      id
      name
      values {
        id
        name
      }
    }
  }
`;

export const PRODUCT_ATTRIBUTE_UPDATE = gql`
  mutation m($attribute: Attribute) {
    product_updateAttribute(attribute: $attribute) {
      id
    }
  }
`;

export const PRODUCT_ATTRIBUTE_VALUES_UPDATE = gql`
  mutation m($attrId: String!, $values: [AttributeValue]) {
    product_updateAttributeValues(attrId: $attrId, values: $values) {
      attrId
      values {
        id
        name
      }
    }
  }
`;

export const PRODUCT_ATTRIBUTE_VALUE_UPDATE = gql`
  mutation m($value: AttributeValue!) {
    product_updateAttributeValue(value: $value) {
      id
    }
  }
`;

export const PRODUCT_ATTRIBUTE_DELETE = gql`
  mutation q($ids: [String]) {
    product_deleteAttribute(ids: $ids) {
      ids
    }
  }
`;

export const PRODUCT_ATTRIBUTE_VALUE_CREATE = gql`
  mutation m($attrId: String!, $values: [AttributeValue]) {
    product_createAttributeValues(attrId: $attrId, values: $values) {
      values {
        id
        name
      }
    }
  }
`;

export const ADD_PRODUCT_ATTRIBUTE_VALUES = gql`
  mutation m($attrId: String!, $values: [AttributeValue]) {
    createAttributeValues(attrId: $attrId, values: $values) {
      results {
        id
        name
      }
    }
  }
`;

export const PRODUCT_ATTRIBUTE_VALUE_DELETE = gql`
  mutation m($id: String, $attrId: String) {
    product_deleteAttributeValue(id: $id, attrId: $attrId) {
      id
    }
  }
`;

export const ADD_NEW_PRODUCT = gql`
  mutation q($product: ProductInput!) {
    product_createProduct(product: $product) {
      id
    }
  }
`;

export const ADD_PRODUCT_BOM = gql`
  mutation m($bom: BOM!) {
    product_createBom(bom: $bom) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_BATCH = gql`
  mutation m($products: [Product!]) {
    product_updateProductBatch(products: $products) {
      ids
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation m($ids: [String!]) {
    product_deleteProduct(ids: $ids) {
      ids
    }
  }
`;

export const CLONE_PRODUCT = gql`
  mutation m($ids: [String!]) {
    product_copyProduct(ids: $ids) {
      ids
    }
  }
`;

export const RESTORE_PRODUCT = gql`
  mutation m($ids: [String!]!) {
    product_restoreProduct(ids: $ids) {
      ids
    }
  }
`;

export const EMPTY_PRODUCT = gql`
  mutation m($ids: [String!]!) {
    product_deleteRecycleBin(ids: $ids) {
      ids
    }
  }
`;

export const PRODUCT_GROUPS_GET = gql`
  query q($filter: FilterInput) {
    product_groups(filter: $filter) {
      results {
        id
        name
        parent {
          id
          name
        }
        countProducts
        updatedBy {
          id
          name
        }
        createdAt
        updatedAt
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PRODUCT_VENDOR = gql`
  query q($id: String!) {
    product_product(id: $id) {
      id
      name
      vendors {
        id
        name
        code
        photo
        type {
          languageKey
          keyName
        }
        emails {
          id
          label {
            languageKey
            label
          }
          labelValue
          email
        }
        phones {
          id
          label {
            languageKey
            label
          }
          labelValue
          country
          phoneNumber
          extension
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation m($product: Product!) {
    product_updateProduct(product: $product) {
      id
    }
  }
`;

export const GET_PRODUCT_ATTR_VALUES = gql`
  query q($filter: SearchFilter) {
    product_attributeValues(filter: $filter) {
      results {
        id
        name
        attr {
          id
          name
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;

export const GET_PRODUCT_VIEW = gql`
  query q($id: string) {
    product_product(id: $id) {
      id
      type
      canBeSold
      name
      group {
        id
        name
      }
      code
      active
      unit {
        id
        name
        unitValues {
          id
          name
          qty
        }
      }
      useAttr
      attributes {
        id
        name
        values {
          id
          name
        }
      }
      assignTo {
        id
        name
      }
    }
  }
`;

export const GET_PUBLISH_PRODUCT_LIST = gql`
  query q($filter: SearchFilter) {
    product_products(filter: $filter) {
      results {
        id
        type
        canBeSold
        name
        group {
          id
          name
        }
        unit {
          id
          name
          unitValues {
            id
            name
            qty
          }
        }
        useAttr
        attributes {
          id
          name
          values {
            id
            name
          }
        }
        items {
          id
          name
        }
      }
      paging {
        totalPage
        totalItems
        currentPage
      }
    }
  }
`;
