// product
export const PRODUCT_NONE = 'none';
export const PRODUCT_TYPE_PRODUCED = 'PRODUCT_TYPE_PRODUCED'; //SUPPLY_METHOD
export const PRODUCT_TYPE_PURCHASE = 'PRODUCT_TYPE_PURCHASE'; //SUPPLY_METHOD
export const PRODUCT_UNIT_TYPE_GENERAL = 'general';
export const PRODUCT_UNIT_TYPE_SUBSCRIPTION = 'subscription';
export const PRODUCT_ITEM_TYPE_GENERAL = 'general';
export const PRODUCT_ITEM_TYPE_BUNDLE = 'bundle';
export const PRODUCT_ITEM_TYPE_PREPAID = 'prepaid';
export const PRODUCT_ITEM_CATEGORY_INVENTORY = 'inventory';
export const PRODUCT_ITEM_CATEGORY_NON_INVENTORY = 'non_inventory';
export const PRODUCT_PREPAID_TYPE_PER_ITEM = 'perItem';
export const PRODUCT_PREPAID_TYPE_AMOUNT = 'amount';

export const PRODUCT_ITEM_TYPE_ENUM_GENERAL = 'ITEM_TYPE_GENERAL';
export const PRODUCT_ITEM_TYPE_ENUM_BUNDLE = 'ITEM_TYPE_COMPOSITE';
export const PRODUCT_ITEM_TYPE_ENUM_PREPAID = 'ITEM_TYPE_PREPAID';

//price list
export const PRODUCT_PRICE_TYPE_STATIC = 'static';
export const PRODUCT_PRICE_TYPE_TIME_BASED = 'timebased';
export const PRODUCT_PRICE_LIST_RULE_MORE = 'more';
export const PRODUCT_PRICE_LIST_RULE_LESS = 'less';
export const PRODUCT_PRICE_LIST_SELECT_ALL = 'all';
export const PRODUCT_PRICE_LIST_SELECT_ITEMS = 'items';
export const PRODUCT_PRICE_LIST_RECUR_METHOD_HOURLY = 'h'; //hourly
export const PRODUCT_PRICE_LIST_RECUR_METHOD_DAILY = 'd'; //daily
export const PRODUCT_PRICE_LIST_RECUR_METHOD_WEEKLY = 'w'; //weekly
export const PRODUCT_PRICE_LIST_RECUR_METHOD_MONTHLY = 'm'; //monthly
export const PRODUCT_PRICE_LIST_RECUR_METHOD_YEARLY = 'y'; //yearly
export const PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_DAY = 'day';
export const PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_WEEK = 'week';
export const PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_LAST_DAY = 'last';
export const PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO = 'auto';
export const PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL = 'manual';
export const PRODUCT_STATUS_ON_SALES = 1;
export const PRODUCT_STATUS_SUSPENDED = 2;
export const PRODUCT_STATUS_TO_BE_RELEASED = 3;
export const PRODUCT_STATUS_DISCONTINUED = 4;

// Define value of key
export const PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO_VALUE = 'PRICELIST_APPLY_AUTO';
export const PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL_VALUE = 'PRICELIST_APPLY_MANUAL';
export const PRODUCT_PRICE_LIST_RULE_MORE_VALUE = 'PRICELIST_RULE_COND_MORE';
export const PRODUCT_PRICE_LIST_RULE_LESS_VALUE = 'PRICELIST_RULE_COND_LESS';

export const SUPPLY_METHODS: any = {
  [PRODUCT_NONE]: 'PRODUCT_TYPE_NONE', //0
  ['produced']: 'PRODUCT_TYPE_PRODUCED', //1
  ['purchase']: 'PRODUCT_TYPE_PURCHASE', //2
};

export const PRODUCT_RELATED_TYPE: any = {
  [PRODUCT_NONE]: 'PRODUCT_RELATED_TYPE_NONE', //0
  o: 'PRODUCT_RELATED_TYPE_ONE_WAY', //1
  b: 'PRODUCT_RELATED_TYPE_BOTH_WAY', //2
};

export const PRODUCT_ITEM_CATEGORY: any = {
  [PRODUCT_NONE]: 'INVENTORY_TYPE_NONE ', //0
  [PRODUCT_ITEM_CATEGORY_INVENTORY]: 'INVENTORY_TYPE_INVENTORY', //1
  [PRODUCT_ITEM_CATEGORY_NON_INVENTORY]: 'INVENTORY_TYPE_NON_INVENTORY', //2
};

export const PRODUCT_ITEM_TYPE: any = {
  [PRODUCT_NONE]: 'ITEM_TYPE_NONE', //0
  [PRODUCT_ITEM_TYPE_GENERAL]: 'ITEM_TYPE_GENERAL', //1
  [PRODUCT_ITEM_TYPE_BUNDLE]: 'ITEM_TYPE_COMPOSITE', //2
  [PRODUCT_ITEM_TYPE_PREPAID]: 'ITEM_TYPE_PREPAID', //3
};

export const PRODUCT_PRICE_TYPE: any = {
  [PRODUCT_NONE]: 'PRODUCT_ITEM_PRICE_TYPE_NONE', //0
  [PRODUCT_PRICE_TYPE_STATIC]: ' PRODUCT_ITEM_PRICE_TYPE_STATIC', //1
  [PRODUCT_PRICE_TYPE_TIME_BASED]: 'PRODUCT_ITEM_PRICE_TYPE_TIME_BASED', //2
};

export const PRODUCT_PREPAID_TYPE: any = {
  [PRODUCT_NONE]: 'PRODUCT_ITEM_PREPAID_TYPE_NONE', //0
  [PRODUCT_PREPAID_TYPE_PER_ITEM]: 'PRODUCT_ITEM_PREPAID_TYPE_PER_ITEM', //1
  [PRODUCT_PREPAID_TYPE_AMOUNT]: 'PRODUCT_ITEM_PREPAID_TYPE_AMOUNT', //2
};

export const PRICE_LIST_TYPE = {
  [PRODUCT_NONE]: 'PRICELIST_TYPE_NONE', //0
  [PRODUCT_PRICE_TYPE_STATIC]: 'PRICELIST_TYPE_STATIC', //1
  [PRODUCT_PRICE_TYPE_TIME_BASED]: 'PRICELIST_TYPE_TIME_BASED', //2
};

export const PRICE_LIST_RULE_CONDITION = {
  [PRODUCT_NONE]: 'PRICELIST_RULE_COND_NONE', //0
  [PRODUCT_PRICE_LIST_RULE_MORE]: PRODUCT_PRICE_LIST_RULE_MORE_VALUE, //1
  [PRODUCT_PRICE_LIST_RULE_LESS]: PRODUCT_PRICE_LIST_RULE_LESS_VALUE, //2
};

export const PRICE_LIST_SELECT_TYPE = {
  [PRODUCT_NONE]: 'PRICELIST_SELECT_TYPE_NONE', //0
  [PRODUCT_PRICE_LIST_SELECT_ALL]: 'PRICELIST_SELECT_TYPE_ALL', //1
  [PRODUCT_PRICE_LIST_SELECT_ITEMS]: 'PRICELIST_SELECT_TYPE_ITEMS', //2
};

export const PRICE_LIST_RECUR_METHOD = {
  [PRODUCT_NONE]: 'PRICELIST_RECUR_METHOD_NONE', //0
  [PRODUCT_PRICE_LIST_RECUR_METHOD_HOURLY]: 'PRICELIST_RECUR_METHOD_HOURLY', //1
  [PRODUCT_PRICE_LIST_RECUR_METHOD_DAILY]: 'PRICELIST_RECUR_METHOD_DAILY', //2
  [PRODUCT_PRICE_LIST_RECUR_METHOD_WEEKLY]: 'PRICELIST_RECUR_METHOD_WEEKLY', //3
  [PRODUCT_PRICE_LIST_RECUR_METHOD_MONTHLY]: 'PRICELIST_RECUR_METHOD_MONTHLY', //4
  [PRODUCT_PRICE_LIST_RECUR_METHOD_YEARLY]: 'PRICELIST_RECUR_METHOD_YEARLY', //5
};

export const PRICE_LIST_RECUR_EVERY_TYPE = {
  [PRODUCT_NONE]: 'PRICELIST_RECUR_TYPE_NONE', //0
  [PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_DAY]: 'PRICELIST_RECUR_TYPE_DAYS', //1
  [PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_WEEK]: 'PRICELIST_RECUR_TYPE_WEEKS', //2
  [PRODUCT_PRICE_LIST_RECUR_EVERY_TYPE_LAST_DAY]: 'PRICELIST_RECUR_TYPE_LAST_DAY_OF_MONTH', //3
};

export const PRICE_LIST_WAY_TO_APPLY = {
  [PRODUCT_NONE]: 'PRICELIST_APPLY_NONE', //0
  [PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO]: PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO_VALUE, //1
  [PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL]: PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL_VALUE, //2
};

//discount
export const PRODUCT_DISCOUNT_TYPE_NONE = 'none';
export const PRODUCT_DISCOUNT_TYPE_SIMPLE = 'simple';
export const PRODUCT_DISCOUNT_TYPE_VOLUMN = 'volumn';
export const PRODUCT_DISCOUNT_TYPE_THRESHOLD = 'threshold';

export const PRODUCT_DISCOUNT_TYPES = {
  [PRODUCT_DISCOUNT_TYPE_NONE]: 'DISCOUNT_TYPE_NONE', //0
  [PRODUCT_DISCOUNT_TYPE_SIMPLE]: 'DISCOUNT_TYPE_SIMPLE', //1
  [PRODUCT_DISCOUNT_TYPE_VOLUMN]: 'DISCOUNT_TYPE_VOLUME', //2
  [PRODUCT_DISCOUNT_TYPE_THRESHOLD]: 'DISCOUNT_TYPE_THRESHOLD', //2
};

export const PRODUCT_DISCOUNT_WAY_NONE = 'none';
export const PRODUCT_DISCOUNT_WAY_LINE = 'line';
export const PRODUCT_DISCOUNT_WAY_SUB_TOTAL = 'subtotal';
export const PRODUCT_DISCOUNT_WAYS = {
  [PRODUCT_DISCOUNT_WAY_NONE]: 'DISCOUNT_WAY_NONE', //0
  [PRODUCT_DISCOUNT_WAY_LINE]: 'DISCOUNT_WAY_LINE', //1
  [PRODUCT_DISCOUNT_WAY_SUB_TOTAL]: 'DISCOUNT_WAY_SUB_TOTAL', //2
};

export const PRODUCT_DISCOUNT_PRICE_TYPE_NONE = 'none';
export const PRODUCT_DISCOUNT_PRICE_TYPE_PERCENT = 'p';
export const PRODUCT_DISCOUNT_PRICE_TYPE_AMOUNT = 'a';
export const PRODUCT_DISCOUNT_PRICE_TYPES = {
  [PRODUCT_DISCOUNT_PRICE_TYPE_NONE]: 'PRICE_TYPE_NONE', //0
  [PRODUCT_DISCOUNT_PRICE_TYPE_PERCENT]: 'PRICE_TYPE_PERCENT', //1
  [PRODUCT_DISCOUNT_PRICE_TYPE_AMOUNT]: 'PRICE_TYPE_AMOUNT', //2
};

export const PRODUCT_DISCOUNT_SELECT_CUSTOMER_NONE = 'none';
export const PRODUCT_DISCOUNT_SELECT_CUSTOMER_ALL = 'all';
export const PRODUCT_DISCOUNT_SELECT_CUSTOMER_CHOOSE = 'choose';
export const PRODUCT_DISCOUNT_CUSTOMER_SELECTTIONS = {
  [PRODUCT_DISCOUNT_SELECT_CUSTOMER_NONE]: 'DISCOUNT_SELECT_CUSTOMER_NONE', //0
  [PRODUCT_DISCOUNT_SELECT_CUSTOMER_ALL]: 'DISCOUNT_SELECT_CUSTOMER_ALL', //1
  [PRODUCT_DISCOUNT_SELECT_CUSTOMER_CHOOSE]: 'DISCOUNT_SELECT_CUSTOMER_CHOOSE', //2
};

// Define list object mapping value with key
export const PRICE_LIST_WAY_TO_APPLY_VALUE = {
  [PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO_VALUE]: PRODUCT_PRICE_TYPE_APPLY_WAY_AUTO,
  [PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL_VALUE]: PRODUCT_PRICE_TYPE_APPLY_WAY_MANUAL,
};

export const PRICE_LIST_RULE_CONDITION_VALUE = {
  [PRODUCT_PRICE_LIST_RULE_MORE_VALUE]: PRODUCT_PRICE_LIST_RULE_MORE,
  [PRODUCT_PRICE_LIST_RULE_LESS_VALUE]: PRODUCT_PRICE_LIST_RULE_LESS,
};

export const WARRANTY_PERIOD_OPTIONS = [
  { value: 'd', label: 'day(s)' },
  { value: 'w', label: 'week(s)' },
  { value: 'm', label: 'month(s)' },
  { value: 'y', label: 'year(s)' },
];

export const WEIGHT_UNIT_OPTIONS = [
  { value: 'kg', label: 'kg' },
  { value: 'lb', label: 'lb' },
];

export const DIMENSION_UNIT_OPTIONS = [
  { value: 'cm', label: 'cm' },
  { value: 'in', label: 'in' },
];

export const OPTION_ITEM_CATEGORIES = [
  {
    label: 'Inventory',
    value: 'inventory',
  },
  {
    label: 'Non-Inventory',
    value: 'non_inventory',
  },
];

export const OPTION_ITEM_TYPES = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Composite',
    value: 'bundle',
  },
  // {
  //   label: 'product_item_type_prepaid',
  //   value: 'prepaid',
  // },
];

export const OPTION_PREPAID_TYPE = [
  {
    label: 'product_item_prepaid_type_number_of_use',
    value: PRODUCT_PREPAID_TYPE_PER_ITEM,
  },
  {
    label: 'product_item_prepaid_type_amount',
    value: PRODUCT_PREPAID_TYPE_AMOUNT,
  },
];

export const PRODUCT_TYPE_OPTIONS: any[] = [
  {
    value: PRODUCT_TYPE_PRODUCED,
    label: 'Produced',
  },
  {
    value: PRODUCT_TYPE_PURCHASE,
    label: 'Purchase',
  },
];

export const PRODUCT_ITEM_TYPE_OPTIONS: any[] = [
  {
    value: PRODUCT_ITEM_TYPE_ENUM_GENERAL,
    label: 'General',
  },
  {
    value: PRODUCT_ITEM_TYPE_ENUM_BUNDLE,
    label: 'Composite',
  },
  // {
  //   value: PRODUCT_ITEM_TYPE_ENUM_PREPAID,
  //   label: 'Prepaid',
  // },
];

export const PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS: any[] = [
  {
    value: 'INVENTORY_TYPE_INVENTORY',
    label: 'Inventory',
  },
  {
    value: 'INVENTORY_TYPE_NON_INVENTORY',
    label: 'Non-Inventory',
  },
];
