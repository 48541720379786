import React  from 'react';
import {ProductGroupTextView as TextView} from "@base/containers/quick-view";

/*const View: React.FC = (props: any) => {
  const { value } = props;

  const [viewId, setViewId] = useState<string>('');

  return (
    <>
      <a href="#" onClick={() => setViewId(value?.id)}>
        {value?.name}
      </a>
      {viewId != '' && <PublishProductGroupView id={viewId} onClose={() => setViewId('')} />}
    </>
  );
};*/

const View = (props: any) => {
  const { value: {id, name} } = props;

  return (
    <TextView value={{id, name}}/>
  );
};

export default View;
