import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ListBody, ListGrid, ListPagination } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
import { countriesAtom, deviceAtom } from '@base/recoil/atoms/app';
import { ListType } from '@base/types/enums';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox, Switch } from '@base/components/form';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { Avatar } from '@base/components';
import { Link } from 'react-router-dom';
import * as keyNames from '@product/item/config/key-names';
import {
  PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS,
  PRODUCT_ITEM_TYPE_OPTIONS,
  WARRANTY_PERIOD_OPTIONS,
} from '@product/main/config/constants';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { convertDateTimeServerToClient, moneyFormat } from '@base/utils/helpers';
import { ChevronDown } from 'react-feather';
import { userItemSetting } from '@product/item/recoil/atom';
import ReactGrid from '@base/components/react-grid';
import _ from 'lodash';

const PageBody = (props: any) => {
  const {
    isSplitMode,
    category, //router category
    isFetching,
    fields,
    listData,
    paging,
    selectedIds,
    onChecked,
    onGridChecked,
    hideColumns,
    sort,
    setSort,
    onChangeSetting,
  } = props;
  const { t } = useTranslation();

  //state
  const { device } = useRecoilValue(deviceAtom);
  const [pageData, setPageData] = useRecoilState(userItemSetting);

  let { filter, listType } = pageData;

  const columnsByGrid: string[] = [keyNames.KEY_ITEM_NAME, keyNames.KEY_ITEM_TYPE];

  //render columns components
  const getMapColumns = () => {
    return {
      images(col: string, data: any) {
        const mainImage = data?.images?.[0] ?? null;
        return (
          <Avatar
            key={mainImage?.id}
            url={mainImage?.name}
            alt={data?.[keyNames.KEY_ITEM_NAME]}
            isCircle={false}
          />
        );
      },
      name(col: string, data: any) {
        let name = data?.[col] ?? '';
        let id = data?.id ?? '';
        return <Link to={`/product/item/${data?.id}`}>{name}</Link>;
      },
      active(col: string, data: any) {
        return <Switch value={data?.active} />;
      },
      [keyNames.KEY_ITEM_CATEGORY](col: string, data: any) {
        return (
          PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS?.find((v: any) => v.value === data[col])?.label ?? ''
        );
      },
      [keyNames.KEY_ITEM_TYPE](col: string, data: any) {
        return PRODUCT_ITEM_TYPE_OPTIONS?.find((v: any) => v.value === data[col])?.label ?? '';
      },
      priceType(col: string, data: any) {
        return t(data?.priceType?.languageKey);
      },
      prepaidType(col: string, data: any) {
        return t(data?.prepaidType?.languageKey);
      },
      [keyNames.KEY_ITEM_PRODUCT](col: string, data: any) {
        return data?.prod?.name;
      },
      [keyNames.KEY_ITEM_UNIT](col: string, data: any) {
        return data?.unit?.name ?? '';
      },
      [keyNames.KEY_ITEM_UNIT_VALUE](col: string, data: any) {
        return data?.unitVal?.name ?? '';
      },
      [keyNames.KEY_ITEM_INVENTORY_SKU](col: string, data: any) {
        const items = data?.sku ? data?.sku?.map((sku: string) => ({ id: sku, name: sku })) : [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          ''
        );
      },
      [keyNames.KEY_ITEM_ATTR_VALUES](col: string, data: any) {
        const items: any[] =
          data?.[col]?.map((item: any) => ({
            id: item?.id,
            name: [item?.attr?.name, item?.name].join(':'),
          })) ?? [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          ''
        );
      },
      [keyNames.KEY_ITEM_UNIT_PRICES](col: string, data: any) {
        let items: any[] = data?.[col] ?? [];
        items = items
          ? items?.map((price: any) => ({
              id: price?.currency,
              name: [price?.currency, moneyFormat(price?.amount ?? 0)].join(':'),
            }))
          : [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          ''
        );
      },
      [keyNames.KEY_ITEM_BASE_PRICES](col: string, data: any) {
        let items: any[] = data?.[col] ?? [];
        items = items
          ? items?.map((price: any) => ({
              id: price?.currency,
              name: [price?.currency, moneyFormat(price?.amount ?? 0)].join(':'),
            }))
          : [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          ''
        );
      },
      [keyNames.KEY_ITEM_UNIT_VAL_QTY](col: string, data: any) {
        return data?.unitVal?.qty ?? '';
      },
      [keyNames.KEY_ITEM_WARRANTY](col: string, data: any) {
        return !data?.[keyNames.KEY_ITEM_WARRANTY] ||
          data?.[keyNames.KEY_ITEM_WARRANTY]?.period <= 0
          ? ''
          : `${data?.[keyNames.KEY_ITEM_WARRANTY]?.period} ${
              WARRANTY_PERIOD_OPTIONS?.find(
                (v: any) => v.value == data?.[keyNames.KEY_ITEM_WARRANTY]?.unit,
              )?.label
            }`;
      },
      [keyNames.KEY_ITEM_COUNTRY_ORIGIN](col: string, data: any) {
        const availableCountries: any = useRecoilValue(countriesAtom);
        const selected = availableCountries?.find((v: any) => {
          return v?.value == data?.[col];
        });
        return selected?.label ?? '';
      },
      [keyNames.KEY_ITEM_MANUFACTURE_DATE](col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.[col] });
      },
      [keyNames.KEY_ITEM_EXPIRATION_DATE](col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.[col] });
      },
      [keyNames.KEY_ITEM_MANUFACTURERS](col: string, data: any) {
        const items = data[col] || [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          '(none)'
        );
      },
      [keyNames.KEY_ITEM_VENDORS](col: string, data: any) {
        const items = data[col] || [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          '(none)'
        );
      },
      [keyNames.KEY_ITEM_ORIGIN_BARCODE](col: string, data: any) {
        const items = data?.[col] ?? [];
        return (
          <div className="dropdown d-flex">
            {items?.length <= 0 ? null : (
              <button
                type="button"
                className="btn d-flex align-items-center"
                data-toggle={items?.length >= 2 ? 'dropdown' : ''}
              >
                <span className="mg-r-5">{items?.[0]}</span>
                {items?.length >= 2 && (
                  <>
                    <span className="badge badge-secondary mg-r-5">+ {items?.length - 1}</span>
                    <ChevronDown />
                  </>
                )}
              </button>
            )}
            {items?.length >= 2 && (
              <div className="dropdown-menu pd-0">
                {items?.map((item: any, index: number) => {
                  return (
                    <div key={index} className="dropdown-item-list">
                      {item}
                    </div>
                  );
                })}
                <div className="dropdown-footer">Total: {items?.length}</div>
              </div>
            )}
          </div>
        );
      },
      [keyNames.KEY_ITEM_RETURNABLE](col: string, data: any) {
        return <Switch value={data?.[col]} />;
      },
      [keyNames.KEY_ITEM_ASSIGN_TO](col: string, data: any) {
        const reps = data[col] || [];
        return reps.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={reps}
          />
        ) : (
          '(none)'
        );
      },
    };
  };

  //columns render in grid split mode
  const getMapColumnsInSplitMode = () => {
    return getMapColumns();
  };

  //columns in grid split mode
  const gridFieldsInSplitMode: any[] = [];
  columnsByGrid?.map((_col: string) => {
    fields.map((_ele: any) => {
      if (_ele.name === _col) {
        gridFieldsInSplitMode.push(_ele);
      }
    });
  });

  /** ================================== React Table 8 ================================== */
  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        width: '45px',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...makeTable8Columns(fields, getMapColumns(), { category }, []),
    ],
    [fields, selectedIds],
  );

  const listTableProps = {
    nodata: <TableNothing />,
    data: listData,
    loading: false,
    columns: columns,
    initialState: {
      pageSize: paging?.itemPerPage || filter?.paging?.size,
      sorts: [sort],
    },
    onRowSelect: onChecked,
    rowSelected: selectedIds,
    onSortBy: (clName: any, isSorted: any) => {
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    className: '',
  };

  //table paging
  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || filter?.paging?.size,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    //isPreviousData,
    onChange: (page: number, size: number) => {
      let newFilter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      if (!_.isEqual(newFilter, filter)) {
        let newListFilter = {
          ...pageData,
          filter: newFilter,
        };
        // setPageData(newListFilter);
        onChangeSetting && onChangeSetting(newListFilter);
      }
    },
  };

  // grid props
  const gridProps = {
    isSplitMode: isSplitMode,
    category: category, // router category
    // primaryKey: 'id',
    // titleKey: 'name',
    // titleUrlKey: 'name',
    gridData: listData
      ? listData?.map((item: any) => ({ ...item, photo: item?.images?.[0]?.name ?? '' }))
      : [],
    isLoading: isFetching,
    device: device !== undefined ? device : 'desktop',
    columns: isSplitMode ? gridFieldsInSplitMode : fields,
    columnRenderRemap: isSplitMode ? getMapColumnsInSplitMode() : getMapColumns(),
    hideColumns: [keyNames.KEY_ITEM_IMAGE],
    onCheckedRow: onGridChecked,
    checkedIds: selectedIds,
    photoKey: 'photo',
  };

  const TableMemo = useMemo(() => {
    return <ListReactTable8 {...listTableProps} />;
  }, [listData, isSplitMode, fields]);

  const GridMemo = useMemo(() => {
    return (
      <ReactGrid
        {...gridProps}
        // gridItemRender={(props: any) => <CustomGridItem {...props} />}
      />
    );
  }, [isSplitMode, listData, fields]);

  return (
    <ListBody isScrollY={listType !== ListType.LIST}>
      {isSplitMode ? (
        GridMemo
      ) : (
        <>
          {/* table list */}
          {listType == ListType.LIST && TableMemo}
          {/* grid list */}
          {listType == ListType.GRID && GridMemo}
        </>
      )}
      <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
    </ListBody>
  );
};

export default PageBody;
