import { Select } from '@base/components/form';
import usePost from '@base/hooks/usePost';
import { DIMENSION_UNIT_OPTIONS } from '@product/main/config/constants';
import { GET_MENU_SETTING } from '@settings/general-setting/services/graphql/format';
import React, { useEffect, useState } from 'react';

const Dimension: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  const [unit, setUnit] = useState<any>(DIMENSION_UNIT_OPTIONS[0]);
  const [data, setData] = useState<any>(value);

  // GET setting
  const params = {
    menu: 'product',
    key: 'item_measurement',
  };
  const { isLoading, data: dimensionConfig } = usePost<any>(
    ['setting_menuSetting', JSON.stringify(params)],
    GET_MENU_SETTING,
    params,
    { enabled: true },
  );

  useEffect(() => {
    if (dimensionConfig) {
      const valueConfig = JSON.parse(dimensionConfig?.value);
      setUnit(
        DIMENSION_UNIT_OPTIONS?.find((v: any) => v.value === valueConfig.dimension) ??
          DIMENSION_UNIT_OPTIONS[0],
      );
    }
  }, [dimensionConfig]);

  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) != JSON.stringify(data)) {
        setData(value);
      }
    } else {
      setData(null);
    }
  }, [value]);

  const onChangeValue = ({ key = '', valueKey = '' }) => {
    const newData = { ...data };
    newData[key] = valueKey;
    newData.unit = unit.value;
    setData(newData);

    onChange && onChange(newData);
  };

  const onChangeUnit = (newValue: any) => {
    const newData = { ...data };
    newData.unit = newValue.value;

    setUnit(newValue);
    onChange && onChange(newData);
  };

  return (
    <div>
      <div className="input-group">
        <div className="form-control form-control-lg d-flex align-items-center">
          <input
            type="number"
            min={0}
            className="wd-100p bd-0 pd-0 text-center"
            autoFocus
            defaultValue={value?.x}
            onChange={(e) => onChangeValue({ key: 'x', valueKey: e.target.value })}
          />
          <span className="mg-x-5 tx-color-03">x</span>
          <input
            type="number"
            min={0}
            className="wd-100p bd-0 pd-0 text-center"
            defaultValue={value?.y}
            onChange={(e) => onChangeValue({ key: 'y', valueKey: e.target.value })}
          />
          <span className="mg-x-5 tx-color-03">x</span>
          <input
            type="number"
            min={0}
            className="wd-100p bd-0 pd-0 text-center"
            defaultValue={value?.z}
            onChange={(e) => onChangeValue({ key: 'z', valueKey: e.target.value })}
          />
        </div>
        <div className="input-group-append">
          <Select value={unit} options={DIMENSION_UNIT_OPTIONS} onChange={onChangeUnit} />
        </div>
      </div>
    </div>
  );
};

export default Dimension;
