import { nanoid } from '@base/utils/helpers/general.utils';
import {
  KEY_ITEM_NAME,
  KEY_ITEM_UNIT_VAL_ID,
  KEY_ITEM_UNIT_VAL_NAME,
  KEY_ITEM_WARRANTY,
  KEY_ITEM_UNIT_VAL_QTY,
  KEY_ITEM_ACTIVE,
  KEY_ITEM_DIMENSION,
  KEY_ITEM_WEIGHT,
  KEY_ITEM_ORIGIN_BARCODE,
  KEY_ITEM_VENDORS,
  KEY_ITEM_MANUFACTURERS,
  KEY_ITEM_DES,
  KEY_ITEM_EXPIRED_DATE,
  KEY_ITEM_MANUFACTURE_DATE,
  KEY_ITEM_RETURNABLE,
} from '@product/item/config/key-names';

const cartesian = (...a: any[]) =>
  a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())));

export const generateProductItems = (unitNames = [], attributes = [], subMoreParams = {}) => {
  if (unitNames.length === 0) return [];
  const newItemLines: any = [];
  let newOptionValues: any = [];
  if (attributes.length !== 0) {
    const arrAttrs: any = [];
    attributes.length > 0 &&
      attributes.forEach((_x: any) => {
        if (_x?.activeValue && _x.activeValue.length > 0) {
          arrAttrs.push(_x.activeValue);
        }
      });
    //// console.log('arrAttrs', arrAttrs);
    newOptionValues = arrAttrs.length > 0 ? cartesian(...arrAttrs) : [];
  }
  //// console.log('newOptionValues', newOptionValues);
  unitNames.forEach((unit: any) => {
    const tempLine = {
      //...model,
      [KEY_ITEM_UNIT_VAL_ID]: unit.id, //base unit
      [KEY_ITEM_UNIT_VAL_NAME]: unit.name, //base unit
      [KEY_ITEM_UNIT_VAL_QTY]: unit.qty,
      [KEY_ITEM_ACTIVE]: 'y',
      // subTemplate: [],
      moreInfo: {
        [KEY_ITEM_DIMENSION]: {
          H: 0,
          W: 0,
          L: 0,
          unit: 'cm',
        },
        [KEY_ITEM_WEIGHT]: {
          value: 0,
          unit: 'kg',
        },
        [KEY_ITEM_ORIGIN_BARCODE]: [],
        [KEY_ITEM_VENDORS]: [],
        [KEY_ITEM_MANUFACTURERS]: [],
        [KEY_ITEM_WARRANTY]: {
          value: 0,
          option: 'd',
        },
        [KEY_ITEM_RETURNABLE]: 'n',
        [KEY_ITEM_DES]: '',
        [KEY_ITEM_EXPIRED_DATE]: new Date(),
        [KEY_ITEM_MANUFACTURE_DATE]: new Date(),
        //...subMoreParams
      },
    };
    if (newOptionValues.length > 0) {
      newOptionValues.map((optValue: any) => {
        let optionName = Array.isArray(optValue)
          ? optValue.map((ov) => ov.label).join('/')
          : optValue.label;
        let itemName = unit.label + ' ' + optionName;
        const newLine = {
          ...tempLine,
          idLocal: nanoid(),
          option_values: optValue,
          [KEY_ITEM_NAME]: itemName,
        };
        newItemLines.push(newLine);
      });
    } else {
      newItemLines.push({
        ...tempLine,
        idLocal: nanoid(),
        option_values: null,
        [KEY_ITEM_NAME]: unit.label,
      });
    }
  });
  return newItemLines;
};
