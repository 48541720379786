import React, { useEffect, useState } from 'react';
import _, { random } from 'lodash';
import { Plus } from 'react-feather';
import { Button } from '@base/components/form';
import {
  KEY_ITEM_CODE,
  KEY_ITEM_NAME,
  KEY_ITEM_PREPAID_AMOUNT,
  KEY_ITEM_PREPAID_DISCOUNT,
  KEY_ITEM_ACTIVE,
  KEY_ITEM_WARRANTY,
  KEY_ITEM_DES,
  KEY_ITEM_MATCH_ITEM,
  KEY_ITEM_NUMBER_USES,
  KEY_ITEM_PREPAID_PER_ITEM,
  KEY_ITEM_DIMENSION,
} from '@product/item/config/key-names';
import {
  PRODUCT_PREPAID_TYPE_AMOUNT,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';

import { useRecoilValue } from 'recoil';
import { defaultCurrencySelector } from '@base/recoil/selectors';

import RowItem from './row-item';

/**
 *
 * @param {*} props
 * @returns
 */
const PrepaidItems: React.FC<any> = (props: any) => {
  const { product, prepaidType, value = null, onChange } = props;

  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);

  //create default
  const defaultValue = {
    [KEY_ITEM_CODE]: '',
    [KEY_ITEM_NAME]: '',
    [KEY_ITEM_PREPAID_AMOUNT]: [
      {
        value: 0,
        currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
      },
    ],
    [KEY_ITEM_PREPAID_DISCOUNT]: 0, // for amount
    [KEY_ITEM_MATCH_ITEM]: null, // for Per Item
    [KEY_ITEM_PREPAID_PER_ITEM]: 0, // Per Item
    [KEY_ITEM_ACTIVE]: 'y',
    [KEY_ITEM_WARRANTY]: { period: 0, unit: 'd' },
    [KEY_ITEM_DIMENSION]: {
      H: 0,
      W: 0,
      L: 0,
      unit: 'cm',
    },
    [KEY_ITEM_DES]: '',
  };

  //state
  const [items, setItems] = useState<any>([_.cloneDeep(defaultValue)]);

  //init items
  useEffect(() => {
    if (value && value?.length) {
      if (JSON.stringify(value) !== JSON.stringify(items)) {
        setItems(value);
      }
    } else {
      setItems([_.cloneDeep(defaultValue)]);
    }
  }, [value]);

  //new item
  const handleAddNewLine = () => {
    const newItems = [...items];
    newItems.push(_.cloneDeep(defaultValue));
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //item value change
  const handleItemChange = (newItem: any, index: number) => {
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //remove item
  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  //copy all values
  const handleCopyAll = (keyName: string) => {
    const newItems = [...items];
    if (newItems.length > 1) {
      newItems.map((_item, index) => {
        if (index !== 0) {
          _item[keyName] = _.cloneDeep(newItems[0][keyName]);
        }
      });
    }
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  // // console.log('...items...', items);
  //render
  return (
    <div className="pos-relative" style={{ minHeight: '150px' }}>
      {/* <InfiniteScroll dataLength={items.length}> */}
      <div className="table-responsive">
        <table className="table table-bordered mg-b-0">
          <thead>
            {/* TODO Produced will be planned from Mr. Kwon */}
            {product?.type === PRODUCT_TYPE_PRODUCED && (
              <tr className="bg-light">
                <th scope="col">
                  Item Name<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  Item ID<span className="tx-danger">*</span>
                </th>
                {prepaidType === PRODUCT_PREPAID_TYPE_PER_ITEM && (
                  <>
                    <th scope="col">
                      Matching Item<span className="tx-danger">*</span>
                    </th>
                    <th scope="col">
                      Qty to Use<span className="tx-danger">*</span>
                    </th>
                  </>
                )}
                <th scope="col">
                  Prepaid Amount<span className="tx-danger">*</span>
                </th>
                <th scope="col">Discount</th>
                {items.length > 1 && <th scope="col">Delete</th>}
              </tr>
            )}

            {/* -Product Suply Method is Purchased and Can be Sold/Non Inventory, Prepaid Item(Amount Type) : Item Name, Item Id, Prepaid Amount, Discount */}
            {/* -Product Suply Method is Purchased and Can be Sold/Non Inventory, Prepaid Item(Per Item) : Item Name, Item Id, Qty to Use, Prepaid Amount, Discount */}
            {product?.type === PRODUCT_TYPE_PURCHASE && (
              <tr className="bg-light">
                <th scope="col">
                  Item Name<span className="tx-danger">*</span>
                </th>
                <th scope="col">
                  Item ID<span className="tx-danger">*</span>
                </th>
                {prepaidType === PRODUCT_PREPAID_TYPE_PER_ITEM && (
                  <>
                    <th scope="col">
                      Matching Item<span className="tx-danger">*</span>
                    </th>
                    <th scope="col">
                      Qty to Use<span className="tx-danger">*</span>
                    </th>
                  </>
                )}
                <th scope="col">
                  Prepaid Amount<span className="tx-danger">*</span>
                </th>
                <th scope="col">Discount</th>
                {items.length > 1 && <th scope="col">Delete</th>}
              </tr>
            )}
          </thead>
          <tbody>
            {items?.length > 0
              ? items.map((_item: any, index: number) => {
                  return (
                    <RowItem
                      key={index}
                      prodType={product?.type}
                      type={prepaidType}
                      rowIndex={index}
                      removable={items.length > 1}
                      value={_item}
                      defaultValue={defaultValue}
                      onChange={(newItem: any, index: number) => handleItemChange(newItem, index)}
                      onRemove={() => handleRemoveItem(index)}
                      onCopy={(keyName: string) => handleCopyAll(keyName)}
                    />
                  );
                })
              : null}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={prepaidType === PRODUCT_PREPAID_TYPE_AMOUNT ? 5 : 7}>
                <Button color="link" className="mg-t-5" onClick={handleAddNewLine}>
                  <Plus className="mg-r-5" />
                  Add New Line
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* </InfiniteScroll> */}
    </div>
  );
};

export default PrepaidItems;
