import React, { useEffect, useMemo, useState } from 'react';
import { ChevronUp, Plus, Trash2 } from 'react-feather';
import { Portal, Canvas } from '@base/components';
import { Button } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { makeReactTableColumns } from '@base/components/utils/helpers/react-table';
import { UPDATE_PRODUCT_UNIT_VALUES } from '@product/unit/services/graphql';

/**
 *
 * @param {*} props
 * @returns
 */
const AddUnitName: React.FC<any> = (props: any) => {
  const { isShow, onClose, onAdd, unit } = props;

  // state
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  // init data
  useEffect(() => {
    if (unit && JSON.stringify(unit?.unitValues) != JSON.stringify(items)) {
      setItems(unit?.unitValues ?? []);
    }
  }, [unit]);

  const mUpdateUnitValues: any = useMutationPost<BaseResponse<any>>(
    UPDATE_PRODUCT_UNIT_VALUES,
    'product_updateUnitValues',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any, error: any, variables: any, context: any) => {
        setIsSaving(false);
        onAdd && onAdd(data?.unitValues ?? []);
        onClose && onClose();
      },
      onError: (error: any, variables: any, context: any) => {
        setIsSaving(false);
      },
    },
  );

  // save
  const onSave = () => {
    const params = {
      unitId: unit.id,
      unitValues: items?.map((item: any) => ({ id: item.id, name: item.name, qty: item.qty })),
    };
    mUpdateUnitValues.mutate(params);
  };

  // save buttons
  const renderSaveButton = () => {
    return (
      <div className="btn-group dropup mg-l-5">
        <Button color="primary" loading={isSaving} onClick={onSave}>
          Save
        </Button>
      </div>
    );
  };

  const handleAddNew = () => {
    if (unit && unit?.name != '') {
      const newItems = [...items];
      newItems.push({
        baseUnit: unit?.name,
        name: '',
        qty: '',
        isBase: false,
      });
      setItems(newItems);
    }
  };

  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  // react-table-v8
  const InputCellv8 = ({ getValue, row: { index }, column: { id }, table }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        className="form-control"
        defaultValue={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        disabled={index === 0}
      />
    );
  };

  const NumberCellv8 = ({ getValue, row: { index }, column: { id }, table }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, parseInt(value));
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        type={'number'}
        className="form-control"
        defaultValue={value as number}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        min={1}
      />
    );
  };

  const DeleteCellv8 = ({ row: { index }, onDelete = () => {} }: any) => {
    if (index === 0) {
      return null;
    }
    return (
      <button
        onClick={() => onDelete(index)}
        type="button"
        className="btn btn-link btn-icon pd-y-0"
      >
        <Trash2 className="tx-danger" />
      </button>
    );
  };

  const columnsTable = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => <span>Unit Name</span>,
        cell: (cell) => {
          return (
            <div className="d-flex flex-1 align-items-center">
              <InputCellv8 {...cell} />
            </div>
          );
        },
      },
      {
        id: 'qty',
        accessorKey: 'qty',
        header: () => <span>Unit Qty</span>,
        cell: (cell) => {
          return <NumberCellv8 {...cell} />;
        },
      },
      {
        id: 'delete',
        header: () => <span></span>,
        cell: (cell) => {
          return <DeleteCellv8 {...cell} onDelete={handleRemove} />;
        },
        size: 70,
      },
    ],
    [items],
  );

  const table = useReactTable({
    data: items ?? [],
    columns: makeReactTableColumns(columnsTable),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        const newItems = [...items];
        newItems[rowIndex][columnId] = value;
        setItems(newItems);
      },
    },
    debugTable: true,
  });

  // render
  return (
    <Portal>
      <Canvas
        isVisible={isShow}
        onCloseSideBar={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={'New Unit Name'} />
        <Canvas.Body>
          <div className="table-responsive bg-white bd mg-b-0 wd-600">
            <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any) => (
                      <th
                        key={header.id}
                        style={{
                          width: header?.column?.columnDef?.width ?? header?.column?.getSize(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button type="button" className="btn btn-link mg-y-10" onClick={handleAddNew}>
            <Plus className="mg-r-5" />
            Add Unit Name
          </button>
        </Canvas.Body>
        <Canvas.Footer closeLabel="Close" onClose={onClose} saveBtn={renderSaveButton()} />
      </Canvas>
    </Portal>
  );
};

export default AddUnitName;
