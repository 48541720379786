import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import SpanLang from '@base/components/form/span-lang';
import { useNavigate, useParams } from 'react-router-dom';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { IFieldConfig, FilterInput } from '@base/types/interfaces/common';
import {
  ListContainer,
  ListHeader,
  ListHeaderFilters,
  ListQuickAction,
  ListSearch,
  ListTableColumnSetting,
} from '@base/components/list';
import { Download, HelpCircle, Trash2 } from 'react-feather';
import { default as configFields } from '@product/item/config/view-field';
import { buildListSchema } from '@base/utils/helpers/schema';
import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import { SearchFilter } from '@base/types/interfaces/app';
import { ListType } from '@base/types/enums';
import { categoryOptions, PRODUCT_ADD_OPTIONS } from '@product/main/config';
import {
  dateByOptions,
  filterByOptions,
  groupByOptions,
  searchFields,
  sortsBy,
} from '@product/item/config/list';
import { useGetList } from '@base/services/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@product/item/config/display-layout';
import { useTranslation } from 'react-i18next';

//for write
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';
import { Button } from '@base/components/form';
//end for write

// Write Product
import WritePageProduct from '@product/product/pages/write';
// Write Item
import WritePageItem from '@product/item/pages/write';
import WritePageUnit from '@product/unit/pages/write';
import { listLayout } from '@product/item/utils/fake-layout';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { DELETE_ITEM } from '@product/item/services/graphql';
import PageBody from './body';
import { itemListData, userItemSetting } from '@product/item/recoil/atom';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
  onChangeSetting: (newSetting: any) => void;
}

const ListPage: React.FC<IListPage> = (props: IListPage) => {
  const { t } = useTranslation();
  const { isSplitMode, setIsSplitMode, onChangeSetting } = props;

  const queryClient = useQueryClient();

  // recoil
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom); //for write page
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  const pageData = useRecoilValue(userItemSetting);
  const [listData, setListData] = useRecoilState(itemListData);

  // react-hook
  const params = useParams();
  const navigate = useNavigate();

  // state
  // const [showColumns, setShowColumns] = useState<any[]>([]);
  const [hideColumns, setHideColumns] = useState<string[]>([]);
  const [sortColumns, setSortColumns] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const loadRef = useRef(false);

  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'item';
  const layoutMenu = MENU_PRODUCT_ITEM;

  // Sort
  let { filter, listType, settingColumns } = pageData;

  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newData = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  // get pagelayout
  let listLayoutData: any = {
    data: listLayout ?? [],
  };

  let fields: any[] = [];
  if (listLayoutData && listLayoutData?.data) {
    fields = listLayoutData?.data;
  }

  // sorts column
  const getSortColumns = () => {
    return sortsBy;
  };

  useEffect(() => {
    if (fields?.length > 0) {
      const newColumns: any[] = [];

      fields?.map((_ele: any) => {
        newColumns.push({
          ..._ele,
          name: _ele?.keyName,
          isViewing: _ele.defaultViewInList,
          enableSorting:
            getSortColumns()?.findIndex((v: any) => v?.value === _ele.keyName) != -1 ? true : false,
        });
      });

      // merge setting columns and page-layout
      const tableColumns: any[] =
        settingColumns && settingColumns?.length
          ? settingColumns?.filter((_item: any) => {
              if (newColumns.findIndex((v: any) => v.keyName === _item?.keyName) >= 0) {
                return _item;
              }
            })
          : newColumns;

      const newData: any = { ...pageData };
      newData.settingColumns = tableColumns;

      if (!loadRef.current) {
        // fist load
        loadRef.current = true;
        // setPageData(newData);
        onChangeSetting && onChangeSetting(newData);
      }
    }
    () => {
      loadRef.current = false;
    };
  }, [fields]);

  // columns will show in list
  const showColumns = settingColumns.filter((_ele: any) => _ele?.isViewing);

  // build list query schema
  const listConfig: IFieldConfig = { ...configFields };
  const listQuerySchema = buildListSchema({ fields, configFields: listConfig });

  // list filters
  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, dateByOptions, searchFields),
  };

  // get list data
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'product_items',
    listQuerySchema,
    filtersQuery,
  );

  useEffect(() => {
    setListData(data?.data ?? []);
  }, [data?.data]);

  const onCheckedRow = useCallback(
    (checkedValue: string | number) => {
      const checkedValueStr = checkedValue as string;
      let newSelectedIds = selectedIds;
      switch (checkedValueStr) {
        case 'all':
          newSelectedIds = [];
          listData?.map((prod: any) => {
            newSelectedIds.push(prod?.id);
          });
          break;
        case 'notAll':
          newSelectedIds = [];
          break;
        default:
          if (selectedIds.indexOf(checkedValueStr) !== -1) {
            newSelectedIds.splice(selectedIds.indexOf(checkedValueStr), 1);
          } else {
            newSelectedIds = [...selectedIds, checkedValueStr];
          }
          break;
      }
      setSelectedIds([...newSelectedIds]);
    },
    [selectedIds, setSelectedIds],
  );

  // build columns for react-table v8
  const handleCheckItem = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ITEM,
    'product_deleteItem',
    {
      onMutate: () => {
        // setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Delete items successfully!');
        // setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_items']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete items failed: ' + JSON.parse(error).message);
        // setQuickLoading(false);
      },
    },
  );

  const quickButtons = [
    {
      title: 'Export',
      icon: <Download />,
      callback: (selected: any) => {},
    },
    // {
    //   title: 'Mark as Active',
    //   icon: <CheckCircle />,
    //   callback: (selected: any) => {},
    // },
    // {
    //   title: 'Mark as Inactive',
    //   icon: <Circle />,
    //   callback: (selected: any) => {},
    // },
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        mutationDelete.mutate({ ids: selectedIds });
      },
    },
  ];

  // more menu
  const actionsProps = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => {},
  };

  const handleSearchSubmit = (formData: any) => {
    if (formData?.type === 'TYPE_NONE') {
      delete formData.type;
    }
    let newFilter: SearchFilter = {
      ...pageData.filter,
    };
    if (formData?.keyword != '') {
      newFilter = {
        ...newFilter,
        keyword: formData?.keyword,
      };
      delete formData.keyword;
    }
    newFilter = {
      ...newFilter,
      searchFilters: formData,
    };
    let newData: any = {
      ...pageData,
      filter: newFilter,
    };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  const onChangeColumnSetting = (newColumns: any) => {
    let newData: any = { ...pageData, settingColumns: newColumns };
    onChangeSetting && onChangeSetting(newData);
  };

  const moreAction = () => {
    return (
      <div className="d-flex align-items-center">
        <Button
          // icon={'Help'}
          color="light"
          isSpanTitle={false}
          onClick={() => {}}
          data-han-tooltip={'Help'}
          aria-label="help"
          className="btn btn-white btn-icon mg-l-5"
        >
          <HelpCircle />
        </Button>
      </div>
    );
  };

  // body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={isSplitMode}
        category={category}
        isFetching={false}
        fields={showColumns ?? []}
        hideColumns={hideColumns}
        listData={listData ?? []}
        paging={data?.paging}
        selectedIds={selectedIds}
        onChecked={handleCheckItem}
        onGridChecked={setSelectedIds}
        sort={sort}
        setSort={setSort}
        onChangeSetting={onChangeSetting}
      />
    );
  }, [isSplitMode, listData, showColumns, selectedIds]);

  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbarContainer
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  // setPageData(newData);
                  onChangeSetting && onChangeSetting(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
              }}
              actionsProps={actionsProps}
              isSplitMode={isSplitMode}
              setIsSplitMode={setIsSplitMode}
              onRefresh={refetch}
              addOptions={PRODUCT_ADD_OPTIONS}
              onAdd={(newCategory: string | undefined) =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: newCategory === undefined ? MENU_PRODUCT_ITEM : newCategory,
                }))
              }
              categoryOptions={categoryOptions}
              categorySelected={category}
              onCategoryChange={(newCategory: string) => {
                return navigate(`/${menu}/${newCategory}`);
              }}
              menu={menu}
              // moreAction={moreAction}
            />
          }
          header={
            <ListHeader
              isSimple={isSplitMode}
              searchComponent={
                <ListSearch
                  isSimple={isSplitMode}
                  placeholder={'Enter keyword to search...'}
                  searchFields={searchFields.reduce((final: any[], field: any) => {
                    if (showColumns?.findIndex((v: any) => v.name == field.name) !== -1) {
                      final.push(field);
                    }
                    return final;
                  }, [])}
                  onSubmit={handleSearchSubmit}
                />
              }
              filterComponent={
                <ListHeaderFilters
                  category={MENU_PRODUCT_ITEM ?? ''}
                  isSplitMode={isSplitMode}
                  groupByOptions={groupByOptions}
                  filterByOptions={filterByOptions}
                  dateByOptions={dateByOptions}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    // setPageData(newData);
                    onChangeSetting && onChangeSetting(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={setSort}
                  sortColumns={listType == ListType.LIST ? [] : getSortColumns()}
                />
              }
              columnSettingComponent={
                !isSplitMode &&
                listType == ListType.LIST && (
                  <ListTableColumnSetting
                    columns={settingColumns}
                    hideColumns={hideColumns}
                    columLimit={10}
                    onChange={onChangeColumnSetting}
                  />
                )
              }
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              onRefresh={refetch}
              actionsProps={actionsProps}
            />
          }
          body={PageBodyMemo}
        />

        {/* Tablet layout here */}
        {/* {isTablet && null} */}

        {/* Mobile layout here */}
        {/* {isMobile && null} */}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => setSelectedIds([])}
        visible={!!selectedIds.length}
      />

      {/* hook form - write new item write */}
      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
        <WritePageItem
          title={<SpanLang keyLang={'Create Item'} />}
          size="xl"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          product={null}
          menuApi={MENU_PRODUCT_ITEM}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
        <WritePageProduct
          title={<SpanLang keyLang={'Create Product'} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={refetch}
          menuApi={MENU_PRODUCT_PRODUCT}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_UNIT && (
        <WritePageUnit
          title={<SpanLang keyLang={'Create Unit'} />}
          size="md"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={refetch}
          menuApi={MENU_PRODUCT_UNIT}
        />
      )}
    </>
  );
};

export default ListPage;
