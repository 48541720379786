import { IFilterByOption } from '@base/types/interfaces/common';
import { lazy } from 'react';

const ProductGroup = lazy(() => import('@product/product/containers/product-group'));
const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
const Switch = lazy(() => import('@base/components/form/switch'));

export const groupByOptions = [
  { label: 'All Products', value: 'all' },
  { label: 'My Product', value: 'myProduct' },
  { label: 'My Group Product', value: 'myGroupProduct' },
  { label: 'Unassigned Product', value: 'unassignedProduct' },
  { label: 'Deleted Product', value: 'deletedProduct' },
  // { label: 'Purchased Products', value: 'purchasedProduct' },
  // { label: 'Produced Products', value: 'producedProduct' },
  // { label: 'Can be Sold Products', value: 'canBeSoldProduct' },
  // { label: 'Not Created Items', value: 'notCreatedItem' },
];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
  // { label: 'Sales Start', value: 'startDate' },
  // { label: 'Sales End', value: 'endDate' },
];

export const filterByOptions: IFilterByOption[] = [
  // { label: 'Base Unit', value: 'unit' },
  // { label: 'Attributes', value: 'attributes' },
  {
    label: 'Product Group',
    value: 'groupId',
    component: ProductGroup,
    getValue: (componentValue: any): string => {
      return componentValue?.id;
    },
  },
  {
    label: 'Assigned Rep',
    value: 'assignedTo',
    component: UserAutoComplete,
    getValue: (componentValue: any) => {
      return componentValue?.length > 0 ? componentValue?.map((v: any) => v?.id).join(',') : '';
    },
  },
  {
    label: 'Active',
    value: 'active',
    component: Switch,
    getValue: (componentValue: any): boolean => {
      return componentValue?.target?.checked;
    },
  },
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: { single: true },
    getValue: (componentValue: any) => {
      return componentValue?.id ?? '';
    },
  },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const sortsBy = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'createdAt',
    label: 'Created At',
  },
  {
    value: 'updatedAt',
    label: 'Updated At',
  },
  {
    value: 'group',
    label: 'Product Group',
  },
  {
    value: 'code',
    label: 'Product Code',
  },
  {
    value: 'unit',
    label: 'Base Unit',
  },
  {
    value: 'type',
    label: 'Supply Method',
  },
];
