import React, { useEffect, useState } from 'react';
import AssignUser from '@product/product/containers/assign-user';
import { useTranslation } from 'react-i18next';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import RelatedCustomer from '@product/product/containers/related-customer';
import DesktopLayout1ViewRight from '@base/layouts/desktop/layout1/view-right';
import { KEY_PRODUCT_TYPE, KEY_PRODUCT_TYPE_BE_SOLD } from '@product/product/config/key-names';
import { PRODUCT_TYPE_PURCHASE } from '@product/main/config/constants';
import { MENU_PRODUCT_PRODUCT } from '@base/config/menus';
import RelatedVendor from '@product/product/containers/related-vendor';

interface IRightProps {
  id: string;
  category: string;
  data?: any;
}

const ViewRight: React.FC<IRightProps> = (props: IRightProps) => {
  const { category, id, data } = props;
  const { t } = useTranslation();

  const [rightConfig, setRightConfig] = useState<any[]>([
    {
      title: t('common_section_assigned_rep'),
      key: 'assigned',
      component: <AssignUser placement="left" id={id} />,
    },
    {
      title: t('common_section_attachment'),
      key: 'attachment',
      component: <TimeAttachment menuSource="product" menuSourceId={id} listType="list" />,
    },
  ]);

  useEffect(() => {
    if (data) {
      const newRight = [...rightConfig];
      // If a Supply Method is 'Purchased', show 'Related Vendor'
      if (data?.[KEY_PRODUCT_TYPE] === PRODUCT_TYPE_PURCHASE) {
        newRight.unshift({
          title: t('Related Vendor'),
          key: 'related_vendor',
          component: (
            <RelatedVendor
              sourceId={id}
              readOnly={false}
              sourceMenu={MENU_PRODUCT_PRODUCT}
              menuTab={'product'}
            />
          ),
        });
      }
      // Show 'Related Customer' if porduct is checked 'Can be Sold' (lasted 3 by related product registration date, view more is available)
      if (data?.[KEY_PRODUCT_TYPE_BE_SOLD]) {
        newRight.unshift({
          title: t('Related Customer'),
          key: 'related_customer',
          component: (
            <RelatedCustomer sourceId={id} sourceMenu={MENU_PRODUCT_PRODUCT} menuTab={'product'} />
          ),
        });
      }
      setRightConfig(newRight);
    }
  }, [data]);

  return <DesktopLayout1ViewRight rightConfig={rightConfig} />;
};

export default ViewRight;
