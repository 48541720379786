import React, { useEffect, useState } from 'react';
import { Select } from '@base/components/form';
import UnitName from '@product/unit/containers/unit-name';

/**
 *
 * @param {*} props
 * @returns
 */
const BaseUnitName: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  // state
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const defaultValue = {
    id: '',
    unitValues: [],
    unitNames: [],
  };
  const [unitValue, setUnitValue] = useState<any>(defaultValue);

  //value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(unitValue)) {
        setUnitValue(value);
      }
    } else {
      setUnitValue(defaultValue);
    }
  }, [value]);

  //unit names change
  const handleUnitNameChange = (newNames: any) => {
    const newUnitValue = { ...unitValue };
    newUnitValue.unitNames = newNames ? newNames : [];
    setUnitValue(newUnitValue);
    //callback
    onChange && onChange(newUnitValue);
  };

  //add new value
  const handleNewUnitNameChange = (newNames: any) => {
    const newUnitValue = { ...unitValue };
    const newValues = newNames.map((_ele: any) => ({ ..._ele, value: _ele.id, label: _ele.name }));
    newUnitValue.unitValues = newUnitValue.unitValues.concat(newValues);
    newUnitValue.unitNames = newUnitValue.unitNames.concat(newValues);
    setUnitValue(newUnitValue);
    //callback
    onChange && onChange(newUnitValue);
  };

  //base and unit names
  const renderBaseUnit = () => {
    const unitOptions: any = unitValue?.unitValues.map((_i: any) => {
      return {
        ..._i,
        value: _i.id,
        label: _i.name,
      };
    });
    return (
      <div className="row row-xs">
        <div className="col-lg-6">
          <div className="form-group">
            <div>{unitValue.name || unitValue.label}</div>
            <div className="mg-t-5 tx-orange">상품의 Base Unit이 자동으로 적용됩니다.</div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column pd-b-20">
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex mg-b-5">
                Unit Name <span className="tx-danger">*</span>
              </div>
            </div>
            {/* <Select
              isMulti
              outSide={true}
              options={unitOptions}
              value={unitValue.unitNames}
              onChange={handleUnitNameChange}
              addLabel="Add Unit Name"
              onAdd={() => setShowAdd(true)}
            /> */}
            <UnitName
              isMulti
              value={unitValue.unitNames}
              onChange={handleUnitNameChange}
              options={unitOptions}
              unit={unitValue}
            />
          </div>
        </div>
      </div>
    );
  };

  //render
  return (
    <>
      {unitValue?.id ? (
        renderBaseUnit()
      ) : (
        <div className={'tx-danger'}>Please choose product base unit first</div>
      )}
    </>
  );
};
export default BaseUnitName;
