import React, { useEffect, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { Portal, Canvas } from '@base/components';
import { Button } from '@base/components/form';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  PRODUCT_ATTRIBUTE_CREATE,
  ADD_PRODUCT_ATTRIBUTE_VALUES,
} from '@product/product/services/graphql';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';

/**
 *
 * @param {*} props
 * @returns
 */
const ProductAddAttribute: React.FC<any> = (props: any) => {
  const {
    isShow,
    onClose,
    defaultValue, //current attribute
    onAdd, //add new attribute and values
    onAddValue, //add new values to current attribute
  } = props;

  //state
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  //init data
  useEffect(() => {
    //set name
    if (defaultValue?.id) {
      setName(defaultValue.name);
    }
  }, [defaultValue]);

  //validate before save
  const onValid = () => {
    let valid = name.length == 0 || value.length == 0 ? false : true;
    setIsValid(valid);
    return valid;
  };

  const mCreateAttribute: any = useMutationPost<BaseResponse<any>>(
    PRODUCT_ATTRIBUTE_CREATE,
    'product_createAttribute',
  );

  const mCreateAttributeValues: any = useMutationPost<BaseResponse<any>>(
    ADD_PRODUCT_ATTRIBUTE_VALUES,
    'product_createAttributeValues',
  );

  //save new
  const onSave = (isApply = false) => {
    if (!onValid()) return;

    // setIsSaving(true);
    if (defaultValue?.id) {
      // just add values

      const params = {
        attrId: defaultValue.id,
        values: value.split(',').map((_item) => ({ name: _item })),
      };

      setIsSaving(true);
      mCreateAttributeValues.mutate(params, {
        onSuccess: (data: any, error: any, variables: any, context: any) => {
          setIsSaving(false);
          setValue('');

          onAddValue &&
            onAddValue(
              {
                ...defaultValue,
                values: data.results.map((_ele: any) => ({
                  ..._ele,
                  label: _ele.name,
                  value: _ele.id,
                })),
              },
              isApply,
            );

          if (isApply) {
            onClose();
          }
        },
        onError: (error: any, variables: any, context: any) => {
          setIsSaving(false);
        },
      });
    } else {
      // add new attribute with values

      const params = {
        attribute: {
          name: name,
          values: value.split(',').map((_item) => ({ name: _item })),
        },
      };

      setIsSaving(true);
      mCreateAttribute.mutate(params, {
        onSuccess: (data: any, error: any, variables: any, context: any) => {
          setName('');
          setValue('');
          setIsSaving(false);
          onAdd &&
            onAdd({
              ...data,
              label: data.name,
              value: data.id,
            });
          if (isApply) {
            onClose();
          }
        },
        onError: (error: any, variables: any, context: any) => {
          setIsSaving(false);
        },
      });
    }
  };

  //add and apply
  const handleAddApply = () => {
    onSave(true);
  };

  //add and create new
  const handleAddCreate = () => {
    onSave(false);
  };

  //save buttons
  const renderSaveButton = () => {
    return (
      <div className="btn-group dropup mg-l-5">
        <Button color="success" loading={isSaving}>
          Add
        </Button>
        <Button
          color="success"
          className="dropdown-toggle-split"
          aria-label="toggle dropdown"
          data-toggle="dropdown"
        >
          <ChevronUp />
        </Button>
        <div className="dropdown-menu">
          <Button
            className="dropdown-item"
            onClick={handleAddApply}
            disabled={name.length > 0 ? false : true}
          >
            Add and apply
          </Button>
          <Button
            className="dropdown-item"
            onClick={handleAddCreate}
            disabled={name.length > 0 ? false : true}
          >
            Add and create new
          </Button>
        </div>
      </div>
    );
  };

  //render
  return (
    <Portal>
      <Canvas
        isVisible={isShow}
        onCloseSideBar={onClose}
        className={'page-sidebar-container-wrap'}
        customStyles={{ zIndex: '1052' }}
        backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={'New Attribute Value'} />
        <Canvas.Body>
          <div className="pos-relative pd-10">
            <p>
              변경된 정보는 Product Settings- Attribute에 저장됩니다. 즉시 사용하려면 'Add and apply
              버튼을 클릭하세요.
            </p>
            <div className="form-group">
              <label className="form-item-title">Attribute Name</label>
              <input
                type="text"
                className="form-control ht-38"
                placeholder="e.g Color"
                disabled={defaultValue?.id}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="form-item-title">Value</label>
              <input
                type="text"
                className="form-control ht-38"
                placeholder="e.g Red,Blue,"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            {!isValid && <p className="tx-danger">* Name and Value are required.</p>}
          </div>
        </Canvas.Body>
        <Canvas.Footer closeLabel="Close" onClose={onClose} saveBtn={renderSaveButton()} />
      </Canvas>
    </Portal>
  );
};

export default ProductAddAttribute;
