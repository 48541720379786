import { lazy } from 'react';

export const WriteFormDesktopLayout1 = lazy(
  () => import('@product/product/layouts/desktop/layout1/write'),
);

export const ListDesktopLayout1 = lazy(
  () => import('@product/product/layouts/desktop/layout1/list'),
);

export const ViewFormDesktopLayout1 = lazy(() => import('@base/layouts/desktop/layout1/view'));
