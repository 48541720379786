import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { NoData } from '@base/components';
import { useTranslation } from 'react-i18next';
import { ChevronDown, X } from 'react-feather';
import Loading from '@base/components/loading';
import { useProductGroups } from '@product/product/services/product-service';
import { Button } from '@base/components/form';

interface IProductGroupAutoCompleteProps {
  className?: string;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  isDisabled?: boolean;
  value?: any;
  onChange?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const ProductGroupAutoComplete: React.FC<IProductGroupAutoCompleteProps> = (props) => {
  const {
    className,
    placeholder = '',
    single = false, //
    visible = true, //hide or display selected items
    isDisabled = false,
    value, //[], initial value
    onChange,
  } = props;
  const { t } = useTranslation();
  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<any>(null);

  //find node in tree
  const findNode = (treeNodes: any[], nodeId: string, result: any): any => {
    for (let i = 0; i < treeNodes.length; i++) {
      if (treeNodes[i].id === nodeId) {
        //// console.log('nodes[i]', nodes[i]);
        result = treeNodes[i];
      }
      if (treeNodes[i]?.children?.length > 0) {
        result = findNode(treeNodes[i].children, nodeId, result);
      }
    }
    return result;
  };

  //build tree data from array
  const buildTree = (arrItems: any[], id: any): any =>
    arrItems
      .filter((_item: any) => _item.parent?.id === id)
      .map((_item: any) => ({ ..._item, children: buildTree(arrItems, _item.id) }));

  //outside click dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(event?.target)) {
        setShowDropdown(false);
      }
    };
    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fNode = findNode(treeData, _item, {});
                if (fNode) {
                  newValue.push(fNode);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (_.isString(value)) {
          if (value !== selectedValue?.id) {
            //find in tree
            const fNode = findNode(treeData, value, {});
            if (fNode) {
              setSelectedValue(fNode);
            }
          }
        } else {
          if (value?.id !== selectedValue?.id) {
            setSelectedValue(value);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  //hook to get categories-folders
  const {
    data: postData,
    isFetching,
    refetch: refetch,
  } = useProductGroups({ keyword: searchText });
  //// console.log('groups data', postData);

  //init states list
  useEffect(() => {
    if (postData?.data) {
      // console.log('newTreeData', buildTree(postData?.data || [], undefined));
      if (inputText != '') {
        setTreeData(postData?.data || []);
      } else {
        setTreeData(buildTree(postData?.data || [], undefined));
      }
    }
  }, [postData]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    //if (event.action !== 'input-blur' && event.action !== 'menu-close') {
    setInputText(inputText);
    setSearchTextDebounced(inputText);
    //}
  };

  //value change
  const handleValueChange = (newValue: any) => {
    if (visible) {
      setSelectedValue(newValue);
    }
    //callback
    onChange && onChange(newValue);
    setInputText('');
    setShowDropdown(false);
  };

  //get node path name
  const getPathName = (node: any, pathName: string) => {
    //combine name
    pathName = node.name + (pathName ? ' > ' + pathName : '');
    //find parent
    if (node.parent) {
      const parentNode = findNode(treeData, node.parent.id, {});
      pathName = getPathName(parentNode, pathName);
    }
    // else {
    //   pathName = getPathName(node.category, pathName);
    // }
    return pathName;
  };

  //render children
  const renderTree = (parent: any, children: any[], depth: number) => {
    let paddingLeft = 10 + 20 * depth;
    return (
      <>
        {children.map((_child: any, index: number) => {
          let morePadding = 0;
          if (_child?.parent === null && _child?.children?.length === 0) {
            morePadding = 10;
          }
          if (_child?.parent !== null && _child?.children?.length === 0) {
            morePadding = 20;
          }
          return (
            <div key={index} className="">
              <div
                key={index}
                className={`dropdown-item cursor-default d-flex align-items-center justify-content-between pd-l-${
                  paddingLeft + morePadding
                } pd-r-20`}
              >
                <div className="d-flex flex-grow-1 align-items-center">
                  {_child?.children?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-xs btn-link link-03 btn-icon"
                      data-toggle="collapse"
                      data-target={'#dropdown-node' + _child.id}
                      aria-expanded="false"
                    >
                      <ChevronDown />
                    </button>
                  )}
                  <div
                    className="d-flex flex-grow-1"
                    onClick={() => handleValueChange({ ..._child, parent })}
                  >
                    <span className="text-truncate">{_child.name}</span>
                  </div>
                </div>
              </div>
              <div id={'dropdown-node' + _child.id} className="collapse fade">
                {renderTree(_child, _child?.children || [], depth + 1)}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  //// console.log('selected value', selectedValue);
  // console.log('tree data', treeData);
  const newPlaceholder = `Type or click to select a group`;
  const noOptMsg = `No product group(s) found.`;
  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <div className={classNames('dropdown', { show: showDropdown })} ref={dropdownRef}>
        {/* <div className="input-group" data-toggle="dropdown"> */}
        <div className="input-group">
          {/* {single && ( */}
          <input
            type="text"
            className="form-control ht-38"
            placeholder={selectedValue ? '' : newPlaceholder}
            value={inputText}
            onFocus={() => setShowDropdown(true)}
            onChange={(e: any) => handleInputChange(e.target.value, e)}
          />
          {inputText.length === 0 && (
            <>
              <div className="pos-absolute l-20 z-index-50" style={{ top: '8px' }}>
                <span>
                  {/* {selectedValue?.name || ''} */}
                  {selectedValue ? getPathName(selectedValue, '') : ''}
                </span>
              </div>
              {selectedValue && (
                <div className="pos-absolute r-50 z-index-50" style={{ top: '8px' }}>
                  <Button
                    color="link"
                    size="sm"
                    icon="X"
                    className="link-03 pd-0"
                    onClick={() => setSelectedValue(null)}
                  />
                </div>
              )}
            </>
          )}
          {/* )} */}
          {/* {!single && (
          <div
            className={'form-control d-flex flex-wrap form-control-tags pd-2'}
            style={{ minHeight: 38 }}
            onClick={() => setShowDropdown(true)}
          >
            {tagValue?.map((_item: any, _index: number) => (
              <TagName
                key={_item.id}
                //id={type == "tag" ? null : _item.id}
                name={_item.name}
                onDelete={() => handleRemoveTag(_index)}
              />
            ))}
          </div>
        )} */}
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              style={{ padding: '0 12px' }}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <ChevronDown />
            </button>
          </div>
        </div>
        <div className={classNames('dropdown-menu wd-100p', { show: showDropdown })}>
          <div className="scroll-box" style={{ maxHeight: 250, overflowY: 'auto' }}>
            {isFetching && treeData.length === 0 && <Loading />}
            {treeData.length === 0 && <NoData label={noOptMsg} />}
            {treeData.length > 0 && renderTree(null, treeData, 0)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGroupAutoComplete;
