import { usePublicPost } from '@base/hooks/usePublicPost';
import usePosts from '@base/hooks/usePosts';
import {
  GET_PRODUCTS_LIST,
  GET_PRODUCT_ATTRS,
  GET_PRODUCT_ATTR_VALUES,
  GET_PRODUCT_VIEW,
  GET_PUBLIC_PRODUCTS_LIST,
  GET_PUBLISH_PRODUCT_LIST,
  PRODUCT_GROUPS_GET,
} from '@product/product/services/graphql';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import usePublicPosts from '@base/hooks/usePublicPosts';
import { getEnabledCategories } from 'trace_events';

// TODO: get all products by point
export const useProducts = (filter: any) => {
  const menu = 'product_products';

  let params = {
    filter: filter,
  };

  const usePostResult = usePosts<any[]>(
    [menu, keyStringify(filter, '')],
    GET_PRODUCTS_LIST,
    params,
  );

  return usePostResult;
};

export const useProductsByGroup = ({ groupId, paging }: any) => {
  const menu = 'product_products';
  let params = {
    filter: {
      paging: paging || { page: 1, size: 10 },
      query: `groupId=\"${groupId}\"`,
    },
  };
  const usePostResult = usePosts<any[]>([menu, groupId, paging], GET_PRODUCTS_LIST, params, {
    enabled: groupId.length > 0,
  });

  return usePostResult;
};

export const useProductsAutoComplete = (params: any, isPublic?: boolean) => {
  //const menu = isPublic ? 'tracking_products' : 'product_products';

  let usePostResult = null;
  if (isPublic) {
    usePostResult = usePublicPosts<any[]>(
      ['tracking_products', keyStringify(params?.filter, '')],
      GET_PUBLIC_PRODUCTS_LIST,
      params,
    );
  } else {
    usePostResult = usePosts<any[]>(
      ['product_products', keyStringify(params?.filter, '')],
      GET_PRODUCTS_LIST,
      params,
    );
  }

  return usePostResult;
};

export const useProductGroups = ({ keyword, parentId, paging }: any) => {
  let query = '';
  if (keyword) {
    query += `name:\"${keyword}\"`;
  }
  if (parentId !== undefined) {
    query += ` parentId:\"${parentId}\"`;
  }

  let queryKey = ['product_groups', keyword, parentId, paging];
  const response = usePosts<any[]>(queryKey, PRODUCT_GROUPS_GET, {
    filter: {
      query,
      // paging: paging || { page: 1, size: 10 },
      sort: {
        field: 'createdAt',
        orderBy: 1,
      },
    },
  });
  return response;
};

export const useProductAttributes = ({ keyword }: any) => {
  let query = '';
  if (keyword) {
    query += `name:\"${keyword}\"`;
  }

  let queryKey = ['product_attributes', keyword];

  const response = usePosts<any[]>(queryKey, GET_PRODUCT_ATTRS, {
    filter: {
      query,
      sort: {
        field: 'createdAt',
        orderBy: 1,
      },
    },
  });
  return response;
};

export const useAttributeValuesAutoComplete = (params: any) => {
  const menu = 'product_attributeValues';

  const usePostResult = usePosts<any[]>(
    [menu, keyStringify(params?.filter, '')],
    GET_PRODUCT_ATTR_VALUES,
    params,
  );

  return usePostResult;
};

export const usePublishProductView = (id: string) => {
  const result = usePublicPost<any>(['product_product', id], GET_PRODUCT_VIEW, { id: id });
  return result;
};

export const usePublishProductsByGroup = ({ groupId, paging }: any) => {
  const menu = 'product_products';
  let params = {
    filter: {
      // paging: paging || { page: 1, size: 999 },
      query: `groupId=\"${groupId}\"`,
    },
  };
  const usePostResult = usePublicPosts<any[]>(
    [menu, groupId, paging],
    GET_PUBLISH_PRODUCT_LIST,
    params,
    {
      enabled: groupId.length > 0,
    },
  );

  return usePostResult;
};
