import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ChevronDown, Edit2, Folder, Package, Plus, PlusCircle } from 'react-feather';
import classNames from 'classnames';
import Loading from '@base/components/loading';
import { Button, FormIcon } from '@base/components/form';
import { NoData } from '@base/components';
import { useProductGroups, useProductsByGroup } from '@product/product/services/product-service';
import { buildTree, findNode } from '@base/utils/helpers/general.utils';
import { useItemsByProduct } from '@product/item/services/item-service';
import SpanLoading from '@base/components/loading/span-loading';
import WriteProductGroupForm from '@settings/preferences/containers/write-product-group-form';

const NODE_TYPE_GROUP = 'GROUP';
const NODE_TYPE_PRODUCT = 'PRODUCT';
const NODE_TYPE_ITEM = 'ITEM';
const PAGE_SIZE = 10;

interface IProductTreeProps {
  className?: string;
  treeId: string;
  onSelect?: (node: any) => void;
}

const ProductTree: React.FC<IProductTreeProps> = (props) => {
  const { className, treeId, onSelect } = props;
  const { t } = useTranslation();
  //state
  const [openNode, setOpenNode] = useState<any>(null);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isOpenWrite, setIsOpenWrite] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null); //highlight
  const [selectedGroup, setSelectedGroup] = useState<any>(null); //for add/edit group
  const [rootPaging, setRootPaging] = useState<any>({ page: 1, nextPage: 1, size: PAGE_SIZE });

  //get product groups
  const {
    data: groupData,
    isFetching: isGroupFetching,
    refetch: groupRefetch,
  } = useProductGroups({
    parentId: openNode?.nodeType === NODE_TYPE_GROUP ? openNode.id : '',
    paging:
      openNode?.nodeType === NODE_TYPE_GROUP
        ? { page: openNode.paging.page, size: PAGE_SIZE }
        : { page: rootPaging.page, size: PAGE_SIZE },
  });

  //get products by group
  const {
    data: productData,
    isFetching: isProductFetching,
    refetch: productRefetch,
  } = useProductsByGroup({
    groupId: openNode?.nodeType === NODE_TYPE_GROUP ? openNode.id : '',
    paging:
      openNode?.nodeType === NODE_TYPE_GROUP
        ? { page: openNode.paging.page, size: PAGE_SIZE }
        : { page: 1, size: PAGE_SIZE },
  });
  //// console.log('productData', productData);

  //get item by product
  const {
    data: itemData,
    isFetching: isItemFetching,
    refetch: itemRefetch,
  } = useItemsByProduct({
    productId: openNode?.nodeType === NODE_TYPE_PRODUCT ? openNode.id : '',
    paging:
      openNode?.nodeType === NODE_TYPE_PRODUCT
        ? { page: openNode.paging.page || 1, size: PAGE_SIZE }
        : { page: 1, size: PAGE_SIZE },
  });
  //// console.log('openNode', openNode);
  //// console.log('itemData', itemData);

  //set tree: group
  useEffect(() => {
    if (groupData?.data) {
      const newGroupData = groupData.data.map((_ele: any) => ({
        ..._ele,
        nodeType: NODE_TYPE_GROUP,
        children: [],
        paging: { page: 1, nextPage: 1, size: PAGE_SIZE },
      }));
      const pagingData = groupData.paging;
      if (openNode === null) {
        if (rootPaging.nextPage <= pagingData.totalPage) {
          if (rootPaging.nextPage !== 1 && rootPaging.nextPage === pagingData.currentPage) {
            setTreeData((curData: any) => curData.concat(newGroupData));
          } else {
            //setTreeData(buildTree(newGroupData || [], undefined)); //build initial tree
            setTreeData(newGroupData); //build initial tree
          }
          //set paging
          setRootPaging({
            page: pagingData.currentPage,
            size: PAGE_SIZE,
            nextPage: pagingData.currentPage + 1,
            totalPage: pagingData.totalPage,
          });
        }
      }
    }
  }, [groupData]);

  //tree: add product/group to current group
  useEffect(() => {
    if (!isProductFetching) {
      if (productData?.data) {
        //add product child + group child
        const newItems = _.cloneDeep(treeData);
        const newProductData = productData.data.map((_ele: any) => ({
          ..._ele,
          nodeType: NODE_TYPE_PRODUCT,
          children: [],
          paging: { page: 1, nextPage: 1, size: 10 }, //for children
        }));
        const pagingData = productData.paging;
        //find selected node
        const curNode = findNode(newItems, openNode.id, {});
        if (curNode?.id && openNode.nodeType === NODE_TYPE_GROUP) {
          //set node
          if (curNode.paging.nextPage <= pagingData.totalPage) {
            if (
              curNode.paging.nextPage !== 1 &&
              curNode.paging.nextPage === pagingData.currentPage
            ) {
              //load more for product
              curNode.children = curNode.children.concat(newProductData);
            } else {
              //add group + product children
              if (groupData?.data) {
                const newGroupData = groupData.data.map((_ele: any) => ({
                  ..._ele,
                  nodeType: NODE_TYPE_GROUP,
                  children: [],
                  paging: { page: 1, nextPage: 1, size: 10 },
                }));
                curNode.children = newGroupData.concat(newProductData);
              } else {
                //add only product children - initial
                curNode.children = newProductData;
              }
            }
            //set next paging
            curNode.paging = {
              page: pagingData.currentPage,
              size: PAGE_SIZE,
              nextPage: pagingData.currentPage + 1,
              totalPage: pagingData.totalPage,
            };
          }
        }
        setTreeData(newItems);
      } else {
        if (groupData?.data && openNode != null) {
          //add child group
          const newItems = _.cloneDeep(treeData);
          const curNode = findNode(newItems, openNode.id, {});
          if (curNode?.id && curNode.nodeType === NODE_TYPE_GROUP) {
            const newGroupData = groupData.data.map((_ele: any) => ({
              ..._ele,
              nodeType: NODE_TYPE_GROUP,
              children: [],
              paging: { page: 1, nextPage: 1, size: PAGE_SIZE },
            }));
            curNode.children = newGroupData;
            setTreeData(newItems);
          }
        }
      }
    }
  }, [productData, groupData, isProductFetching]); //isGroupFetching

  //tree: add item to product
  useEffect(() => {
    if (itemData?.data) {
      const newItems = _.cloneDeep(treeData);
      const newItemData = itemData.data.map((_ele: any) => ({
        ..._ele,
        nodeType: NODE_TYPE_ITEM,
        children: [],
        paging: { page: 1, nextPage: 1, size: PAGE_SIZE }, //for children
      }));
      const pagingData = itemData.paging;
      //find selected node
      const curNode = findNode(newItems, openNode.id, {});
      if (curNode?.id && curNode.nodeType === NODE_TYPE_PRODUCT) {
        //set node
        if (curNode.paging.nextPage <= pagingData.totalPage) {
          if (curNode.paging.nextPage !== 1 && curNode.paging.nextPage === pagingData.currentPage) {
            curNode.children = curNode.children.concat(newItemData);
          } else {
            curNode.children = newItemData;
          }
          //set next paging
          curNode.paging = {
            page: pagingData.currentPage,
            size: PAGE_SIZE,
            nextPage: pagingData.currentPage + 1,
            totalPage: pagingData.totalPage,
          };
        }
      }
      setTreeData(newItems);
    }
  }, [itemData]);

  /** ===================================== HANDLER ==================================== */
  //selected node
  const handleSelectNode = (item: any) => {
    if (item.id !== selectedItem?.id) {
      setSelectedItem(item);
      onSelect && onSelect(item);
    } else {
      setSelectedItem(null);
      onSelect && onSelect(null);
    }
  };

  //load more items in parent node
  const handleLoadMore = (parent: any) => {
    const newItems = _.cloneDeep(treeData);
    const curNode = findNode(newItems, parent.id, {});
    if (curNode?.id) {
      setOpenNode(curNode);
      if (curNode.paging.page < curNode.paging.totalPage) {
        curNode.paging.page = curNode.paging.page + 1;
      }
      setTreeData(newItems);
    }
  };

  //load more for root
  const handleRootLoadMore = () => {
    setOpenNode(null);
    if (rootPaging.page < rootPaging.totalPage) {
      setRootPaging((curValue: any) => ({ ...curValue, page: curValue.page + 1 }));
    }
  };

  //new/update folder
  const handleAfterCreateUpdate = (group: any) => {
    //// console.log('group after', group);
    /**
     * parent !== null --> destination is parent
     * source: findNode
     */
    const newItems = _.cloneDeep(treeData);
    const newGroup = {
      ...group,
      nodeType: NODE_TYPE_GROUP,
      countProducts: 0,
      paging: { page: 1, nextPage: 1, size: PAGE_SIZE },
    };
    //1. delete in source if exist
    const curNode = findNode(newItems, group.id, {});
    if (curNode?.id) {
      //EDIT
      if (curNode.parent) {
        const sourceParentNode = findNode(newItems, curNode.parent.id, {}); //this is current Parent
        const destParentNode = findNode(newItems, group.parent.id, {}); //this is new Parent
        const fChildIdx = sourceParentNode.children.findIndex((_ele: any) => _ele.id === group.id);
        if (fChildIdx > -1) {
          if (sourceParentNode.id === destParentNode.id) {
            destParentNode.children[fChildIdx] = newGroup;
          } else {
            sourceParentNode.children.splice(fChildIdx, 1); //remove from source
            //add to destination
            if (destParentNode.children) {
              destParentNode.children.push(newGroup);
            } else {
              destParentNode.children = [newGroup];
            }
          }
        }
      } else {
        //root node
        const fIndex = newItems.findIndex((_ele: any) => _ele.id === group.id);
        if (fIndex > -1) {
          newItems[fIndex] = newGroup;
        }
      }
    } else {
      //NEW
      if (group.parent) {
        const destParentNode = findNode(newItems, group.parent.id, {}); //this is new Parent
        if (destParentNode?.id) {
          if (destParentNode.children) {
            destParentNode.children.push(newGroup);
          } else {
            destParentNode.children = [newGroup];
          }
        }
      } else {
        newItems.push(newGroup);
      }
    }
    setTreeData(newItems);
  };

  //add child to item
  const handleAddGroup = (item: any) => {
    setSelectedGroup({ parent: item });
    setIsOpenWrite(true);
  };

  //edit item
  const handleEditGroup = (parent: any, item: any, depth: number) => {
    setSelectedGroup({ ...item, parent });
    setIsOpenWrite(true);
  };

  /** ===================================== RENDER ==================================== */
  //empty node
  const renderEmptyNode = (parent: any) => {
    return (
      <div
        key={parent?.id}
        className="d-flex align-items-center justify-content-center ht-30 rounded mg-b-5"
        style={{ backgroundColor: 'var(--background-hover-color)' }}
      >
        {/* <Folder
          // className="mg-r-5" style={{ width: '15px' }}
          size={20}
          className="mg-x-5"
        /> */}
        <span className="text-truncate mg-l-5">No data available.</span>
        <button type="button" className="btn btn-link" onClick={() => handleAddGroup(parent)}>
          <PlusCircle className="tx-bold" />
        </button>
      </div>
    );
  };

  //render item
  const renderNode = (parent: any, node: any, depth: number) => {
    return (
      <div
        className={classNames('tree-btn-wrap mg-b-3 rounded')}
        style={{
          backgroundColor: selectedItem?.id === node.id ? 'lightskyblue' : 'transparent',
        }}
      >
        {node.nodeType === NODE_TYPE_GROUP && (
          <Folder color="orange" fill="rgba(253,126,20,.3)" size={20} className="mg-x-5" />
        )}
        {node.nodeType === NODE_TYPE_PRODUCT && (
          <Package color="#bcdc97" fill="rgba(253,126,20,.3)" size={20} className="mg-x-5" />
        )}
        {node.nodeType === NODE_TYPE_ITEM && (
          <FormIcon icon="product_unit" iconType="icon" color="lightgreen" className="mg-x-5-f" />
        )}
        {node.nodeType !== NODE_TYPE_ITEM && (
          <button
            type="button"
            className="btn btn-xs btn-link link-03 btn-icon"
            data-toggle="collapse"
            data-target={'#node' + treeId + node.id}
            aria-expanded="false"
            onClick={() => setOpenNode(node)}
          >
            <ChevronDown />
          </button>
        )}
        <div className="d-flex text-truncate cursor-default" onClick={() => handleSelectNode(node)}>
          {node.name}
          {node.nodeType !== NODE_TYPE_ITEM && (
            <span className="badge badge-dark pd-y-5 mg-l-5">
              {node.nodeType === NODE_TYPE_GROUP ? node.countProducts : node.countItems}
            </span>
          )}
          {(isProductFetching || isItemFetching) && node.id === openNode?.id && <SpanLoading />}
        </div>
        {node.nodeType === NODE_TYPE_GROUP && (
          <div className="tree-btn-actions">
            <button
              type="button"
              className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
              data-han-tooltip="Add"
              onClick={() => handleAddGroup(node)}
            >
              <Plus />
            </button>
            <button
              type="button"
              className="btn btn-xs btn-link link-03 btn-icon han-tooltip--bottom"
              data-han-tooltip="Edit"
              onClick={() => handleEditGroup(parent, node, depth)}
            >
              <Edit2 />
            </button>
          </div>
        )}
      </div>
    );
  };

  //all tree
  const renderTree = (parent: any, children: any[], depth: number) => {
    // const marginLeft = 10 + 20 * depth;
    const marginLeft = 30;
    const leftClass = 'mg-l-' + marginLeft;
    return (
      <div key={parent?.id} className={classNames(`rounded ${depth > 0 ? leftClass : ''}`)}>
        {children.map((_item: any, index: number) => (
          <div key={index}>
            {renderNode(parent, _item, depth)}
            <div
              id={'node' + treeId + _item.id}
              className={classNames('collapse fade mg-r-5', {
                show: _item?.children?.length > 0,
              })}
            >
              {renderTree(_item, _item?.children || [], depth + 1)}
            </div>
          </div>
        ))}

        {(isProductFetching || isItemFetching) && parent?.id === openNode?.id
          ? ''
          : children?.length === 0
          ? renderEmptyNode(parent)
          : ''}

        {parent?.paging?.page < parent?.paging?.totalPage && (
          <Button
            outline
            color="link"
            className="link-03 pd-x-5"
            onClick={() => handleLoadMore(parent)}
            name="More..."
          />
        )}
      </div>
    );
  };

  //tree context
  const TreeMemo = useMemo(() => {
    return (
      <>
        {renderTree(null, treeData, 0)}
        {isGroupFetching && openNode === null && <SpanLoading />}
        {rootPaging?.page < rootPaging?.totalPage && (
          <Button
            outline
            color="link"
            className="link-03 pd-x-5"
            onClick={() => handleRootLoadMore()}
            name="More..."
          />
        )}
      </>
    );
  }, [treeData, selectedItem, isGroupFetching, isProductFetching, isItemFetching]);

  //// console.log('product treeData', treeData);
  //main render
  return (
    <>
      <div className={classNames('d-flex flex-grow-1 flex-column', className)}>
        <div className="card w-100">
          <div className="card-header bg-gray-100 pd-x-20 pd-y-10">
            <div className="list-wrap">
              <table className="table dataTable">
                <thead>
                  <tr>
                    <th className="sorting bd-0-f pd-y-0-f pd-l-0-f">Name</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="card-body pd-10">
            <div
              className="kb-tree list-unstyled w-100 mg-b-0 scroll-box"
              style={{ maxHeight: 'calc(100vh - 220px)' }}
            >
              {isGroupFetching && treeData.length === 0 && <Loading />}
              {treeData.length > 0 && TreeMemo}
              {treeData.length === 0 && (
                <NoData icon="Package" label="No product group(s) available." />
              )}
            </div>
          </div>
        </div>
        <div className="d-flex mg-t-5">
          <Button
            color="link"
            size="sm"
            icon="Plus"
            iconClass="mg-r-5"
            name="Add Group"
            className="pd-x-0"
            onClick={() => {
              setSelectedGroup(null);
              setIsOpenWrite(true);
            }}
          />
        </div>
      </div>
      {isOpenWrite && (
        <WriteProductGroupForm
          title="New Product Group"
          size="md"
          isOpen={isOpenWrite}
          onClose={() => setIsOpenWrite(false)}
          data={selectedGroup}
          onSuccess={handleAfterCreateUpdate}
        />
      )}
    </>
  );
};

export default ProductTree;
