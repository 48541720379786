import React, { VFC } from 'react';
import WriteFrom from '@product/unit/containers/write-form';

const WriteFormDesktopLayout1: React.FC<any> = (props: any) => {
  return (
    <>
      <WriteFrom {...props} />
    </>
  );
};

export default WriteFormDesktopLayout1;
