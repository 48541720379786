import React from 'react';
import classnames from 'classnames';
import ProductAutoComplete from '../../product-auto-complete';

const Edit: React.FC = (props: any) => {
  const { value, errors, componentProps, onChange } = props;

  return (
    <div>
      <div className={classnames({ 'is-required': errors.isRequired })}>
        <ProductAutoComplete {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
