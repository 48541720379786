import { identity } from 'lodash';
import { gql } from 'graphql-request';

export const ADD_NEW_PRODUCT_ITEM = gql`
  mutation q($items: ProductItemInput!) {
    product_createItems(items: $items) {
      ids
    }
  }
`;

export const GET_BULK_CODE_NEXT_ID = gql`
  mutation ($menu: String!, $bulk: Int) {
    setting_nextIdBulk(menu: $menu, bulk: $bulk) {
      codes
      setting {
        id
        prefixString
        autoGenerate
        nextNumber
      }
    }
  }
`;

export const GET_ITEM_SKU_LIST = gql`
  query q($itemUnitId: String!) {
    product_itemUnitSku(itemUnitId: $itemUnitId) {
      sku
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation m($item: ItemData!) {
    product_updateItem(item: $item) {
      id
    }
  }
`;

export const GET_ITEM_UNIT_INVENTORY = gql`
  query q($id: String!) {
    product_itemUnit(id: $id) {
      id
      name
      code
      itemCategory {
        keyName
        languageKey
      }
      itemType {
        keyName
        languageKey
      }
      prod {
        id
        name
        code
        type {
          keyName
          languageKey
        }
        canBeSold
        groupId
        group {
          id
          languageKey
        }
      }
      unit {
        id
        name
      }
      unitVal {
        id
        name
        qty
      }
      attrValues {
        id
        name
        attr {
          id
          name
        }
      }
      # sku
      # openStock
      # replenishmentPoint
      # traceability
      # reserveMethod
      # trackingType
      # batchNo
      # manufacturerDate
      # expiredDate
    }
  }
`;

export const UPDATE_INVENTORY_ITEM_WAREHOUSES = gql`
  mutation m($inventoryItem: InventoryItem!) {
    product_updateInventoryItemWarehouses(inventoryItem: $inventoryItem) {
      id
    }
  }
`;

export const GET_INVENTORY_ITEM_WAREHOUSES = gql`
  query q($inventoryItemId: String!) {
    product_inventoryItemWarehouses(inventoryItemId: $inventoryItemId) {
      results {
        id
        warehouse {
          id
          name
        }
        items {
          sku
          stockOnHand
          locations {
            location {
              id
              name
              whId
              warehouse {
                id
                name
              }
              buildingId
              building {
                id
                name
                sname
              }
              floorId
              floor {
                id
                name
                sname
                priority
              }
              storageZone {
                id
                name
                sname
              }
              rowId
              rackId
              levelId
              binId
            }
            traceabilityNo
            qtyAllocated
          }
        }
      }
    }
  }
`;

export const GET_INVENTORY_ITEM_STOCK_TOTAL = gql`
  query q($itemUnitId: String!) {
    product_inventoryItemStockTotal(itemUnitId: $itemUnitId) {
      itemUnitId
      stockOnHand
      reserved
      backOrder
      awaiting
      available
    }
  }
`;

export const GET_ITEM_UNIT_SUBSCRIPTION = gql`
  query q($id: String!) {
    product_itemUnit(id: $id) {
      id
      name
      prod {
        id
        name
        code
        type {
          keyName
          languageKey
        }
        canBeSold
      }
      useSubscribe
      subTpl {
        id
        name
      }
    }
  }
`;

export const PRODUCT_ITEM_UNIT_UPDATE = gql`
  mutation m($item: ItemData!) {
    product_updateItem(item: $item) {
      id
    }
  }
`;

export const GET_ITEM_UNIT_ASSOCIATED_ITEMS = gql`
  query q($id: String!) {
    product_item(id: $id) {
      id
      name
      associatedItems {
        qty
        item {
          id
          name
          code
          sku
          unitVal {
            id
            name
            qty
          }
          attrValues {
            id
            name
            attr {
              id
              name
            }
          }
          basePrices {
            amount
            currency
          }
          costPrices {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const GET_ITEM_UNIT_ASSIGNTO = gql`
  query q($id: String!) {
    product_itemAssignTo(id: $id) {
      results {
        id
        name
      }
    }
  }
`;

export const CREATE_ITEM_UNIT_ASSIGN_REP = gql`
  mutation m($ids: [String!]!, $assignTo: [AssignToInput!]!) {
    product_createItemAssignTo(ids: $ids, assignTo: $assignTo) {
      ids
    }
  }
`;

export const DELETE_ITEM_UNIT_ASSIGN_REP = gql`
  mutation m($ids: [String!]!, $repIds: [String!]!) {
    product_deleteItemAssignTo(ids: $ids, repIds: $repIds) {
      ids
    }
  }
`;

export const GET_ITEM_VENDOR = gql`
  query q($id: String!) {
    product_item(id: $id) {
      id
      name
      vendors {
        id
        name
        code
        photo
        type {
          languageKey
          keyName
        }
        emails {
          id
          label {
            languageKey
            label
          }
          labelValue
          email
        }
        phones {
          id
          label {
            languageKey
            label
          }
          labelValue
          country
          phoneNumber
          extension
        }
      }
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation m($ids: [String!]) {
    product_deleteItem(ids: $ids) {
      ids
    }
  }
`;

export const GET_ITEM_VIEW = gql`
  query q($id: String!) {
    product_item(id: $id) {
      id
      name
      code
      prod {
        id
        name
        code
        type
        canBeSold
      }
      itemType
      inventoryType
      sku
      unit {
        id
        name
      }
      unitVal {
        id
        name
        qty
      }
      attrValues {
        id
        name
        attr {
          id
          name
        }
      }
      basePrices {
        amount
        currency
      }
      costPrices {
        amount
        currency
      }
      purchasePrices {
        amount
        currency
      }
      images {
        id
        name
        orgName
      }
    }
  }
`;
