import { lazy } from 'react';

export const ProductTypeView = lazy(
  () => import('@product/product/containers/view-field/product-type'),
);
export const ProductGroupView = lazy(
  () => import('@product/product/containers/view-field/product-group'),
);
export const ProductBaseUnitView = lazy(
  () => import('@product/product/containers/view-field/base-unit'),
);
export const ProductAttributesView = lazy(
  () => import('@product/product/containers/view-field/attributes'),
);
export const CustomerView = lazy(
  () => import('@customer/customer/containers/view-field/customer-view'),
);
export const ProductWarrantyView = lazy(
  () => import('@product/product/containers/view-field/product-warranty'),
);
export const ProductCostOfGoodsView = lazy(
  () => import('@product/product/containers/view-field/product-cost-of-goods'),
);
export const CountryOriginView = lazy(
  () => import('@product/item/containers/view-field/country-origin'),
);
