import {
  KEY_ITEM_TYPE,
  KEY_ITEM_NAME,
  KEY_ITEM_PREPAID_AMOUNT,
  KEY_ITEM_ID,
} from '@product/item/config/key-names';
import {
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';

//step 1 - validate base unit, product name, product group
export const unitIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  if (data.id && data.unitNames.length === 0) {
    isValid = false;
  }
  return isValid;
};

//step 1 - required attribute
export const attributeIsRequired = (data: any, itemType: string) => {
  //const itemType = getValues(KEY_ITEM_TYPE);
  if (!data) return false;
  let isValid = true;
  if (itemType === PRODUCT_ITEM_TYPE_GENERAL) {
    if (data.applyItemAttribute && data.attributes.length === 0) {
      isValid = false;
    }
    if (data.applyItemAttribute && data.attributes.length > 0) {
      data.attributes.map((_attr: any) => {
        if (_attr.activeValue.length === 0) {
          isValid = false;
        }
      });
    }
  }
  return isValid;
};

//step 2 - validate basic info
export const basicIsRequired = (
  data: any,
  product: any,
  category?: string,
  type?: string,
  prepaidType?: string,
) => {
  if (!data) return false;
  let isValid = true;
  data.map((_item: any) => {
    // prices validator
    // _item.prices.map((_price: any) => {
    //   if (product?.canBeSold) {
    //     if (
    //       parseFloat(_price.base_price?.value || 0) == 0 ||
    //       parseFloat(_price.best_price?.value || 0) == 0 ||
    //       parseFloat(_price.cost_price?.value || 0) == 0
    //     ) {
    //       isValid = false;
    //     }
    //   }

    //   if (!product?.canBeSold && product?.type === PRODUCT_TYPE_PRODUCED) {
    //     if (parseFloat(_price.cost_price?.value || 0) == 0) {
    //       isValid = false;
    //     }
    //   }

    //   if (!product?.canBeSold && product?.type === PRODUCT_TYPE_PURCHASE) {
    //     if (parseFloat(_price.purchase_price?.value || 0) == 0) {
    //       isValid = false;
    //     }
    //   }
    // });

    // SKU validator
    if (category === PRODUCT_ITEM_CATEGORY_INVENTORY && type != PRODUCT_ITEM_TYPE_PREPAID) {
      if (!_item?.sku || _item?.sku === '') {
        isValid = false;
      }
    }

    // Name validator
    if (!_item?.name || _item?.name === '') {
      isValid = false;
    }

    // CODE validator
    if (!_item?.code || _item?.code === '') {
      isValid = false;
    }
  });
  return isValid;
};

//step 3 - inventory
export const inventoryIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  data.map((_item: any) => {
    _item.inventories.map((_inventory: any) => {
      if (_inventory.locations.length === 0) {
        isValid = false;
      }
    });
  });
  return isValid;
};

//step 4 - batch
export const batchIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  // data.map(_item => {
  //     _item.inventories.map(_inventory => {
  //         if(_inventory.locations.length === 0) {
  //             isValid = false;
  //         }
  //     })
  // })
  return isValid;
};

//step 5 - prepaid
export const prepaidIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  data.map((_item: any) => {
    _item.prepaid.map((_pre: any) => {
      if (_pre.name.length === 0) {
        isValid = false;
      }
    });
  });
  return isValid;
};

//prepaid item for item type
export const prepaidItemIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  data?.map((_item: any) => {
    if (_item?.[KEY_ITEM_NAME]?.length === 0 || _item?.[KEY_ITEM_ID]?.length === 0) {
      isValid = false;
    }
    if (_item?.[KEY_ITEM_PREPAID_AMOUNT]?.length === 0) {
      isValid = false;
    }
  });
  return isValid;
};

export const associatedIsRequired = (data: any) => {
  if (!data) return false;
  let isValid = true;
  data?.map((_item: any) => {
    if (!_item?.item || _item?.item?.id == '' || _item?.contained_qty == '') {
      isValid = false;
    }
  });
  return isValid;
};
