import { ITab } from '@base/types/interfaces/app';

import Timeline from '@base/containers/timeline';
import Notes from '@base/containers/note';
import Items from '@product/product/containers/product-items';
// import Pricing from '@product/product/containers/pricing';
// import Purchase from '@demo-page/product/desktop/theme1/view-new/purchase';
// import Transaction from '@demo-page/product/desktop/theme1/view-new/transaction';
// import StockMovement from '@demo-page/product/desktop/theme1/view-new/stockMovement';
// import LinkedProduct from '@demo-page/product/desktop/theme1/view-new/linkedProduct';
// import BillOfMaterial from '@product/product/containers/product-bill-materials';

export const TABS: ITab[] = [
  {
    order: 1,
    default: true,
    label: 'Timeline',
    path: 'timeline',
    tabComponent: Timeline,
  },
  {
    order: 2,
    label: 'Item',
    path: 'item',
    tabComponent: Items,
  },
  // {
  //   order: 3,
  //   label: 'Pricing',
  //   path: 'pricing',
  //   tabComponent: Pricing,
  // },
  // {
  //   order: 4,
  //   label: 'Transactions',
  //   path: 'transaction',
  //   tabComponent: Transaction,
  // },
  // {
  //   order: 5,
  //   label: 'StockMovement',
  //   path: 'stock_movement',
  //   tabComponent: StockMovement,
  // },
  // {
  //   order: 6,
  //   label: 'Purchase',
  //   path: 'purchase',
  //   tabComponent: Purchase,
  // },
  // {
  //   order: 7,
  //   label: 'Bill of Materials',
  //   path: 'bill_of_material',
  //   tabComponent: BillOfMaterial,
  // },
  // {
  //   order: 8,
  //   label: 'LinkedProduct',
  //   path: 'linked_product',
  //   tabComponent: LinkedProduct,
  // },
  {
    order: 9,
    label: 'Notes',
    path: 'note',
    tabComponent: Notes,
  },
];
