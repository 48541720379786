import React, { useEffect, useState } from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import WriteForm from '../write-form';
import {
  KEY_BASE_UNIT,
  KEY_PRODUCT_ATTRIBUTE,
  KEY_USE_ATTR,
} from '@product/product/config/key-names';

import { KEY_ITEM_BASE_UNIT, KEY_ITEM_ATTRIBUTES } from '@product/item/config/key-names';

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteModal: React.FC<any> = (props: any) => {
  const { fullScreen, setFullScreen, product, onClose, onReload, showCanvas = false } = props;

  //const [value, setValue] = useState<any>(null);
  //const [prodData, setProdData] = useState<any>(product);

  // useEffect(() => {
  //   setValue({
  //     [KEY_ITEM_BASE_UNIT]: {
  //       ...prodData?.[KEY_BASE_UNIT],
  //       id: prodData?.[KEY_BASE_UNIT]?.id,
  //       name: prodData?.[KEY_BASE_UNIT]?.name,
  //       unitValues: prodData?.[KEY_BASE_UNIT]?.unitValues,
  //     },
  //     [KEY_ITEM_ATTRIBUTES]: {
  //       applyItemAttribute: prodData?.[KEY_USE_ATTR],
  //       attributes: prodData?.[KEY_PRODUCT_ATTRIBUTE],
  //     },
  //   });
  // }, [prodData]);

  // const onProductChange = (productSelected: any) => {
  //   const newProduct = {
  //     ...productSelected,
  //     unitId: { ...productSelected?.unit },
  //     type: productSelected?.type?.keyName,
  //     isSubmitSuccessful: false,
  //   };
  //   setProdData(newProduct);
  // };

  // render
  return (
    <div className="pd-5">
      <div className="bd-b pd-b-10">
        {/* <NoteAndError errors={errors} /> */}
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <WriteForm
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        product={product}
        //value={value}
        onClose={onClose}
        onReload={onReload}
        //onChange={onProductChange}
        showCanvas={showCanvas}
      />
    </div>
  );
};

export default withMiModal(WriteModal);
