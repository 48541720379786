import { useState, useEffect } from 'react';

/**
 *
 * @param {*} props
 * @returns
 */
const BasicInfo: React.FC<any> = (props: any) => {
  const { value, onChange } = props;
  //state
  const [baseValue, setBaseValue] = useState({ id: '', name: '', active: 'y' });

  //init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(baseValue)) {
        setBaseValue(value);
      }
    } else {
      // setBaseValue({ name: '', active: 'y' });
    }
  }, [value]);

  //value change
  const handleValueChange = (keyAttribute: any, newValue: any) => {
    const newBase: any = { ...baseValue };
    newBase[keyAttribute] = newValue;
    setBaseValue(newBase);
    //callback
    onChange && onChange(newBase);
  };

  //render
  return (
    <div className="form-row">
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">
          Base Unit<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control wd-500 mx-wd-100p"
          disabled={baseValue?.id != ''}
          value={baseValue.name}
          onChange={(e) => handleValueChange('name', e.target.value)}
        />
        <div className="mg-t-5 tx-orange">
          When the base unit is created, it will be automatically set as the unit name, quantity 1
        </div>
      </div>
      <div className="form-col col-lg-6 form-group">
        <label className="form-item-title">
          Status<span className="tx-danger">*</span>
        </label>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="unitStatusSwitch1"
            disabled={baseValue?.id != ''}
            checked={baseValue.active === 'y' ? true : false}
            onChange={(e) => handleValueChange('active', e.target.checked ? 'y' : 'n')}
          />
          <label className="custom-control-label" htmlFor="unitStatusSwitch1">
            Active
          </label>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
