import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import Icon, { mainIcons } from '@base/assets/icons/svg-icons';
import { PRODUCT_MENUS } from '@base/config/route-menus';
import { slideToObject } from '@base/utils/helpers/array-utils';

export const categoryOptions = slideToObject(PRODUCT_MENUS, 'value', 'label');

export const PRODUCT_ADD_OPTIONS = {
  [MENU_PRODUCT_PRODUCT]: {
    name: 'Product',
    icon: mainIcons?.product,
  },
  [MENU_PRODUCT_ITEM]: {
    name: 'Item',
    icon: mainIcons?.product,
  },
  // [MENU_PRODUCT_COMPONENT]: {
  //   name: 'Component',
  //   icon: mainIcons?.product,
  // },
  [MENU_PRODUCT_UNIT]: {
    name: 'Unit',
    icon: mainIcons?.product,
  },
  // [MENU_PRICELIST]: {
  //   name: 'Price List',
  //   icon: mainIcons?.product,
  // },
  // [MENU_DISCOUNT]: {
  //   name: 'Discount',
  //   icon: mainIcons?.product,
  // },
};
