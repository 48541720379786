import { atom } from 'recoil';

export const productWriteOptionAtom = atom({
  key: 'productWriteOptionAtom',
  default: {
    writeMode: 'list', //view
    writeParam: {},
    writeType: '', //'product', 'item', ....
    isOpenWrite: false,
  },
});
