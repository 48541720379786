import { lazy } from 'react';

const ProductMain = lazy(() => import('@product/product/pages'));

export default [
  {
    path: '/product/product/*',
    component: ProductMain,
  },
];
