import { ModalContainer } from '@base/containers';
import React, { useEffect, useState } from 'react';
import { UserAutoComplete } from '@base/containers';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import {
  CHANGE_PRODUCT_ASSIGN_REP,
  CREATE_PRODUCT_ASSIGN_REP,
  DELETE_PRODUCT_ASSIGN_REP,
  UPDATE_PRODUCT_BATCH,
} from '@product/product/services/graphql';
import classNames from 'classnames';
import { Select } from '@base/components/form';

interface IProps {
  isOpen: boolean;
  mode: string; // update, add, delete
  onClose?: () => void;
  onSuccess?: any;
  ids: string[];
  products?: any[];
}

const ChangeAssign: React.FC<IProps> = (props: IProps) => {
  const { isOpen, mode, onClose, onSuccess, ids, products } = props;

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [assignTo, setAssignTo] = useState<any[]>([]);
  const [reps, setReps] = useState<any[]>([]);
  const [fromRep, setFromRep] = useState<any>(null);
  const [toRep, setToRep] = useState<any>(null);

  useEffect(() => {
    let items: any[] = [];
    products?.map((prod: any) => {
      if (ids?.indexOf(prod?.id) >= 0) {
        prod?.assignTo?.map((ass: any) => {
          if (items?.findIndex((v: any) => v.id === ass.id) < 0) {
            items.push({ ...ass, value: ass.id, label: ass.name });
          }
        });
      }
    });
    setReps(items);
  }, [mode, ids, products]);

  const handleAssignToChange = (users: any[]) => {
    let newAssignTo: any[] = [];
    users?.length &&
      users?.map((user: any) => {
        if (user?.id != '') {
          newAssignTo.push({
            user: {
              id: user?.id,
              name: user?.name,
            },
            group: {},
          });
        }
      });
    setAssignTo(newAssignTo);
  };

  const mutationAssignTo: any = useMutationPost<BaseMutationResponse>(
    mode == 'add'
      ? CREATE_PRODUCT_ASSIGN_REP
      : mode == 'update'
      ? CHANGE_PRODUCT_ASSIGN_REP
      : DELETE_PRODUCT_ASSIGN_REP,
    mode == 'add'
      ? 'product_createAssignTo'
      : mode == 'update'
      ? 'product_updateProductBatch'
      : 'product_deleteAssignTo',
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Change reps successfully!');
        setLoading(false);
        onSuccess && onSuccess();
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Change reps failed: ' + JSON.parse(error).message);
        setLoading(false);
      },
    },
  );

  return (
    <>
      {isOpen && (
        <ModalContainer
          isOpen={isOpen}
          onNo={() => {
            onClose && onClose();
          }}
          onYes={() => {
            let params: any = {};
            if (mode == 'delete') {
              params = {
                ids: ids,
                refIds: assignTo?.map((assign: any) => assign?.user?.id),
              };
            } else if (mode == 'add') {
              params = {
                ids: ids,
                assignTo: assignTo,
              };
            } else if (mode == 'update') {
              params = {
                ids: ids,
                from: {
                  user: {
                    id: fromRep?.id,
                    name: fromRep?.name,
                  },
                  group: {},
                },
                to: {
                  user: {
                    id: toRep?.id,
                    name: toRep?.name,
                  },
                  group: {},
                },
              };
            }
            mutationAssignTo.mutate(params);
          }}
          saveLabel={mode == 'add' ? 'Add' : mode == 'delete' ? 'Delete' : 'Change'}
          title={mode == 'add' ? 'Add Reps' : mode == 'delete' ? 'Delete Reps' : 'Change Reps'}
          isLoading={loading}
        >
          <div
            className={classNames({ 'pd-20': mode != 'update' }, { 'pd-t-10': mode == 'update' })}
          >
            {mode == 'update' ? (
              <>
                <div className="form-group col-12">
                  <label className="form-item-title">
                    From<span className="tx-danger">*</span>
                  </label>
                  <Select
                    options={reps}
                    isSearchable={false}
                    onChange={(user: any) => {
                      setFromRep(user);
                    }}
                  />
                </div>
                <div className="form-group col-12">
                  <label className="form-item-title">
                    To<span className="tx-danger">*</span>
                  </label>
                  <UserAutoComplete
                    single={true}
                    onChange={(user: any) => {
                      setToRep(user);
                    }}
                  />
                </div>
              </>
            ) : mode == 'delete' ? (
              <Select
                options={reps}
                isSearchable={false}
                isMulti={true}
                onChange={handleAssignToChange}
              />
            ) : (
              <UserAutoComplete onChange={handleAssignToChange} />
            )}
          </div>
        </ModalContainer>
        // <AssignRepModal
        //   show={isOpen}
        //   onClose={onClose}
        //   onAssign={handleAssignToChange}
        //   isAssigning={mutationAssignTo.isLoading}
        // />
      )}
    </>
  );
};

export default ChangeAssign;
