import React, { useState } from 'react';
import { AssignCustomerContainer } from '@base/containers';

interface IRelatedCustomerProps {
  placement?: string;
  sourceId: string;
  sourceMenu?: string;
  menuTab?: string;
  menuType?: string;
  readOnly?: boolean;
}

/**
 *
 * @param {*} props
 * @returns
 */
const RelatedCustomer: React.FC<IRelatedCustomerProps> = (props: IRelatedCustomerProps) => {
  const {
    placement,
    sourceId,
    sourceMenu,
    menuTab, //on url
    menuType, //for filter 'account', 'contact'
    readOnly = true,
  } = props;

  const [items, setItems] = useState<any[]>([]);

  return (
    <AssignCustomerContainer
      items={items}
      placement={placement}
      isLoading={false}
      menuSourceName={sourceMenu}
      menuTab={menuTab}
      menuType={menuType}
      // onAssign={onAssignCustomerContact}
      // onDelete={onDeleteCustomerContact}
      readOnly={readOnly}
    />
  );
};

RelatedCustomer.defaultProps = {
  placement: 'auto',
  sourceId: '',
  sourceMenu: '',
  menuTab: '',
  menuType: '',
};

export default RelatedCustomer;
