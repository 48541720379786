import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Button } from '@base/components/form';
import { Minus, Plus } from 'react-feather';
import usePosts from '@base/hooks/usePosts';
import { GET_MENU_SETTINGS } from '@settings/general-setting/services/graphql/format';
import SKUInput from '@product/item/containers/sku-input';
import {
  SKU_GENERATOR_OPTION_ITEM_NAME,
  SKU_GENERATOR_OPTION_PRODUCT_NAME,
  SKU_GENERATOR_OPTION_UNIT_NAME,
} from '@product/item/config/sku';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = '', errors = {}, onChange, metadata } = props;

  const [sku, setSku] = useState<string[]>([]);
  const [skuSetting, setSkuSetting] = useState<any>(null);

  // GET SKU format setting
  const params = {
    menus: ['product'],
    keys: ['product_sku_setting'],
  };
  const { isLoading: isSkuLoading, data } = usePosts<any>(
    ['setting_menusSettings', JSON.stringify(params)],
    GET_MENU_SETTINGS,
    params,
  );
  useEffect(() => {
    if (data?.data?.length) {
      const newSetting = JSON.parse(data?.data?.[0]?.value);
      setSkuSetting({ ...newSetting, id: data?.data?.[0]?.id });
    }
  }, [data]);

  useEffect(() => {
    if (value && JSON.stringify(value) != JSON.stringify(sku)) {
      setSku(value);
    }
  }, [value]);

  const handleAddSku = () => {
    const newSku = [...sku];
    newSku.push('');
    setSku(newSku);

    onChange && onChange(newSku);
  };

  const handleRemoveSku = (index: number) => {
    const newSku = [...sku];
    newSku.splice(index, 1);
    setSku(newSku);

    onChange && onChange(newSku);
  };

  const handleOnChangeRow = (index: number, newItem: any) => {
    const newSku = [...sku];
    newSku[index] = newItem?.sku ?? '';
    setSku(newSku);
    onChange && onChange(newSku);
  };

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <ul className="list-group list-group-flush">
          {sku &&
            sku.map((vSku: string, index: number) => {
              return (
                <li
                  key={index}
                  className={classnames(
                    'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                    { 'pd-t-0': index === 0 },
                  )}
                >
                  <div className="d-flex flex-column">
                    <div className="form-group pd-b-0 mg-b-0 d-flex" style={{ minWidth: '250px' }}>
                      <SKUInput
                        data={{
                          ...metadata,
                          [SKU_GENERATOR_OPTION_ITEM_NAME]: metadata?.name ?? '',
                          [SKU_GENERATOR_OPTION_PRODUCT_NAME]: metadata?.prod?.name ?? '',
                          attributes: metadata?.attrValues ?? [],
                          [SKU_GENERATOR_OPTION_UNIT_NAME]: metadata?.unitVal?.name ?? '',
                        }}
                        defaultSkuSetting={skuSetting}
                        value={vSku ?? ''}
                        values={sku ?? []}
                        onChange={(data: any) => handleOnChangeRow(index, data)}
                      />
                      {sku.length > 1 && (
                        <Button
                          color="link"
                          className="tx-danger"
                          onClick={() => handleRemoveSku(index)}
                        >
                          <Minus className="mg-r-5" />
                        </Button>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="d-flex flex-grow-1 justify-content-between">
          <Button color="link" className="tx-nowrap" onClick={() => handleAddSku()}>
            <Plus className="mg-r-5" />
            Add Sku
          </Button>
        </div>
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
