import * as keyNames from '@product/product/config/key-names';
import { KEY_ITEM_BASIC_INFO, KEY_ITEM_WARRANTY_PERIOD } from '@product/item/config/key-names';
import { PRODUCT_RELATED_TYPE } from '@product/main/config/constants';

/**
 * mapping params in API for write product
 * @param {*} formData
 * @returns
 */
export const generateProductPayload = (formData: any = {}) => {
  let newParams: any = {};

  const { ...remainParams } = formData;

  newParams = {
    ...newParams,
    ...remainParams,
  };

  // newParams[keyNames.KEY_PRODUCT_ID] = formData[keyNames.KEY_PRODUCT_ID];
  // newParams[keyNames.KEY_PRODUCT_TYPE] = formData[keyNames.KEY_PRODUCT_TYPE]?.type ?? 'none';
  // newParams[keyNames.KEY_PRODUCT_TYPE_BE_SOLD] =
  //   formData[keyNames.KEY_PRODUCT_TYPE]?.canSold ?? true;
  // newParams[keyNames.KEY_PRODUCT_NAME] = formData[keyNames.KEY_PRODUCT_NAME];
  // newParams[keyNames.KEY_PRODUCT_BRAND] = formData[keyNames.KEY_PRODUCT_BRAND];
  // newParams[keyNames.KEY_SALE_START_DATE] = new Date(
  //   formData[keyNames.KEY_SALE_START_DATE],
  // ).toISOString();
  // newParams[keyNames.KEY_SALE_END_DATE] = new Date(
  //   formData[keyNames.KEY_SALE_END_DATE].dataTime,
  // ).toISOString();
  // newParams[keyNames.KEY_SALE_NO_END_DATE] = formData[keyNames.KEY_SALE_END_DATE]?.isUnlimit;
  // newParams[keyNames.KEY_RETURNABLE] = formData[keyNames.KEY_RETURNABLE] === 'n' ? false : true;
  // newParams[keyNames.KEY_PRODUCT_COST_OF_GOODS] = parseInt(
  //   formData[keyNames.KEY_PRODUCT_COST_OF_GOODS] || 0,
  // );
  // newParams[keyNames.KEY_PRODUCT_ACTIVE] = formData[keyNames.KEY_PRODUCT_ACTIVE];

  newParams.group = {
    id: formData[keyNames.KEY_PRODUCT_GROUP]?.id,
    name: formData[keyNames.KEY_PRODUCT_GROUP]?.name,
  };
  newParams.unit = {
    id: formData[keyNames.KEY_BASE_UNIT]?.value,
    name: formData[keyNames.KEY_BASE_UNIT]?.label,
  };
  newParams.useAttr = formData[keyNames.KEY_PRODUCT_ATTRIBUTE]?.applyItemAttribute;
  newParams.attributes = formData[keyNames.KEY_PRODUCT_ATTRIBUTE]?.attributes?.map((_ele: any) => ({
    id: _ele?.value,
    name: _ele?.label,
  }));

  // // MORE INFO: hidden in new UI
  // if (formData[keyNames.KEY_PRODUCT_COUNTRY_ORIGIN]) {
  //   newParams[keyNames.KEY_PRODUCT_COUNTRY_ORIGIN] =
  //     formData[keyNames.KEY_PRODUCT_COUNTRY_ORIGIN]?.value || '';
  // }
  // if (formData[keyNames.KEY_PRODUCT_DESCRIPTION]) {
  //   newParams[keyNames.KEY_PRODUCT_DESCRIPTION] = formData[keyNames.KEY_PRODUCT_DESCRIPTION];
  // }
  // if (formData[KEY_ITEM_WARRANTY_PERIOD]) {
  //   newParams.warranty = {
  //     period: parseInt(formData[KEY_ITEM_WARRANTY_PERIOD]?.value),
  //     unit: formData[KEY_ITEM_WARRANTY_PERIOD]?.option,
  //   };
  // }
  // if (formData[keyNames.KEY_RELATED_PRODUCTS]) {
  //   newParams[keyNames.KEY_RELATED_PRODUCTS] = formData[keyNames.KEY_RELATED_PRODUCTS]?.map(
  //     (_ele: any) => ({
  //       prodId: _ele.id,
  //       prodName: _ele.name,
  //       type: PRODUCT_RELATED_TYPE[_ele.type.value],
  //     }),
  //   );
  // }
  if (formData[keyNames.KEY_ASSIGN_TO]) {
    newParams[keyNames.KEY_ASSIGN_TO] = formData[keyNames.KEY_ASSIGN_TO].map((_ele: any) => ({
      user: {
        id: _ele.id ? _ele.id : '',
        name: _ele.name ? _ele.name : '',
      },
      group: {
        id: _ele.properties?.crmGroups?.length > 0 ? _ele.properties?.crmGroups[0]?.id : '',
        name: _ele.properties?.crmGroups?.length > 0 ? _ele.properties?.crmGroups[0]?.name : '',
      },
    }));
  }
  // if (formData[keyNames.KEY_VENDOR]) {
  //   newParams[keyNames.KEY_VENDOR] = formData[keyNames.KEY_VENDOR].map((_ele: any) => ({
  //     linkId: _ele.value,
  //     linkName: _ele.label,
  //   }));
  // }
  // if (formData[keyNames.KEY_PRODUCT_MANUFACTURERS]) {
  //   newParams[keyNames.KEY_PRODUCT_MANUFACTURERS] = formData[
  //     keyNames.KEY_PRODUCT_MANUFACTURERS
  //   ].map((_ele: any) => ({
  //     linkId: _ele.value,
  //     linkName: _ele.label,
  //   }));
  // }
  // // Generate items unit []
  // newParams.itemsUnit = [];
  // let itemData = formData[keyNames.KEY_PRODUCT_ITEMS];
  // if (itemData && itemData[KEY_ITEM_BASIC_INFO] && itemData[KEY_ITEM_BASIC_INFO].length > 0) {
  //   const newItemParams = generateItemPayload(formData, itemData);
  //   newParams.itemsUnit = newItemParams; // items unit array
  // }

  delete newParams.unitId;
  delete newParams.groupId;
  delete newParams.items;

  return newParams;
};

//step 1-2: warehouses
const getBasicParams = (productData: any, itemData: any) => {
  let itemUnitParams = {};

  // itemUnitParams[keyNames.KEY_ITEM_CODE] = itemData[keyNames.KEY_ITEM_CODE];
  // itemUnitParams[keyNames.KEY_ITEM_NAME] = itemData[keyNames.KEY_ITEM_NAME];
  // itemUnitParams[keyNames.KEY_ITEM_ACTIVE] = itemData[keyNames.KEY_ITEM_ACTIVE] === 'y' ? true : false; //'y'

  // //unit names value
  // itemUnitParams[keyNames.KEY_ITEM_UNIT_VAL_ID] = itemData[keyNames.KEY_ITEM_UNIT_VAL_ID]; //unit name
  // itemUnitParams[keyNames.KEY_ITEM_UNIT_VAL_NAME] = itemData[keyNames.KEY_ITEM_UNIT_VAL_NAME];; //unit name

  // //attribute value
  // if(itemData.option_values) {
  //     if(Array.isArray(itemData.option_values) && itemData.option_values.length > 1) {
  //         itemUnitParams[keyNames.KEY_ITEM_ATTR_VAL_ID] = itemData.option_values[0].value;
  //         itemUnitParams[keyNames.KEY_ITEM_ATTR_VAL_NAME] = itemData.option_values[0].label
  //     } else {
  //         itemUnitParams[keyNames.KEY_ITEM_ATTR_VAL_ID] = itemData.option_values.value;
  //         itemUnitParams[keyNames.KEY_ITEM_ATTR_VAL_NAME] = itemData.option_values.label;
  //     }
  // }

  // //itemUnitParams.subTplId = "04bfaf75-4130-4a1c-8abd-63d357181adb"; //TODO
  // //itemUnitParams.subTplName = "Hello"; //TODO
  // //isFavorite: true, //TODO
  // //isPrepaid: true, //TODO

  // //more info
  // itemUnitParams[keyNames.KEY_ITEM_DES] = itemData.moreInfo[keyNames.KEY_ITEM_DES];
  // itemUnitParams[keyNames.KEY_ITEM_DIMENSION] = {
  //     unit: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].unit,
  //     val: {
  //         x: itemData.moreInfo[[keyNames.KEY_ITEM_DIMENSION]].W.toString(),
  //         y: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].H.toString(),
  //         z: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].L.toString()
  //     }
  // }
  // itemUnitParams[keyNames.KEY_ITEM_ORIGIN_BARCODE] = itemData.moreInfo[keyNames.KEY_ITEM_ORIGIN_BARCODE];
  // itemUnitParams[keyNames.KEY_ITEM_WARRANTY] = {
  //     [keyNames.KEY_ITEM_WARRANTY_PERIOD]: parseInt(itemData.moreInfo[keyNames.KEY_ITEM_WARRANTY].value || 0),
  //     [keyNames.KEY_ITEM_WARRANTY_UNIT]: itemData.moreInfo[keyNames.KEY_ITEM_WARRANTY].option
  // };
  // itemUnitParams[keyNames.KEY_ITEM_WEIGHT] = {
  //     [keyNames.KEY_ITEM_WEIGHT_VALUE]: parseInt(itemData.moreInfo[keyNames.KEY_ITEM_WEIGHT].value || 0),
  //     [keyNames.KEY_ITEM_WEIGHT_UNIT]: itemData.moreInfo[keyNames.KEY_ITEM_WEIGHT].unit
  // };
  // itemUnitParams[keyNames.KEY_ITEM_MANUFACTURE_DATE] = new Date(itemData.moreInfo[keyNames.KEY_ITEM_MANUFACTURE_DATE]).toISOString();
  // itemUnitParams[keyNames.KEY_ITEM_EXPIRED_DATE] = new Date(itemData.moreInfo[keyNames.KEY_ITEM_EXPIRED_DATE]).toISOString();
  // itemUnitParams[keyNames.KEY_ITEM_COUNTRY_ORIGIN] = itemData.moreInfo[keyNames.KEY_ITEM_COUNTRY_ORIGIN]?.value || '';
  // itemUnitParams[keyNames.KEY_ITEM_RETURNABLE] = itemData.moreInfo[keyNames.KEY_ITEM_RETURNABLE] === 'y' ? true : false;
  // itemUnitParams[keyNames.KEY_ITEM_VENDORS] = itemData.moreInfo[keyNames.KEY_ITEM_VENDORS].map(_ele => ({
  //     linkId: _ele.value,
  //     linkName: _ele.label
  // }))
  // itemUnitParams[keyNames.KEY_ITEM_MANUFACTURERS] = itemData.moreInfo[keyNames.KEY_ITEM_MANUFACTURERS].map(_ele => ({
  //     linkId: _ele.value,
  //     linkName: _ele.label
  // }))

  // //prices
  // if(productData[keyNames.KEY_PRODUCT_TYPE].type === PRODUCT_TYPE_PRODUCED) {
  //     itemUnitParams[keyNames.KEY_ITEM_BASE_PRICES] = itemData.prices.map(_ele => ({
  //         value: parseFloat(_ele.base_price.value),
  //         currency: _ele.base_price.currency.value
  //     }));
  //     itemUnitParams[keyNames.KEY_ITEM_BEST_PRICES] = itemData.prices.map(_ele => ({
  //         value: parseFloat(_ele.best_price.value),
  //         currency: _ele.best_price.currency.value
  //     }));
  //     itemUnitParams[keyNames.KEY_ITEM_COST_PRICES] = itemData.prices.map(_ele => ({
  //         value: parseFloat(_ele.cost_price.value),
  //         currency: _ele.cost_price.currency.value
  //     }));
  // }
  // if(productData[keyNames.KEY_PRODUCT_TYPE].type === PRODUCT_TYPE_PURCHASE) {
  //     itemUnitParams[keyNames.KEY_ITEM_PURCHASE_PRICES] = itemData.prices.map(_ele => ({
  //         value: parseFloat(_ele.purchase_price.value),
  //         currency: _ele.purchase_price.currency.value
  //     }));
  // }

  // // itemUnitParams.prices = itemData.prices.map(_ele => {
  // //     let newPrice = {
  // //         currency: _ele.base_price.currency.value
  // //     };
  // //     if(productData.type === PRODUCT_TYPE_PRODUCED) {
  // //         newPrice = {
  // //             ...newPrice,
  // //             basePrice: _ele.base_price.value,
  // //             costPrice: _ele.best_price.value,
  // //             bestPrice: _ele.cost_price.value
  // //         }
  // //     }
  // //     if(productData.type === PRODUCT_TYPE_PURCHASE) {
  // //         newPrice = {
  // //             ...newPrice,
  // //             purchasePrice: _ele.purchase_price.value
  // //         };
  // //     }
  // //     return newPrice;
  // // });

  return itemUnitParams;
};

//step 3 + 4
const getInventoryParams = (itemData: any) => {
  // let itemUnitParams = {};
  // itemUnitParams[keyNames.KEY_ITEM_LEAD_TIME] = parseInt(
  //   itemData[keyNames.KEY_ITEM_LEAD_TIME].value || 0,
  // );
  // itemUnitParams[keyNames.KEY_ITEM_LEAD_UNIT] = itemData[keyNames.KEY_ITEM_LEAD_TIME].option || 'd';
  // itemUnitParams[keyNames.KEY_ITEM_LOT_SIZE] = parseInt(itemData[keyNames.KEY_ITEM_LOT_SIZE] || 0);
  // itemUnitParams[keyNames.KEY_ITEM_OPEN_STOCK] = parseInt(
  //   itemData[keyNames.KEY_ITEM_OPEN_STOCK] || 0,
  // );
  // itemUnitParams[keyNames.KEY_ITEM_REORDER_POINT] = parseInt(
  //   itemData[keyNames.KEY_ITEM_REORDER_POINT] || 0,
  // );
  // let warehouses = [];
  // itemData.inventories.map((_inv) => {
  //   let newWarehouse = {
  //     id: _inv.warehouse.id,
  //     //name: _inv.warehouse.name,
  //     sku: _inv.sku,
  //     locations: _inv.locations.map((_loc) => ({
  //       id: _loc.id,
  //       //name: _loc.sname,
  //       stockOnHand: parseInt(_loc.stock_on_hand || 0),
  //       batches: _loc.batch_numbers.map((_batch) => ({
  //         batchNumber: _batch.value,
  //         batchQty: parseInt(_batch.qty || 0),
  //         expiredDate: _batch.expiration_date ? new Date(_batch.expiration_date).toISOString() : '',
  //         manufactureDate: _batch.manufacturer_date
  //           ? new Date(_batch.manufacturer_date).toISOString()
  //           : '',
  //       })),
  //     })),;
  //   };
  //   warehouses.push(newWarehouse);
  // });;
  // itemUnitParams.warehouses = warehouses;
  // return itemUnitParams;
};

//step 5
const getPrepaidParams = (itemData: any) => {
  // let prepaidItems = [];
  // itemData.prepaid.map((_pre) => {
  //   let newPreItem = {
  //     code: _pre.code,
  //     description: _pre.description,
  //     name: _pre.name,
  //     returnable: 'n', //TODO - remove
  //     warranty_period: parseInt(_pre.warranty.value || 0),
  //     warranty_unit: _pre.warranty.option || 'd',
  //     number_of_use: {
  //       number_uses: parseInt(_pre.uses[0].nr_of_use || 0),
  //       discount: parseInt(_pre.uses[0].discount.value || 0),
  //       active: _pre.uses[0].status ? 'y' : 'n',
  //       base_prices: _pre.uses[0].amount.map((_ele) => ({
  //         value: _ele.value.toString(),
  //         currency: _ele.currency.value,
  //       })),
  //     },;
  //   };
  //   prepaidItems.push(newPreItem);
  // });;
  // return prepaidItems;
};

//item type = prepaid
const getPrepaidItemParams = (prepaidItem: any, prepaidType: any) => {
  // let itemUnitParams = {};
  // itemUnitParams[keyNames.KEY_ITEM_CODE] = prepaidItem[keyNames.KEY_ITEM_CODE];
  // itemUnitParams[keyNames.KEY_ITEM_NAME] = prepaidItem[keyNames.KEY_ITEM_NAME];
  // itemUnitParams[keyNames.KEY_ITEM_ACTIVE] =
  //   prepaidItem[keyNames.KEY_ITEM_ACTIVE] === 'y' ? true : false;
  // itemUnitParams[keyNames.KEY_ITEM_PREPAID_DISCOUNT] = parseInt(
  //   prepaidItem[keyNames.KEY_ITEM_PREPAID_DISCOUNT] || 0,
  // );
  // itemUnitParams[keyNames.KEY_ITEM_DES] = prepaidItem[keyNames.KEY_ITEM_DES];
  // itemUnitParams[keyNames.KEY_ITEM_WARRANTY] = {
  //   period: parseInt(prepaidItem[keyNames.KEY_ITEM_WARRANTY_PERIOD].value),
  //   unit: prepaidItem[keyNames.KEY_ITEM_WARRANTY_PERIOD].option,
  // };
  // if (prepaidType === PRODUCT_PREPAID_TYPE_AMOUNT) {
  //   itemUnitParams[KEY_ITEM_PREPAID_AMOUNT] = prepaidItem[KEY_ITEM_PREPAID_AMOUNT].map((_ele) => ({
  //     amount: parseFloat(_ele.value),
  //     currency: _ele.currency.value,
  //   }));;
  // }
  // if (prepaidType === PRODUCT_PREPAID_TYPE_PER_ITEM) {
  //   itemUnitParams[keyNames.KEY_ITEM_PREPAID_PER_ITEM] = parseInt(
  //     prepaidItem[keyNames.KEY_ITEM_PREPAID_PER_ITEM] || 0,
  //   );
  //   itemUnitParams[keyNames.KEY_ITEM_MATCH_ITEM_UNIT_ID] =
  //     prepaidItem[keyNames.KEY_ITEM_MATCH_ITEM].id;
  //   itemUnitParams[keyNames.KEY_ITEM_MATCH_ITEM_UNIT_NAME] =
  //     prepaidItem[keyNames.KEY_ITEM_MATCH_ITEM].name;
  // }
  // return itemUnitParams;
};

/**
 * mapping params in API for write items
 * @param: productData, formData (item data)
 * @returns
 */
export const generateItemPayload = (productData: any, formData: any) => {
  // let itemParams = {};
  // //HIDDEN IN NEW UI
  // //itemParams[keyNames.KEY_PRICING_TYPE] = PRODUCT_PRICE_TYPE[formData[keyNames.KEY_PRICING_TYPE]];
  // //itemParams[keyNames.KEY_USE_PREPAID] = formData[keyNames.KEY_USE_PREPAID] === 'n' ? false : true;
  // //itemParams[keyNames.KEY_USE_SUBSCRIBE] = formData[keyNames.KEY_USE_SUBSCRIBE] === 'n' ? false : true;
  // //END HIDDEN
  // //general info
  // itemParams[keyNames.KEY_ITEM_CATEGORY] =
  //   PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
  // itemParams[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
  // itemParams[keyNames.KEY_BASE_UNIT] = formData[keyNames.KEY_ITEM_BASE_UNIT].value; //base unit
  // itemParams[keyNames.KEY_BASE_UNIT_NAME] = formData[keyNames.KEY_ITEM_BASE_UNIT].label; //base unit
  // //TODO: bundle
  // if (formData[keyNames.KEY_ITEM_TYPE] === PRODUCT_ITEM_TYPE_BUNDLE) {
  //   itemParams[keyNames.KEY_ASSOCIATED_ITEMS] = formData[keyNames.KEY_ASSOCIATED_ITEMS].map(
  //     (_ele) => ({
  //       itemUnitId: _ele.item.id,
  //       itemUnitName: _ele.item.item_name,
  //       containedQty: parseInt(_ele.contained_qty),
  //     }),;
  //   );
  // }
  // //item details
  // let itemUnits = [];
  // if (formData[keyNames.KEY_ITEM_TYPE] !== PRODUCT_ITEM_TYPE_PREPAID) {
  //   // CURRENT VERSION - 2 STEPS
  //   formData[keyNames.KEY_ITEM_BASIC_INFO].map((_item) => {
  //     let itemUnitParams = {};
  //     itemUnitParams = getBasicParams(productData, _item);
  //     //HIDDEN IN NEW UI
  //     // if(formData[keyNames.KEY_USE_PREPAID] === 'y') {
  //     //     //prepaid params
  //     //     const _itemPrepaid = formData[keyNames.KEY_ITEM_PREPAID_INFO].find(_ele => _ele.id === _item.id);
  //     //     itemUnitParams = {...itemUnitParams, [KEY_ITEM_UNIT_PREPAID]: getPrepaidParams(_itemPrepaid)};
  //     // }
  //     // itemUnitParams[keyNames.KEY_USE_PREPAID] = formData[keyNames.KEY_USE_PREPAID] === 'n' ? false : true;
  //     // itemUnitParams[keyNames.KEY_USE_SUBSCRIBE] = formData[keyNames.KEY_USE_SUBSCRIBE] === 'n' ? false : true;
  //     // itemUnitParams[keyNames.KEY_PRICING_TYPE] = PRODUCT_PRICE_TYPE[formData[keyNames.KEY_PRICING_TYPE]];
  //     //END HIDDEN
  //     //add general info
  //     itemUnitParams[keyNames.KEY_ITEM_CATEGORY] =
  //       PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
  //     itemUnitParams[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
  //     itemUnitParams[keyNames.KEY_BASE_UNIT] = formData[keyNames.KEY_ITEM_BASE_UNIT].value; //base unit
  //     itemUnitParams[keyNames.KEY_BASE_UNIT_NAME] = formData[keyNames.KEY_ITEM_BASE_UNIT].label; //base unit
  //     //add new
  //     itemUnits.push(itemUnitParams);
  //   });;
  //   // PREVIOUS VERSION - 5 STEPS
  //   //case 1: non-inventory + general
  //   // if(formData[keyNames.KEY_ITEM_CATEGORY] === PRODUCT_ITEM_CATEGORY_NON_INVENTORY) {
  //   //     formData[keyNames.KEY_ITEM_BASIC_INFO].map(_item => {
  //   //         let itemUnitParams = {};
  //   //         itemUnitParams = getBasicParams(productData, _item);
  //   //         //HIDDEN IN NEW UI
  //   //         // if(formData[keyNames.KEY_USE_PREPAID] === 'y') {
  //   //         //     //prepaid params
  //   //         //     const _itemPrepaid = formData[keyNames.KEY_ITEM_PREPAID_INFO].find(_ele => _ele.id === _item.id);
  //   //         //     itemUnitParams = {...itemUnitParams, [KEY_ITEM_UNIT_PREPAID]: getPrepaidParams(_itemPrepaid)};
  //   //         // }
  //   //         // itemUnitParams[keyNames.KEY_USE_PREPAID] = formData[keyNames.KEY_USE_PREPAID] === 'n' ? false : true;
  //   //         // itemUnitParams[keyNames.KEY_USE_SUBSCRIBE] = formData[keyNames.KEY_USE_SUBSCRIBE] === 'n' ? false : true;
  //   //         // itemUnitParams[keyNames.KEY_PRICING_TYPE] = PRODUCT_PRICE_TYPE[formData[keyNames.KEY_PRICING_TYPE]];
  //   //         //END HIDDEN
  //   //         //add general info
  //   //         itemUnitParams[keyNames.KEY_ITEM_CATEGORY] = PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
  //   //         itemUnitParams[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
  //   //         itemUnitParams[keyNames.KEY_BASE_UNIT] = formData[keyNames.KEY_ITEM_BASE_UNIT].value; //base unit
  //   //         itemUnitParams[keyNames.KEY_BASE_UNIT_NAME] = formData[keyNames.KEY_ITEM_BASE_UNIT].label; //base unit
  //   //         //add new
  //   //         itemUnits.push(itemUnitParams);
  //   //     })
  //   // }
  //   // //case 2: inventory + general
  //   // if(formData[keyNames.KEY_ITEM_CATEGORY] === PRODUCT_ITEM_CATEGORY_INVENTORY) {
  //   //     formData[keyNames.KEY_ITEM_BATCH_INFO].map(_item => {
  //   //         let itemUnitParams = {};
  //   //         itemUnitParams = getBasicParams(productData, _item);
  //   //         //HIDDEN IN NEW UI - NOT having inventory
  //   //         //itemUnitParams = {...getBasicParams(productData, _item), ...getInventoryParams(_item)};
  //   //         // if(formData[keyNames.KEY_USE_PREPAID] === 'y') {
  //   //         //     //prepaid params
  //   //         //     const _itemPrepaid = formData[keyNames.KEY_ITEM_PREPAID_INFO].find(_ele => _ele.id === _item.id);
  //   //         //     itemUnitParams = {...itemUnitParams, [KEY_ITEM_UNIT_PREPAID]: getPrepaidParams(_itemPrepaid)};
  //   //         // }
  //   //         // itemUnitParams[keyNames.KEY_PRICING_TYPE] = PRODUCT_PRICE_TYPE[formData[keyNames.KEY_PRICING_TYPE]];
  //   //         // itemUnitParams[keyNames.KEY_USE_PREPAID] = formData[keyNames.KEY_USE_PREPAID] === 'n' ? false : true;
  //   //         // itemUnitParams[keyNames.KEY_USE_SUBSCRIBE] = formData[keyNames.KEY_USE_SUBSCRIBE] === 'n' ? false : true;
  //   //         //END HIDDEN
  //   //         //add general info
  //   //         itemUnitParams[keyNames.KEY_ITEM_CATEGORY] = PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
  //   //         itemUnitParams[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
  //   //         itemUnitParams[keyNames.KEY_BASE_UNIT] = formData[keyNames.KEY_ITEM_BASE_UNIT].value; //base unit
  //   //         itemUnitParams[keyNames.KEY_BASE_UNIT_NAME] = formData[keyNames.KEY_ITEM_BASE_UNIT].label; //base unit
  //   //         //add new
  //   //         itemUnits.push(itemUnitParams);
  //   //     })
  //   // }
  // } else {
  //   //formData.itemType === PRODUCT_ITEM_TYPE_PREPAID
  //   formData[keyNames.KEY_PREPAID_ITEM].map((_preItem) => {
  //     let itemUnitParams = {};
  //     itemUnitParams = getPrepaidItemParams(_preItem, formData[keyNames.KEY_PREPAID_TYPE]);
  //     //HIDDEN IN NEW UI - NOT having inventory
  //     //itemUnitParams[keyNames.KEY_PRICING_TYPE] = PRODUCT_PRICE_TYPE[formData[keyNames.KEY_PRICING_TYPE]];
  //     //itemUnitParams[keyNames.KEY_USE_PREPAID] = formData[keyNames.KEY_USE_PREPAID] === 'n' ? false : true;
  //     //itemUnitParams[keyNames.KEY_USE_SUBSCRIBE] = formData[keyNames.KEY_USE_SUBSCRIBE] === 'n' ? false : true;
  //     //END HIDDEN
  //     //add general info
  //     itemUnitParams[keyNames.KEY_ITEM_CATEGORY] =
  //       PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
  //     itemUnitParams[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
  //     itemUnitParams[keyNames.KEY_PREPAID_TYPE] =
  //       PRODUCT_PREPAID_TYPE[formData[keyNames.KEY_PREPAID_TYPE]];
  //     itemUnitParams[keyNames.KEY_BASE_UNIT] = formData[keyNames.KEY_ITEM_BASE_UNIT].value; //base unit
  //     itemUnitParams[keyNames.KEY_BASE_UNIT_NAME] = formData[keyNames.KEY_ITEM_BASE_UNIT].label; //base unit
  //     //add new
  //     itemUnits.push(itemUnitParams);
  //   });;
  // }
  // //set items params
  // itemParams[keyNames.KEY_ITEM_UNITS] = itemUnits;
  // return itemParams;
};

export const finalizeParams = (formData: any = {}) => {
  return { product: generateProductPayload(formData) };
};
