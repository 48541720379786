import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@base/components/form';
import {
  OPTION_ITEM_TYPES,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import { KEY_PRODUCT_TYPE, KEY_PRODUCT_TYPE_BE_SOLD } from '@product/product/config/key-names';

/**
 *
 * @param {*} props
 * @returns
 */
const ItemType: React.FC<any> = (props: any) => {
  const { onChange = null, value = null, disabled = false, productData = null } = props;

  // state
  const [options, setOptions] = useState(OPTION_ITEM_TYPES);
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  //init data
  useEffect(() => {
    if (value) {
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(options[0].value);
    }
  }, [value]);

  useEffect(() => {
    if (productData) {
      const newOptions: any[] = [];
      OPTION_ITEM_TYPES?.map((item: any, index: number) => {
        const newItem: any = { ...item };
        if (
          productData?.[KEY_PRODUCT_TYPE] === PRODUCT_TYPE_PURCHASE &&
          !productData[KEY_PRODUCT_TYPE_BE_SOLD] &&
          (index == 1 || index == 2)
        ) {
          newItem.disabled = true;
        }
        // If the Supply Method- Produced' is checked, Item Type: Bundle cannot be selected (automatically disabled)
        if (productData?.[KEY_PRODUCT_TYPE] === PRODUCT_TYPE_PRODUCED && index == 1) {
          newItem.disabled = true;
        }
        // Prepaid item cannot be created when the 'Suply Method-Can be Sold' is not checked. (Automatically disable)
        if (!productData[KEY_PRODUCT_TYPE_BE_SOLD] && index == 2) {
          newItem.disabled = true;
        }
        newOptions.push(newItem);
      });
      setOptions(newOptions);
    }
  }, [productData]);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
    //callback
    onChange && onChange(newOption.value);
  };

  // render
  return (
    <RadioGroup
      isVertical={false}
      disabled={disabled}
      options={options}
      value={selectedValue}
      onChange={handleValueChange}
    />
  );
};

export default ItemType;
