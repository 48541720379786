import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Select } from '@base/components/form';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = null, errors = {}, options = [], onChange } = props;

  const [selected, setSelected] = useState<any>(null);
  useEffect(() => {
    const selected = options?.find((v: any) => v?.value === value);
    if (selected && selected?.value != '') {
      setSelected(selected);
    }
  }, [value, options]);

  const handleOnChange = (newValue: any) => {
    setSelected(newValue);
    onChange && onChange(newValue?.value);
  };

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <Select options={options} value={selected} onChange={handleOnChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
