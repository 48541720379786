import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@base/components/form';
import { OPTION_PREPAID_TYPE } from '@product/main/config/constants';

const PrepaidType: React.FC<any> = (props: any) => {
  const { onChange = null, value = null, disabled = false } = props;

  const [selectedValue, setSelectedValue] = useState(OPTION_PREPAID_TYPE[0].value);

  // useEffect(() => {
  //     onChange && onChange(currValue.value);
  // }, [currValue]);

  //init data
  useEffect(() => {
    if (value) {
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTION_PREPAID_TYPE[0].value);
    }
  }, [value]);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
    //callback
    onChange && onChange(newOption.value);
  };

  //render
  return (
    <RadioGroup
      isVertical={false}
      disabled={disabled}
      options={OPTION_PREPAID_TYPE}
      value={selectedValue}
      onChange={handleValueChange}
    />
  );
};

export default PrepaidType;
