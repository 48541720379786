import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductTextView as TextView } from '@base/containers/quick-view';

const View: React.FC = (props: any) => {
  const {
    value: { id, name },
  } = props;

  return <TextView value={{ id, name }} />;
};

export default View;
