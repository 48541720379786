import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import AsyncSelect from '@base/components/form/async-select';
import { GET_PRODUCT_BASE_UNIT } from '@product/product/services/graphql';
import { ResponsePaging } from '@base/types/interfaces/response';
import { graphQLApi } from '@base/utils/axios/graphql';

/**
 * author: trungtm
 * @param {*} props
 * @returns
 */
const BaseUnitAutoComplete: React.FC<any> = (props: any) => {
  const {
    className,
    isMulti = false,
    value, //[]
    onChange = null,
    onAdd = null,
  } = props;
  //state
  const [options, setOptions] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //set selected items
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  const fetchOptions = async (inputValue: string) => {
    const params = {
      filter: {
        keyword: inputValue ? inputValue : '',
        paging: {
          page: 1,
          size: 15,
        },
      },
    };
    const data = await graphQLApi<ResponsePaging<any>>(
      'product_units',
      GET_PRODUCT_BASE_UNIT,
      params,
    );
    const newOptions = data?.results?.map((_item) => ({
      ..._item,
      label: _item.name,
      value: _item.id,
    }));
    // setOptions(newOptions);
    return newOptions;
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <AsyncSelect
        isMulti={isMulti}
        placeholder="Select a base unit..."
        cacheOptions
        value={selectedValue}
        loadOptions={fetchOptions}
        onChange={handleSelectChange}
        //more add
        addLabel="Add base unit"
        onAdd={onAdd}
        menuPlacement="auto"
      />
    </div>
  );
};

export default BaseUnitAutoComplete;
