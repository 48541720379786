import React, { useMemo, useRef, useState } from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import { Button, CheckboxGroup } from '@base/components/form';
import ReactToPrint from 'react-to-print';
import * as keyNames from '@product/item/config/key-names';
import { ViewFields } from '@base/components/hook-form/view';
import Timeline from '@base/containers/timeline';
import Notes from '@base/containers/note';

interface PrintPreviewProps {
  data: any;
  onClose?: () => void;
}

const PRINT_OPTIONS = [
  { label: 'Summary', value: 'summary' },
  { label: 'Details', value: 'detail' },
  { label: 'Timeline', value: 'timeline' },
  { label: 'Notes', value: 'note' },
];

const PrintPreview = (props: PrintPreviewProps) => {
  const { data, onClose } = props;
  const printRef = useRef<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<any>([
    { label: 'Summary', value: 'summary' },
    // { label: 'Details', value: 'detail' }
  ]);
  //console.log('print data', data);
  const basicFields = data?.layout?.data?.[0]?.children || [];
  const detailFields = data?.layout?.data?.[1]?.children || [];

  //print options
  const renderPrintOptions = () => {
    return (
      <div className="view-aside-section">
        <div className='card mg-10'>
          <div className='card-header'>
            <span className='tx-bold'>Print Options</span>
          </div>
          <div className='card-body'>
            <CheckboxGroup
              isVertical={false}
              options={PRINT_OPTIONS}
              fieldLabel='label'
              fieldValue='value'
              value={selectedOptions}
              onChange={(newOptions: any) => setSelectedOptions(newOptions)}
            />
          </div>
        </div>
      </div>
    );
  }

  //summary
  const renderSummary = () => {
    const viewBasicFields = basicFields.map((_ele: any) => (
      { ..._ele, userPermission: { ..._ele.userPermission, isEdit: false } }
    ));

    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">Summary</span>
          {/* <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#printsummary"
          /> */}
        </div>
        <div id="printsummary" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={viewBasicFields}
              ignoreFields={[keyNames.KEY_ITEM_IMAGE]}
              column={2}
            />
          </div>
        </div>
      </div>
    );
  }

  //details
  const renderDetails = () => {
    const viewDetailFields = detailFields.map((_ele: any) => (
      { ..._ele, userPermission: { ..._ele.userPermission, isEdit: false } }
    ));

    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">Details</span>
          {/* <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#printdetail"
          /> */}
        </div>
        <div id="printdetail" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <React.Suspense fallback={<></>}>
              <ViewFields
                fields={viewDetailFields}
                ignoreFields={[]}
                column={2}
              />
            </React.Suspense>
          </div>
        </div>
      </div>
    );
  }

  //timeline
  const renderTimeline = () => {
    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">Timline</span>
          {/* <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#printtimeline"
          /> */}
        </div>
        <div id="printtimeline" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <Timeline
              className='w-100'
              menuSourceId={data.menuSourceId}
              menuSource={data.menuSource}
            />
          </div>
        </div>
      </div>
    );
  }

  //ticket
  const renderNotes = () => {
    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <span className="view-aside-section-header-title">Notes</span>
          {/* <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#printnote"
          /> */}
        </div>
        <div id="printnote" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <Notes
              className='w-100'
              menuSourceId={data.menuSourceId}
              menuSource={data.menuSource}
            />
          </div>
        </div>
      </div>
    );
  }

  //content
  const BodyRender = useMemo(() => {
    const isShowSummary = selectedOptions.findIndex((_ele: any) => _ele.value === 'summary') > -1 ? true : false;
    const isShowDetail = selectedOptions.findIndex((_ele: any) => _ele.value === 'detail') > -1 ? true : false;
    const isShowTimeline = selectedOptions.findIndex((_ele: any) => _ele.value === 'timeline') > -1 ? true : false;
    const isShowNote = selectedOptions.findIndex((_ele: any) => _ele.value === 'note') > -1 ? true : false;

    return (
      <div className='pos-relative'>
        {renderPrintOptions()}
        <div className='pos-relative scroll-box' ref={printRef} style={{ maxHeight: "calc(100vh - 300px)" }}>
          {isShowSummary && renderSummary()}
          {isShowDetail && renderDetails()}
          {isShowTimeline && renderTimeline()}
          {isShowNote && renderNotes()}
        </div>
      </div>
    );
  }, [data, selectedOptions]);

  //render footer
  const FooterRender = useMemo(() => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <Button
            color="light mg-r-5"
            onClick={onClose}
          >
            Close
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">Print</Button>}
            content={() => printRef.current}
            //onBeforeGetContent={() => setIsLoading(true)}
            //onBeforePrint={() => setIsLoading(false)}
            bodyClass='pd-t-30'
          />
        </div>
      </div>
    );
  }, []);

  return (
    <div className='pos-relative'>
      {BodyRender}
      {FooterRender}
    </div>
  );
}

export default withMiModal(PrintPreview);