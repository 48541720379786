import React, { useState, useEffect, forwardRef, useCallback, useMemo, lazy, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import SpanLang from '@base/components/form/span-lang';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deviceAtom } from '@base/recoil/atoms/app';
import { FilterInput } from '@base/types/interfaces/common';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListPagination,
  ListToolbar,
  ListHeader,
  ListHeaderFilters,
  ListQuickAction,
  ListSearch,
  ListTableColumnSetting,
} from '@base/components/list';
import {
  CheckCircle,
  ChevronDown,
  Circle,
  Download,
  Folder,
  HelpCircle,
  RotateCcw,
  Trash,
  Trash2,
  UserCheck,
  UserMinus,
  UserPlus,
} from 'react-feather';
import { default as configFields } from '@product/product/config/view-field';
import { buildListSchema } from '@base/utils/helpers/schema';
import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import { MenuData, SearchFilter } from '@base/types/interfaces/app';
import { ListType } from '@base/types/enums';
import { categoryOptions, PRODUCT_ADD_OPTIONS } from '@product/main/config';
import {
  dateByOptions,
  filterByOptions,
  groupByOptions,
  searchFields,
  sortsBy,
} from '@product/product/config/list';
import { useGetList } from '@base/services/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@product/product/config/display-layout';
import { useTranslation } from 'react-i18next';

//for write
import WritePage from '@product/product/pages/write';
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';
import { Button, Checkbox, Switch } from '@base/components/form';
//end for write

// Write Item
import WritePageItem from '@product/item/pages/write';
import WriteUnit from '@product/unit/pages/write';

import ListToolbarContainer from '@base/containers/list/toolbar';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import {
  DELETE_PRODUCT,
  EMPTY_PRODUCT,
  RESTORE_PRODUCT,
  UPDATE_PRODUCT_BATCH,
} from '@product/product/services/graphql';
import { useQueryClient } from '@tanstack/react-query'; //v4
import ProductUpdateGroup from '@product/product/containers/update-group';
import ChangeAssign from '@product/product/containers/change-assign';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import { productListData, userProductSetting } from '@product/product/recoil/atom';
import Body from './body';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode?: (isSplitMode: boolean) => void;
  onChangeSetting: (newSetting: any) => void;
}

const ListPage: React.FC<IListPage> = (props: IListPage) => {
  const { t } = useTranslation();
  const { isSplitMode, setIsSplitMode, onChangeSetting } = props;

  const queryClient = useQueryClient();

  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'product';

  //recoil
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom); //for write page
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  const [pageData, setPageData] = useRecoilState(userProductSetting);
  const [listData, setListData] = useRecoilState(productListData);

  // react-hook
  const params = useParams();
  const navigate = useNavigate();

  // state
  // const [showColumns, setShowColumns] = useState<any[]>([]);
  const [hideColumns, setHideColumns] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [quickLoading, setQuickLoading] = useState<boolean>(false);
  const [showUpdateGroup, setShowUpdateGroup] = useState<boolean>(false);
  const [showChangeAssign, setShowChangeAssign] = useState<string>('');

  const loadRef = useRef(false);

  let { filter, listType, settingColumns } = pageData;
  // console.log('...PRODUCT.pageData...', pageData);

  // Sort
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newData = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  // get pagelayout
  const layoutMenu = MENU_PRODUCT_PRODUCT;
  let { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    layoutMenu,
    'list',
  );

  let fields: any[] = [];
  if (listLayoutData && listLayoutData?.data) {
    fields = listLayoutData?.data;
  }

  const getSortColumns = () => {
    return sortsBy;
  };

  useEffect(() => {
    if (fields?.length > 0) {
      const newColumns: any[] = [];

      fields?.map((_ele: any) => {
        newColumns.push({
          ..._ele,
          isViewing: _ele.defaultViewInList,
          enableSorting:
            getSortColumns()?.findIndex((v: any) => v?.value === _ele.keyName) != -1 ? true : false,
        });
      });

      // merge setting columns and page-layout
      const tableColumns: any[] =
        settingColumns && settingColumns?.length
          ? settingColumns?.filter((_item: any) => {
              if (newColumns.findIndex((v: any) => v.keyName === _item?.keyName) >= 0) {
                return _item;
              }
            })
          : newColumns;

      const newData: any = { ...pageData };
      newData.settingColumns = tableColumns;

      if (!loadRef.current) {
        // fist load
        loadRef.current = true;
        // setPageData(newData);
        onChangeSetting && onChangeSetting(newData);
      }
    }
    () => {
      loadRef.current = false;
    };
  }, [fields]);

  // columns will show in list
  // useEffect(() => {
  //   let newShowColumns: any[] = [];
  //   newShowColumns = settingColumns.filter((_ele: any) => _ele?.isViewing);
  //   setShowColumns(newShowColumns);
  // }, [settingColumns]);
  const showColumns = settingColumns.filter((_ele: any) => _ele?.isViewing);

  // build list query schema
  const listQuerySchema = buildListSchema({ fields, configFields });

  // list filters
  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, dateByOptions, searchFields),
  };

  // get list data
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'product_products',
    listQuerySchema,
    filtersQuery,
  );

  useEffect(() => {
    setListData(data?.data ?? []);
  }, [data?.data]);

  const onCheckedRow = (checkedValue: string | number) => {
    const checkedValueStr = checkedValue as string;
    let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, listData);
    setSelectedIds([...newSelectedIds]);
  };

  // build columns for react-table v8
  const onTableCheckedRow = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );

  const mutationMarkAsActive: any = useMutationPost<BaseMutationResponse>(
    UPDATE_PRODUCT_BATCH,
    'product_updateProductBatch',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Mark as active successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Mark as active failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const mutationMarkAsInActive: any = useMutationPost<BaseMutationResponse>(
    UPDATE_PRODUCT_BATCH,
    'product_updateProductBatch',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Mark as inactive successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Mark as inactive failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT,
    'product_deleteProduct',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Move to trash products successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Move to trash products failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const mutationRestore: any = useMutationPost<BaseMutationResponse>(
    RESTORE_PRODUCT,
    'product_restoreProduct',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Restore products successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Restore products failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const mutationEmpty: any = useMutationPost<BaseMutationResponse>(
    EMPTY_PRODUCT,
    'product_deleteRecycleBin',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Delete products successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete products failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const quickButtons = [
    {
      title: 'Update Group',
      icon: <Folder />,
      callback: (selected: any) => {
        setShowUpdateGroup(true);
      },
    },
    {
      title: 'Mark as Active',
      icon: <CheckCircle />,
      callback: (selected: any) => {
        let products: any = [];
        selectedIds?.map((id: string) => {
          products.push({
            id: id,
            active: true,
          });
        });
        mutationMarkAsActive.mutate({ products: products });
      },
    },
    {
      title: 'Mark as Inactive',
      icon: <Circle />,
      callback: (selected: any) => {
        let products: any = [];
        selectedIds?.map((id: string) => {
          products.push({
            id: id,
            active: false,
          });
        });
        mutationMarkAsInActive.mutate({ products: products });
      },
    },
    {
      title: 'Change Rep(s)',
      icon: <UserCheck />,
      callback: (selected: any) => {
        setShowChangeAssign('update');
      },
    },
    {
      title: 'Add Rep(s)',
      icon: <UserPlus />,
      callback: (selected: any) => {
        setShowChangeAssign('add');
      },
    },
    {
      title: 'Delete Rep(s)',
      icon: <UserMinus />,
      callback: (selected: any) => {
        setShowChangeAssign('delete');
      },
    },
  ];

  if (filter?.headerFilters?.groupBy === 'deletedProduct') {
    quickButtons.push({
      title: 'Restore',
      icon: <RotateCcw />,
      callback: () => {
        mutationRestore.mutate({ ids: selectedIds });
      },
    });

    quickButtons.push({
      title: 'Delete',
      icon: <Trash />,
      callback: () => {
        mutationEmpty.mutate({ ids: selectedIds });
      },
    });
  } else {
    quickButtons.unshift({
      title: 'Export',
      icon: <Download />,
      callback: (selected: any) => {},
    });

    quickButtons.push({
      title: 'Move to trash',
      icon: <Trash2 />,
      callback: (selected: any) => {
        mutationDelete.mutate({ ids: selectedIds });
      },
    });
  }

  // more menu
  const actionsProps = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => {},
  };

  const handleSearchSubmit = (formData: any) => {
    if (formData?.type === 'TYPE_NONE') {
      delete formData.type;
    }
    let newFilter: SearchFilter = {
      ...pageData.filter,
    };

    if (formData?.keyword != '') {
      newFilter = {
        ...newFilter,
        keyword: formData?.keyword,
      };
      delete formData.keyword;
    }
    newFilter = {
      ...newFilter,
      searchFilters: formData,
    };
    let newData: any = {
      ...pageData,
      filter: newFilter,
    };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  const onChangeColumnSetting = (newColumns: any) => {
    let newData: any = { ...pageData, settingColumns: newColumns };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  const moreAction = () => {
    return (
      <div className="d-flex align-items-center">
        <Button
          // icon={'Help'}
          color="light"
          isSpanTitle={false}
          onClick={() => {}}
          data-han-tooltip={'Help'}
          aria-label="help"
          className="btn btn-white btn-icon mg-l-5"
        >
          <HelpCircle />
        </Button>
      </div>
    );
  };

  const BodyMemo = useMemo(() => {
    return (
      <Body
        isSplitMode={isSplitMode}
        listData={listData}
        selectedIds={selectedIds}
        hideColumns={hideColumns}
        fields={showColumns}
        sort={sort}
        setSort={setSort}
        paging={data?.paging}
        isFetching={false}
        onGridChecked={setSelectedIds}
        onTableChecked={onTableCheckedRow}
        onChangeSetting={onChangeSetting}
      />
    );
  }, [isSplitMode, showColumns, listData, selectedIds]);

  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbarContainer
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  // setPageData(newData);
                  onChangeSetting && onChangeSetting(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
              }}
              actionsProps={actionsProps}
              isSplitMode={isSplitMode}
              setIsSplitMode={setIsSplitMode}
              onRefresh={refetch}
              addOptions={PRODUCT_ADD_OPTIONS}
              onAdd={(category: string | undefined) =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: category === undefined ? MENU_PRODUCT_PRODUCT : category,
                }))
              }
              categoryOptions={categoryOptions}
              categorySelected={category}
              onCategoryChange={(newCategory: string) => {
                return navigate(`/${menu}/${newCategory}`);
              }}
              menu={menu} //main menu
              // moreAction={moreAction}
            />
          }
          header={
            <ListHeader
              isSimple={isSplitMode}
              searchComponent={
                <ListSearch
                  isSimple={isSplitMode}
                  placeholder={'Enter product name to search'}
                  // searchFields={searchFields.reduce((final: any[], field: any) => {
                  //   if (showColumns?.findIndex((v: any) => v.name == field.name) !== -1) {
                  //     final.push(field);
                  //   }
                  //   return final;
                  // }, [])}
                  searchValue={filter?.keyword}
                  onSubmit={handleSearchSubmit}
                />
              }
              filterComponent={
                <ListHeaderFilters
                  category={MENU_PRODUCT_PRODUCT ?? ''}
                  isSplitMode={isSplitMode}
                  groupByOptions={groupByOptions}
                  filterByOptions={filterByOptions}
                  dateByOptions={dateByOptions}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    // setPageData(newData);
                    onChangeSetting && onChangeSetting(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={(s: any) => {
                    setSort(s);
                  }}
                  sortColumns={listType == ListType.LIST ? [] : getSortColumns()}
                />
              }
              columnSettingComponent={
                !isSplitMode &&
                listType == ListType.LIST && (
                  <ListTableColumnSetting
                    columns={settingColumns}
                    hideColumns={hideColumns}
                    columLimit={10}
                    onChange={onChangeColumnSetting}
                  />
                )
              }
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              onRefresh={refetch}
              actionsProps={actionsProps}
            />
          }
          body={BodyMemo}
        />

        {/* Tablet layout here */}
        {/* {isTablet && null} */}

        {/* Mobile layout here */}
        {/* {isMobile && null} */}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => onCheckedRow('notAll')}
        visible={!!selectedIds.length}
      />

      {/* hook form - write new product write */}
      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
        <WritePage
          title={<SpanLang keyLang={'Create Product'} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          menuApi={MENU_PRODUCT_PRODUCT}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
        <WritePageItem
          title={<SpanLang keyLang={'Create Item'} />}
          size="xl"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={reloadItemUnits}
          product={null}
          menuApi={MENU_PRODUCT_ITEM}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_UNIT && (
        <WriteUnit
          title={<SpanLang keyLang={'Create Unit'} />}
          size="md"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={reloadItemUnits}
          menuApi={MENU_PRODUCT_UNIT}
        />
      )}

      {showUpdateGroup && (
        <ProductUpdateGroup
          isOpen={showUpdateGroup}
          onClose={() => {
            setShowUpdateGroup(false);
          }}
          onSuccess={() => {
            setTimeout(() => {
              queryClient.invalidateQueries(['product_products']);
            }, 500);
            onCheckedRow('notAll');
            setShowUpdateGroup(false);
          }}
          ids={selectedIds}
        />
      )}

      {showChangeAssign != '' && (
        <ChangeAssign
          isOpen={showChangeAssign != ''}
          mode={showChangeAssign}
          ids={selectedIds}
          products={listData ?? []}
          onClose={() => {
            setShowChangeAssign('');
          }}
          onSuccess={() => {
            setTimeout(() => {
              queryClient.invalidateQueries(['product_products']);
            }, 500);
            onCheckedRow('notAll');
            setShowChangeAssign('');
          }}
        />
      )}
    </>
  );
};

export default ListPage;
