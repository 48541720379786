import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Select, SpanLang } from '@base/components/form';
import { components } from 'react-select';
import _ from 'lodash';
import { useProductsAutoComplete } from '@product/product/services/product-service';
import WritePage from '@product/product/pages/write';
import { MENU_PRODUCT_PRODUCT } from '@base/config/menus';
import Icon from '@base/assets/icons/svg-icons';

interface ProductAutoCompleteProps {
  outSide?: boolean;
  className?: string;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  disabled?: boolean;
  hiddenAdd?: boolean;
  type?: string; // all, produced, purchase
  showAllOption?: boolean;
  value?: any | any[]; // [], initial value
  onChange?: any;
  excludes?: string[];
  isPublic?: boolean; //for public site
  token?: string; //for public site
}

const ProductAutoComplete = (props: ProductAutoCompleteProps) => {
  const {
    outSide = false,
    className,
    placeholder,
    single = false,
    disabled = false,
    hiddenAdd = false,
    visible = true,
    showAllOption = true,
    value = null,
    onChange,
    excludes = [],
    isPublic,
    token,
  } = props;

  // state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;

  // initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
            setSelectedValue(value);
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        // single object
        if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
          setSelectedValue(value);
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  // build params
  const getSearchParams = () => {
    let queryFilters: any[] = [];

    if (searchText != '') {
      queryFilters.push(`name:"${searchText}"`);
    }

    if (excludes?.length > 0) {
      queryFilters.push(`-id=(${excludes.join(' ')})`);
    }

    let params: any = {
      filter: {
        query: queryFilters.join(' '),
        sort: {
          field: 'createdAt',
          orderBy: 2,
        },
        paging: {
          page: 1,
          size: 99,
        },
      },
    };
    //for public site
    if (isPublic) {
      params.token = token;
    }

    return params;
  };

  // convert to select options
  const formatSelectOptions = (results: any) => {
    let newOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.name,
        value: _item.id,
      };
    });

    // TODO: filter by execptIds
    if (showAllOption && excludes?.length == 0) {
      newOptions?.unshift({
        label: 'All',
        value: 'all',
        id: 'all',
        name: 'All',
      });
    }
    return newOptions;
  };

  const {
    data: results,
    status: searchStatus,
    isLoading,
    refetch,
  } = useProductsAutoComplete(getSearchParams(), isPublic);

  useEffect(() => {
    if (results) {
      let newOptions = formatSelectOptions(results);
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [results]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
        setSelectedValue(newItem);
      } else {
        setSelectedValue(newItem);
      }
    }
    //callback
    onChange && onChange(newItem);
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <>
          <div
            className={classNames('', { 'font-weight-bold pd-b-0 mg-b-0': data?.value === 'all' })}
          >
            {children}
          </div>
        </>
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: '250px' }}>
      <Select
        isMulti={!single}
        outSide={outSide}
        isDisabled={disabled}
        noOptionsMessage={() => 'No products found.'}
        placeholder={
          <div className="react-select-placeholder">
            {placeholder || 'Type or click to select a product'}
          </div>
        }
        iconIndicator={<div className="icons-size-1">{Icon('product_unit')}</div>}
        isClearable
        components={{ Option: CustomOption }}
        isLoading={searchStatus === 'loading' || isLoading}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={options}
        onChange={handleSelectChange}
        addLabel={hiddenAdd ? '' : 'Add new product'}
        onAdd={hiddenAdd ? null : () => setShowAdd(true)}
      />

      {!hiddenAdd && showAdd && (
        <WritePage
          title={<SpanLang keyLang={'Create Product'} />}
          size="md"
          isOpen={showAdd}
          onClose={() => setShowAdd(false)}
          onReload={refetch}
          //OnSuccess={}
          menuApi={MENU_PRODUCT_PRODUCT}
          showCanvas
        />
      )}
    </div>
  );
};

export default ProductAutoComplete;
