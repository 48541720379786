import React, { useEffect, useState } from 'react';
import Select from '@base/components/form/select';
import AddUnitName from './add';
import item from '@activity/components/sequence/item';

interface IUnitNameProps {
  unit?: any;
  value: any | null;
  options: any[];
  isMulti?: boolean;
  onChange?: (newValue: any) => void;
}

const UnitName: React.FC<IUnitNameProps> = (props: IUnitNameProps) => {
  const { unit, value, options, isMulti = false, onChange } = props;

  const [unitValuesOptions, setUnitValuesOptions] = useState<any[]>([]);
  const [unitValues, setUnitValues] = useState<any[]>([]);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(unitValues)) {
        setUnitValues(value);
      }
    } else {
      setUnitValues([]);
    }
  }, [value]);

  useEffect(() => {
    if (JSON.stringify(options) != JSON.stringify(unitValuesOptions)) {
      setUnitValuesOptions(options);
    }
  }, [options]);

  const handleUnitValueChange = (newValue: any) => {
    setUnitValues(newValue);
    onChange && onChange(newValue);
  };

  const handleUnitValuesChange = (unitValues: any) => {
    setUnitValuesOptions(
      unitValues?.map((item: any) => ({ ...item, value: item.id, label: item.name })),
    );
  };

  return (
    <>
      <Select
        isMulti={isMulti}
        outSide={true}
        isSearchable={false}
        options={unitValuesOptions}
        value={unitValues}
        onChange={handleUnitValueChange}
        addLabel={unit && unit?.id != '' ? 'Add Unit Name' : ''}
        onAdd={() => {
          unit && unit?.id != '' ? setShowAdd(true) : null;
        }}
        placeholder={
          (unit && unit?.id != '') || unitValuesOptions?.length > 0
            ? 'Select...'
            : 'Please choose base unit first'
        }
      />
      {showAdd && (
        <AddUnitName
          isShow={showAdd}
          onClose={() => setShowAdd(false)}
          unit={unit}
          onAdd={(items: any) => handleUnitValuesChange(items)}
        />
      )}
    </>
  );
};

export default UnitName;
