import React from 'react';
import CommonViewField from '@base/containers/view-field/common';

import View from './view';
import Edit from './edit';

const ItemBarCodeViewField: React.FC = (props: any) => {
  return (
    <CommonViewField {...props} componentView={View} componentEdit={Edit} clickIconToEdit={false} />
  );
};

export default ItemBarCodeViewField;
