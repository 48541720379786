import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import SalesCommission from '@product/item/components/sales-commission';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = null, errors = {}, onChange } = props;

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <SalesCommission value={value} onChange={onChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
