import { Checkbox, Switch } from '@base/components/form';
import ListLoading, { GridNothing } from '@base/components/list/list-loading';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { MENU_PRODUCT, MENU_PRODUCT_PRODUCT } from '@base/config/menus';
import { ProductItemTextView } from '@base/containers/quick-view';
import { convertDateTimeServerToClient } from '@base/utils/helpers';
import { PRODUCT_TYPE_OPTIONS } from '@product/main/config/constants';
import * as keyNames from '@product/product/config/key-names';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeTable8Columns, makeTableColumns } from '@base/components/utils/helpers/react-table';
import { ASC, DESC } from '@base/config/constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userProductSetting } from '@product/product/recoil/atom';
import { ListBody, ListGrid, ListPagination } from '@base/components/list';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { ListType } from '@base/types/enums';
import { deviceAtom } from '@base/recoil/atoms';
import ReactGrid from '@base/components/react-grid';

const Body = (props: any) => {
  const {
    isSplitMode,
    listData,
    onGridChecked,
    selectedIds,
    hideColumns,
    fields,
    onTableChecked,
    sort,
    setSort,
    paging,
    onChangeSetting,
    isFetching,
  } = props;

  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'product';

  // Recoil
  const { device } = useRecoilValue(deviceAtom);
  const [pageData, setPageData] = useRecoilState(userProductSetting);

  let { filter, listType } = pageData;

  const columnsByGrid: string[] = [keyNames.KEY_PRODUCT_NAME];

  const getMapColumns = () => {
    return {
      code(col: string, data: any) {
        return data?.[col] ?? '';
      },
      name(col: string, data: any) {
        let name = data?.[col] ?? '';
        let id = data?.id ?? '';
        return <Link to={`/${menu}/${category}/${id}`}>{name}</Link>;
      },
      type(col: string, data: any) {
        return PRODUCT_TYPE_OPTIONS?.find((v: any) => v.value === data[col])?.label;
      },
      active(col: string, data: any) {
        return <Switch value={data?.active || false} />;
      },
      [keyNames.KEY_PRODUCT_GROUP](col: string, data: any) {
        return data[col]?.name;
      },
      attributes(col: string, data: any) {
        const items = data[col] || [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
          />
        ) : (
          ''
        );
      },
      assignTo(col: string, data: any) {
        const reps = data[col] || [];
        return reps.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={reps}
          />
        ) : (
          '(none)'
        );
      },
      startDate(col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.startDate });
      },
      endDate(col: string, data: any) {
        return convertDateTimeServerToClient({ date: data?.endDate });
      },
      [keyNames.KEY_PRODUCT_BASE_UNIT](col: string, data: any) {
        return data?.unit?.name ?? '';
      },
      costOfGoods(col: string, data: any) {
        return `${data?.costOfGoods} %`;
      },
      [keyNames.KEY_PRODUCT_ITEMS](col: string, data: any) {
        const items = data[col] || [];
        return items.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            showAvatar={false}
            values={items}
            cellComponent={(item: any) => (
              <ProductItemTextView value={{ id: item?.id, name: item?.name }} />
            )}
          />
        ) : (
          ''
        );
      },
    };
  };

  //columns render in grid split mode
  const getMapColumnsInSplitMode = () => {
    return getMapColumns();
  };

  //columns in grid split mode
  const gridFieldsInSplitMode: any[] = [];
  columnsByGrid?.map((_col: string) => {
    fields.map((_ele: any) => {
      if (_ele.name === _col) {
        gridFieldsInSplitMode.push(_ele);
      }
    });
  });

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        size: 50,
      },
      ...makeTable8Columns(fields, getMapColumns(), { category }, []),
    ],
    [fields, selectedIds],
  );

  const listTable8Props = {
    className: '',
    // style: { maxHeight: 'calc(100vh - 260px)' },
    columns,
    data: listData ?? [],
    initialState: {
      // pageCount: paging?.totalItems || 0,
      pageSize: paging?.itemPerPage || filter?.paging?.size,
      // pageIndex: paging?.currentPage || 1,
      sorts: [sort], // sorts: [{ field: 'createdAt', orderBy: DESC }],
    },
    onRowSelect: onTableChecked,
    onSortBy: (clName: any, isSorted: any) => {
      // isSorted from react-table-v8 that is false, 'asc' or 'desc'
      // // console.log('isSorted', isSorted);
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    rowSelected: selectedIds,
  };

  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || filter?.paging?.size,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    // isPreviousData,
    onChange: (page: number, size: number) => {
      filter = {
        ...filter,
        paging: {
          page,
          size,
        },
      };
      let newFilter = {
        ...pageData,
        filter: filter,
      };
      // setPageData(newFilter);
      onChangeSetting && onChangeSetting(newFilter);
    },
  };

  // grid props
  const gridProps = {
    isSplitMode: isSplitMode,
    category: category, // router category
    // primaryKey: 'id',
    // titleKey: 'name',
    // titleUrlKey: 'name',
    gridData: listData ?? [],
    isLoading: isFetching,
    device: device !== undefined ? device : 'desktop',
    columns: isSplitMode ? gridFieldsInSplitMode : fields,
    columnRenderRemap: isSplitMode ? getMapColumnsInSplitMode() : getMapColumns(),
    hideColumns,
    onCheckedRow: onGridChecked,
    checkedIds: selectedIds,
  };

  const BodyMemo = useMemo(() => {
    return (
      <ListBody>
        {isSplitMode ? (
          <ReactGrid
            {...gridProps}
            // gridItemRender={(props: any) => <CustomGridItem {...props} />}
          />
        ) : (
          <>
            {/* table list */}
            {listType == ListType.LIST && <ListReactTable8 {...listTable8Props} />}
            {/* grid list */}
            {listType == ListType.GRID && (
              <ReactGrid
                {...gridProps}
                // gridItemRender={(props: any) => <CustomGridItem {...props} />}
              />
            )}
          </>
        )}
        <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} />
      </ListBody>
    );
  }, [isSplitMode, listType, listData, fields]);

  return BodyMemo;
};

export default Body;
