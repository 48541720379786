import React from 'react';
import PagelayoutSummary from '@base/containers/pagelayout-summary';

interface IViewLeftProps {
  id: string;
  category: string;
}
const ViewLeft: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { category, id } = props;

  return (
    <div className="scroll-box h-100 overflow-auto">
      <PagelayoutSummary
        menuSource={category}
        menuSourceId={id}
        ignoreFields={['unitValues', 'relatedProducts']}
      />
    </div>
  );
};
export default ViewLeft;
