import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Select } from '@base/components/form';
import { useAttributeValuesAutoComplete } from '@product/product/services/product-service';

const AttributeValueAutoComplete: React.FC<any> = (props: any) => {
  const { value, onChange, placeholder, single = false, className } = props;

  // state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //set selected items
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  //value change
  const handleSelectChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //build params
  const getSearchParams = () => {
    let query = '';
    if (searchText != '') {
      query = `name:"${searchText}"`;
    }

    let params: any = {
      filter: {
        query: query,
        sort: {
          field: 'createdAt',
          orderBy: 2,
        },
        paging: {
          page: 1,
          size: 999,
        },
      },
    };

    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let newOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: [_item.attr.name, _item.name].join('/'),
        value: _item.id,
      };
    });
    return newOptions;
  };

  const { data: results, status: searchStatus } = useAttributeValuesAutoComplete(getSearchParams());
  let newOptions = formatSelectOptions(results);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        isMulti={!single}
        outSide
        menuPlacement="auto"
        noOptionsMessage={() => 'No items found.'}
        placeholder={
          <div className="react-select-placeholder">
            {placeholder || 'Type or click to select an item'}
          </div>
        }
        // iconIndicator={
        //   <div className="icons-size-1">{<FormIcon icon={'product'} iconType="main" />}</div>
        // }
        isClearable
        // components={{ Option: CustomOption }}
        isLoading={searchStatus === 'loading'}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={newOptions}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default AttributeValueAutoComplete;
