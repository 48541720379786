import React, { useState, useEffect, useMemo } from 'react';
import _, { uniqueId } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStatePromise from '@base/hooks/useStatePromise';
import { useRecoilValue } from 'recoil';
import { defaultCurrencySelector } from '@base/recoil/selectors';
import { graphQLApi } from '@base/utils/axios/graphql';
import { GET_BULK_CODE_NEXT_ID } from '@product/item/services/graphql';
import {
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_CATEGORY_NON_INVENTORY,
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_PREPAID_TYPE_AMOUNT,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import {
  KEY_ITEM_CODE,
  KEY_ITEM_TEMPLATE,
  KEY_ITEM_BASE_PRICES,
  KEY_ITEM_BEST_PRICES,
  KEY_ITEM_COST_PRICES,
  KEY_ITEM_PURCHASE_PRICES,
  KEY_ITEM_OPEN_STOCK,
  KEY_ITEM_INVENTORY_REORDER_POINT,
  KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
} from '@product/item/config/key-names';
import RowItem from './row-item';
import { GET_MENU_SETTINGS } from '@settings/general-setting/services/graphql/format';
import usePosts from '@base/hooks/usePosts';
import usePost from '@base/hooks/usePost';

interface IResponseBulk {
  codes: any;
  setting: any;
}
/**
 *
 * @param {*} props
 * @returns
 */
const BasicInfo: React.FC<any> = (props: any) => {
  const {
    product,
    category,
    type,
    prepaidType,
    usePrepaid, // 'y', 'n'
    useSubscribe, // 'y', 'n'
    value,
    onChange,
  } = props;

  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);
  const { type: productType, canBeSold } = product;

  // state
  // const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [subTemplateOptions, setSubTemplateOptions] = useState<any>([]);
  const [items, setItems] = useStatePromise([]);
  const [skuSetting, setSkuSetting] = useStatePromise(null);

  // create default price for Both
  let defaultPrice: any = {
    purchase_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
    base_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
    cost_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
      cost_fields: [],
    },
    best_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
  };

  // GET SKU format setting
  const params = {
    menus: ['product'],
    keys: ['product_sku_setting'],
  };
  const { isLoading: isSkuLoading, data } = usePosts<any>(
    ['setting_menusSettings', JSON.stringify(params)],
    GET_MENU_SETTINGS,
    params,
  );
  useEffect(() => {
    if (data?.data?.length) {
      const newSetting = JSON.parse(data?.data?.[0]?.value);
      setSkuSetting({ ...newSetting, id: data?.data?.[0]?.id });
    }
  }, [data]);

  // GET CODES
  const codeParams = {
    menu: 'item',
    bulk: value?.length,
  };
  const { isLoading: isGenerating, data: nextIdData } = usePost<IResponseBulk>(
    ['setting_nextIdBulk', JSON.stringify(codeParams)],
    GET_BULK_CODE_NEXT_ID,
    codeParams,
    { enabled: value?.length > 0 },
  );
  useEffect(() => {
    if (nextIdData?.codes?.length) {
      const codes = nextIdData?.codes;
      let newItems: any = [];
      if (codes) {
        newItems = value?.map((_item: any, _index: number) => ({
          ..._item,
          [KEY_ITEM_CODE]: codes[_index],
        }));
        setItems(newItems);
      }
    }
  }, [nextIdData]);

  // set amount use for prepaid info
  const getPrepaidUseAmount = (prices: any) => {
    const newUseAmount: any = [];
    if (prices && prices.length > 0) {
      prices.map((_priEle: any) => {
        newUseAmount.push({
          value: 0,
          base_value: _priEle.base_price.value,
          currency: _priEle.base_price.currency,
        });
      });
    }
    return newUseAmount;
  };

  //item value change
  const handleItemChange = (newItem: any, index: number) => {
    const newItems = [...items];
    newItems[index] = newItem;
    setItems(newItems);
    // callback
    onChange && onChange(newItems);
  };

  // copy first item to other
  // itemIndex == 0
  const handleItemCopy = async (itemIndex: number, keyName: string) => {
    setIsCopying(true);
    let newItems = [...items];
    if (keyName === KEY_ITEM_BASE_PRICES) {
      const copyLength = newItems[itemIndex].prices.length;
      //copy to other items
      newItems.map((_item, _idx) => {
        if (_idx !== itemIndex) {
          // add default price
          if (_item.prices.length != copyLength) {
            const newPrices: any[] = [];
            while (copyLength != newPrices?.length) {
              newPrices.push(_.cloneDeep(defaultPrice));
            }
            _item.prices = [...newPrices];
          }
          const copyPrices = _item.prices.map((_price: any, pIdx: number) => {
            if (pIdx < copyLength) {
              _price.base_price = _.cloneDeep(newItems[itemIndex].prices[pIdx].base_price);
            }
            return _price;
          });
          _item.prices = copyPrices;

          //prepaid step ON
          if (usePrepaid === 'y') {
            _item.prepaid.map(
              (_ele: any) => (_ele.uses[0].amount = getPrepaidUseAmount(_item.prices)),
            );
          }
        }
      });
    }
    if (keyName === KEY_ITEM_COST_PRICES) {
      const copyLength = newItems[itemIndex].prices.length;
      //copy to other items
      newItems.map((_item: any, _idx: number) => {
        if (_idx !== itemIndex) {
          // add default price
          if (_item.prices.length != copyLength) {
            const newPrices: any[] = [];
            while (copyLength != newPrices?.length) {
              newPrices.push(_.cloneDeep(defaultPrice));
            }
            _item.prices = [...newPrices];
          }
          const copyPrices = _item.prices.map((_price: any, pIdx: number) => {
            if (pIdx < copyLength) {
              _price.cost_price = _.cloneDeep(newItems[itemIndex].prices[pIdx].cost_price);
            }
            return _price;
          });
          _item.prices = copyPrices;
        }
      });
    }
    if (keyName === KEY_ITEM_BEST_PRICES) {
      const copyLength = newItems[itemIndex].prices.length;
      //copy to other items
      newItems.map((_item, _idx) => {
        if (_idx !== itemIndex) {
          // add default price
          if (_item.prices.length != copyLength) {
            const newPrices: any[] = [];
            while (copyLength != newPrices?.length) {
              newPrices.push(_.cloneDeep(defaultPrice));
            }
            _item.prices = [...newPrices];
          }
          const copyPrices = _item.prices.map((_price: any, pIdx: number) => {
            if (pIdx < copyLength) {
              _price.best_price = _.cloneDeep(newItems[itemIndex].prices[pIdx].best_price);
            }
            return _price;
          });
          _item.prices = copyPrices;
        }
      });
    }
    if (keyName === KEY_ITEM_PURCHASE_PRICES) {
      const copyLength = newItems[itemIndex].prices.length;
      //copy to other items
      newItems.map((_item, _idx) => {
        if (_idx !== itemIndex) {
          // add default price
          if (_item.prices.length != copyLength) {
            const newPrices: any[] = [];
            while (copyLength != newPrices?.length) {
              newPrices.push(_.cloneDeep(defaultPrice));
            }
            _item.prices = [...newPrices];
          }
          const copyPrices = _item.prices.map((_price: any, pIdx: number) => {
            if (pIdx < copyLength) {
              _price.purchase_price = _.cloneDeep(newItems[itemIndex].prices[pIdx].purchase_price);
            }
            return _price;
          });
          _item.prices = copyPrices;
        }
      });
    }
    if (keyName === KEY_ITEM_TEMPLATE) {
      //copy to other items, itemIndex === 0
      newItems.map((_item: any, _idx: number) => {
        if (_idx !== itemIndex) {
          _item.template = _.cloneDeep(newItems[itemIndex].template);
        }
      });
    }
    if (keyName === KEY_ITEM_OPEN_STOCK) {
      newItems.map((_item: any, _idx: number) => {
        if (_idx !== itemIndex) {
          _item[KEY_ITEM_OPEN_STOCK] = _.cloneDeep(newItems[itemIndex][KEY_ITEM_OPEN_STOCK]);
        }
      });
    }
    if (keyName === KEY_ITEM_INVENTORY_REORDER_POINT) {
      newItems.map((_item: any, _idx: number) => {
        if (_idx !== itemIndex) {
          _item[KEY_ITEM_INVENTORY_REORDER_POINT] = _.cloneDeep(
            newItems[itemIndex][KEY_ITEM_INVENTORY_REORDER_POINT],
          );
        }
      });
    }
    if (keyName === KEY_ITEM_INVENTORY_REPLENISHMENT_POINT) {
      newItems.map((_item: any, _idx: number) => {
        if (_idx !== itemIndex) {
          _item[KEY_ITEM_INVENTORY_REPLENISHMENT_POINT] = _.cloneDeep(
            newItems[itemIndex][KEY_ITEM_INVENTORY_REPLENISHMENT_POINT],
          );
        }
      });
    }
    await setItems(newItems);
    setIsCopying(false);
    //callback
    onChange && onChange(newItems);
  };

  // remove a item
  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  };

  // table rows
  const TableRows = useMemo(() => {
    return (
      <>
        {!isGenerating &&
          !isSkuLoading &&
          items.length > 0 &&
          items.map((_item: any, index: number) => {
            _item.product = product;
            return (
              <RowItem
                key={_item.idLocal}
                productType={product.type}
                canBeSold={product.canBeSold}
                category={category}
                type={type}
                prepaidType={prepaidType}
                usePrepaid={usePrepaid === 'y' ? true : false}
                useSubscribe={useSubscribe === 'y' ? true : false}
                subTemplateOptions={subTemplateOptions}
                isCopyAll={items.length > 1}
                rowIndex={index}
                value={_item}
                onChange={(newItem: any, rIndex: number) => handleItemChange(newItem, rIndex)}
                onCopy={(rIndex: number, keyName: string) => handleItemCopy(rIndex, keyName)}
                onRemove={() => handleRemoveItem(index)}
                defaultSkuSetting={skuSetting}
              />
            );
          })}
      </>
    );
  }, [
    items,
    isGenerating,
    skuSetting,
    product,
    category,
    type,
    prepaidType,
    subTemplateOptions,
    useSubscribe,
    usePrepaid,
  ]);

  // render
  return (
    <div className="pos-relative" style={{ minHeight: '180px' }}>
      <InfiniteScroll
        dataLength={items.length}
        next={() => null}
        hasMore={false}
        loader={
          <div className="wrap-hanloading">
            <div className="han-loading" />
          </div>
        }
      >
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="bg-light">
                {/* Item Name */}
                <th scope="col">
                  Item Name<span className="tx-danger">*</span>
                </th>
                {/* Item ID */}
                <th scope="col">
                  Item ID<span className="tx-danger">*</span>
                </th>
                {/* Base Price */}
                {canBeSold && (
                  <th scope="col">
                    Base Price<span className="tx-danger">*</span>
                  </th>
                )}
                {/* Base Cost */}
                {(canBeSold || productType === PRODUCT_TYPE_PRODUCED) && (
                  <th scope="col">
                    Base Cost<span className="tx-danger">*</span>
                  </th>
                )}
                {/* Best Price */}
                {/* {canBeSold && (
                  <th scope="col">
                    Best Price<span className="tx-danger">*</span>
                  </th>
                )} */}
                {/* Base Purchase Price */}
                {!canBeSold && productType === PRODUCT_TYPE_PURCHASE && (
                  <th scope="col">
                    Base Purchase Price<span className="tx-danger">*</span>
                  </th>
                )}
                {/* Opening Stock */}
                {category === PRODUCT_ITEM_CATEGORY_INVENTORY && <th scope="col">Opening Stock</th>}
                {/* Reorder Point */}
                {/* {canBeSold &&
                  productType === PRODUCT_TYPE_PURCHASE &&
                  category === PRODUCT_ITEM_CATEGORY_INVENTORY &&
                  type === PRODUCT_ITEM_TYPE_GENERAL && <th scope="col">Reorder Point</th>} */}
                {/* Replenishment Point */}
                {category === PRODUCT_ITEM_CATEGORY_INVENTORY && (
                  <th scope="col">Replenishment Point</th>
                )}
                {/* SKU */}
                {category === PRODUCT_ITEM_CATEGORY_INVENTORY && <th scope="col">SKU</th>}
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {(isGenerating || isCopying || isSkuLoading) && (
                <tr key={uniqueId()}>
                  <td colSpan={10} height={150}>
                    <div className="wrap-hanloading">
                      <div className="han-loading" style={{ top: '50px' }} />
                    </div>
                  </td>
                </tr>
              )}
              {!isSkuLoading && !isGenerating && !isCopying && TableRows}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default BasicInfo;
