export const generateBaseUnitParams = (formData: {
  baseUnit: { name: any; active: string };
  unitDetail: any[];
}) => {
  let newParams: any = {};
  newParams.name = formData.baseUnit.name;
  newParams.active = formData.baseUnit.active === 'y' ? true : false;
  newParams.unitValues = formData.unitDetail.map((_ele: { unitName: any; unitQty: string }) => ({
    name: _ele.unitName,
    qty: parseInt(_ele.unitQty),
  }));
  return newParams;
};

export const generateUnitValueParams = (formData: { baseUnit: { id: any }; unitDetail: any[] }) => {
  let newParams: any = {};
  newParams.unitId = formData.baseUnit.id;
  newParams.unitValues = formData.unitDetail.map((_ele: { unitName: any; unitQty: string }) => ({
    name: _ele.unitName,
    qty: parseInt(_ele.unitQty),
  }));
  return newParams;
};
