import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import DesktopLayout1ViewRight from '@base/layouts/desktop/layout1/view-right';
import RelatedCustomer from '@product/item/containers/related-customer';
// import Pricing from '@product/item/containers/pricing';
import AssignUser from '@product/item/containers/assign-user';
import { KEY_PRODUCT_TYPE, KEY_PRODUCT_TYPE_BE_SOLD } from '@product/product/config/key-names';
import {
  PRODUCT_ITEM_TYPE_ENUM_PREPAID,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import { KEY_ITEM_TYPE } from '@product/item/config/key-names';
import RelatedVendor from '@product/product/containers/related-vendor';
import { MENU_PRODUCT_ITEM } from '@base/config/menus';

interface IRightProps {
  id: string;
  category: string;
  data?: any;
}

const ViewRight: React.FC<IRightProps> = (props: IRightProps) => {
  const { category, id, data } = props;
  const { t } = useTranslation();

  const [rightConfig, setRightConfig] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const newRight = [
        {
          title: t('common_section_assigned_rep'),
          key: 'assigned',
          component: <AssignUser placement="left" id={id} />,
        },
        {
          title: t('common_section_attachment'),
          key: 'attachment',
          component: (
            <TimeAttachment menuSource={category ?? 'item'} menuSourceId={id} listType="list" />
          ),
        },
      ];

      if (data?.prod?.[KEY_PRODUCT_TYPE]?.keyName === PRODUCT_TYPE_PURCHASE) {
        newRight.unshift({
          title: t('Related Customer'),
          key: 'related_customer',
          component: <RelatedCustomer sourceId={id} />,
        });
      }

      if (data?.prod?.[KEY_PRODUCT_TYPE_BE_SOLD]) {
        newRight.unshift({
          title: t('Related Vendor'),
          key: 'related_vendor',
          component: (
            <RelatedVendor
              sourceId={id}
              readOnly={false}
              sourceMenu={MENU_PRODUCT_ITEM}
              menuTab={'item'}
            />
          ),
        });
      }

      // if (
      //   data?.prod?.[KEY_PRODUCT_TYPE_BE_SOLD] &&
      //   data?.[KEY_ITEM_TYPE] != PRODUCT_ITEM_TYPE_ENUM_PREPAID
      // ) {
      //   newRight.unshift({
      //     title: t('common_section_pricing'),
      //     key: 'pricing',
      //     component: <Pricing sourceId={id} />,
      //   });
      // }

      setRightConfig(newRight);
    }
  }, [data]);

  return <DesktopLayout1ViewRight rightConfig={rightConfig} />;
};

export default ViewRight;
