import React from 'react';
import { Minus } from 'react-feather';
import BarCodeInput from '@product/item/containers/barcode-input';
import { Button } from '@base/components/form';
import useBarcode from '@base/hooks/useBarCode';
import classnames from 'classnames';

const RowItem: React.FC<any> = (props: any) => {
  const { value = '', rowIndex = 0, onRemove, onChange } = props;

  const { inputRef } = useBarcode({
    value: value,
    options: {
      background: '#ccffff',
      height: 50,
      width: 1,
    },
  });

  return (
    <li
      key={rowIndex}
      className={classnames('list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white', {
        'pd-t-0': rowIndex === 0,
      })}
    >
      <div className="d-flex flex-1 flex-column">
        <div className="w-100 d-flex">
          <svg ref={inputRef} />
        </div>
        <div className="form-group pd-b-0 mg-b-0 d-flex" style={{ minWidth: '250px' }}>
          <BarCodeInput
            value={value}
            onChange={(newItem: any) => {
              onChange && onChange(newItem, rowIndex);
            }}
          />
          <Button
            color="link"
            className="tx-danger"
            onClick={() => {
              onRemove && onRemove(rowIndex);
            }}
          >
            <Minus className="mg-r-5" />
          </Button>
        </div>
      </div>
    </li>
  );
};

export default RowItem;
