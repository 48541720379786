/* eslint-disable react/no-children-prop */
/* eslint-disable no-empty-function */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@base/components/form';
import { ChevronUp } from 'react-feather';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import { generateBaseUnitParams, generateUnitValueParams } from './payload';
import { KEY_UNIT_BASE, KEY_UNIT_DETAIL } from '@product/unit/config/key-names';
import BasicUnit from './basic-unit';
import UnitDetail from './unit-detail';
import { toast } from 'react-toastify';
import useMutationPost from '@base/hooks/useMutationPost';
import { ADD_PRODUCT_UNIT } from '@product/unit/services/graphql';

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<any> = (props: any) => {
  const {
    fullScreen, //for modal full-screen
    defaultValue = {},
    onClose = () => {},
    onReload,
    onSuccess,
  } = props;
  const { t } = useTranslation();

  //state
  const [isSaving, setIsSaving] = useState(false);

  //hook-form
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    defaultValues: defaultValue,
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //watching
  const baseUnit = watch(KEY_UNIT_BASE);
  useEffect(() => {
    if (baseUnit && baseUnit?.id) {
      setValue(KEY_UNIT_DETAIL, [], { shouldValidate: false });
    } else {
      if (baseUnit?.name) {
        //default value
        const defaultValue = [
          {
            baseUnit: baseUnit.name,
            unitName: baseUnit.name,
            unitQty: 1,
            isBase: true,
          },
        ];
        setValue(KEY_UNIT_DETAIL, defaultValue, { shouldValidate: true });
      }
    }
  }, [baseUnit]);

  //create mutation
  const mutationAddBaseUnit: any = useMutationPost<any>(ADD_PRODUCT_UNIT, 'product_createUnit', {
    onSuccess: (data: any, variables: any, context: any) => {
      //// console.log('after save', context);
      toast.success('Create base unit successfully!');
      // refecth data
      setIsSaving(false);
      onSuccess && onSuccess(data);
      onReload && onReload();
      onClose && onClose();
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      //// console.log('mutation error', error);
      setIsSaving(false);
      toast.error('Create base unit failed: ' + JSON.parse(error).message);
      // refecth data
    },
  });

  //add new
  const onAddNewBaseUnit = (defaultParams: any, isReset: any) => {
    const params = {
      unit: defaultParams,
    };
    mutationAddBaseUnit.mutate(params);
  };

  //add unit values
  const onAddNewUnitValues = (defaultParams: any, isReset: any) => {};

  //submit form
  const onSubmit = (formData: any, isReset: any) => {
    setIsSaving(true);
    if (formData.baseUnit.id) {
      //add unit names
      let defaultParams = generateUnitValueParams(formData);
      onAddNewUnitValues(defaultParams, isReset);
    } else {
      //add base unit + unit names
      let defaultParams = generateBaseUnitParams(formData);
      onAddNewBaseUnit(defaultParams, isReset);
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log(errors, e);
  };

  //step 1 - base unit required
  const baseUnitIsRequired = (data: any) => {
    if (!data) return false;
    let isValid = true;
    if (data.name.length === 0) isValid = false;
    return isValid;
  };

  //step 2 - unit names
  const unitNameIsRequired = (data: any) => {
    if (!data) return false;
    let isValid = true;
    data.map((_item: any) => {
      if (_item.unitName.length === 0) {
        isValid = false;
      }
      if (parseInt(_item.unitQty) < 1) {
        isValid = false;
      }
    });
    return isValid;
  };

  //form view
  const renderForm = () => {
    return (
      <>
        <Controller
          name={KEY_UNIT_BASE}
          control={control}
          //defaultValue={{name: '', active: 'y'}}
          rules={{ validate: baseUnitIsRequired }}
          render={({ field: { value, onChange } }: any) => (
            <BasicUnit value={value?.undefined ? value?.undefined : value} onChange={onChange} />
          )}
        />
        <Controller
          name={KEY_UNIT_DETAIL}
          control={control}
          //defaultValue={[]}
          rules={{ validate: unitNameIsRequired }}
          render={({ field: { value, onChange } }: any) => (
            <UnitDetail
              value={value?.undefined ? value?.undefined : value}
              onChange={onChange}
              baseUnit={baseUnit}
            />
          )}
        />
      </>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              type="button"
              color="success"
              onClick={() => {
                handleSubmit((data) => onSubmit(data, false), onError)();
              }}
              disabled={isSaving || !isValid}
              loading={isSaving}
              children="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                type="button"
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  handleSubmit((data) => onSubmit(data, true), onError)();
                }}
                disabled={isSaving || !isValid}
                loading={isSaving}
                children="Save and Create New"
              />
              {props?.showCanvas && (
                <Button
                  type="button"
                  className="dropdown-item"
                  color="primary"
                  onClick={() => {
                    handleSubmit((data) => onSubmit(data, false), onError)();
                  }}
                  disabled={isSaving || !isValid}
                  loading={isSaving}
                  children="Save and Apply"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // // console.log('form values', watch()); //get form values when inputing
  //// console.log('form values', getValues()); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = props?.showCanvas || fullScreen ? 'calc(100vh - 180px)' : 'calc(100vh - 320px)';
  // console.log('...fullScreen...', fullScreen, props?.showCanvas);

  return (
    <div className="pd-5">
      <div className="bd-b pd-y-10">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You can't except a required field</span>
          </span>
        </div>
      </div>
      {/* render form */}
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="pos-relative pd-15"
          style={{
            height: formHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxWidth: props?.showCanvas ? 800 : 'auto',
          }}
        >
          {renderForm()}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(withWriteForm(WriteForm));
