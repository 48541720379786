import { PRODUCT_MENUS } from '@base/config/route-menus';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { isEmptyLayoutData, mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { buildViewSchema } from '@base/utils/helpers/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { TABS } from './tabs';
import { useGetView } from '@base/services/view';
import { default as viewConfig } from '@product/product/config/view-field';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@product/product/config/display-layout';
import { useTranslation } from 'react-i18next';
import ViewLeft from '@product/product/layouts/desktop/layout1/view/view-left';
import ViewRight from '@product/product/layouts/desktop/layout1/view/view-right';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '@base/containers/view-field/title';
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';
import { SpanLang } from '@base/components/form';
import Loading from '@base/components/loading';
import NotFound from '@base/components/errors/notFound';
import {
  KEY_PRODUCT_COST_OF_GOODS,
  KEY_PRODUCT_NAME,
  KEY_PRODUCT_TYPE,
  KEY_PRODUCT_TYPE_BE_SOLD,
  KEY_SALE_END_DATE,
  KEY_SALE_START_DATE,
} from '@product/product/config/key-names';
import { PRODUCT_TYPE_PRODUCED, PRODUCT_TYPE_PURCHASE } from '@product/main/config/constants';
import { PRODUCT_ADD_OPTIONS } from '@product/main/config';

// Write Product
import WritePage from '@product/product/pages/write';
// Write Item
import WritePageItem from '@product/item/pages/write';

import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { CLONE_PRODUCT, DELETE_PRODUCT } from '@product/product/services/graphql';
import { toast } from 'react-toastify';
//import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
import PrintPreview from '@product/product/containers/print-preview';

interface IViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device,  init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { t } = useTranslation();
  const { isSplitMode } = props;
  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'product';

  const queryClient = useQueryClient();

  // recoil
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_PRODUCT_PRODUCT));
  const pageData = useRecoilValue(pageDataByMenuAtom(MENU_PRODUCT));

  // params
  const params = useParams();
  const navigate = useNavigate();
  const { activeId, activeTab } = pageData;
  let id = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = params['*'] && activeTab != params['*'] ? params['*'] : activeTab;
  // state
  const [tabs, setTabs] = useState<any[]>(TABS);
  const [ignoreFields, setIgnoreFields] = useState<string[]>([]);
  const [showPrint, setShowPrint] = useState(false);

  /** Get data */
  const layoutMenu = [MENU_PRODUCT, category].join('_');
  const { data: layoutView, isLoading: layoutLoading } = usePageLayoutByMenu(layoutMenu, 'view');
  const viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const {
    isLoading,
    error,
    data: product,
    isFetching: dataLoading,
    isPreviousData,
    refetch,
  } = useGetView<any>({ menu: 'product_product', schemas: viewSchema, params: { id: id } });

  useEffect(() => {
    if (!isLoading && !layoutLoading && product) {
      const layoutData = mergeLayoutData(layoutView, product, viewConfig);

      // custom show/hide fields, tabs by product type
      const _ignoreFields: string[] = [];
      _ignoreFields.push(KEY_PRODUCT_NAME);
      if (!product?.[KEY_PRODUCT_TYPE_BE_SOLD]) {
        _ignoreFields.push(KEY_SALE_START_DATE);
        _ignoreFields.push(KEY_SALE_END_DATE);
        _ignoreFields.push(KEY_PRODUCT_COST_OF_GOODS);
      }
      setIgnoreFields(_ignoreFields);

      const _tabs: any[] = [];
      TABS?.map((tab: any) => {
        if (['bill_of_material', 'purchase', 'pricing'].indexOf(tab.path) === -1) {
          _tabs.push(tab);
        }

        if (
          product?.[KEY_PRODUCT_TYPE]?.keyName === PRODUCT_TYPE_PRODUCED &&
          tab?.path === 'bill_of_material'
        ) {
          _tabs.push(tab);
        }
        if (
          product?.[KEY_PRODUCT_TYPE]?.keyName === PRODUCT_TYPE_PURCHASE &&
          tab?.path === 'purchase'
        ) {
          _tabs.push(tab);
        }
        if (product?.[KEY_PRODUCT_TYPE_BE_SOLD] && tab?.path === 'pricing') {
          _tabs.push(tab);
        }
      });
      setTabs(_tabs);

      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menuSource = MENU_PRODUCT_PRODUCT;
      newLayoutData.data = product;
      setViewData(newLayoutData);
    }

    return () => { };
  }, [product, isLoading, layoutLoading]);

  //for write form
  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT,
    'product_deleteProduct',
    {
      onMutate: () => { },
      onSuccess: (data: any, variables: any, context: any) => {
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        return navigate(`/${menu}/${category}`);
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete product failed: ' + JSON.parse(error).message);
      },
    },
  );

  const mutationClone: any = useMutationPost<BaseMutationResponse>(
    CLONE_PRODUCT,
    'product_copyProduct',
    {
      onMutate: () => { },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Clone product successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['product_products']);
        }, 500);
        return navigate(`/${menu}/${category}`);
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Clone product failed: ' + JSON.parse(error).message);
      },
    },
  );

  const View = useMemo(() => {
    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: `${category}`,
        menus: PRODUCT_MENUS,
        onMenuChange: (newCategory: string) => {
          return navigate(`/${menu}/${newCategory}`);
        },
        onPrint: () => setShowPrint(true),
        onClone: () => mutationClone.mutate({ ids: [id] }),
        onDelete: () => mutationDelete.mutate({ ids: [id] }),
        // addOptions: PRODUCT_ADD_OPTIONS,
        onNew: (category: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: category === undefined ? MENU_PRODUCT_PRODUCT : category,
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              keyName="name"
              value={product?.name}
              userPermission={{
                isEdit: true,
              }}
              onSave={(params: any) => { }}
              menuSourceId={id}
              menuSource={MENU_PRODUCT_PRODUCT}
            />
          </>
        ),
      },
      centerProps: {
        menuSource: MENU_PRODUCT_PRODUCT, // using for timeline, notes
        menuSourceId: id,
        tabs: tabs,
        showTabs: 2,
        currentTab: currentTab,
      },
      componentLeft: (
        <ViewLeft
          id={id}
          category={MENU_PRODUCT_PRODUCT}
          ignoreFields={ignoreFields}
          onRefetch={refetch}
        />
      ),
      leftProps: {},
      componentRight: <ViewRight id={id} category={MENU_PRODUCT_PRODUCT} data={product} />,
      rightProps: {},
    };
    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />
        {/* hook form - write new product write */}
        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
          <WritePage
            title={<SpanLang keyLang={'Create Product'} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            onReload={refetch}
            menuApi={MENU_PRODUCT_PRODUCT}
          />
        )}

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
          <WritePageItem
            title={<SpanLang keyLang={'Create Item'} />}
            size="xl"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={reloadItemUnits}
            product={null}
            menuApi={MENU_PRODUCT_ITEM}
          />
        )}
        {showPrint && (
          <PrintPreview
            title='Product Print Preview'
            isOpen={showPrint}
            onClose={() => setShowPrint(false)}
            data={viewData}
          />
        )}
      </>
    );
  }, [id, product, currentTab, ignoreFields, tabs, writeOption, showPrint]);

  return <>{isLoading || layoutLoading ? <Loading /> : product ? View : <NotFound />}</>;
};
export default ViewPage;
