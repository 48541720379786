import React, { useState, useEffect } from 'react';
import { Settings } from 'react-feather';
import { Input } from '@base/components/form';
import { GENERATOR_AUTO, generateCode, validateSKU } from '@product/item/config/sku';
import SKUGenerator from '@product/item/containers/sku-generator-modal';
import { GET_MENU_SETTINGS } from '@settings/general-setting/services/graphql/format';
import usePosts from '@base/hooks/usePosts';

/**
 *
 * @param {*} props
 */
const ProductSKUInput: React.FC<any> = (props: any) => {
  const {
    data, //info for generate code
    defaultSetting, //default setting
    value: initValue, //initial sku
    values = [], // item's SKU list
    onChange = null,
  } = props;

  //state
  const [generator, setGenerator] = useState(GENERATOR_AUTO);
  const [setting, setSetting] = useState<any>(defaultSetting); //reset setting when change setting
  const [showGenerateSKU, setShowGenerateSKU] = useState(false);
  const [value, setValue] = useState(initValue ?? '');

  // GET SKU format setting
  const params = {
    menus: ['product'],
    keys: ['product_sku_setting'],
  };
  const { isLoading, data: skuSetting } = usePosts<any>(
    ['setting_menusSettings', JSON.stringify(params)],
    GET_MENU_SETTINGS,
    params,
    { enabled: !defaultSetting || !defaultSetting?.id },
  );
  useEffect(() => {
    if (skuSetting?.data?.length) {
      const newSetting = JSON.parse(skuSetting?.data?.[0]?.value);
      setSetting({ ...newSetting, id: skuSetting?.data?.[0]?.id });
    }
  }, [skuSetting]);

  //get data and generate
  useEffect(() => {
    if (data && setting && value == '') {
      //generate sku by setting
      const newSKU = generateCode(
        {
          valueRows: setting?.valueRows,
          seperator: setting?.seperator,
          caseUse: setting?.caseUse,
        },
        data,
      );
      if (value != newSKU) {
        //set data
        // onChange && onChange({ sku: newSKU });
        setValue(newSKU);
        handleOnChange({ sku: newSKU });
      }
    }

    if (setting && setting?.generator != generator) {
      setGenerator(setting?.generator);
    }
  }, [data, setting]);

  useEffect(() => {
    if (initValue && value != initValue) {
      setValue(initValue);
    }
  }, [initValue]);

  //update new setting
  const handleSettingChange = (newValue: any) => {
    // // console.log('>>> SKU input >>> handleSettingChange >>>', newValue);
    setSetting(newValue.setting);
    setValue(newValue?.sku);
    // onChange &&
    //   onChange({
    //     sku: newValue.sku,
    //     setting: newValue.setting,
    //   });
    handleOnChange({ sku: newValue?.sku, setting: newValue?.setting });
  };

  const handleOnChange = (v: any) => {
    if (v?.sku != value) {
      let stringSku = validateSKU(v?.sku, values);
      onChange && onChange({ ...v, sku: stringSku });
    }
  };

  // // console.log('ProductSKUInput... > value >', value);
  //render
  return (
    <>
      <Input
        type="text"
        value={initValue}
        disabled={generator === GENERATOR_AUTO}
        rightIcon={<Settings className="wd-20" />}
        isLoading={isLoading}
        onChange={(value: string) => onChange && onChange({ sku: value })}
        onRightIconClick={() => setShowGenerateSKU(true)}
      />
      {showGenerateSKU && (
        <SKUGenerator
          isShow={showGenerateSKU}
          onClose={() => setShowGenerateSKU(false)}
          data={data}
          setting={setting}
          //value
          onChange={handleSettingChange}
        />
      )}
    </>
  );
};

export default ProductSKUInput;
