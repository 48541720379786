import React, { useState, useEffect, forwardRef, useCallback, useMemo, lazy } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import SpanLang from '@base/components/form/span-lang';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deviceAtom, languagesAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { FilterInput } from '@base/types/interfaces/common';
import { DESC, ASC } from '@base/config/constant';
import ListLoading, { GridNothing, TableNothing } from '@base/components/list/list-loading';
import {
  ListBody,
  ListContainer,
  ListGrid,
  ListPagination,
  ListToolbar,
  ListHeader,
  ListHeaderFilters,
  ListQuickAction,
  ListSearch,
  ListTableColumnSetting,
} from '@base/components/list';
import ListGridItem from '@base/components/list/list-grid/item';
import { ChevronDown, Trash2 } from 'react-feather';
import { makeTable8Columns, makeTableColumns } from '@base/components/utils/helpers/react-table';
import { buildListSchema } from '@base/utils/helpers/schema';
import {
  MENU_PRODUCT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import { SearchFilter } from '@base/types/interfaces/app';
import { ListType } from '@base/types/enums';
import { useGetList } from '@base/services/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import {
  dateByOptions,
  filterByOptions,
  groupByOptions,
  searchFields,
  sortsBy,
} from '../../config/list';
import { default as configFields } from '../../config/view-field';
import displayLayoutConfig from '../../config/display-layout';
import * as keyNames from '../../config/key-names';

//for write
import WritePage from '../write';
import { Checkbox, Switch } from '@base/components/form';
import WritePageProduct from '../../../product/pages/write';
import WritePageItem from '@product/item/pages/write';
//end for write

import { listDataByMenuAtom } from '@base/recoil/atoms';
import ListToolbarContainer from '@base/containers/list/toolbar';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { ParseFiltersToQuery } from '@base/utils/helpers/advanced-search-query';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { ColumnDef } from '@tanstack/react-table';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';
import { DELETE_PRODUCT_UNIT } from '@product/unit/services/graphql';
import { categoryOptions, PRODUCT_ADD_OPTIONS } from '@product/main/config';
import { ProductTextView } from '@base/containers/quick-view';
import { productUnitListData, userProductUnitSetting } from '@product/unit/recoil/atom';
import { handleCheckbox } from '@base/utils/helpers/list-page-utils';
import Body from './body';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode?: (isSplitMode: boolean) => void;
  onChangeSetting: (newSetting: any) => void;
}

const ListPage: React.FC<IListPage> = (props: IListPage) => {
  const { t } = useTranslation();
  const { isSplitMode, setIsSplitMode, onChangeSetting } = props;

  const queryClient = useQueryClient();

  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'unit';

  //recoil
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom); //for write page
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  const [listData, setListData] = useRecoilState(productUnitListData); //current list
  const [pageData, setPageData] = useRecoilState(userProductUnitSetting);

  // react-hook
  const params = useParams();
  const navigate = useNavigate();

  // state
  // const [showColumns, setShowColumns] = useState<any[]>([]);
  const [hideColumns, setHideColumns] = useState<string[]>([]);
  const [sortColumns, setSortColumns] = useState<any[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [quickLoading, setQuickLoading] = useState<boolean>(false);

  let { filter, listType, settingColumns } = pageData;

  // Sort
  const sort = filter?.sort;
  const setSort = (sort: any) => {
    let newFilter = {
      ...pageData,
      filter: {
        ...pageData.filter,
        sort: sort,
      },
    };
    // setPageData(newFilter);
    onChangeSetting && onChangeSetting(newFilter);
  };

  // get pagelayout
  const layoutMenu = 'product_units';
  let { data: listLayoutData } = usePageLayoutByMenu(layoutMenu, 'list');

  let fields: any[] = [];
  if (listLayoutData && listLayoutData?.data) {
    fields = listLayoutData?.data;
  }

  const getSortColumns = () => {
    return sortsBy;
  };

  useEffect(() => {
    if (fields?.length > 0) {
      const newColumns: any[] = [];

      fields?.map((_ele: any) => {
        newColumns.push({
          ..._ele,
          isViewing: _ele.defaultViewInList,
          enableSorting:
            getSortColumns()?.findIndex((v: any) => v?.value === _ele.keyName) != -1 ? true : false,
        });
      });

      // merge setting columns and page-layout
      const tableColumns: any[] =
        settingColumns && settingColumns?.length
          ? settingColumns?.filter((_item: any) => {
              if (newColumns.findIndex((v: any) => v.keyName === _item?.keyName) >= 0) {
                return _item;
              }
            })
          : newColumns;

      const newData: any = { ...pageData };
      newData.settingColumns = tableColumns;

      // setPageData(newData);
      onChangeSetting && onChangeSetting(newData);
    }
  }, [fields]);

  // columns will show in list
  const showColumns = settingColumns.filter((_ele: any) => _ele?.isViewing);

  // build list query schema
  const listQuerySchema = buildListSchema({ fields, configFields });

  // list filters
  let filtersQuery: FilterInput = {
    keyword: filter?.keyword ?? '',
    sort: filter?.sort,
    paging: filter?.paging,
    query: ParseFiltersToQuery(filter, dateByOptions, searchFields),
  };

  // get list data
  const { isLoading, data, isFetching, isPreviousData, refetch } = useGetList<any[]>(
    'product_units',
    listQuerySchema,
    filtersQuery,
  );
  useEffect(() => {
    setListData(data?.data ?? []);
  }, [data?.data]);

  const onCheckedRow = (checkedValue: string | number) => {
    const checkedValueStr = checkedValue as string;
    let newSelectedIds = handleCheckbox(checkedValueStr, selectedIds, listData);
    setSelectedIds([...newSelectedIds]);
  };

  const onTableCheckedRow = useCallback(
    (checkedValue: any[]) => {
      setSelectedIds(checkedValue);
    },
    [selectedIds],
  );

  // quick toolbar
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT_UNIT,
    'product_deleteUnit',
    {
      onMutate: () => {
        setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Delete product unit successfully!');
        setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_units']);
        }, 500);
        onCheckedRow('notAll');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete product unit failed: ' + JSON.parse(error).message);
        setQuickLoading(false);
      },
    },
  );

  const quickButtons = [
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: (selected: any) => {
        mutationDelete.mutate({ ids: selectedIds });
      },
    },
  ];

  // more menu
  const actionsProps = {
    actions: {
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => {},
  };

  const handleSearchSubmit = (formData: any) => {
    if (formData?.type === 'TYPE_NONE') {
      delete formData.type;
    }
    let newFilter: SearchFilter = {
      ...pageData.filter,
    };

    if (formData?.keyword != '') {
      newFilter = {
        ...newFilter,
        keyword: formData?.keyword,
      };
      delete formData.keyword;
    }
    newFilter = {
      ...newFilter,
      searchFilters: formData,
    };
    let newData: any = {
      ...pageData,
      filter: newFilter,
    };
    // setPageData(newData);
    onChangeSetting && onChangeSetting(newData);
  };

  const onChangeColumnSetting = (newColumns: any) => {
    const newData = {
      ...pageData,
      settingColumns: {
        ...settingColumns,
        [layoutMenu]: newColumns,
      },
    };
    onChangeSetting && onChangeSetting(newData);
    // setPageData({
    //   ...pageData,
    //   settingColumns: {
    //     ...settingColumns,
    //     [layoutMenu]: newColumns,
    //   },
    // });
  };

  const BodyMemo = useMemo(() => {
    return (
      <Body
        isSplitMode={isSplitMode}
        listData={listData}
        selectedIds={selectedIds}
        hideColumns={hideColumns}
        fields={showColumns}
        sort={sort}
        setSort={setSort}
        paging={data?.paging}
        isFetching={false}
        onGridChecked={setSelectedIds}
        onTableChecked={onTableCheckedRow}
        onChangeSetting={onChangeSetting}
      />
    );
  }, [isSplitMode, showColumns, listData, selectedIds]);

  return (
    <>
      <ListContainer
        isLoading={isLoading || isFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={
            <ListToolbarContainer
              listTypeProps={{
                listTypeStr: listType,
                onChangeListType: (listType: ListType) => {
                  let newData = {
                    ...pageData,
                    listType: listType,
                  };
                  // setPageData(newData);
                  onChangeSetting && onChangeSetting(newData);
                },
                listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
              }}
              actionsProps={actionsProps}
              isSplitMode={isSplitMode}
              setIsSplitMode={setIsSplitMode}
              onRefresh={refetch}
              addOptions={PRODUCT_ADD_OPTIONS}
              onAdd={(category: string | undefined) =>
                setWriteOption((filter) => ({
                  ...filter,
                  isOpenWrite: true,
                  writeType: category === undefined ? MENU_PRODUCT_UNIT : category,
                }))
              }
              categoryOptions={categoryOptions}
              categorySelected={category}
              onCategoryChange={(newCategory: string) => {
                return navigate(`/${menu}/${newCategory}`);
              }}
              menu={menu}
            />
          }
          header={
            <ListHeader
              isSimple={isSplitMode}
              searchComponent={
                <ListSearch
                  isSimple={isSplitMode}
                  placeholder={'Enter name to search'}
                  // searchFields={searchFields.reduce((final: any[], field: any) => {
                  //   if (showColumns?.findIndex((v: any) => v.name == field.name) !== -1) {
                  //     final.push(field);
                  //   }
                  //   return final;
                  // }, [])}
                  searchValue={filter?.keyword}
                  onSubmit={handleSearchSubmit}
                />
              }
              filterComponent={
                <ListHeaderFilters
                  category={MENU_PRODUCT_UNIT ?? ''}
                  isSplitMode={isSplitMode}
                  groupByOptions={groupByOptions}
                  filterByOptions={filterByOptions}
                  dateByOptions={dateByOptions}
                  filterData={filter?.headerFilters}
                  onFilterDataChanged={(valueData: any) => {
                    let newFilter: SearchFilter = {
                      ...filter,
                      headerFilters: valueData,
                    };
                    let newData = {
                      ...pageData,
                      filter: newFilter,
                    };
                    // setPageData(newData);
                    onChangeSetting && onChangeSetting(newData);
                  }}
                  sortData={sort}
                  onSortDataChanged={(s: any) => {
                    // console.log('...onSortDataChanged...', s);
                    setSort(s);
                  }}
                  sortColumns={getSortColumns()}
                />
              }
              columnSettingComponent={
                !isSplitMode &&
                listType == ListType.LIST && (
                  <ListTableColumnSetting
                    columns={
                      settingColumns && settingColumns[layoutMenu] ? settingColumns[layoutMenu] : []
                    }
                    hideColumns={hideColumns}
                    columLimit={10}
                    onChange={onChangeColumnSetting}
                  />
                )
              }
              onSelectedAll={() => onCheckedRow('all')}
              onDeselectedAll={() => onCheckedRow('notAll')}
              selectedIds={selectedIds}
              onRefresh={refetch}
              actionsProps={actionsProps}
            />
          }
          body={BodyMemo}
        />

        {/* Tablet layout here */}
        {/* {isTablet && null} */}

        {/* Mobile layout here */}
        {/* {isMobile && null} */}
      </ListContainer>

      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons || []}
        resetSelected={() => onCheckedRow('notAll')}
        visible={!!selectedIds.length}
      />

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_UNIT && (
        <WritePage
          title={<SpanLang keyLang={'Create Unit'} />}
          size="md"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          onReload={refetch}
          menuApi={MENU_PRODUCT_UNIT}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
        <WritePageProduct
          title={<SpanLang keyLang={'Create Product'} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={refetch}
          menuApi={MENU_PRODUCT_PRODUCT}
        />
      )}

      {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
        <WritePageItem
          title={<SpanLang keyLang={'Create Item'} />}
          size="lg"
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          isOpen={writeOption.isOpenWrite}
          onClose={() =>
            setWriteOption((filter) => ({
              ...filter,
              isOpenWrite: false,
            }))
          }
          // onReload={refetch}
          menuApi={MENU_PRODUCT_ITEM}
        />
      )}
    </>
  );
};

export default ListPage;
