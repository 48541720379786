import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, X, Edit2, Check, ChevronDown, Trash2, Save } from 'react-feather';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
import useMutationPost from '@base/hooks/useMutationPost';
import { UPDATE_PRODUCT_UNIT_VALUES } from '@product/unit/services/graphql';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { Button } from '@base/components/form';
import Icon from '@base/assets/icons/svg-icons';
import styled from '@emotion/styled';
import { useTable } from 'react-table';
import { Empty } from '@base/components';
import { generateUUID } from '@base/utils/helpers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { MENU_PRODUCT_UNIT } from '@base/config/menus';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { makeReactTableColumns } from '@base/components/utils/helpers/react-table';
import { useQueryClient } from '@tanstack/react-query';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { ProductTextView } from '@base/containers/quick-view';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

let oldData: any[] = [];

const Styles = styled.div`
  table {
    table-layout: fixed;
  }
`;

const Tr = styled.tr((props: any) => ({
  display: props?.isDragging ? 'table' : '',
}));

const UnitDetail: React.FC<any> = (props: any) => {
  const { t } = useTranslation();

  const data = useRecoilValue(viewDataByMenuAtom(MENU_PRODUCT_UNIT));

  const { showEdit = false } = props;

  const [rows, setRows] = useState<any[]>([]);
  const [unitName, setUnitName] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.data) {
      const { id, name, unitValues } = data?.data ?? {};
      setRows(unitValues);
      oldData = unitValues;
    }
  }, [data]);

  const onAddUnitName = () => {
    setRows([
      ...rows,
      {
        name: unitName,
        qty: '',
      },
    ]);
    setUnitName('');
  };

  const onDelete = (rowIndex: number) => {
    setRows((old) => old?.filter((row, index) => index != rowIndex));
  };

  const updateData = (rowIndex: number, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setRows((old) =>
      old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const mUpdateUnitValues: any = useMutationPost<BaseMutationResponse>(
    UPDATE_PRODUCT_UNIT_VALUES,
    'product_updateUnitValues',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        setIsSaving(false);
        setIsEdit(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_unit']);
        }, 500);
      },
      onError: (error: any, variables: any, context: any) => {
        setIsSaving(false);
      },
    },
  );

  const handleOnSave = () => {
    const params = {
      unitId: data?.data?.id ?? '',
      unitValues: rows?.map((v: any) => ({ id: v.id, name: v.name, qty: v.qty })),
    };
    mUpdateUnitValues.mutate(params);
  };

  // drag start
  const handleDragStart = useCallback((event: any) => {
    //event = item dragging
  }, []);

  // drag end, update new position
  const handleDragEnd = useCallback(
    (result: any) => {
      const { source, destination } = result;
      if (!destination || destination?.index === 0) {
        return;
      }
      // re-order the items
      const newItems = [...rows];
      const [removedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removedItem);
      setRows(newItems);
    },
    [rows, setRows],
  );

  // react-table-v8
  const InputCellv8 = ({ getValue, row: { index }, column: { id }, table, isEdit }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <>
        {isEdit ? (
          <div className="d-flex flex-1 align-items-center">
            <button
              type="button"
              className="btn btn-xs mg-r-5 pd-0 tx-color-03"
              style={{ cursor: 'grab' }}
            >
              {Icon('move')}
            </button>{' '}
            <input
              className="form-control"
              defaultValue={value as string}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
              disabled={index === 0}
            />
          </div>
        ) : (
          value
        )}
      </>
    );
  };

  const NumberCellv8 = ({ getValue, row: { index }, column: { id }, table, isEdit }: any) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = (e: any) => {
      table.options.meta?.updateData(index, id, parseInt(value));
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <>
        {isEdit ? (
          <input
            type={'number'}
            className="form-control"
            defaultValue={value as number}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            min={1}
          />
        ) : (
          value
        )}
      </>
    );
  };

  const DeleteCellv8 = ({ row: { index }, onDelete = () => {} }: any) => {
    if (index === 0) {
      return null;
    }
    return (
      <button
        onClick={() => onDelete(index)}
        type="button"
        className="btn btn-link btn-icon pd-y-0"
      >
        <Trash2 className="tx-danger" />
      </button>
    );
  };

  const columnsTable = useMemo<ColumnDef<any>[]>(() => {
    let _columns: ColumnDef<any>[] = [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => <span>Unit Name</span>,
        cell: (cell: any) => {
          return <InputCellv8 {...cell} isEdit={isEdit} />;
        },
      },
      {
        id: 'qty',
        accessorKey: 'qty',
        header: () => <span>Unit Qty</span>,
        cell: (cell: any) => {
          return <NumberCellv8 {...cell} isEdit={isEdit} />;
        },
      },
      {
        id: 'relatedProducts',
        accessorKey: 'relatedProducts',
        header: () => <span>Related Products</span>,
        cell: (cell: any) => {
          const items = cell?.getValue() ?? [];
          return items.length > 0 ? (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              showAvatar={false}
              values={items}
              cellComponent={(item: any) => (
                <ProductTextView value={{ id: item?.id, name: item?.name }} />
              )}
            />
          ) : (
            ''
          );
        },
      },
    ];

    if (isEdit) {
      _columns.push({
        id: 'delete',
        header: () => <span></span>,
        cell: (cell: any) => {
          return <DeleteCellv8 {...cell} onDelete={onDelete} />;
        },
        size: 70,
      });
    }

    return _columns;
  }, [rows, isEdit]);

  const table = useReactTable({
    data: rows,
    columns: makeReactTableColumns(columnsTable),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        updateData(rowIndex, columnId, value);
      },
    },
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  return (
    <div className="pd-15">
      {showEdit && (
        <div className="mg-b-10 text-right">
          {!isEdit && (
            <button type="button" className="btn btn-link" onClick={() => setIsEdit(true)}>
              <Edit2 className="mg-r-5" />
              Edit
            </button>
          )}
          {isEdit && (
            <button
              type="button"
              className="btn btn-light mg-r-10"
              onClick={() => {
                setIsEdit(false);
                setRows(oldData);
              }}
            >
              <X className="ma-r-5" /> Cancel
            </button>
          )}
          {isEdit && (
            <Button
              loading={isSaving}
              type="button"
              className="btn btn-success"
              onClick={() => handleOnSave()}
            >
              <Save className="ma-r-5" /> Save
            </Button>
          )}
        </div>
      )}
      <div className="card">
        {/* react-table-v8 */}
        <Styles
          className="table-responsive scroll-box"
          style={{ maxHeight: isEdit ? 'calc(100vh - 280px)' : 'calc(100vh - 230px)' }}
        >
          <table className="table table-bordered mg-b-0 bd-0 product-detail-tb">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th
                        key={header.id}
                        style={{
                          width: header?.column?.columnDef?.width ?? header?.column?.getSize(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Droppable droppableId={generateUUID()}>
                {(provided, snapshot) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {table.getRowModel().rows.map((row: any, rIdx: number) => (
                      <Draggable
                        key={rIdx}
                        draggableId={`tr-draggableId-${rIdx}`}
                        index={rIdx}
                        isDragDisabled={rIdx === 0 || !isEdit}
                      >
                        {(provided, snapshot) => (
                          <Tr
                            key={row.id}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                          >
                            {row.getVisibleCells().map((cell: any, ceIdx: number) => (
                              <td key={ceIdx}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </Tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </Styles>

        {isEdit && (
          <div className="d-flex align-items-center pd-10 bd-b bd-t">
            <input
              type="text"
              className="form-control mg-r-5"
              placeholder="Unit Name"
              value={unitName}
              onInput={(e: any) => setUnitName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e !== null && e.keyCode === 13 && e?.target?.value != '') {
                  onAddUnitName();
                }
              }}
            />
            <button
              type="button"
              className="btn btn-primary flex-shrink-0"
              onClick={() => onAddUnitName()}
            >
              <Plus className="mg-r-5" />
              Add
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UnitDetail;
