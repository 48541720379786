import React, { useMemo, useState } from 'react';
import withMiModal from '@base/hooks/hocs/withMiModal';
import classNames from 'classnames';
import { ArrowLeft, ChevronUp } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { ADD_NEW_PRODUCT } from '@product/product/services/graphql';
import { toast } from 'react-toastify';
import { Button } from '@base/components/form';

import WriteProductForm from '@product/product/containers/write-form';
import WriteItemForm from '@product/item/containers/write-form';
import Loading from '@base/components/loading';
import { KEY_PRODUCT_TYPE } from '@product/product/config/key-names';

const STEPS = [
  {
    value: 1,
    label: 'Product',
  },
  {
    value: 2,
    label: 'Items',
  },
];

const WriteProductModal: React.FC<any> = (props) => {
  const { onClose } = props;

  // state
  const [formStep, setFormStep] = useState(1);
  const [itemStep, setItemStep] = useState(1);
  const [product, setProduct] = useState<any>(null);
  const [isProductValid, setIsProductValid] = useState(true);
  const [item, setItem] = useState<any>(null);
  const [isItemValid, setIsItemValid] = useState(true);
  const [submitProduct, setSubmitProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_NEW_PRODUCT,
    'product_createProduct',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  // save product + item
  const handleSave = (isOnlyProduct: boolean) => {
    if (isOnlyProduct) {
      // product params
      // console.log('[PRODUCT]...', product);
      setSubmitProduct(true);
    } else {
      // product + item params
      // console.log('[ITEM]...', item);
      setSubmitProduct(true);
    }
  };

  // render steps
  const FormSteps = useMemo(() => {
    return (
      <ul className="steps steps-justified steps-tab">
        {STEPS.map((_item: any, _index: number) => (
          <li
            key={_index}
            className={classNames('step-item', {
              complete: _item.value < formStep,
              active: _item.value === formStep,
              disabed: _item.value > formStep,
            })}
          >
            <button
              type="button"
              className="btn step-link rounded-0"
              //onClick={(e) => setFormStep(_item.value)}
            >
              <span className="step-number">{_item.value}</span>
              <span className="step-title">{_item.label}</span>
            </button>
          </li>
        ))}
      </ul>
    );
  }, [formStep]);

  // render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        {/* Previous */}
        {formStep > 1 && (
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              itemStep > 1 ? setItemStep((cur) => cur - 1) : setFormStep((cur) => cur - 1);
            }}
          >
            <ArrowLeft />
            Previous
          </button>
        )}

        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={onClose}>
            Close
          </button>

          {formStep == 1 && (
            <div className="btn-group dropup">
              <Button
                color="primary"
                onClick={() => {
                  setFormStep((cur) => cur + 1);
                }}
                name="Next"
                disabled={!isProductValid}
                loading={mutationAdd.isLoading || isLoading}
              />
              <button
                type="button"
                className="btn btn-primary dropdown-toggle-split"
                data-toggle="dropdown"
              >
                <ChevronUp />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Button
                  className="dropdown-item"
                  color="primary"
                  onClick={() => handleSave(true)}
                  disabled={mutationAdd.isLoading || !isProductValid || isLoading}
                  loading={mutationAdd.isLoading || isLoading}
                  name="Save and Create Items later"
                />
              </div>
            </div>
          )}

          {/* Item Next */}
          {formStep == 2 && itemStep == 1 && (
            <Button
              color="primary"
              onClick={() => {
                setItemStep((cur) => cur + 1);
              }}
              name="Next"
              disabled={!isItemValid}
              loading={mutationAdd.isLoading || isLoading}
            />
          )}

          {/* Save */}
          {formStep == 2 && itemStep == 2 && (
            <Button
              color="success"
              onClick={() => handleSave(false)}
              disabled={mutationAdd.isLoading || !isProductValid || !isItemValid || isLoading}
              loading={mutationAdd.isLoading || isLoading}
              name="Save"
            />
          )}
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('[product with item] form', product);
  // console.log('[items in product] form', item);
  //// console.log('[product with item] form valid', isProductValid);

  //======================== end Debug ========================//

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-8">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>You cannot except a required field</span>
          </span>
        </div>
      </div>
      {/* Steps */}
      {FormSteps}

      {formStep >= 1 && (
        <WriteProductForm
          className={formStep !== 1 ? 'd-none' : ''}
          withItem={true}
          // value={product}
          onValid={(isValid: boolean) => setIsProductValid(isValid)}
          onChange={(newProduct: any) =>
            setProduct({ ...newProduct, [KEY_PRODUCT_TYPE]: newProduct?.[KEY_PRODUCT_TYPE]?.value })
          }
          submitProduct={submitProduct}
          setIsSubmit={setIsLoading}
          itemParams={item ?? null}
          {...props}
        />
      )}
      {formStep == 2 && (
        <React.Suspense fallback={<Loading />}>
          <WriteItemForm
            withinProduct={true}
            product={product}
            onValid={(isValid: boolean) => setIsItemValid(isValid)}
            onChange={(newItem: any) => {
              setItem(newItem);
            }}
            itemStep={itemStep}
            {...props}
          />
        </React.Suspense>
      )}

      {renderFooter()}
    </div>
  );
};

export default withMiModal(WriteProductModal);
