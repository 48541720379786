import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@base/components/form';
import { OPTION_ITEM_CATEGORIES } from '@product/main/config/constants';

/**
 *
 * @param {*} props
 * @returns
 */
const ItemCategory: React.FC<any> = (props: any) => {
  const { onChange = null, value = null, disabled = false } = props;
  //state
  const [selectedValue, setSelectedValue] = useState(OPTION_ITEM_CATEGORIES[0].value);

  //init data
  useEffect(() => {
    if (value) {
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(OPTION_ITEM_CATEGORIES[0].value);
    }
  }, [value]);

  //change
  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
    //callback
    onChange && onChange(newOption.value);
  };

  //render
  return (
    <RadioGroup
      isVertical={false}
      disabled={disabled}
      options={OPTION_ITEM_CATEGORIES}
      value={OPTION_ITEM_CATEGORIES.find((_x) => _x.value === selectedValue)}
      onChange={handleValueChange}
    />
  );
};

export default ItemCategory;
