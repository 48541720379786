import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { countriesAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';

const View: React.FC = (props: any) => {
  const { value = '' } = props;

  const availableCountries: any = useRecoilValue(countriesAtom);

  const _country = availableCountries?.find((v: any) => {
    return v?.value == value;
  });

  return <TextView value={_country?.country ?? value} />;
};

export default View;
