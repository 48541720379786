import { useState, Suspense, useEffect, useMemo, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import { useLanguageByMenu } from '@base/services/i18n';

import ListPage from '@product/item/pages/list';
import ViewPage from '@product/item/pages/view';
import { MENU_PRODUCT, MENU_PRODUCT_ITEM } from '@base/config/menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_USER_SETTING } from '@base/services/graphql/setting';
import { useUserSettings } from '@base/services';
import Loading from '@base/components/loading';
import { ListType } from '@base/types/enums';
import _ from 'lodash';
import { userItemSetting } from '../recoil/atom';

const MainPage = () => {
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);

  // local state
  const [isSplitMode, setIsSplitMode] = useState<boolean>(false);
  const firstLoadRef = useRef(false);

  // load menu language
  useLanguageByMenu([MENU_PRODUCT]);

  // get/set user setting from recoil
  const [pageData, setPageData] = useRecoilState(userItemSetting);
  const { listType, settingColumns } = pageData;

  // get user settings
  const { data, isLoading } = useUserSettings(MENU_PRODUCT_ITEM, undefined, {
    enabled: settingColumns?.length == 0,
  });

  const mChangeUserSetting: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  // get setting set to recoil
  useEffect(() => {
    if (data && !isLoading) {
      if (data?.results?.length > 0) {
        data?.results?.map((setting: any) => {
          if (setting.key === 'list') {
            const listSetting: any = JSON.parse(setting?.value);
            setPageData(listSetting);
          }
        });
      }
      firstLoadRef.current = true;
    }
    () => {
      firstLoadRef.current = false;
    };
  }, [data, isLoading]);

  useEffect(() => {
    setIsSplitMode(listType === ListType.SPLIT);
  }, [listType]);

  const onChangeSetting = (newSetting: any) => {
    if (!_.isEqual(newSetting, pageData)) {
      setPageData(newSetting);

      // save setting to db
      const param = {
        userSetting: {
          menu: MENU_PRODUCT_ITEM,
          key: 'list',
          value: JSON.stringify(newSetting),
        },
      };
      mChangeUserSetting.mutate(param);
    }
  };

  const splitViewProps = {
    isSplitMode: isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode,
    setIsSplitMode,
    onChangeSetting: onChangeSetting,
  };

  const Page = useMemo(() => {
    console.log('...ITEM.pageData...', pageData);
    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <Routes>
            <Route
              path=""
              element={
                isSplitMode && isDesktop ? (
                  <SplitView
                    {...splitViewProps}
                    leftPane={<ListPage {...listProps} />}
                    rightPane={<EmptySplitViewFC />}
                  />
                ) : (
                  <ListPage {...listProps} />
                )
              }
            />
            <Route
              path=":id/*"
              element={
                isSplitMode && isDesktop ? (
                  <SplitView
                    {...splitViewProps}
                    leftPane={<ListPage {...listProps} />}
                    rightPane={
                      <div className="pane-content" style={{ flex: 1 }}>
                        <Suspense fallback={<div className="han-loading" />}>
                          <ViewPage isSplitMode={isSplitMode} />
                        </Suspense>
                      </div>
                    }
                  />
                ) : (
                  <ViewPage />
                )
              }
            />
          </Routes>
        )}
      </>
    );
  }, [isSplitMode, listType, isLoading]);

  return Page;
};

export default MainPage;
