import ModalContainer from '@base/containers/modal-container';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_PRODUCT_BATCH } from '@product/product/services/graphql';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ProductGroup from '../product-group';

interface IProps {
  isOpen: boolean;
  ids: string[];
  onClose: () => void;
  onSuccess?: any;
}

const ProductUpdateGroup: React.FC<IProps> = (props: IProps) => {
  const { isOpen, onClose, onSuccess, ids } = props;

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<any>();

  const mutationUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_PRODUCT_BATCH,
    'product_updateProductBatch',
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Update group successfully!');
        setLoading(false);
        onSuccess && onSuccess();
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Update group failed: ' + JSON.parse(error).message);
        setLoading(false);
      },
    },
  );

  return (
    <>
      {isOpen && (
        <ModalContainer
          isOpen={isOpen}
          onNo={onClose}
          onYes={() => {
            if (newGroup && newGroup?.id != '') {
              let products: any = [];
              ids?.map((id: string) => {
                products.push({
                  id: id,
                  group: {
                    id: newGroup?.id,
                    name: newGroup?.name,
                  },
                });
              });
              mutationUpdate.mutate({ products: products });
            } else {
              toast.error('undefined group');
            }
          }}
          saveLabel={'Update'}
          title={'Update Product Group'}
          isLoading={loading}
        >
          <div className="pd-20">
            <ProductGroup
              onChange={(group: any) => {
                setNewGroup(group);
              }}
            />
          </div>
        </ModalContainer>
      )}
    </>
  );
};

export default ProductUpdateGroup;
