import {
  PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS,
  PRODUCT_ITEM_TYPE_OPTIONS,
} from '@product/main/config/constants';
import { lazy } from 'react';

const Select = lazy(() => import('@base/components/form/select'));
const Switch = lazy(() => import('@base/components/form/switch'));
const ProductAutoComplete = lazy(() => import('@product/product/containers/product-auto-complete'));
const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
const CountryOrigin = lazy(() => import('@product/item/containers/country-origin'));
const UnitValueAutoComplete = lazy(
  () => import('@product/unit/containers/unit-name-auto-complete'),
);
const AttrValueAutoComplete = lazy(
  () => import('@product/product/containers/attribute-value-auto-complete'),
);

export const groupByOptions = [
  { label: 'All Items', value: 'all' },
  { label: 'My Items', value: 'myItem' },
  // { label: 'My Group Items', value: 'myGroupItem' },
  // { label: 'Deleted Items', value: 'deletedItem' },
  // { label: 'Items per Product', value: 'itemPerProduct' },
  { label: 'Inventory Items', value: 'inventoryItem' },
  { label: 'Non Inventory Items', value: 'nonInventoryItem' },
  { label: 'General Items', value: 'generalItem' },
  { label: 'Composite Items', value: 'compositeItem' },
  // { label: 'Prepaid Items', value: 'prepaidItem' },
];

export const dateByOptions = [
  { label: 'Created', value: 'createdAt' },
  { label: 'Updated', value: 'updatedAt' },
  { label: 'Manufacture Date', value: 'manufacturerDate' },
  { label: 'Experation Date', value: 'expiredDate' },
  // { label: 'Purchased', value: 'purchased' },
  // { label: 'Sold', value: 'sold' },
  // { label: 'Shipped', value: 'shipped' },
  // { label: 'Received', value: 'received' },
];

export const filterByOptions = [
  {
    label: 'Item Type',
    value: 'itemType',
    component: Select,
    componentProps: {
      options: PRODUCT_ITEM_TYPE_OPTIONS,
      isSearchable: false,
    },
    getValue: (componentValue: any): number => {
      return PRODUCT_ITEM_TYPE_OPTIONS.findIndex((v: any) => v.value == componentValue?.value) + 1;
    },
    setValue: (value: number) => {
      return PRODUCT_ITEM_TYPE_OPTIONS[value + 1];
    },
  },
  {
    label: 'Inventory Type',
    value: 'inventoryType',
    component: Select,
    componentProps: {
      options: PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS,
      isSearchable: false,
    },
    getValue: (componentValue: any): number => {
      return (
        PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS.findIndex(
          (v: any) => v.value == componentValue?.value,
        ) + 1
      );
    },
    setValue: (value: number) => {
      return PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS[value + 1];
    },
  },
  // { label: 'Supply Method', value: 'type' },
  {
    label: 'Active',
    value: 'active',
    component: Switch,
    // componentProps: { defaultChecked: true },
    getValue: (componentValue: any): boolean => {
      return componentValue?.target?.checked;
    },
  },
  {
    label: 'Unit Name',
    value: 'unitValId',
    component: UnitValueAutoComplete,
    getValue: (componentValue: any) => {
      return componentValue?.map((v: any) => v?.value).join(',');
    },
  },
  {
    label: 'Attributes',
    value: 'attrValues',
    component: AttrValueAutoComplete,
    getValue: (componentValue: any) => {
      return componentValue?.map((v: any) => v?.value).join(',');
    },
  },
  // { label: 'Rate', value: 'rate' },
  {
    label: 'Product',
    value: 'prodId',
    component: ProductAutoComplete,
    getValue: (componentValue: any) => {
      return componentValue?.length > 0
        ? componentValue?.findIndex((v: any) => v?.value == 'all') >= 0
          ? ''
          : componentValue?.map((v: any) => v?.value).join(',')
        : '';
    },
  },
  {
    label: 'Assigned Rep',
    value: 'assignTo',
    component: UserAutoComplete,
    getValue: (componentValue: any) => {
      return componentValue?.length > 0 ? componentValue?.map((v: any) => v?.id).join(',') : '';
    },
  },
  {
    label: 'Owner',
    value: 'createdBy',
    component: UserAutoComplete,
    componentProps: { single: true },
    getValue: (componentValue: any) => {
      return componentValue?.id ?? '';
    },
  },
  // { label: 'Warranty Period', value: 'warrantyPeriod' },
  {
    label: 'Country of Origin',
    value: 'countryOrigin',
    component: CountryOrigin,
    getValue: (componentValue: any) => {
      return componentValue?.value ?? '';
    },
  },
  // { label: 'Returnable', value: 'returnable' },
  // { label: 'Subscription', value: 'subScription' },
];

export const searchFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    defaultValue: '',
  },
];

export const sortsBy = [
  { label: 'Name', value: 'name' },
  { label: 'SKU', value: 'sku' },
  { label: 'Product Name', value: 'prod' },
  { label: 'Item Id', value: 'code' },
  { label: 'Item Type', value: 'itemType' },
  { label: 'Inventory Type', value: 'inventoryType' },
  { label: 'Unit Name', value: 'unitVal' },
  { label: 'Opening Stock', value: 'openStock' },
  { label: 'Stock on Hand', value: 'stockOnHand' },
  { label: 'Replenishment Point', value: 'replenishmentPoint' },
  { label: 'Country of Origin', value: 'countryOrigin' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'Updated At', value: 'updatedAt' },
];
