import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { components } from 'react-select';
import { FormIcon } from '@base/components/form/icon';
import { moneyFormat } from '@base/utils/helpers/general.utils';
import { useItemsAutoComplete } from '@product/item/services/item-service';
import _ from 'lodash';
import { PRODUCT_ITEM_TYPE } from '@product/main/config/constants';

interface IItemAutoCompleteProps {
  className?: string;
  placeholder?: string;
  single?: boolean;
  visible?: boolean;
  currency?: string; // for display price below name
  currencySymbol?: string; // for display price below name
  type?: string; // all, general, bundle, prepaid
  showAllOption?: boolean;
  value?: any | any[]; // [], initial value
  onChange?: any;
  id?: string;
  name?: string;
  code?: string;
  fromMenu?: string;
  warehouse?: any;
  warehouseIds?: any[];
  exceptIds?: any;
  vendors?: any;
  onCache?: any;
  cacheOptions?: any;
}

const ItemAutoComplete: React.FC<IItemAutoCompleteProps> = (props: IItemAutoCompleteProps) => {
  const {
    className,
    placeholder,
    single = false,
    visible = true,
    currency = '',
    currencySymbol = '',
    type = 'all',
    showAllOption = true,
    value = null,
    onChange,
  } = props;

  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
            setSelectedValue(value);
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
          setSelectedValue(value);
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  //build params
  const getSearchParams = () => {
    let query = '';
    if (searchText != '') {
      query = `name:"${searchText}"`;
    }

    let params: any = {
      filter: {
        query: query,
        sort: {
          field: 'createdAt',
          orderBy: 2,
        },
        paging: {
          page: 1,
          size: 999,
        },
      },
    };

    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let newOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.name,
        value: _item.id,
      };
    });
    //TODO: filter by execptIds
    if (showAllOption) {
      newOptions?.unshift({
        label: 'All',
        value: 'all',
      });
    }
    return newOptions;
  };

  const { data: results, status: searchStatus } = useItemsAutoComplete(getSearchParams());
  let newOptions = formatSelectOptions(results);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (type === 'a' && !single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
        setSelectedValue(newItem);
      } else {
        setSelectedValue(newItem);
      }
    }
    //callback
    onChange && onChange(newItem);
  };

  //select custom option
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    const idx = data?.unitPrice?.findIndex((ele: any) => ele.currency === currency);
    let rate = '';
    if (idx > -1) {
      rate = data?.unitPrice[idx].value;
    }
    return (
      <components.Option {...props}>
        <>
          <div>{children}</div>
          {rate && (
            <div>
              Price: {currencySymbol}
              {moneyFormat(rate)}
            </div>
          )}
          {data.value === 'all' && <hr className="mg-y-0" />}
        </>
      </components.Option>
    );
  };

  //render
  return (
    <div className={classNames('pos-relative', className)} style={{ minWidth: 300 }}>
      <Select
        isMulti={!single}
        outSide
        menuPlacement="auto"
        noOptionsMessage={() => 'No items found.'}
        placeholder={
          <div className="react-select-placeholder">
            {placeholder || 'Type or click to select an item'}
          </div>
        }
        iconIndicator={
          <div className="icons-size-1">{<FormIcon icon={'product'} iconType="main" />}</div>
        }
        isClearable
        components={{ Option: CustomOption }}
        isLoading={searchStatus === 'loading'}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={newOptions}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default ItemAutoComplete;
