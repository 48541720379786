import React, { useState, useEffect, useCallback } from 'react';
import { Minus, Plus, Copy } from 'react-feather';
import _ from 'lodash';
import classNames from 'classnames';
import { Button, Input, Select, Switch } from '@base/components/form';
import CodeGenerator from '@base/containers/code-generator';
import { moneyFormat } from '@base/utils/helpers/general.utils';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';
// import ItemAddDetailCostPrice from '@product/item/containers/item-add-detail-cost-price';
import { WarrantyPeriod } from '@product/product/components';
import CountryOrigin from '@product/item/containers/country-origin';
import { DatePicker } from '@base/components/date-picker';
import {
  //KEY_ITEM_ID,
  KEY_ITEM_CODE,
  KEY_ITEM_NAME,
  KEY_ITEM_ACTIVE,
  KEY_ITEM_TEMPLATE,
  KEY_ITEM_BASE_PRICES,
  KEY_ITEM_BEST_PRICES,
  KEY_ITEM_COST_PRICES,
  KEY_ITEM_PURCHASE_PRICES,
  KEY_ITEM_WARRANTY,
  KEY_ITEM_DIMENSION,
  KEY_ITEM_WEIGHT,
  KEY_ITEM_ORIGIN_BARCODE,
  KEY_ITEM_VENDORS,
  KEY_ITEM_MANUFACTURERS,
  KEY_ITEM_DES,
  KEY_ITEM_EXPIRED_DATE,
  KEY_ITEM_MANUFACTURE_DATE,
  KEY_ITEM_RETURNABLE,
  KEY_ITEM_COUNTRY_ORIGIN,
  KEY_ITEM_UNIT_VAL_QTY,
  KEY_ITEM_UNIT_VAL_NAME,
  KEY_ITEM_INVENTORY_SKU,
  KEY_ITEM_INVENTORY_OPEN_STOCK,
  KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
  KEY_ITEM_INVENTORY_REORDER_POINT,
  KEY_ITEM_OPEN_STOCK,
} from '@product/item/config/key-names';
import {
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import SKUInput from '@product/item/containers/sku-input';
import { useRecoilValue } from 'recoil';
import { currenciesAtom } from '@base/recoil/atoms';
import { defaultCurrencySelector } from '@base/recoil/selectors';

/**
 *
 * @param {*} props
 * @returns
 */
const RowItem: React.FC<any> = (props: any) => {
  const {
    isCopyAll = true,
    rowIndex = 0,
    subTemplateOptions,
    productType = PRODUCT_TYPE_PRODUCED,
    canBeSold = false,
    category,
    type,
    prepaidType,
    usePrepaid,
    useSubscribe = false,
    value = null, //value
    onChange,
    onCopy,
    onRemove,
    defaultSkuSetting,
  } = props;

  const currencies: any = useRecoilValue(currenciesAtom);
  const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);

  //state
  // const [itemValue, setItemValue] = useState(value);
  const itemValue = value;

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showAddDetailCost, setShowAddDetailCost] = useState(false);
  const [editPriceIndex, setEditPriceIndex] = useState(0); //index
  const [currencyOptions, setCurrencyOptions] = useState([]);

  //memorize values
  const [itemName, setItemName] = useState('');
  const [itemCode, setItemCode] = useState(itemValue?.[KEY_ITEM_CODE] || '');
  const [itemPrices, setItemPrices] = useState(itemValue.prices || []);
  const [sku, setSku] = useState(value?.sku || []);
  const [itemMoreInfo, setItemMoreInfo] = useState<any>(null);
  // const [itemActive, setItemActive] = useState('y');
  // const [itemTemplate, setItemTemplate] = useState<any>(null);
  // const [openStock, setOpenStock] = useState(0);
  // const [replenishmentPoint, setReplenishmentPoint] = useState(0);

  //create default price for Both
  let defaultPrice: any = {
    purchase_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
    base_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
    cost_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
      cost_fields: [],
    },
    best_price: {
      value: 0,
      currency: { ...defaultCurrency, label: defaultCurrency.code, value: defaultCurrency.code },
    },
  };

  //init data
  useEffect(() => {
    //init currency
    if (currencies && currencies.length) {
      initOptions(currencies);
    } else {
      //loadCurrency(); //load in another place because load many times when render
    }
  }, []);

  //item value change
  useEffect(() => {
    //// console.log('init value', value);
    if (value) {
      //if(JSON.stringify(value) !== JSON.stringify(itemValue)) {
      //setItemValue(value); //all item row info
      //}
      if (value[KEY_ITEM_NAME] !== itemName) {
        setItemName(value[KEY_ITEM_NAME]);
      }
      if (value[KEY_ITEM_CODE] !== itemCode) {
        setItemCode(value[KEY_ITEM_CODE]);
      }
      if (JSON.stringify(value.prices) !== JSON.stringify(itemPrices)) {
        setItemPrices(value.prices);
      }
      if (JSON.stringify(value.sku) !== JSON.stringify(sku)) {
        setSku(value.sku);
      }
    } else {
      //setItemValue(null);
    }
  }, [value]);

  //set options
  const initOptions = (currencyList: any) => {
    let newOptions: any = [];
    currencyList.map((item: any) => {
      const newItem = {
        label: item.code, //item.currency_name,
        value: item.code,
        ...item,
      };
      newOptions.push(newItem);
    });
    setCurrencyOptions(newOptions);
  };

  //set amount use for prepaid info
  const getPrepaidUseAmount = (prices: any) => {
    const newUseAmount: any = [];
    if (prices && prices.length > 0) {
      prices.map((_priEle: any) => {
        newUseAmount.push({
          value: 0,
          base_value: _priEle.base_price.value,
          currency: _priEle.base_price.currency,
        });
      });
    }
    return newUseAmount;
  };

  //item name
  const handleNameChange = (newValue: any) => {
    setItemName(newValue);
    //set to item and callback
    handleValueChangeDebounce(KEY_ITEM_NAME, newValue);
  };

  //item code
  const handleCodeChange = (newValue: any) => {
    // setItemCode(newValue);
    //set to item and callback
    handleValueChangeDebounce(KEY_ITEM_CODE, newValue);
  };

  //field value change
  const handleValueChange = async (keyName: string, newValue: any) => {
    const newItemValue = itemValue;
    newItemValue[keyName] = newValue;
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };

  //debounce function
  const handleValueChangeDebounce = useCallback(_.debounce(handleValueChange, 200), []);

  //add new price
  const handleAddPrice = () => {
    // const newItemPrices = [...itemPrices];
    // newItemPrices.push(_.cloneDeep(defaultPrice));
    // setItemPrices(newItemPrices);
    // set to item and callback
    handleAddItemPriceDebounce();
  };

  const handleAddItemPrice = async () => {
    const newItemValue = { ...itemValue };
    newItemValue.prices.push(_.cloneDeep(defaultPrice));
    // prepaid step ON
    if (category === PRODUCT_ITEM_CATEGORY_INVENTORY && usePrepaid) {
      newItemValue.prepaid.map(
        (_item: any) => (_item.uses[0].amount = getPrepaidUseAmount(newItemValue.prices)),
      );
    }
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  //debounce function
  const handleAddItemPriceDebounce = useCallback(_.debounce(handleAddItemPrice, 200), []);

  //local price remov
  const handleRemovePrice = (index: number) => {
    const newItemPrices = [...itemPrices];
    newItemPrices.splice(index, 1);
    // setItemPrices(newItemPrices);
    //set to item and callback
    handleRemoveItemPriceDebounce(index);
  };
  //remove price
  const handleRemoveItemPrice = async (index: number) => {
    const newItemValue = { ...itemValue };
    newItemValue.prices.splice(index, 1);
    // prepaid step ON
    if (category === PRODUCT_ITEM_CATEGORY_INVENTORY && usePrepaid) {
      newItemValue.prepaid.map(
        (_item: any) => (_item.uses[0].amount = getPrepaidUseAmount(newItemValue.prices)),
      );
    }
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  //debounce function
  const handleRemoveItemPriceDebounce = useCallback(_.debounce(handleRemoveItemPrice, 200), []);

  //local state price amount change
  const handlePriceAmountChange = (index: number, priceType: string, value: any) => {
    const newItemPrices = [...itemPrices];
    newItemPrices[index][priceType].value = value;
    // setItemPrices(newItemPrices);
    // set to item and callback
    handleItemPriceAmountChangeDebounce(index, priceType, value);
  };

  //item price amount change
  const handleItemPriceAmountChange = async (index: number, priceType: string, value: any) => {
    const newItemValue = itemValue;
    newItemValue.prices[index][priceType].value = value;
    // prepaid step ON
    if (category === PRODUCT_ITEM_CATEGORY_INVENTORY && usePrepaid) {
      newItemValue.prepaid.map(
        (_item: any) => (_item.uses[0].amount = getPrepaidUseAmount(newItemValue.prices)),
      );
    }
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  // debounce function
  const handleItemPriceAmountChangeDebounce = useCallback(
    _.debounce(handleItemPriceAmountChange, 200),
    [],
  );

  //local currency state change
  const handlePriceCurrencyChange = (index: number, priceType: string, value: any) => {
    const newItemPrices = [...itemPrices];
    if (productType === PRODUCT_TYPE_PURCHASE && !canBeSold) {
      newItemPrices[index].purchase_price.currency = value;
    } else {
      newItemPrices[index].base_price.currency = value;
      newItemPrices[index].cost_price.currency = value;
      newItemPrices[index].best_price.currency = value;
    }
    // setItemPrices(newItemPrices);
    // set to item and callback
    handleItemPriceCurrencyChangeDebounce(index, priceType, value);
  };
  //price currency change
  const handleItemPriceCurrencyChange = async (index: number, priceType: string, value: any) => {
    const newItemValue = { ...itemValue };
    if (productType === PRODUCT_TYPE_PURCHASE && !canBeSold) {
      newItemValue.prices[index].purchase_price.currency = value;
    } else {
      newItemValue.prices[index].base_price.currency = value;
      newItemValue.prices[index].cost_price.currency = value;
      newItemValue.prices[index].best_price.currency = value;
    }
    if (category === PRODUCT_ITEM_CATEGORY_INVENTORY && usePrepaid) {
      newItemValue.prepaid.map(
        (_item: any) => (_item.uses[0].amount = getPrepaidUseAmount(newItemValue.prices)),
      );
    }
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  // debounce function
  const handleItemPriceCurrencyChangeDebounce = useCallback(
    _.debounce(handleItemPriceCurrencyChange, 200),
    [],
  );

  //local base cost change
  const handleAddBaseDetailCost = (data: any) => {
    const newItemPrices = [...itemPrices];
    newItemPrices[editPriceIndex].cost_price.value = data.total;
    newItemPrices[editPriceIndex].cost_price.cost_fields = data.cost_fields;
    // setItemPrices(newItemPrices);
    // set to item and callback
    handleAddItemBaseDetailCostDebounce(data);
  };
  //add base detail cost
  const handleAddItemBaseDetailCost = async (data: any) => {
    const newItemValue = { ...itemValue };
    newItemValue.prices[editPriceIndex].cost_price.value = data.total;
    newItemValue.prices[editPriceIndex].cost_price.cost_fields = data.cost_fields;
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  // debounce function
  const handleAddItemBaseDetailCostDebounce = useCallback(
    _.debounce(handleAddItemBaseDetailCost, 500),
    [],
  );

  //local more info change
  const handleMoreValueChange = (fieldKey: string, value: any) => {
    const newItemMoreInfo = { ...itemMoreInfo };
    newItemMoreInfo[fieldKey] = value;
    // setItemMoreInfo(newItemMoreInfo);

    // set to item and callback
    handleItemMoreValueChangeDebounce(fieldKey, value);
  };

  //more info value change
  const handleItemMoreValueChange = async (fieldKey: string, value: any) => {
    itemValue.moreInfo[fieldKey] = value;
    const newItemValue = { ...itemValue };
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  // debounce function
  const handleItemMoreValueChangeDebounce = useCallback(
    _.debounce(handleItemMoreValueChange, 500),
    [],
  );

  //copy all prices
  const handleCopyAllPrice = (keyName: string) => {
    //callback
    onCopy && onCopy(rowIndex, keyName);
  };

  const handleCopyAllOpenStock = () => {
    onCopy && onCopy(rowIndex, KEY_ITEM_OPEN_STOCK);
  };

  const handleCopyAllReorderPoint = () => {
    onCopy && onCopy(rowIndex, KEY_ITEM_INVENTORY_REORDER_POINT);
  };

  const handleCopyAllReplenishmentPoint = () => {
    onCopy && onCopy(rowIndex, KEY_ITEM_INVENTORY_REPLENISHMENT_POINT);
  };

  //local state SKU change
  const handleGetSKU = (index: number, data: any) => {
    const newSKU = [...sku];
    let stringSku = data?.sku ?? '';
    if (newSKU[index] != stringSku && stringSku != '') {
      // newSKU[index] = stringSku;
      // setSku(newSKU);
      // set to item and callback
      handleItemSKUChangeDebounce(index, stringSku);
    }
  };
  //item's SKU list change
  const handleItemSKUChangeDebounce = async (index: number, value: any) => {
    const newItemValue = { ...itemValue };
    newItemValue.sku[index] = value;
    //callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };

  const handleOpenStockChange = (value: string) => {
    // setOpenStock(parseInt(value));
    handleValueChangeDebounce(KEY_ITEM_INVENTORY_OPEN_STOCK, parseInt(value));
  };

  const handleReplenishmentPointChange = (value: string) => {
    // setReplenishmentPoint(parseInt(value));
    handleValueChangeDebounce(KEY_ITEM_INVENTORY_REPLENISHMENT_POINT, parseInt(value));
  };

  const handleReorderPointChange = (value: string) => {
    handleValueChangeDebounce(KEY_ITEM_INVENTORY_REORDER_POINT, parseInt(value));
  };

  const handleAddSKU = () => {
    handleAddItemSKUDebounce();
  };

  const handleAddItemSKU = async () => {
    const newItemValue = { ...itemValue };
    newItemValue.sku.push('');
    //callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  //debounce function
  const handleAddItemSKUDebounce = useCallback(_.debounce(handleAddItemSKU, 200), []);

  //local SKU remove
  const handleRemoveSKU = (index: number) => {
    handleRemoveItemSKUDebounce(index);
  };

  //remove SKU
  const handleRemoveItemSKU = async (index: number) => {
    const newItemValue = { ...itemValue };
    newItemValue.sku.splice(index, 1);
    // callback
    (await onChange) && onChange(newItemValue, rowIndex);
  };
  //debounce function
  const handleRemoveItemSKUDebounce = useCallback(_.debounce(handleRemoveItemSKU, 200), []);

  // // console.log('Row-Item...sku...', rowIndex, sku);
  //render
  return (
    <>
      <tr>
        {/* Item Name */}
        <td style={{ height: '36px' }}>
          <input
            className="form-control"
            style={{ minWidth: '150px' }}
            value={itemName}
            onChange={(e: any) => handleNameChange(e.target.value)}
          />
        </td>
        {/* Item ID */}
        <td>
          <div className="input-group wd-200">
            {itemValue && (
              <CodeGenerator
                menu="item"
                value={itemValue?.code}
                onChange={(newCode: string) => handleCodeChange(newCode)}
              />
            )}
          </div>
        </td>
        {/* Base Price */}
        {canBeSold && (
          <td>
            <ul className="list-group list-group-flush">
              {itemPrices.map(
                (ele: any, index: number) =>
                  ele &&
                  ele.base_price && (
                    <li
                      key={index}
                      className={classNames(
                        'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                        { 'pd-t-0': index === 0 },
                      )}
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <div className="mg-r-5 w-100" style={{ minWidth: '100px' }}>
                            <Input
                              type="money"
                              value={moneyFormat(ele.base_price.value || 0)}
                              onChange={(value: string) =>
                                handlePriceAmountChange(index, 'base_price', value)
                              }
                            />
                          </div>
                          <Select
                            className="wd-100-f flex-shrink-0 mg-r-5"
                            outSide={true}
                            value={ele.base_price.currency}
                            options={currencyOptions}
                            onChange={(option: any) =>
                              handlePriceCurrencyChange(index, 'base_price', option)
                            }
                          />
                          {itemPrices.length > 1 && (
                            <Button
                              color="link"
                              className="tx-danger mg-l-5"
                              onClick={() => handleRemovePrice(index)}
                            >
                              <Minus className="mg-r-5" />
                            </Button>
                          )}
                        </div>
                      </div>
                    </li>
                  ),
              )}
            </ul>
            <div className="d-flex flex-grow-1 justify-content-between mg-t-0">
              {/* <Button color="link" className="tx-nowrap" onClick={() => handleAddPrice()}>
                <Plus className="mg-r-5" />
                Add base price
              </Button> */}
              {rowIndex == 0 && isCopyAll && (
                <button
                  type="button"
                  className="btn btn-link text-nowrap"
                  onClick={() => handleCopyAllPrice(KEY_ITEM_BASE_PRICES)}
                >
                  <Copy className="mg-r-5" />
                  Copy All
                </button>
              )}
            </div>
          </td>
        )}

        {/* Base Cost */}
        {(canBeSold || productType === PRODUCT_TYPE_PRODUCED) && (
          <td>
            <ul className="list-group list-group-flush">
              {itemPrices.map(
                (ele: any, index: number) =>
                  ele &&
                  ele.cost_price && (
                    <li
                      key={index}
                      className={classNames(
                        'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                        { 'pd-t-0': index === 0 },
                      )}
                    >
                      <div className="d-flex justify-content-around">
                        <div className="mg-r-5" style={{ minWidth: '100px' }}>
                          <Input
                            type="money"
                            value={moneyFormat(ele.cost_price.value)}
                            onChange={(value: string) =>
                              handlePriceAmountChange(index, 'cost_price', value)
                            }
                          />
                        </div>
                        <Select
                          className="wd-100-f flex-shrink-0 mg-r-5"
                          isDisabled={!(productType === PRODUCT_TYPE_PRODUCED && !canBeSold)}
                          value={ele.cost_price.currency}
                          onChange={(option: any) =>
                            handlePriceCurrencyChange(index, 'cost_price', option)
                          }
                          options={currencyOptions}
                        />
                        {/* show remove price when just have only Base Cost */}
                        {productType === PRODUCT_TYPE_PRODUCED &&
                          !canBeSold &&
                          itemPrices.length > 1 && (
                            <Button
                              color="link"
                              className="tx-danger mg-l-5"
                              onClick={() => handleRemovePrice(index)}
                            >
                              <Minus className="mg-r-5" />
                            </Button>
                          )}
                        {/* <button
                          type="button"
                          className="btn btn-white text-nowrap"
                          onClick={() => {
                            setShowAddDetailCost(true);
                            setEditPriceIndex(index);
                          }}
                        >
                          <Plus className="mg-r-5" /> Detail Cost
                        </button> */}
                      </div>
                    </li>
                  ),
              )}
            </ul>
            <div className="d-flex flex-grow-1 justify-content-between mg-t-0">
              {productType === PRODUCT_TYPE_PRODUCED && !canBeSold && (
                <Button color="link" className="tx-nowrap" onClick={() => handleAddPrice()}>
                  <Plus className="mg-r-5" />
                  Add base cost
                </Button>
              )}
              {rowIndex == 0 && isCopyAll && (
                <button
                  type="button"
                  className="btn btn-link text-nowrap"
                  onClick={() => handleCopyAllPrice(KEY_ITEM_COST_PRICES)}
                >
                  <Copy className="mg-r-5" />
                  Copy All
                </button>
              )}
            </div>
          </td>
        )}

        {/* Best Price */}
        {/* {canBeSold && (
          <td>
            <ul className="list-group list-group-flush">
              {itemPrices.map(
                (ele: any, index: number) =>
                  ele &&
                  ele.best_price && (
                    <li
                      key={index}
                      className={classNames(
                        'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                        { 'pd-t-0': index === 0 },
                      )}
                    >
                      <div className="d-flex">
                        <div className="mg-r-5" style={{ minWidth: '100px' }}>
                          <Input
                            type="money"
                            value={moneyFormat(ele.best_price.value)}
                            onChange={(value: string) =>
                              handlePriceAmountChange(index, 'best_price', value)
                            }
                          />
                        </div>
                        <Select
                          className="wd-100-f flex-shrink-0 mg-r-5"
                          isDisabled
                          value={ele.best_price.currency}
                          //onChange={option => handlePriceCurrencyChange(index, 'best_price', option)}
                          options={currencyOptions}
                        />
                      </div>
                    </li>
                  ),
              )}
            </ul>
            {rowIndex === 0 && isCopyAll && (
              <button
                type="button"
                className="btn btn-link text-nowrap"
                onClick={() => handleCopyAllPrice(KEY_ITEM_BEST_PRICES)}
              >
                <Copy className="mg-r-5" />
                Copy All
              </button>
            )}
          </td>
        )} */}

        {/* Base Purchase Price */}
        {!canBeSold && productType === PRODUCT_TYPE_PURCHASE && (
          <td>
            <ul className="list-group list-group-flush">
              {itemPrices.map(
                (ele: any, index: number) =>
                  ele &&
                  ele.purchase_price && (
                    <li
                      key={index}
                      className={classNames(
                        'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                        { 'pd-t-0': index === 0 },
                      )}
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <div className="mg-r-5 w-100" style={{ minWidth: '100px' }}>
                            <Input
                              type="money"
                              value={moneyFormat(ele.purchase_price.value || 0)}
                              onChange={(value: string) =>
                                handlePriceAmountChange(index, 'purchase_price', value)
                              }
                            />
                          </div>
                          <Select
                            className="wd-100-f flex-shrink-0 mg-r-5"
                            outSide={true}
                            value={ele.purchase_price.currency}
                            options={currencyOptions}
                            onChange={(option: any) =>
                              handlePriceCurrencyChange(index, 'purchase_price', option)
                            }
                          />
                          {itemPrices.length > 1 && (
                            <Button
                              color="link"
                              className="tx-danger mg-l-5"
                              onClick={() => handleRemovePrice(index)}
                            >
                              <Minus className="mg-r-5" />
                            </Button>
                          )}
                        </div>
                        <div className="d-flex flex-grow-1 justify-content-between mg-t-0">
                          {/* <Button color="link" className="tx-nowrap" onClick={() => handleAddPrice()}>
                            <Plus className="mg-r-5" />
                            Add purchase price
                          </Button> */}
                          {rowIndex == 0 && isCopyAll && (
                            <button
                              type="button"
                              className="btn btn-link text-nowrap"
                              onClick={() => handleCopyAllPrice(KEY_ITEM_PURCHASE_PRICES)}
                            >
                              <Copy className="mg-r-5" />
                              Copy All
                            </button>
                          )}
                        </div>
                      </div>
                    </li>
                  ),
              )}
            </ul>
          </td>
        )}

        {/* Opening Stock */}
        {category === PRODUCT_ITEM_CATEGORY_INVENTORY && (
          <td>
            <Input
              className="wd-100"
              type="decimal"
              digits={0}
              value={itemValue?.openStock ?? 0}
              onChange={(v: string) => handleOpenStockChange(v)}
            />
            {rowIndex === 0 && isCopyAll && (
              <button
                type="button"
                className="btn btn-link text-nowrap"
                onClick={() => handleCopyAllOpenStock()}
              >
                <Copy className="mg-r-5" />
                Copy All
              </button>
            )}
          </td>
        )}

        {/* Reorder Point */}
        {/* {canBeSold &&
          productType === PRODUCT_TYPE_PURCHASE &&
          category === PRODUCT_ITEM_CATEGORY_INVENTORY &&
          type === PRODUCT_ITEM_TYPE_GENERAL && (
            <td>
              <Input
                className="wd-100"
                type="decimal"
                digits={0}
                value={itemValue?.reorderPoint ?? 0}
                onChange={(v: string) => handleReorderPointChange(v)}
              />
              {rowIndex === 0 && isCopyAll && (
                <button
                  type="button"
                  className="btn btn-link text-nowrap pd-t-15"
                  onClick={() => handleCopyAllReorderPoint()}
                >
                  <Copy className="mg-r-5" />
                  Copy All
                </button>
              )}
            </td>
          )} */}

        {/* Replenishment Point */}
        {category === PRODUCT_ITEM_CATEGORY_INVENTORY && (
          <td>
            <Input
              className="wd-100"
              type="decimal"
              digits={0}
              value={itemValue?.replenishmentPoint ?? 0}
              onChange={(v: string) => handleReplenishmentPointChange(v)}
            />
            {rowIndex === 0 && isCopyAll && (
              <button
                type="button"
                className="btn btn-link text-nowrap"
                onClick={() => handleCopyAllReplenishmentPoint()}
              >
                <Copy className="mg-r-5" />
                Copy All
              </button>
            )}
          </td>
        )}

        {/* SKU */}
        {category === PRODUCT_ITEM_CATEGORY_INVENTORY && (
          <td>
            <ul className="list-group list-group-flush">
              {sku.map((valueSku: string, index: number) => (
                <li
                  key={index}
                  className={classNames(
                    'list-group-item d-flex align-items-center pd-l-0 pd-r-0 bg-white',
                    { 'pd-t-0': index === 0 },
                  )}
                >
                  <div className="d-flex flex-column">
                    <div className="form-group pd-b-0 mg-b-0 d-flex" style={{ minWidth: '250px' }}>
                      <SKUInput
                        data={{
                          ...itemValue,
                          itemName: itemValue?.name,
                          productName: itemValue?.product?.name,
                          attributes: itemValue?.option_values
                            ? Array.isArray(itemValue?.option_values)
                              ? itemValue?.option_values
                              : [itemValue?.option_values]
                            : [],
                        }}
                        defaultSkuSetting={defaultSkuSetting}
                        value={valueSku ?? ''}
                        values={sku ?? []}
                        onChange={(data: any) => handleGetSKU(index, data)}
                      />
                      {sku.length > 1 && (
                        <Button
                          color="link"
                          className="tx-danger"
                          onClick={() => handleRemoveSKU(index)}
                        >
                          <Minus className="mg-r-5" />
                        </Button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="d-flex flex-grow-1 justify-content-between">
              <Button color="link" className="tx-nowrap" onClick={() => handleAddSKU()}>
                <Plus className="mg-r-5" />
                Add SKU
              </Button>
            </div>
          </td>
        )}
        <td>
          <Button
            classSpanIcon="mg-r-5"
            color="icon"
            icon="Minus"
            className="tx-danger"
            onClick={onRemove}
          />
        </td>
      </tr>

      {showMoreInfo && (
        <tr>
          <td colSpan={11} className="more-info-cell">
            <div id="itemMoreInfo" className="product-item-more-info">
              <div className="form-row mg-0">
                {/* Dimensions */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Dimensions</label>
                    <div className="input-group">
                      <div className="form-control form-control-lg d-flex align-items-center">
                        <input
                          type="number"
                          className="wd-100p bd-0 pd-0 text-center"
                          autoFocus
                          value={itemMoreInfo[KEY_ITEM_DIMENSION]?.H}
                          onChange={(e) =>
                            handleMoreValueChange(KEY_ITEM_DIMENSION, {
                              ...itemMoreInfo[KEY_ITEM_DIMENSION],
                              H: e.target.value,
                            })
                          }
                        />
                        <span className="mg-x-5 tx-color-03">x</span>
                        <input
                          type="number"
                          className="wd-100p bd-0 pd-0 text-center"
                          value={itemMoreInfo[KEY_ITEM_DIMENSION]?.W}
                          onChange={(e) =>
                            handleMoreValueChange(KEY_ITEM_DIMENSION, {
                              ...itemMoreInfo[KEY_ITEM_DIMENSION],
                              W: e.target.value,
                            })
                          }
                        />
                        <span className="mg-x-5 tx-color-03">x</span>
                        <input
                          type="number"
                          className="wd-100p bd-0 pd-0 text-center"
                          value={itemMoreInfo[KEY_ITEM_DIMENSION]?.L}
                          onChange={(e) =>
                            handleMoreValueChange(KEY_ITEM_DIMENSION, {
                              ...itemMoreInfo[KEY_ITEM_DIMENSION],
                              L: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text pd-t-2">
                          {itemMoreInfo[KEY_ITEM_DIMENSION]?.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Weight */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Weight</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        value={itemMoreInfo[KEY_ITEM_WEIGHT]?.value}
                        onChange={(e) =>
                          handleMoreValueChange(KEY_ITEM_WEIGHT, {
                            ...itemMoreInfo[KEY_ITEM_WEIGHT],
                            value: e.target.value,
                          })
                        }
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {itemMoreInfo[KEY_ITEM_WEIGHT]?.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Original Barcode */}
                {/* <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Original Barcode</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={itemMoreInfo[KEY_ITEM_ORIGIN_BARCODE]}
                      onChange={(e) =>
                        handleMoreValueChange(KEY_ITEM_ORIGIN_BARCODE, e.target.value)
                      }
                    />
                  </div>
                </div> */}
                {/* Country of Origin */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Country of Origin</label>
                    <CountryOrigin
                      value={itemMoreInfo[KEY_ITEM_COUNTRY_ORIGIN]}
                      onChange={(data: any) => handleMoreValueChange(KEY_ITEM_COUNTRY_ORIGIN, data)}
                    />
                  </div>
                </div>
                {/* Vendor */}
                {productType === PRODUCT_TYPE_PURCHASE && (
                  <div className="form-col col-lg-6">
                    <div className="form-group">
                      <label className="form-item-title">Vendor</label>
                      <CustomerAutoComplete
                        single={true}
                        category="account"
                        type={'vendor, partner'}
                        value={itemMoreInfo[KEY_ITEM_VENDORS]}
                        onChange={(data: any) => handleMoreValueChange(KEY_ITEM_VENDORS, data)}
                      />
                    </div>
                  </div>
                )}
                {/* Manufacturer */}
                {productType === PRODUCT_TYPE_PRODUCED && (
                  <>
                    <div className="form-col col-lg-6">
                      <div className="form-group">
                        <label className="form-item-title">Manufacturer</label>
                        <CustomerAutoComplete
                          single={true}
                          category="account"
                          type={'vendor, partner'}
                          value={itemMoreInfo[KEY_ITEM_MANUFACTURERS]}
                          onChange={(data: any) =>
                            handleMoreValueChange(KEY_ITEM_MANUFACTURERS, data)
                          }
                        />
                      </div>
                    </div>
                    {/* Manufacture Date */}
                    <div className="form-col col-lg-6">
                      <div className="form-group">
                        <label className="form-item-title">Manufacture Date</label>
                        <DatePicker
                          selected={new Date()}
                          onChange={(date: any) =>
                            handleMoreValueChange(KEY_ITEM_MANUFACTURE_DATE, date)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* Expiration Date */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Expiration Date</label>
                    <DatePicker
                      selected={new Date()}
                      onChange={(date: any) => handleMoreValueChange(KEY_ITEM_EXPIRED_DATE, date)}
                    />
                  </div>
                </div>
                {/* Returnable */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Returnable</label>
                    <Switch
                      label={''}
                      //keyLang={''}
                      defaultChecked={itemMoreInfo[KEY_ITEM_RETURNABLE] || false}
                      onChange={(e: any) =>
                        handleMoreValueChange(KEY_ITEM_RETURNABLE, e?.target?.checked)
                      }
                    />
                  </div>
                </div>
                {/* Warranty Period */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Warranty Period</label>
                    <div className="d-flex align-items-center wd-300 mx-wd-100p">
                      <WarrantyPeriod
                        onChange={(data: any) => handleMoreValueChange(KEY_ITEM_WARRANTY, data)}
                      />
                    </div>
                  </div>
                </div>
                {/* Description */}
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <textarea
                      cols={10}
                      rows={3}
                      className="form-control"
                      value={itemMoreInfo[KEY_ITEM_DES]}
                      onChange={(e) => handleMoreValueChange(KEY_ITEM_DES, e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <Button
                classSpanIcon="mg-r-5"
                color="link"
                icon="X"
                className="mg-l-auto"
                onClick={() => setShowMoreInfo(false)}
              >
                Close more info
              </Button>
            </div>
          </td>
        </tr>
      )}

      {/* {showAddDetailCost && (
        <ItemAddDetailCostPrice
          isShow={showAddDetailCost}
          onClose={() => setShowAddDetailCost(false)}
          value={itemValue.prices[editPriceIndex].cost_price}
          onChange={(data: any) => handleAddBaseDetailCost(data)}
        />
      )} */}
    </>
  );
};

export default RowItem;
