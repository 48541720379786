import { Avatar } from '@base/components';
import React, { useEffect, useMemo, useState } from 'react';

const ImageView: React.FC<any> = (props: any) => {
  const { className = '', imgSrc } = props;

  return (
    <Avatar key={imgSrc} url={imgSrc} alt={''} height={'100%'} width={'100%'} isCircle={false} />
  );
};

export default ImageView;
