import usePosts from '@base/hooks/usePosts';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import { GET_UNIT_VALUES } from './graphql';

export const useUnitValuesAutoComplete = (params: any) => {
  const menu = 'product_unitValues';

  const usePostResult = usePosts<any[]>(
    [menu, keyStringify(params?.filter, '')],
    GET_UNIT_VALUES,
    params,
  );

  return usePostResult;
};
