import React, { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { ListContainer } from '@base/components/list';
import { deviceAtom } from '@base/recoil/atoms/app';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { MENU_PRODUCT_TREE } from '@base/config/menus';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import displayLayoutConfig from '@product/group/config/display-layout';

import PageToolbar from './toolbar';
import PageBody from './body';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<IListPage> = (props) => {
  const { isSplitMode, setIsSplitMode } = props;
  //router
  const params = useParams();
  let category = 'group';
  //state
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_PRODUCT_TREE)); //current list

  /*===================================== HOOK ===================================== */
  /** ============================ RENDER ================================ */
  //toolbar
  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        category={category}
        // refetch={refetch}
      />
    );
  }, [isSplitMode, category]);

  //body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={isSplitMode}
        category={category}
        isFetching={false}
        itemsList={listData}
        // paging={data?.paging}
        // isPreviousData={isPreviousData}
        // selectedIds={selectedIds}
        // onChecked={handleCheckItem}
      />
    );
  }, [isSplitMode, category, listData]);

  //main
  return (
    <>
      <ListContainer isLoading={false} isSplitMode={isSplitMode} isDesktop={isDesktop}>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          // header={PageHeaderMemo}
          body={PageBodyMemo}
        />
      </ListContainer>
    </>
  );
};

export default ListPage;
