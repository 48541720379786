import React from 'react';
import classnames from 'classnames';
import { ProductAttributes } from '@product/product/containers';

const Edit: React.FC = (props: any) => {
  const { value = [], onChange = () => {}, errors = {}, onSave = () => {} } = props;

  return (
    <div className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <ProductAttributes {...props} value={value} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
