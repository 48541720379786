import usePosts from '@base/hooks/usePosts';
import { GET_PRODUCT_ITEM_UNITS } from '@product/product/services/graphql';
import { keyStringify } from '@base/utils/helpers/list-page-utils';
import usePublicPost from '@base/hooks/usePublicPost';
import { GET_ITEM_VIEW } from './graphql';

export const useItemsAutoComplete = (params: any) => {
  const menu = 'product_items';

  const usePostResult = usePosts<any[]>(
    [menu, keyStringify(params?.filter, '')],
    GET_PRODUCT_ITEM_UNITS,
    params,
  );

  return usePostResult;
};

//get product items list
export const useProductItems = (filter: any) => {
  const menu = 'product_items';

  let params = {
    filter: filter,
  };

  const usePostResult = usePosts<any[]>(
    [menu, keyStringify(filter, '')],
    GET_PRODUCT_ITEM_UNITS,
    params,
  );

  return usePostResult;
};

//get items by product
export const useItemsByProduct = ({ productId, paging }: any) => {
  const menu = 'product_items';

  let params = {
    filter: {
      paging: paging || { page: 1, size: 10 },
      query: `prodId=\"${productId}\"`,
    },
  };

  const usePostResult = usePosts<any[]>([menu, productId, paging], GET_PRODUCT_ITEM_UNITS, params, {
    enabled: productId.length > 0,
  });

  return usePostResult;
};

export const usePublishItemView = (id: string) => {
  const result = usePublicPost<any>(['product_item', id], GET_ITEM_VIEW, { id: id });
  return result;
};
