import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Select } from '@base/components/form';
import { countriesAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';

/**
 *
 * @param {*} props
 * @returns
 */
const CountryOrigin: React.FC<any> = (props: any) => {
  const {
    className,
    value, //= {value: "kr", label: 'Korean'}
    onChange,
  } = props;
  const availableCountries: any = useRecoilValue(countriesAtom);

  //state
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);

  //re-initial data for table
  useEffect(() => {
    //set select options
    if (availableCountries && availableCountries.length > 0) {
      const newOptions = [...options];
      availableCountries.map((item: any) => {
        newOptions.push({
          //...item,
          value: item.isoCode2,
          label: item.country,
        });
      });
      setOptions(newOptions);
    }
    return () => {};
  }, [availableCountries]);

  //set selected item
  useEffect(() => {
    if (value) {
      if (value?.value !== selectedOption?.value) {
        const _selected = availableCountries?.find((v: any) => {
          return v?.value == value?.value;
        });
        setSelectedOption(_selected);
      }
    } else {
      //get default item in list
      const idx = availableCountries.findIndex((el: any) => el.isDefault == '1');
      if (idx > -1) {
        setSelectedOption({
          //...availableCountries[idx],
          value: availableCountries[idx].isoCode2,
          label: availableCountries[idx].country,
        });
      }
    }
    // return () => {};
  }, [value]);

  //option
  const handleOptionChange = (newOption: any) => {
    setSelectedOption(newOption);
    //callback
    onChange && onChange(newOption);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <Select
        outSide={true}
        value={selectedOption}
        options={options}
        onChange={handleOptionChange}
      />
    </div>
  );
};

export default CountryOrigin;
