import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import CountryOrigin from '@product/item/containers/country-origin';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = '', onChange = () => {}, errors = {}, onSave = () => {} } = props;

  const _value: any = { value: value, label: '' };

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <CountryOrigin {...props} value={value} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
