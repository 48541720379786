import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WEIGHT_UNIT_OPTIONS } from '@product/main/config/constants';
import TextView from '@base/containers/view-field/text/view';

const View: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  const valueString = useMemo(() => {
    const valueString = value?.val ?? '';
    if (!valueString) {
      return '';
    }
    const option = value?.unit ?? '';
    const optionItem = WEIGHT_UNIT_OPTIONS.find((element: any) => element.value == option);
    return `${valueString} ${optionItem?.label}`;
  }, [value?.unit, value?.val]);

  return <TextView value={valueString} />;
};

export default View;
