import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Plus, Minus } from 'react-feather';
import classNames from 'classnames';
import _ from 'lodash';
import { NoData } from '@base/components';
import { Button, Select } from '@base/components/form';
import { nanoid } from '@base/utils/helpers/general.utils';
import { BaseResponse } from '@base/types/interfaces/response';
import {
  GENERATOR_AUTO,
  GENERATOR_MANUAL,
  POSITION_FIRST,
  POSITION_LAST,
  SEPERATOR_HYPHEN,
  SEPERATOR_SLASH,
  CASE_UPPER,
  CASE_LOWER,
  SKU_GENERATOR_OPTION_PRODUCT_GROUP,
  SKU_GENERATOR_OPTION_CUSTOM,
  SHOW_OPTIONS,
  SKU_VALUE_OPTIONS,
  generateCode,
  SKU_GENERATOR_OPTION_ITEM_NAME,
  SKU_GENERATOR_OPTION_UNIT_NAME,
  SKU_GENERATOR_OPTION_ATTRIBUTE_1,
  SKU_GENERATOR_OPTION_ATTRIBUTE_2,
  SKU_GENERATOR_OPTION_PRODUCT_NAME,
} from '@product/item/config/sku';
import {
  COMMON_BARCODE_GENERATE,
  CREATE_MENU_SETTING,
  UPDATE_MENU_SETTING,
} from '@settings/general-setting/services/graphql/format';
import Loading from '@base/components/loading';
import { useMenuSetting } from '@settings/general-setting/services/format-service';
import useMutationPost from '@base/hooks/useMutationPost';
import { useSetRecoilState } from 'recoil';
import { savingPreferencesAtom } from '@settings/preferences/recoil/atoms';

const SKUGeneratorForm: React.FC<any> = (props: any) => {
  const {
    className,
    data, //{}, input data
    onChange,
    setting,
  } = props;

  //a setting row
  const defaultValueRow = {
    id: nanoid(),
    attribute: { value: SKU_GENERATOR_OPTION_PRODUCT_GROUP, label: 'Product Group' },
    show: { value: POSITION_FIRST, label: 'First' },
    lettersNr: 1,
    customValue: '',
  };

  //get default rows
  const defaultValueRows = [
    {
      id: nanoid(),
      attribute: { value: SKU_GENERATOR_OPTION_ITEM_NAME, label: 'Item Name' },
      show: { value: POSITION_FIRST, label: 'First' },
      lettersNr: 3,
      customValue: '',
    },
    {
      id: nanoid(),
      attribute: { value: SKU_GENERATOR_OPTION_UNIT_NAME, label: 'Unit Name' },
      show: { value: POSITION_FIRST, label: 'First' },
      lettersNr: 3,
      customValue: '',
    },
    {
      id: nanoid(),
      attribute: { value: SKU_GENERATOR_OPTION_ATTRIBUTE_1, label: 'Attribute 1' },
      show: { value: POSITION_FIRST, label: 'First' },
      lettersNr: 3,
      customValue: '',
    },
    {
      id: nanoid(),
      attribute: { value: SKU_GENERATOR_OPTION_ATTRIBUTE_2, label: 'Attribute 2' },
      show: { value: POSITION_FIRST, label: 'First' },
      lettersNr: 3,
      customValue: '',
    },
    // {
    //   id: nanoid(),
    //   attribute: { value: 'warehouse', label: 'Warehouse' },
    //   show: { value: POSITION_FIRST, label: 'First' },
    //   lettersNr: 1,
    //   customValue: '',
    // },
  ];

  //sample for general code
  const SAMPLE_DATA = {
    [SKU_GENERATOR_OPTION_PRODUCT_GROUP]: 'GROUPDESK',
    [SKU_GENERATOR_OPTION_PRODUCT_NAME]: 'PRODUCTDESK',
    [SKU_GENERATOR_OPTION_ITEM_NAME]: 'ITEMDESK',
    [SKU_GENERATOR_OPTION_UNIT_NAME]: 'UNITDESK',
    [SKU_GENERATOR_OPTION_ATTRIBUTE_1]: 'COLOR',
    [SKU_GENERATOR_OPTION_ATTRIBUTE_2]: 'SIZE',
    [SKU_GENERATOR_OPTION_CUSTOM]: 'CUSTOMDESK',
  };

  //state
  const setSavingOption = useSetRecoilState(savingPreferencesAtom);
  const [settingId, setSettingId] = useState('');
  const [generator, setGenerator] = useState(GENERATOR_AUTO);
  const [valueRows, setValueRows] = useState<any>([]);
  const [debValueRows, setDebValueRows] = useState<any>([]);
  const setValueRowsDebounced = useRef(
    _.debounce((newRows) => setDebValueRows(newRows), 1000),
  ).current;
  const [seperator, setSeperator] = useState(SEPERATOR_HYPHEN);
  const [caseUse, setCaseUse] = useState(CASE_UPPER);
  const [skuValue, setSkuValue] = useState('');
  const [skuBarcode, setSkuBarcode] = useState<any>(null);
  const initialLoad = useRef<any>(false);

  //mutation update
  const mCreate: any = useMutationPost<BaseResponse<any>>(
    CREATE_MENU_SETTING,
    'setting_createMenuSetting',
  );
  const mUpdate: any = useMutationPost<BaseResponse<any>>(
    UPDATE_MENU_SETTING,
    'setting_updateMenuSetting',
  );
  const mGenerateBarcode: any = useMutationPost<BaseResponse<any>>(
    COMMON_BARCODE_GENERATE,
    'common_generateBarCode',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // console.log('mGenerateBarcode data', data);
        setSkuBarcode(data);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        //toast.success('Create customer failed: ' + JSON.parse(error).message);
      },
    },
  );

  //create or update success
  useEffect(() => {
    if (mCreate.isSuccess || mUpdate.isSuccess) {
      onChange &&
        onChange({
          sku: generateCode({ valueRows, seperator, caseUse }, data || SAMPLE_DATA),
          setting: { generator, valueRows, seperator, caseUse },
        });
      //onClose && onClose();
    }
  }, [mCreate.isSuccess, mUpdate.isSuccess]);

  //set saving option
  useEffect(() => {
    setSavingOption((curOption: any) => ({
      ...curOption,
      isLoading: mCreate.isLoading || mUpdate.isLoading,
    }));
  }, [mCreate.isLoading, mUpdate.isLoading]);

  //init more attributes in value options
  // useEffect(() => {
  //   if (setting) {
  //     setGenerator(setting.generator);
  //     setValueRows(setting.valueRows);
  //     setSeperator(setting.seperator);
  //     setCaseUse(setting.caseUse);
  //     // generate sku by setting
  //     setSkuValue(
  //       generateCode(
  //         {
  //           valueRows: setting?.valueRows,
  //           seperator: setting?.seperator,
  //           caseUse: setting?.caseUse,
  //         },
  //         data,
  //       ),
  //     );
  //   }
  // }, [setting]);

  //generate sku auto
  useEffect(() => {
    setSkuValue(generateCode({ valueRows, seperator, caseUse }, data || SAMPLE_DATA));
  }, [valueRows, seperator, caseUse]);

  //get setting
  const params = {
    menu: 'product',
    key: 'product_sku_setting',
  };
  const { data: postSetting, isFetching } = useMenuSetting(params);
  //// console.log('postSetting', postSetting);

  //init setting
  useEffect(() => {
    if (postSetting) {
      try {
        const skuSetting: any = JSON.parse(postSetting.value);
        if (skuSetting) {
          setSettingId(postSetting.id);
          setGenerator(skuSetting.generator || GENERATOR_AUTO);
          setValueRows(skuSetting.valueRows || []);
          setSeperator(skuSetting.seperator || SEPERATOR_HYPHEN);
          setCaseUse(skuSetting.caseUse || CASE_UPPER);
          //generate sku by setting
          setSkuValue(
            generateCode(
              {
                valueRows: skuSetting.valueRows,
                seperator: skuSetting.seperator,
                caseUse: skuSetting.caseUse,
              },
              data || SAMPLE_DATA,
            ),
          );
        } else {
          setValueRows(defaultValueRows);
          setSkuValue(
            generateCode({ valueRows: defaultValueRows, seperator, caseUse }, data || SAMPLE_DATA),
          );
        }
      } catch (e) {
        // console.log('error parse json');
      }
    } else {
      setValueRows(defaultValueRows);
      setSkuValue(
        generateCode({ valueRows: defaultValueRows, seperator, caseUse }, data || SAMPLE_DATA),
      );
    }
  }, [postSetting]);

  //debounced saving for input text
  useEffect(() => {
    if (initialLoad?.current) {
      handleSaveDebounce(generator, debValueRows, seperator, caseUse);
    } else {
      initialLoad.current = true;
    }
    () => {
      initialLoad.current = false;
    };
  }, [debValueRows]);

  //generate barcode
  useEffect(() => {
    if (skuValue) {
      const params = {
        content: skuValue,
        width: 200,
        Height: 200,
        margin: 10,
        format: 'auto',
        lineColor: '#000000',
        background: '#ffffff',
        textAlign: 'center',
        textPosition: 'bottom',
      };
      mGenerateBarcode.mutate(params);
    }
  }, [skuValue]);

  //add empty value row
  const handleAddValueRow = () => {
    const newValueRows = [...valueRows];
    newValueRows.push(defaultValueRow);
    setValueRows(newValueRows);
    //save changes
    handleSaveDebounce(generator, newValueRows, seperator, caseUse);
  };

  //remove empty value row
  const handleRemoveValueRow = (index: number) => {
    const newValueRows = [...valueRows];
    newValueRows.splice(index, 1);
    setValueRows(newValueRows);
    //save changes
    handleSaveDebounce(generator, newValueRows, seperator, caseUse);
  };

  //value change
  const handleValueChange = (index: number, field: string, value: any) => {
    const newValueRows = [...valueRows];
    newValueRows[index][field] = value;
    if (field === 'attribute' && value?.value === SKU_GENERATOR_OPTION_CUSTOM) {
      newValueRows[index].show = { value: POSITION_LAST, label: 'Last' }; //Last
    }
    setValueRows(newValueRows);
    setValueRowsDebounced(newValueRows);
  };

  //save seting
  const handleSave = (generator: string, valueRows: any, seperator: string, caseUse: string) => {
    const params: any = {
      menu: 'product',
      key: 'product_sku_setting',
      value: JSON.stringify({ generator, valueRows, seperator, caseUse }),
    };
    if (settingId) {
      params.id = settingId;
      mUpdate.mutate({ menuSetting: params });
    } else {
      mCreate.mutate({ menuSetting: params });
    }
  };
  //debounce function
  const handleSaveDebounce = useCallback(_.debounce(handleSave, 500), [
    settingId,
    valueRows,
    seperator,
    caseUse,
  ]);

  //// console.log('valueRows', valueRows);
  //render
  return (
    <div className={classNames('pos-relative', className)}>
      {isFetching && <Loading />}
      <div className="card">
        <div className="card-body scroll-box" style={{ maxHeight: 'calc(100vh - 200px)' }}>
          <div className="d-flex">
            <div className="d-flex w-50">
              <div className="custom-control custom-radio mg-r-10">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="productSKUOption"
                  id="productSKUOptionAuto"
                  checked={generator === GENERATOR_AUTO ? true : false}
                  onChange={() => {
                    setGenerator(GENERATOR_AUTO);
                    handleSaveDebounce(GENERATOR_AUTO, valueRows, seperator, caseUse);
                  }}
                />
                <label className="custom-control-label" htmlFor="productSKUOptionAuto">
                  Auto generating
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="productSKUOption"
                  id="productCodeOptionManual"
                  checked={generator === GENERATOR_MANUAL ? true : false}
                  onChange={() => {
                    setGenerator(GENERATOR_MANUAL);
                    handleSaveDebounce(GENERATOR_MANUAL, valueRows, seperator, caseUse);
                  }}
                />
                <label className="custom-control-label" htmlFor="productCodeOptionManual">
                  Manual
                </label>
              </div>
            </div>
          </div>
          {generator === GENERATOR_AUTO && (
            <div className="form-group">
              <div className="form-group-title">SKU Format Settings</div>
              <p>Select the attribute to construct the SKU for the items.</p>
              {valueRows?.length === 0 && <NoData label="No Value(s) selected." icon="Tool" />}
              {valueRows?.length > 0 &&
                valueRows.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="form-row">
                        <div className="form-group">
                          <label className="form-item-title">Value</label>
                          <Select
                            className="wd-250-f flex-shrink-0 mg-r-5"
                            options={SKU_VALUE_OPTIONS}
                            value={item.attribute}
                            onChange={(option: any) =>
                              handleValueChange(index, 'attribute', option)
                            }
                          />
                        </div>
                        <div className="form-row col-sm-8">
                          <div className="form-group">
                            {item.attribute.value !== SKU_GENERATOR_OPTION_CUSTOM && (
                              <>
                                <label className="form-item-title">Show</label>
                                <div className="d-flex">
                                  <Select
                                    className="wd-150-f flex-shrink-0 mg-r-5"
                                    options={SHOW_OPTIONS}
                                    value={item.show}
                                    onChange={(option: any) =>
                                      handleValueChange(index, 'show', option)
                                    }
                                  />
                                  <div className="input-group mg-r-5">
                                    <input
                                      type="number"
                                      className="form-control form-control-lg wd-150"
                                      min="1"
                                      value={item.lettersNr}
                                      onChange={(e) =>
                                        handleValueChange(index, 'lettersNr', e.target.value)
                                      }
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text">letter(s)</span>
                                    </div>
                                  </div>
                                  <Button
                                    color="link"
                                    className="tx-danger"
                                    onClick={() => handleRemoveValueRow(index)}
                                  >
                                    <Minus />
                                  </Button>
                                </div>
                              </>
                            )}
                            {item.attribute.value == SKU_GENERATOR_OPTION_CUSTOM && (
                              <>
                                <label className="form-item-title">Custom</label>
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="form-control ht-38"
                                    value={item.customValue}
                                    onChange={(e) =>
                                      handleValueChange(index, 'customValue', e.target.value)
                                    }
                                  />
                                  <Button
                                    color="link"
                                    className="tx-danger"
                                    onClick={() => handleRemoveValueRow(index)}
                                  >
                                    <Minus />
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <Button color="link" onClick={handleAddValueRow}>
                <Plus className="mg-r-5" />
                Add a value
              </Button>

              {/* sanple */}
              <div className="form-group-title">SKU Sample</div>
              <div className="row row-xs">
                <div className="col-sm-6">
                  <div className="sku-preview">
                    <img src={skuBarcode?.image} className="w-100 ht-150" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="d-block form-item-title">Seperated by</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        id="productSKUSeperatedRadio1"
                        name="productSKUSeperatedRadio"
                        className="custom-control-input"
                        checked={seperator == SEPERATOR_HYPHEN ? true : false}
                        onChange={() => {
                          setSeperator(SEPERATOR_HYPHEN);
                          handleSaveDebounce(generator, valueRows, SEPERATOR_HYPHEN, caseUse);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="productSKUSeperatedRadio1">
                        Hyphen (-)
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        id="productSKUSeperatedRadio2"
                        name="productSKUSeperatedRadio"
                        className="custom-control-input"
                        checked={seperator == SEPERATOR_SLASH ? true : false}
                        onChange={() => {
                          setSeperator(SEPERATOR_SLASH);
                          handleSaveDebounce(generator, valueRows, SEPERATOR_SLASH, caseUse);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="productSKUSeperatedRadio2">
                        Slash (/)
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="d-block form-item-title">Case used</label>
                    <div className="custom-control custom-radio d-inline-block mg-r-10">
                      <input
                        type="radio"
                        id="productSKUCaseRadio1"
                        name="productSKUCaseRadio"
                        className="custom-control-input"
                        checked={caseUse === CASE_UPPER ? true : false}
                        onChange={() => {
                          setCaseUse(CASE_UPPER);
                          handleSaveDebounce(generator, valueRows, seperator, CASE_UPPER);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="productSKUCaseRadio1">
                        Upper Case
                      </label>
                    </div>
                    <div className="custom-control custom-radio d-inline-block">
                      <input
                        type="radio"
                        id="productSKUCaseRadio2"
                        name="productSKUCaseRadio"
                        className="custom-control-input"
                        checked={caseUse === CASE_LOWER ? true : false}
                        onChange={() => {
                          setCaseUse(CASE_LOWER);
                          handleSaveDebounce(generator, valueRows, seperator, CASE_LOWER);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="productSKUCaseRadio2">
                        Lower Case
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SKUGeneratorForm;
