import { ITab } from '@base/types/interfaces/app';

import Timeline from '@base/containers/timeline';
import UnitDetail from '@product/unit/containers/detail';

export const TABS: ITab[] = [
  {
    order: 1,
    default: true,
    label: 'Details',
    path: 'detail',
    tabComponent: UnitDetail,
    tabComponentProps: {
      showEdit: true,
    },
  },
  {
    order: 2,
    label: 'Timeline',
    path: 'timeline',
    tabComponent: Timeline,
  },
];
