import { Checkbox } from '@base/components/form';
import React from 'react';

interface IProps {
  value: boolean;
  onChange?: AnalyserNode;
}

const CanBeSold: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  return <Checkbox checked={value} onChange={onChange} label={'Can be sold'} />;
};
export default CanBeSold;
