import React, { useState } from 'react';
import usePost from '@base/hooks/usePost';
import { AssignCustomerContainer } from '@base/containers';
import { GET_PRODUCT_VENDOR, UPDATE_PRODUCT } from '@product/product/services/graphql';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { MENU_PRODUCT_PRODUCT } from '@base/config/menus';
import { GET_ITEM_VENDOR, PRODUCT_ITEM_UNIT_UPDATE } from '@product/item/services/graphql';

interface RelatedVendorProps {
  placement?: string;
  sourceId: string;
  sourceMenu?: string;
  menuTab?: string;
  menuType?: string;
  readOnly?: boolean;
}

/**
 *
 * @param {*} props
 * @returns
 */
const RelatedVendor: React.FC<RelatedVendorProps> = (props: RelatedVendorProps) => {
  const {
    placement,
    sourceId,
    sourceMenu,
    menuTab, // on url
    menuType, // for filter 'account', 'contact'
    readOnly = false,
  } = props;

  // state
  const [onAssigning, setOnAssigning] = useState<boolean>(false);

  // get related vendors
  const { data, isLoading, refetch } = usePost<any>(
    [sourceMenu === MENU_PRODUCT_PRODUCT ? 'product_product' : 'product_item', sourceId, 'vendors'],
    sourceMenu === MENU_PRODUCT_PRODUCT ? GET_PRODUCT_VENDOR : GET_ITEM_VENDOR,
    {
      id: sourceId,
    },
  );

  // console.log('Related-vendors', data);

  // assign mutation
  const mVendorAssign: any = useMutationPost<BaseMutationResponse>(
    sourceMenu === MENU_PRODUCT_PRODUCT ? UPDATE_PRODUCT : PRODUCT_ITEM_UNIT_UPDATE,
    sourceMenu === MENU_PRODUCT_PRODUCT ? 'product_updateProduct' : 'product_updateItem',
    {
      onMutate: () => {
        setOnAssigning(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        setOnAssigning(false);
        refetch();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Assign vendor failed: ' + JSON.parse(error).message);
        // refecth data
        setOnAssigning(false);
      },
    },
  );

  const onAssignVendor = (vendor: any) => {
    const foundIdx = data?.vendors?.findIndex((_ele: any) => _ele.id === vendor.id) || -1;
    if (foundIdx > -1) return;

    let vendors: any = [];
    data?.vendors?.map((item: any) => {
      vendors.push({ id: item.id, name: item.name });
    });
    vendors.push({ id: vendor.id, name: vendor.name });

    const prodParams = {
      product: {
        id: sourceId,
        vendors: vendors,
      },
    };

    const itemParams = {
      item: {
        id: sourceId,
        vendors: vendors,
      },
    };

    mVendorAssign.mutate(sourceMenu === MENU_PRODUCT_PRODUCT ? prodParams : itemParams);
  };

  // delete vendor
  function onDeleteVendor(vendor: any, cb: any) {
    // remove
    let vendors: any = [];
    data?.vendors?.map((item: any) => {
      if (item.id != vendor.id) {
        vendors.push({ id: item.id, name: item.name });
      }
    });
    const prodData = {
      id: sourceId,
      vendors: vendors,
    };
    mVendorAssign.mutate({ product: prodData });
  }

  return (
    <AssignCustomerContainer
      items={data?.vendors || []}
      placement={placement}
      isLoading={isLoading}
      menuSourceName={sourceMenu}
      menuTab={menuTab}
      menuType={menuType}
      onAssign={onAssignVendor}
      onDelete={onDeleteVendor}
      readOnly={readOnly}
      sendMail={true}
      sendSms={true}
    />
  );
};

RelatedVendor.defaultProps = {
  placement: 'auto',
  sourceId: '',
  sourceMenu: '',
  menuTab: 'vendor',
  menuType: 'account',
};

export default RelatedVendor;
