import React from 'react';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { isArray } from 'lodash';

const View: React.FC = (props: any) => {
  const { value } = props;

  let products: any[] = [];

  if (isArray(value)) {
    products = value;
  } else {
    products = [value];
  }

  return <ListTableCellDroplist className="no-padding cursor-default" values={products} />;
};

export default View;
