import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import { useLanguageByMenu } from '@base/services/i18n';
import { MENU_PRODUCT, MENU_PRODUCT_TREE } from '@base/config/menus';
import Loading from '@base/components/loading';

import ListPage from '@product/group/pages/list';
import ViewPage from '@product/group/pages/view';

const MainPage: React.FC = () => {
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);

  // load menu language
  useLanguageByMenu([MENU_PRODUCT]);

  const [isLoadingSetting, setIsLoadingSetting] = useState(false);
  const isSplitMode = true;

  const setIsSplitMode = (isSplitMode: boolean) => {};

  const splitViewProps = {
    isSplitMode: isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  const listProps = {
    isSplitMode: isSplitMode,
    setIsSplitMode: setIsSplitMode,
  };

  return (
    <>
      {isLoadingSetting ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path=""
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={<EmptySplitViewFC />}
                />
              ) : (
                <ListPage {...listProps} />
              )
            }
          />
          <Route
            path=":type/:id/*"
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={
                    <div className="pane-content" style={{ flex: 1 }}>
                      <Suspense fallback={<div className="han-loading" />}>
                        <ViewPage isSplitMode={isSplitMode} />
                      </Suspense>
                    </div>
                  }
                />
              ) : (
                <ViewPage />
              )
            }
          />
          <Route path="/" element={<Navigate replace to="/product/group" />} />
        </Routes>
      )}
    </>
  );
};

export default MainPage;
