import React, { useEffect } from 'react';
import PagelayoutSummary from '@base/containers/pagelayout-summary';

interface IViewLeftProps {
  id: string;
  category: string;
  ignoreFields?: string[];
  onRefetch?: () => void;
}
const ViewLeft: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { category, id, ignoreFields, onRefetch } = props;

  return (
    <div className="h-100">
      <PagelayoutSummary
        menuSource={category}
        menuSourceId={id}
        ignoreFields={ignoreFields}
        onRefetch={onRefetch}
      />
    </div>
  );
};
export default ViewLeft;
