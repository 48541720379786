import { lazy } from 'react';

export const InputCodeGenerator = lazy(() => import('@base/containers/code-generator'));
export const ProductType = lazy(() => import('@product/product/containers/product-type'));
export const ProductGroup = lazy(() => import('@product/product/containers/product-group'));
export const ProductCostOfGoods = lazy(
  () => import('@product/product/containers/product-cost-of-good'),
);
export const Switch = lazy(() => import('@base/components/form/switch'));
export const DatePicker = lazy(() => import('@base/components/form/date-selector'));
export const UnLimitDatePicker = lazy(() => import('@base/components/form/unlimit-date-picker'));
export const UserAutoComplete = lazy(() => import('@base/containers/user-auto-complete'));
export const BaseUnit = lazy(() => import('@product/unit/containers/base-unit'));
export const ProductAttributes = lazy(
  () => import('@product/product/containers/product-attributes'),
);
export const Input = lazy(() => import('@base/components/form/input'));
export const CheckBox = lazy(() => import('@base/components/form/checkbox'));
export const TextArea = lazy(() => import('@base/components/form/text-area'));
export const CanBeSold = lazy(() => import('@product/product/components/can-be-sold'));
export const ProductGroupAutoComplete = lazy(
  () => import('@product/product/containers/product-group-auto-complete'),
);
