import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextView from '@base/containers/view-field/text/view';

const View: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { value, options = [] } = props;
  const [stringValue, setStringValue] = useState('');

  useEffect(() => {
    const selected = options?.find((v: any) => v?.value === value);
    if (selected && selected?.value != '') {
      setStringValue(selected?.label);
    }
  }, [value, options]);

  return <TextView value={stringValue} />;
};

export default View;
