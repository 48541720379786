// product
// export const KEY_CODE = 'code';
export const KEY_PRODUCT_NAME = 'name';
export const KEY_PRODUCT_ID = 'code';
export const KEY_PRODUCT_BASE_UNIT = 'unit';
export const KEY_PRODUCT_BASE_UNIT_NAME = 'unitName';
export const KEY_PRODUCT_ATTRIBUTE = 'attributes';
export const KEY_PRODUCT_TYPE = 'type';
export const KEY_PRODUCT_TYPE_BE_SOLD = 'canBeSold';
export const KEY_PRODUCT_COST_OF_GOODS = 'costOfGoods';
export const KEY_PRODUCT_GROUP = 'group';
export const KEY_PRODUCT_STATUS = 'status';
export const KEY_SALE_END_DATE = 'endDate';
export const KEY_PRODUCT_ACTIVE = 'active';
export const KEY_SALE_NO_END_DATE = 'noEndDate';
export const KEY_SALE_START_DATE = 'startDate';
export const KEY_RETURNABLE = 'returnable';
export const KEY_VENDOR = 'vendors';
export const KEY_WARRANTY_PERIOD = 'warrantyPeriod';
export const KEY_WARRANTY_UNIT = 'warrantyUnit';
export const KEY_SUBSCRIPTION = 'subscription';
export const KEY_PRODUCT_ITEMS = 'items';
export const KEY_PREPAID_ITEM_INFO = 'itemsPrepaidItemInfo';
export const KEY_PREPAID_ITEM_AMOUNT = 'itemsPrepaidAmount';
export const KEY_USE_ATTR = 'useAttr';
export const KEY_PRODUCT_BRAND = 'brand';
export const KEY_IMAGES = 'images';
export const KEY_STAFFS = 'staffs';
export const KEY_ASSIGN_TO = 'assignTo';
export const KEY_PRODUCT_CREATED_BY = 'createdBy';
export const KEY_PRODUCT_UPDATED_AT = 'updatedAt';
export const KEY_RELATED_PRODUCTS = 'relatedProducts';
export const KEY_PRODUCT_MANUFACTURERS = 'manufacturers';
export const KEY_PRODUCT_COUNTRY_ORIGIN = 'countryOrigin';
export const KEY_PRODUCT_WARRANTY_PERIOD = 'warrantyPeriod';
export const KEY_PRODUCT_DESCRIPTION = 'description';

export const KEY_PRODUCT_ITEMS_COUNT = 'itemsCount';
export const KEY_PRODUCT_STOCK_QTY = 'stockQty';
export const KEY_BASE_UNIT = 'unit';
