import React, { useEffect, useState } from 'react';
import BaseUnitAutoComplete from './auto-complete';
import { SpanLang } from '@base/components/form';
import UnitWritePage from '@product/unit/pages/write';
import { Portal } from '@base/components';
import { MENU_PRODUCT_UNIT } from '@base/config/menus';
import classNames from 'classnames';

/**
 *
 * @param {*} props
 * @returns
 */
const BaseUnit: React.FC<any> = (props: any) => {
  const { className = '', value, onChange } = props;

  //state
  const [baseUnit, setBaseUnit] = useState<any>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  //init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(baseUnit)) {
        setBaseUnit(value);
      }
    } else {
      setBaseUnit(null);
    }
  }, [value]);

  //new base unit
  const handleValueChange = (newValue: any) => {
    const newUnit = {
      ...newValue,
      label: newValue.name,
      value: newValue.id,
    };
    setBaseUnit(newUnit);
    //callback
    onChange && onChange(newUnit);
  };

  const keyTitle = 'Create Unit';

  //render
  return (
    <div className={classNames("pos-relative", className)}>
      <div className="d-flex flex-column">
        <BaseUnitAutoComplete
          value={baseUnit}
          onChange={handleValueChange}
          onAdd={() => setShowAdd(true)}
        />
        <div className="mg-t-5 tx-orange">
          Subscription Item 생성하려면 Subscription 타입의 Base Unit을 선택하세요.
        </div>
      </div>
      {showAdd && (
        <Portal>
          <UnitWritePage
            title={<SpanLang keyLang={keyTitle} />}
            size="md"
            fullScreen={false}
            setFullScreen={() => { }}
            isOpen={showAdd}
            onClose={() => setShowAdd(false)}
            onReload={() => { }}
            onSuccess={(data: any) => {
              handleValueChange(data);
            }}
            menuApi={MENU_PRODUCT_UNIT}
            showCanvas
          />
        </Portal>
      )}
    </div>
  );
};

export default BaseUnit;
