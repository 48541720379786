export const KEY_ITEM_IMAGE = 'images';
export const KEY_ITEM_NAME = 'name';
export const KEY_ITEM_UNIT = 'unit';
export const KEY_ITEM_UNIT_VALUE = 'unitVal';
export const KEY_ITEM_ATTRIBUTE = 'attrVal';
export const KEY_ITEM_PRICE = 'basePrices';
export const KEY_ITEM_DESCRIPTION = 'description';
export const KEY_ITEM_BRAND = 'brand';
export const KEY_ITEM_ASSIGN_TO = 'assignTo';

export const KEY_ITEM_UNIT_ITEM = 'itemId';
export const KEY_ITEM_CATEGORY = 'inventoryType';
export const KEY_ITEM_TYPE = 'itemType';
export const KEY_ITEM_BASE_UNIT = 'itemBaseUnit';
export const KEY_ITEM_ATTRIBUTES = 'itemAttributes';
export const KEY_PRICING_TYPE = 'priceType';
export const KEY_USE_PREPAID = 'usePrepaid';
export const KEY_USE_SUBSCRIBE = 'useSubscribe';
export const KEY_ASSOCIATED_ITEMS = 'associatedItems';
export const KEY_PREPAID_TYPE = 'prepaidType';
export const KEY_ITEMS_UNIT = 'itemsUnit';
export const KEY_ITEM_ID_LOCAL = 'idLocal';
export const KEY_ITEM_ID = 'id';
export const KEY_ITEM_ACTIVE = 'active';
export const KEY_ITEM_DES = 'description';
export const KEY_ITEM_TEMPLATE = 'template';
export const KEY_SKU = 'sku';
export const KEY_ITEM_CODE = 'code';
export const KEY_ITEM_RETURNABLE = 'returnable';
export const KEY_ITEM_UNIT_VAL_QTY = 'unitValQty';
export const KEY_ITEM_UNIT_VAL_ID = 'unitValId';
export const KEY_ITEM_UNIT_VAL_NAME = 'unitValName';
export const KEY_ITEM_ATTR_VAL_ID = 'attrValId';
export const KEY_ITEM_ATTR_VAL_NAME = 'attrValName';
export const KEY_ITEM_UNIT_PRICES = 'prices';
export const KEY_ITEM_BASE_PRICES = 'basePrices';
export const KEY_ITEM_COST_PRICES = 'costPrices';
export const KEY_ITEM_BEST_PRICES = 'bestPrices';
export const KEY_ITEM_PURCHASE_PRICES = 'purchasePrices';
export const KEY_ITEM_BASE_PRICE = 'basePrice';
export const KEY_ITEM_COST_PRICE = 'costPrice';
export const KEY_ITEM_BEST_PRICE = 'bestPrice';
export const KEY_ITEM_PURCHASE_PRICE = 'purchasePrice';
export const KEY_ITEM_SUB_TPL_ID = 'subTplId';
export const KEY_ITEM_SUB_TPL = 'subTpl';
export const KEY_ITEM_DIMENSION = 'dimension';
export const KEY_ITEM_DIMENSION_UNIT = 'dimensionUnit';
export const KEY_ITEM_DIMENSION_VAL = 'dimensionVal';
export const KEY_ITEM_ORIGIN_BARCODE = 'barcode';
export const KEY_ITEM_COUNTRY_ORIGIN = 'countryOrigin';
export const KEY_ITEM_MANUFACTURE_DATE = 'manufacturerDate';
export const KEY_ITEM_BEST_BEFORE_DATE = 'bestBeforeDate';
export const KEY_ITEM_EXPIRED_DATE = 'expiredDate';
export const KEY_ITEM_WARRANTY = 'warranty';
export const KEY_ITEM_WARRANTY_PERIOD = 'period';
export const KEY_ITEM_WARRANTY_UNIT = 'unit';
export const KEY_ITEM_WEIGHT = 'weight';
export const KEY_ITEM_WEIGHT_VALUE = 'val';
export const KEY_ITEM_WEIGHT_UNIT = 'unit';
export const KEY_ITEM_MANUFACTURERS = 'manufacturers';
export const KEY_ITEM_VENDORS = 'vendors';
export const KEY_ITEM_OPEN_STOCK = 'openStock';
export const KEY_ITEM_LEAD_TIME = 'leadTime';
export const KEY_ITEM_LEAD_UNIT = 'leadUnit';
export const KEY_ITEM_REORDER_POINT = 'reorderPoint';
export const KEY_ITEM_LOT_SIZE = 'lotSize';
export const KEY_ITEM_PRICES = 'prices';
export const KEY_ITEM_WAREHOUSES = 'warehouses';
export const KEY_ITEM_INVENTORIES = 'inventories';
export const KEY_ITEM_BASIC_INFO = 'itemBasicInfo';
export const KEY_ITEM_INVENTORY_INFO = 'itemInventoryInfo';
export const KEY_ITEM_BATCH_INFO = 'itemBatchInfo';
export const KEY_ITEM_PREPAID_INFO = 'itemPrepaidInfo';
export const KEY_PREPAID_ITEM = 'prepaidItem';
export const KEY_ITEM_UNIT_PREPAID = 'unitsPrepaid';
export const KEY_ITEM_UNITS = 'items';
export const KEY_PROD_ID = 'prodId';
export const KEY_UNIT_ID = 'unitId';
export const KEY_ITEM_PRODUCT = 'prod';
export const KEY_ITEM_MATCH_ITEM_UNIT_ID = 'matchItemId';
export const KEY_ITEM_MATCH_ITEM_UNIT_NAME = 'matchItemName';
export const KEY_ITEM_MATCH_ITEM = 'matchItem';
export const KEY_ITEM_PREPAID_DISCOUNT = 'prepaidDiscount';
export const KEY_ITEM_NUMBER_USES = 'numberUses'; //--> prepaidPerItem
export const KEY_ITEM_PREPAID_PER_ITEM = 'prepaidPerItem'; //--> prepaidPerItem
export const KEY_ITEM_PREPAID_AMOUNT = 'prepaidAmount'; //array
export const KEY_PREPAID_ITEM_NAME = 'name';
export const KEY_ITEM_LOCATIONS = 'locations';
export const KEY_AMOUNT_CURRENCY = 'amountCurrency';
export const KEY_NAME_WAREHOUSE = 'warehouse';
export const KEY_VIEW_ITEM_TYPE = 'itemType';
export const KEY_VIEW_ITEM_PREPAID_TYPE = 'prepaidType';
export const KEY_VIEW_ITEM_PRICE_TYPE = 'priceType';
export const KEY_VIEW_ITEM_WARRANTY = 'warrantyPeriod';
export const KEY_VIEW_ITEM_ACTIVE = 'active';
export const KEY_VIEW_ITEM_BASE_UNIT = 'baseUnit';
export const KEY_VIEW_ITEM_NAME = 'itemName';
export const KEY_ITEM_ATTR_VALUES = 'attrValues';
export const KEY_ITEM_EXPIRATION_DATE = 'expiredDate';
export const KEY_ITEM_SALES_COMMISSION = 'salesCommission';
export const KEY_ITEM_SALES_COMMISSION_TYPE = 'salesCommissionType';

export const KEY_ITEM_INVENTORY_OPEN_STOCK = 'openStock';
export const KEY_ITEM_INVENTORY_SKU = 'sku';
export const KEY_ITEM_INVENTORY_STOCK_ON_HAND = 'stockOnHand';
export const KEY_ITEM_INVENTORY_REPLENISHMENT_POINT = 'replenishmentPoint';
export const KEY_ITEM_INVENTORY_REORDER_POINT = 'reorderPoint';
export const KEY_ITEM_INVENTORY_RESERVE_METHOD = 'reserveMethod';
export const KET_ITEM_INVENTORY_TRACEABILITY = 'traceability';
export const KET_ITEM_INVENTORY_TRACEABILITY_NO = 'traceabilityNo';
export const KEY_ITEM_INVENTORY_TRACKING_TYPE = 'trackingType';
export const KEY_ITEM_INVENTORY_TRACKING_NO = 'trackingNo';
export const KEY_ITEM_INVENTORY_SERIAL_NO = 'serialNo';
export const KEY_ITEM_INVENTORY_BATCH_NO = 'batchNo';
