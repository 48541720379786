import * as keyNames from '../key-names';
import * as commonConfig from '@base/config/view-field';

export default {
  ...commonConfig?.default,
  [keyNames.KEY_UNIT_RELATED_PRODUCTS]: {
    schema: `relatedProducts {
      id
      name
    }`,
    component: null,
  },
  [keyNames.KEY_UNIT_VALUES]: {
    schema: `unitValues {
      id
      name
      qty
      relatedProducts {
        id
        name
      }
    }`,
    component: null,
  },
};
