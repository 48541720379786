import React, { useState, useEffect } from 'react';
import { Button } from '@base/components/form';
import { NoData } from '@base/components';
import { ChevronDown, Edit, Plus, Save } from 'react-feather';
import { toast } from 'react-toastify';
import ProductItemAutoComplete from '@product/item/containers/item-auto-complete';
import { moneyFormat, nanoid } from '@base/utils/helpers/general.utils';
import usePost from '@base/hooks/usePost';
import {
  GET_ITEM_UNIT_ASSOCIATED_ITEMS,
  PRODUCT_ITEM_UNIT_UPDATE,
} from '@product/item/services/graphql';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseResponse } from '@base/types/interfaces/response';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { useQueryClient } from '@tanstack/react-query';
import { ProductItemTextView } from '@base/containers/quick-view';

/**
 *
 * @param {*} props
 */
const AssociatedItems: React.FC<any> = (props: any) => {
  const { value = [], onChange = null, itemId = '' } = props;

  const queryClient = useQueryClient();

  const defaultItem = {
    id: nanoid(),
    item: null,
    sku: [],
    unit_name: '',
    unit_qty: '',
    contained_qty: '',
    base_price: [],
    cost_price: [],
    best_price: [],
    actions: { canRemove: true },
  };

  //state
  const [items, setItems] = useState<any>([{ ...defaultItem }]);
  const [itemTotal, setItemTotal] = useState<any>(null);
  const [cacheOptions, setCacheOptions] = useState<any>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [oldItems, setOldItems] = useState<any[]>([]);

  // get init data
  const { data, isLoading, refetch } = usePost<any>(
    ['product_item', itemId, 'tabAssociatedItems'],
    GET_ITEM_UNIT_ASSOCIATED_ITEMS,
    {
      id: itemId,
    },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      if (data && data?.associatedItems?.length > 0) {
        const newItems: any[] = [];
        data?.associatedItems?.map((item: any) => {
          const _temp: any = {
            id: item?.id,
            item: { ...item.item, value: item?.item?.id, label: item?.item?.name },
            sku: item?.item?.sku,
            unit_name: item?.item?.unitVal?.name,
            unit_qty: item?.item?.unitVal?.qty,
            attrValues: item?.item?.attrValues ?? [],
            contained_qty: item?.qty,
            base_price: item?.item?.basePrices ?? [],
            cost_price: item?.item?.costPrices ?? [],
            best_price: item?.item?.bestPrices ?? [],
            actions: { canRemove: true },
          };
          newItems.push(_temp);
        });
        // newItems.push({ ...defaultItem });
        setItems(newItems);
        setOldItems(newItems);
      } else {
        setItems([{ ...defaultItem }]);
      }
    }
  }, [data, isLoading]);

  // mutation
  const mUpdateItemUnit: any = useMutationPost<BaseResponse<string>>(
    PRODUCT_ITEM_UNIT_UPDATE,
    'product_updateItem',
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSuccess: (res: any) => {
        setIsSaving(false);
        toast.success('Updated successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['product_item']);
        }, 500);
      },
      onError: (error: any) => {
        setIsSaving(false);
        toast.warning('Updated failed');
      },
    },
  );

  //onchange
  useEffect(() => {
    //calculate total
    calPriceTotal();
    //callback
    onChange && onChange(items);
  }, [items]);

  //total
  const calPriceTotal = () => {
    const newItemTotal: any = {
      base_price: [],
      cost_price: [],
      best_price: [],
    };
    items.map((item: any, index: number) => {
      //total base price by currency
      item?.base_price?.map((price: any) => {
        //find value with same currency
        const idx = newItemTotal.base_price.findIndex((el: any) => el.currency === price.currency);
        if (idx > -1) {
          newItemTotal.base_price[idx].amount += parseFloat(price.amount);
        } else {
          newItemTotal.base_price.push({
            amount: parseFloat(price.amount) * item?.contained_qty ?? 0,
            currency: price.currency,
          });
        }
      });

      //total cost price by currency
      item?.cost_price?.map((price: any) => {
        //find value with same currency
        const idx = newItemTotal.cost_price.findIndex((el: any) => el.currency === price.currency);
        if (idx > -1) {
          newItemTotal.cost_price[idx].amount += parseFloat(price.amount);
        } else {
          newItemTotal.cost_price.push({
            amount: parseFloat(price.amount) * item?.contained_qty ?? 0,
            currency: price.currency,
          });
        }
      });

      //total cost price by currency
      item?.best_price?.map((price: any) => {
        //find value with same currency
        const idx = newItemTotal.best_price.findIndex((el: any) => el.currency === price.currency);
        if (idx > -1) {
          newItemTotal.best_price[idx].amount += parseFloat(price.amount);
        } else {
          newItemTotal.best_price.push({
            amount: parseFloat(price.amount) * item?.contained_qty ?? 0,
            currency: price.currency,
          });
        }
      });
    });
    setItemTotal(newItemTotal);
  };

  //add new default item
  const handleAddItem = () => {
    const newItems: any = [...items];
    newItems.push(defaultItem);
    setItems(newItems);
  };

  //remove item
  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  //item change
  const handleItemChange = (item: any, index: number) => {
    const newItems: any = [...items];
    // check exist
    const idx = newItems.findIndex((el: any) => el?.item?.id === item?.id);

    if (idx === -1) {
      newItems[index].item = item;
      newItems[index].sku = item?.sku ?? [];
      newItems[index].unit_name = item?.unitVal?.name ?? '';
      newItems[index].unit_qty = item?.unitVal?.qty ?? 1;
      newItems[index].base_price = item?.basePrices ?? [];
      newItems[index].cost_price = item?.costPrices ?? [];
      newItems[index].best_price = item?.bestPrices ?? [];
      newItems[index].attrValues = item?.attrValues ?? [];
      setItems(newItems);
    } else {
      toast.error('Item already selected');
    }
  };

  //qty bundle
  const handleContainedQtyChange = (value: any, index: number) => {
    const newItems: any = [...items];
    newItems[index].contained_qty = parseInt(value);
    setItems(newItems);
  };

  const handleOnSave = () => {
    const associatedItems: any[] = [];
    items?.map((item: any) => {
      if (item?.item?.id && item?.item?.id != '') {
        associatedItems.push({
          item: { id: item?.item?.id, name: item?.item?.name },
          qty: item?.contained_qty,
        });
      }
    });
    const params: any = {
      id: itemId,
      associatedItems: associatedItems?.length > 0 ? associatedItems : null,
    };
    mUpdateItemUnit.mutate({ item: params });
  };

  //new render
  return (
    <div className="pos-realtive w-100 pd-15">
      <div className="table-responsive bd-b bd-l bd-r bd-t">
        <table
          className="table table-bordered mg-b-0 bd-0 product-detail-tb"
          id={'asseciatedTable'}
        >
          <thead>
            <tr>
              <th scope="col" className="">
                Item Name
              </th>
              <th scope="col" className="">
                SKU
              </th>
              <th scope="col" className="">
                Unit Name
              </th>
              <th scope="col" className="">
                Unit Qty
              </th>
              <th scope="col" className="">
                Attributes
              </th>
              <th scope="col" className="">
                Contained Qty
              </th>
              <th scope="col" className="">
                Base Price
              </th>
              <th scope="col" className="">
                Base Cost
              </th>
              {/* <th scope="col" className="">
                Best Price
              </th> */}
              <th scope="col" className="">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length === 0 && (
              <tr>
                <td colSpan={10}>
                  <NoData label="No item(s) added." icon="AlertTriangle" />
                </td>
              </tr>
            )}
            {items.length > 0 &&
              items.map((item: any, index: number) => {
                const sku = item?.sku
                  ? item?.sku?.map((sku: string) => ({ id: sku, name: sku }))
                  : [];

                const attrValues: any[] =
                  item?.attrValues?.map((item: any) => ({
                    id: item.id,
                    name: [item?.attr?.name, item?.name].join(':'),
                  })) ?? [];

                return (
                  <tr key={index}>
                    <td className="align-middle">
                      {showEdit || itemId == '' ? (
                        <ProductItemAutoComplete
                          className=""
                          type="general"
                          value={item?.item}
                          single={true}
                          showAllOption={false}
                          exceptIds={items.map((ele: any) => ele?.id || '')}
                          onChange={(item: any) => handleItemChange(item, index)}
                          cacheOptions={cacheOptions}
                          onCache={setCacheOptions}
                        />
                      ) : (
                        <ProductItemTextView
                          value={{ id: item?.item?.id, name: item?.item?.name }}
                        />
                      )}
                    </td>
                    <td className="align-middle">
                      <ListTableCellDroplist
                        className="no-padding cursor-default"
                        showAvatar={false}
                        values={sku}
                      />
                    </td>
                    <td className="align-middle">{item.unit_name}</td>
                    <td className="align-middle">{item.unit_qty}</td>
                    <td className="align-middle">
                      <ListTableCellDroplist
                        className="no-padding cursor-default"
                        showAvatar={false}
                        values={attrValues}
                      />
                    </td>
                    <td className="align-middle">
                      {showEdit || itemId == '' ? (
                        <input
                          type="number"
                          className="form-control tx-center wd-100 ht-38"
                          min={1}
                          value={item.contained_qty}
                          onChange={(e) => handleContainedQtyChange(e.target.value, index)}
                        />
                      ) : (
                        item?.contained_qty
                      )}
                    </td>
                    {/* Base price */}
                    <td className="align-middle">
                      {item?.base_price?.map((price: any, baIndex: number) => (
                        <div key={baIndex}>
                          {moneyFormat(price.amount)} <strong>{price.currency || '--'}</strong>
                        </div>
                      ))}
                    </td>
                    {/* Base cost */}
                    <td className="align-middle">
                      {item?.cost_price?.map((price: any, coIndex: number) => (
                        <div key={coIndex}>
                          {moneyFormat(price.amount)} <strong>{price.currency || '--'}</strong>
                        </div>
                      ))}
                    </td>
                    {/* Best price */}
                    {/* <td className="align-middle">
                      {item?.best_price?.map((price: any, beIndex: number) => (
                        <div key={beIndex}>
                          {moneyFormat(price.amount)} <strong>{price.currency || '--'}</strong>
                        </div>
                      ))}
                    </td> */}
                    <td className="align-middle">
                      {showEdit && (
                        <Button
                          classSpanIcon="mg-r-5"
                          color="icon"
                          icon="X"
                          className="tx-danger"
                          onClick={() => handleRemoveItem(index)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6} className="tx-right align-middle">
                <div className="d-flex flex-grow-1 justify-content-between align-items-center mg-b-5">
                  {(showEdit || itemId == '') && (
                    <button type="button" className="btn btn-link" onClick={handleAddItem}>
                      <Plus /> Add New Line
                    </button>
                  )}
                  <span className="tx-bold tx-16">Total: </span>
                </div>
              </td>
              {/* Base price */}
              <td className="align-middle">
                {itemTotal?.base_price.map((item: any, baIndex: number) => (
                  <div key={baIndex}>
                    {moneyFormat(item.amount)} <strong>{item.currency}</strong>
                  </div>
                ))}
              </td>
              {/* Base cost */}
              <td className="align-middle">
                {itemTotal?.cost_price.map((item: any, coIndex: number) => (
                  <div key={coIndex}>
                    {moneyFormat(item.amount)} <strong>{item.currency}</strong>
                  </div>
                ))}
              </td>
              {/* Best price */}
              {/* <td className="align-middle">
                {itemTotal?.best_price.map((item: any, beIndex: number) => (
                  <div key={beIndex}>
                    {moneyFormat(item.amount)} <strong>{item.currency}</strong>
                  </div>
                ))}
              </td> */}
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* Save/Close button */}
      {itemId != '' && showEdit && (
        <div className="d-flex mg-t-5 mg-l-auto justify-content-end">
          <button
            type="button"
            className="btn btn-light mg-r-5 mg-l-auto"
            onClick={() => {
              setShowEdit(false);
              setItems(oldItems);
            }}
          >
            Close
          </button>
          <Button
            type="button"
            className="btn mg-l-5"
            onClick={handleOnSave}
            loading={isSaving}
            color="success"
          >
            <Save className="mg-r-5" />
            Save
          </Button>
        </div>
      )}
      {/* Edit button */}
      {itemId != '' && !showEdit && (
        <div className="d-flex mg-t-5 mg-l-auto justify-content-end">
          <Button
            type="button"
            className="btn mg-l-5"
            onClick={() => {
              setShowEdit(true);
            }}
            color={'primary'}
          >
            <Edit className="mg-r-5" />
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssociatedItems;
