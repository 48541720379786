import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
import Field from '@base/components/hook-form/field';
import { ArrowLeft, ChevronUp } from 'react-feather';
import {
  KEY_RELATED_PRODUCTS,
  KEY_PRODUCT_ITEMS,
  KEY_BASE_UNIT,
  KEY_PRODUCT_ATTRIBUTE,
  KEY_PRODUCT_TYPE,
  KEY_PRODUCT_STATUS,
  KEY_PRODUCT_GROUP,
  KEY_PRODUCT_NAME,
  KEY_PRODUCT_ACTIVE,
  KEY_PRODUCT_COST_OF_GOODS,
  KEY_SALE_END_DATE,
  KEY_STAFFS,
  KEY_PRODUCT_TYPE_BE_SOLD,
  KEY_SALE_START_DATE,
} from '@product/product/config/key-names';
import {
  KEY_ITEM_BASE_UNIT,
  KEY_ITEM_ATTRIBUTES,
  KEY_ITEM_TYPE,
  KEY_ITEM_CATEGORY,
  KEY_PREPAID_TYPE,
  KEY_ITEM_UNITS,
} from '@product/item/config/key-names';
import { ADD_NEW_PRODUCT } from '@product/product/services/graphql';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { finalizeParams } from './payload';
import classNames from 'classnames';
import {
  OPTION_ITEM_CATEGORIES,
  OPTION_ITEM_TYPES,
  PRODUCT_ITEM_CATEGORY,
  PRODUCT_ITEM_TYPE,
} from '@product/main/config/constants';
import { finalizeParams as finalizeItemParams } from '@product/item/containers/write-form/payload';

interface WriteFormProps {
  className?: string;
  fullScreen: boolean;
  menuApi: string;
  withItem?: boolean;
  value?: any;
  onChange?: (data: any) => void;
  onValid?: (data: boolean) => void;
  onClose: () => void;
  onReload?: () => void;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
  showCanvas: boolean;
  submitProduct?: boolean;
  setIsSubmit?: any;
  itemParams?: any;
}

const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    className,
    fullScreen,
    menuApi,
    showCanvas = false,
    withItem = false,
    value,
    onChange: onFormChange,
    onValid,
    onClose,
    onReload,
    //withWriteForm
    defaultValues,
    fields,
    getParams,
    submitProduct,
    setIsSubmit,
    itemParams,
  } = props;
  // const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    //trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      [KEY_PRODUCT_ACTIVE]: true,
      [KEY_PRODUCT_ITEMS]: {
        [KEY_ITEM_TYPE]: OPTION_ITEM_TYPES[0]?.value,
        [KEY_ITEM_CATEGORY]: OPTION_ITEM_CATEGORIES[0]?.value,
      },
      //test
      // 'name': 'aa',
      // 'groupId': {
      //   label: "Product Group 1",
      //   value: "24142cce-b50f-47ac-a8cd-02300911cb14",
      // },
      // attributes: {
      //   applyItemAttribute: true,
      //   attributes: [
      //     {
      //       id: "2481079d-34a2-4226-a185-a7bcafc27d91",
      //       name: "Size",
      //       label: "Size",
      //       value: "2481079d-34a2-4226-a185-a7bcafc27d91",
      //       values: [
      //         { id: '2481079e-272c-4dee-905f-678c1786b7d0', name: 'S' },
      //         { id: '2481079e-2731-43c6-9c3d-b0bbfe299bde', name: 'M' },
      //         { id: '2481079e-2733-42c5-adf6-b769ec9c4252', name: 'L' }
      //       ]
      //     }
      //   ]
      // }
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //on valid change
  useEffect(() => {
    if (onValid) {
      onValid(isValid);
    }
  }, [isValid]);

  //form values change
  watch((data: any) => {
    //// console.log('watch data', data);
    onFormChange && onFormChange(data);
  });

  //product BASE UNIT watching
  const productBaseUnit = watch(KEY_BASE_UNIT);
  useEffect(() => {
    if (productBaseUnit) {
      const itemData = getValues(KEY_PRODUCT_ITEMS);
      setValue(KEY_PRODUCT_ITEMS, {
        ...itemData,
        [KEY_ITEM_BASE_UNIT]: {
          ...productBaseUnit,
          unitNames: productBaseUnit?.unitValues?.map((_i: any) => ({
            ..._i,
            value: _i.id,
            label: _i.name,
          })),
        },
      });
    }
  }, [productBaseUnit]);

  // product attributes watch
  const productAttribute = watch(KEY_PRODUCT_ATTRIBUTE);
  useEffect(() => {
    if (productAttribute) {
      const itemData = getValues(KEY_PRODUCT_ITEMS);
      setValue(KEY_PRODUCT_ITEMS, {
        ...itemData,
        [KEY_ITEM_ATTRIBUTES]: {
          ...productAttribute,
          attributes: productAttribute.attributes?.map((_attr: any) => ({
            ..._attr,
            values:
              _attr?.values?.map((_child: any) => ({
                ..._child,
                value: _child.id,
                label: _child.name,
              })) ?? [],
            activeValue: _attr.activeValue
              ? _attr.activeValue
              : _attr?.values?.map((_child: any) => ({
                  ..._child,
                  value: _child.id,
                  label: _child.name,
                })) ?? [],
          })),
        },
      });
    }
  }, [productAttribute]);

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_NEW_PRODUCT,
    'product_createProduct',
    {
      onMutate: () => {
        setIsSubmit(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        // toast.success('Create product successfully!');
        // refecth data
        // onReload && onReload();
        setIsSubmit(false);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Create product failed: ' + JSON.parse(error).message);
        // refecth data
        setIsSubmit(false);
      },
    },
  );

  //check success
  useEffect(() => {
    if (mutationAdd.isSuccess) {
      toast.success('Created product successfully!');
      onReload && onReload();
      onClose && onClose();
    }
  }, [mutationAdd.isSuccess]);

  //submit form
  const onSubmit = (formData: any) => {
    const configParams = getParams(formData); //generate api params by fields
    const newParams: any = finalizeParams(configParams);

    let items: any = [];

    if (itemParams) {
      let item: any = finalizeItemParams(
        itemParams,
        formData,
        itemParams?.[KEY_ITEM_CATEGORY],
        itemParams?.[KEY_ITEM_TYPE],
        itemParams?.[KEY_PREPAID_TYPE],
      );
      items = item?.[KEY_ITEM_UNITS] ?? [];
    }
    newParams.product.items = items;
    mutationAdd.mutate(newParams);
  };

  useEffect(() => {
    if (submitProduct) {
      const formData = getValues();
      onSubmit(formData);
    }
  }, [submitProduct]);

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    // console.log(errors, e);
  };

  //render form
  const renderFields = () => {
    return (
      <div className={classNames('form-row')}>
        {fields.map((_item, _index) => {
          let newComponentProps = { ..._item?.componentProps };
          return (
            <Field
              key={_index}
              item={{
                ..._item,
                columns: [KEY_PRODUCT_TYPE_BE_SOLD].indexOf(_item.keyName) >= 0 ? 1 : 2,
                hideTitle:
                  _item.keyName == KEY_RELATED_PRODUCTS ||
                  _item.keyName == KEY_PRODUCT_ITEMS ||
                  _item.keyName == KEY_SALE_END_DATE ||
                  _item.keyName == KEY_PRODUCT_TYPE_BE_SOLD,
                componentProps: newComponentProps,
              }}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  // render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <Button
            color="success"
            onClick={() => {
              handleSubmit((data) => onSubmit(data), onError)();
            }}
            disabled={mutationAdd.isLoading || !isValid}
            loading={mutationAdd.isLoading}
            name="Save"
          />
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  //// console.log('[Product] watch', watch()); //get form values when inputing JSON.stringify(watch(), null, 2)
  //// console.log('[Product] form errors', errors);
  //// console.log('[Product] form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 280px)';
  if (fullScreen) {
    formHeight = 'calc(100vh - 200px)';
  } else if (showCanvas) {
    formHeight = 'calc(100vh - 175px)';
  }

  const productType = watch(KEY_PRODUCT_TYPE);
  const productGroup = watch(KEY_PRODUCT_GROUP);
  const productName = watch(KEY_PRODUCT_NAME);
  const canBeSold = watch(KEY_PRODUCT_TYPE_BE_SOLD);

  return (
    <div className={classNames('pos-relative', className)}>
      {!withItem && (
        <div className="bd-b pd-y-8">
          <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
            <span>
              <span className="tx-danger">*</span>
              <span>You cannot except a required field</span>
            </span>
          </div>
        </div>
      )}
      {/* render form */}
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className={classNames('form', { 'wd-800': showCanvas })}
      >
        <div
          className={classNames('pos-relative scroll-box pd-15')}
          style={{ maxHeight: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
        >
          {renderFields()}
        </div>
        {!withItem && renderFooter()}
      </form>
    </div>
  );
};

// export default withMiModal(withWriteForm(WriteForm));
export default withWriteForm(WriteForm);
