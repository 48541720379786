import React, { useEffect, useState } from 'react';
import usePost from '@base/hooks/usePost';
import { AssignRepContainer } from '@base/containers';
import { User } from '@base/types/interfaces/user';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import useMutationPost from '@base/hooks/useMutationPost';
import {
  CREATE_PRODUCT_ASSIGN_REP,
  DELETE_PRODUCT_ASSIGN_REP,
  GET_PRODUCT_STAFFS,
} from '@product/product/services/graphql';

interface IAssignUserProps {
  placement?: string;
  id: string;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignRep: React.FC<IAssignUserProps> = (props: IAssignUserProps) => {
  const { placement, id } = props;

  // hook get data
  const {
    data: repsData,
    isLoading,
    refetch,
  } = usePost<BaseResponse<User>>(['product_assignTo', id], GET_PRODUCT_STAFFS, { prodId: id });

  // create mutation
  const mutationCreate: any = useMutationPost<BaseMutationResponse>(
    CREATE_PRODUCT_ASSIGN_REP,
    'product_createAssignTo',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // toast.success('Create sales order successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Create assign rep failed: ' + JSON.parse(error).message);
      },
    },
  );

  // delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_PRODUCT_ASSIGN_REP,
    'product_deleteAssignTo',
    {
      onSuccess: (res: any) => {},
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete assign rep failed: ' + JSON.parse(error).message);
      },
    },
  );

  // check success
  useEffect(() => {
    if (mutationCreate.isSuccess) {
      refetch();
    }
    if (mutationDelete.isSuccess) {
      refetch();
    }
  }, [mutationCreate.isSuccess, mutationDelete.isSuccess]);

  // add new assign
  const handleCreateAssign = (item: any) => {
    // check exist before add
    const foundIdx = repsData?.results?.findIndex((_ele: any) => _ele.id === item.id) || -1;
    if (foundIdx > -1) return;

    const params: any = {
      ids: [id],
      assignTo: [
        {
          user: {
            id: item.id,
            name: item.name,
          },
          group: {},
        },
      ],
    };
    mutationCreate.mutate(params);
  };

  // delete customer contact
  const handleDeleteAssign = (item: any, cb: any) => {
    let params: any = {
      ids: [id],
      refIds: [item?.id],
    };
    mutationDelete.mutate(params);
    // callback
    cb && cb();
  };

  return (
    <AssignRepContainer
      items={repsData?.results || []}
      placement={placement}
      isLoading={isLoading || mutationCreate.isLoading || mutationDelete.isLoading}
      onAssign={handleCreateAssign}
      onDelete={handleDeleteAssign}
    />
  );
};

AssignRep.defaultProps = {
  placement: 'right',
  id: '',
};

export default AssignRep;
