import { DESC } from '@base/config/constant';
import { ListType } from '@base/types/enums';
import { MenuData } from '@base/types/interfaces/app';
import { atom } from 'recoil';

export const userItemSetting = atom<MenuData>({
  key: 'UserItemSetting',
  default: {
    isSplitMode: false,
    listType: ListType.LIST,
    // activeMenu: '',
    // activeId: '',
    // activeTab: '',
    settingColumns: [],
    filter: {
      baseFilters: {},
      headerFilters: {
        groupBy: 'all',
      },
      searchFilters: {},
      keyword: '',
      paging: {
        page: 1,
        size: 10,
      },
      sort: {
        field: 'createdAt',
        orderBy: DESC,
      },
    },
  },
});

export const itemListData = atom<any[]>({
  key: 'ItemListData',
  default: [],
});
