import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Dimension } from '@product/item/components';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { errors = {} } = props;
  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <Dimension {...props} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
