import { lazy } from 'react';

const GroupMain = lazy(() => import('@product/group/pages'));

export default [
  {
    path: '/product/group/*',
    component: GroupMain,
  },
];
