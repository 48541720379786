import {
  PRODUCT_ITEM_CATEGORY,
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_TYPE,
  PRODUCT_ITEM_TYPE_BUNDLE,
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_PREPAID_TYPE,
  PRODUCT_PREPAID_TYPE_AMOUNT,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import * as keyNames from '@product/item/config/key-names';

/**
 * mapping params in API for write product
 * @param {*} formData
 * @returns
 */
export const finalizeParams = (
  formData: any = {},
  productData: any = undefined,
  itemCategory = '',
  itemType = '',
  prepaidType = '',
) => {
  let itemParams: any = {};
  let itemUnits: any[] = [];
  // console.log('...finalizeParams...', formData);

  //item details
  if (formData[keyNames.KEY_ITEM_TYPE] !== PRODUCT_ITEM_TYPE_PREPAID) {
    // CURRENT VERSION - 2 STEPS
    formData[keyNames.KEY_ITEM_BASIC_INFO].map((_item: any) => {
      let itemUnitParams: any = {};
      itemUnitParams = getBasicParams(_item, productData, itemCategory, itemType, prepaidType);
      itemUnits.push(itemUnitParams);
    });
  } else {
    formData[keyNames.KEY_PREPAID_ITEM].map((_preItem: any) => {
      let itemUnitParams: any = {};
      itemUnitParams = getPrepaidItemParams(_preItem, formData[keyNames.KEY_PREPAID_TYPE]);

      // add prepaid type info
      itemUnitParams[keyNames.KEY_PREPAID_TYPE] =
        PRODUCT_PREPAID_TYPE[formData[keyNames.KEY_PREPAID_TYPE]];

      // add new
      itemUnits.push(itemUnitParams);
    });
  }

  itemUnits?.map((itemUnit: any) => {
    // add general info
    itemUnit[keyNames.KEY_ITEM_CATEGORY] =
      PRODUCT_ITEM_CATEGORY[formData[keyNames.KEY_ITEM_CATEGORY]];
    itemUnit[keyNames.KEY_ITEM_TYPE] = PRODUCT_ITEM_TYPE[formData[keyNames.KEY_ITEM_TYPE]];
    itemUnit.prod = {
      id: productData?.id,
      name: productData?.name,
    };
    itemUnit.unit = {
      id: formData[keyNames.KEY_ITEM_BASE_UNIT].id,
      name: formData[keyNames.KEY_ITEM_BASE_UNIT].name,
    };

    // TODO: bundle
    if (formData[keyNames.KEY_ITEM_TYPE] === PRODUCT_ITEM_TYPE_BUNDLE) {
      itemUnit[keyNames.KEY_ASSOCIATED_ITEMS] = formData[keyNames.KEY_ASSOCIATED_ITEMS].map(
        (_ele: any) => ({
          item: { id: _ele.item.id, name: _ele.item.name },
          qty: parseInt(_ele.contained_qty),
        }),
      );
    }
  });

  // set items params
  itemParams[keyNames.KEY_ITEM_UNITS] = itemUnits;

  return itemParams;
};

const getBasicParams = (
  itemData: any,
  productData: any,
  itemCategory = '',
  itemType = '',
  prepaidType = '',
) => {
  let itemUnitParams: any = {};

  itemUnitParams[keyNames.KEY_ITEM_CODE] = itemData[keyNames.KEY_ITEM_CODE];
  itemUnitParams[keyNames.KEY_ITEM_NAME] = itemData[keyNames.KEY_ITEM_NAME];
  itemUnitParams[keyNames.KEY_ITEM_ACTIVE] =
    itemData[keyNames.KEY_ITEM_ACTIVE] === 'y' ? true : false;
  itemUnitParams.unitVal = {
    id: itemData[keyNames.KEY_ITEM_UNIT_VAL_ID],
    name: itemData[keyNames.KEY_ITEM_UNIT_VAL_NAME],
  };

  // attribute value
  if (itemData.option_values) {
    if (Array.isArray(itemData.option_values) && itemData.option_values.length > 1) {
      itemUnitParams[keyNames.KEY_ITEM_ATTR_VALUES] = itemData.option_values?.map((attr: any) => ({
        id: attr?.id,
        name: attr?.name,
      }));
    } else {
      itemUnitParams[keyNames.KEY_ITEM_ATTR_VALUES] = [
        {
          id: itemData?.option_values?.id,
          name: itemData?.option_values?.name,
        },
      ];
    }
  }

  // more info
  itemUnitParams[keyNames.KEY_ITEM_DES] = itemData.moreInfo[keyNames.KEY_ITEM_DES];
  // itemUnitParams[keyNames.KEY_ITEM_DIMENSION] = {
  //   unit: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].unit,
  //   val: {
  //     x: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].W.toString(),
  //     y: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].H.toString(),
  //     z: itemData.moreInfo[keyNames.KEY_ITEM_DIMENSION].L.toString(),
  //   },
  // };
  // itemUnitParams[keyNames.KEY_ITEM_ORIGIN_BARCODE] =
  //   itemData.moreInfo[keyNames.KEY_ITEM_ORIGIN_BARCODE];
  // itemUnitParams[keyNames.KEY_ITEM_WARRANTY] = {
  //   [keyNames.KEY_ITEM_WARRANTY_PERIOD]: parseInt(
  //     itemData.moreInfo[keyNames.KEY_ITEM_WARRANTY].value || 0,
  //   ),
  //   [keyNames.KEY_ITEM_WARRANTY_UNIT]: itemData.moreInfo[keyNames.KEY_ITEM_WARRANTY].option,
  // };
  // itemUnitParams[keyNames.KEY_ITEM_WEIGHT] = {
  //   [keyNames.KEY_ITEM_WEIGHT_VALUE]: parseInt(
  //     itemData.moreInfo[keyNames.KEY_ITEM_WEIGHT].value || 0,
  //   ),
  //   [keyNames.KEY_ITEM_WEIGHT_UNIT]: itemData.moreInfo[keyNames.KEY_ITEM_WEIGHT].unit,
  // };
  // itemUnitParams[keyNames.KEY_ITEM_COUNTRY_ORIGIN] =
  //   itemData.moreInfo[keyNames.KEY_ITEM_COUNTRY_ORIGIN]?.value || '';

  // itemUnitParams[keyNames.KEY_ITEM_RETURNABLE] = itemData[keyNames.KEY_ITEM_RETURNABLE];

  // itemUnitParams[keyNames.KEY_ITEM_VENDORS] = itemData.moreInfo[keyNames.KEY_ITEM_VENDORS].map(
  //   (_ele: any) => ({
  //     linkId: _ele.value,
  //     linkName: _ele.label,
  //   }),
  // );
  // itemUnitParams[keyNames.KEY_ITEM_MANUFACTURERS] = itemData.moreInfo[
  //   keyNames.KEY_ITEM_MANUFACTURERS
  // ].map((_ele: any) => ({
  //   linkId: _ele.value,
  //   linkName: _ele.label,
  // }));

  // prices
  itemUnitParams[keyNames.KEY_ITEM_BASE_PRICES] = itemData?.prices?.map((_ele: any) => ({
    amount: parseFloat(_ele.base_price?.value),
    currency: _ele.base_price?.currency?.value,
  }));
  // itemUnitParams[keyNames.KEY_ITEM_BEST_PRICES] = itemData?.prices?.map((_ele: any) => ({
  //   amount: parseFloat(_ele.best_price?.value),
  //   currency: _ele.best_price?.currency?.value,
  // }));
  itemUnitParams[keyNames.KEY_ITEM_COST_PRICES] = itemData?.prices?.map((_ele: any) => ({
    amount: parseFloat(_ele.cost_price?.value),
    currency: _ele.cost_price?.currency?.value,
  }));
  itemUnitParams[keyNames.KEY_ITEM_PURCHASE_PRICES] = itemData?.prices?.map((_ele: any) => ({
    amount: parseFloat(_ele.purchase_price?.value),
    currency: _ele.purchase_price?.currency?.value,
  }));

  itemUnitParams[keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK] = itemData?.openStock ?? 0;
  itemUnitParams[keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT] =
    itemData?.replenishmentPoint ?? 0;
  itemUnitParams[keyNames.KEY_ITEM_INVENTORY_SKU] = itemData?.sku ?? [];

  return itemUnitParams;
};

const getPrepaidItemParams = (prepaidItem: any, prepaidType: any) => {
  let itemUnitParams: any = {};

  itemUnitParams[keyNames.KEY_ITEM_CODE] = prepaidItem[keyNames.KEY_ITEM_CODE];
  itemUnitParams[keyNames.KEY_ITEM_NAME] = prepaidItem[keyNames.KEY_ITEM_NAME];
  itemUnitParams[keyNames.KEY_ITEM_ACTIVE] =
    prepaidItem[keyNames.KEY_ITEM_ACTIVE] === 'y' ? true : false;
  itemUnitParams[keyNames.KEY_ITEM_PREPAID_DISCOUNT] = parseInt(
    prepaidItem[keyNames.KEY_ITEM_PREPAID_DISCOUNT] || 0,
  );
  itemUnitParams[keyNames.KEY_ITEM_DES] = prepaidItem[keyNames.KEY_ITEM_DES];
  itemUnitParams[keyNames.KEY_ITEM_WARRANTY] = prepaidItem[keyNames.KEY_ITEM_WARRANTY_PERIOD];

  itemUnitParams[keyNames.KEY_ITEM_PREPAID_AMOUNT] = prepaidItem[
    keyNames.KEY_ITEM_PREPAID_AMOUNT
  ].map((_ele: any) => ({
    value: parseFloat(_ele.value),
    currency: _ele.currency.value,
  }));

  if (prepaidType === PRODUCT_PREPAID_TYPE_PER_ITEM) {
    itemUnitParams[keyNames.KEY_ITEM_PREPAID_PER_ITEM] = parseInt(
      prepaidItem[keyNames.KEY_ITEM_PREPAID_PER_ITEM] || 0,
    );
    itemUnitParams.matchItemUnit = {
      id: prepaidItem[keyNames.KEY_ITEM_MATCH_ITEM].id,
      name: prepaidItem[keyNames.KEY_ITEM_MATCH_ITEM].name,
    };
  }

  return itemUnitParams;
};
