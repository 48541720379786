import { IFieldConfig } from '@base/types/interfaces/common';
import * as keyNames from '@product/item/config/key-names';
import * as commonConfig from '@base/config/view-field';
import * as components from './components';
import * as commonComponents from '@base/config/view-field/components';
import { numberFormat } from '@base/utils/helpers';
import {
  DIMENSION_UNIT_OPTIONS,
  PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS,
  PRODUCT_ITEM_TYPE_OPTIONS,
} from '@product/main/config/constants';

const ViewConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_ITEM_PRODUCT]: {
    component: components.ProductView,
    schema: `prod {
      id
      name
      type
      group {
        id
        name
      }
      canBeSold
    }`,
    getValue: (value: any) => {
      return value?.prod;
    },
  },
  [keyNames.KEY_ITEM_UNIT]: {
    schema: `unit {
      id
      name
    }`,
  },
  [keyNames.KEY_ITEM_NAME]: {
    schema: keyNames.KEY_ITEM_NAME,
  },
  [keyNames.KEY_ITEM_ACTIVE]: {
    ...commonConfig?.default[keyNames.KEY_ITEM_ACTIVE],
  },
  [keyNames.KEY_ITEM_UNIT_VALUE]: {
    schema: `unitVal {
      id
      name
      qty
    }`,
  },
  [keyNames.KEY_ITEM_UNIT_VAL_QTY]: {
    schema: `unitVal {
      id
      name
      qty
    }`,
  },
  [keyNames.KEY_ITEM_ATTR_VALUES]: {
    schema: `attrValues {
      id
      name
      attr {
        id
        name
      }
    }`,
  },
  [keyNames.KEY_ITEM_WEIGHT]: {
    schema: `weight {
      unit
      val
    }`,
    component: components.WeightView,
  },
  [keyNames.KEY_VIEW_ITEM_WARRANTY]: {
    schema: `warrantyPeriod {
      unit
      period
    }`,
    component: components.WarrantyView,
  },
  [keyNames.KEY_ITEM_DIMENSION]: {
    component: components.DimensionView,
    schema: `dimension {
      unit
      val {
        x
        y
        z
      }
    }`,
    getValueView: (value: any) => {
      return value?.val
        ? `${value?.val?.x} x ${value?.val?.y} x ${value?.val?.z} ${
            DIMENSION_UNIT_OPTIONS?.find((v: any) => v.value == value?.unit)?.label
          }`
        : '';
    },
    getValueEdit: (value: any) => {
      return value
        ? {
            unit: value?.unit,
            x: value?.val?.x,
            y: value?.val?.y,
            z: value?.val?.z,
          }
        : '';
    },
    getMutationValue: (value: any) => {
      return {
        dimension: {
          unit: value?.unit,
          val: { x: parseInt(value?.x), y: parseInt(value?.y), z: parseInt(value?.z) },
        },
      };
    },
    getChangedValue: (value: any) => {
      return {
        unit: value?.unit,
        val: {
          x: value?.x,
          y: value?.y,
          z: value?.z,
        },
      };
    },
  },
  [keyNames.KEY_ITEM_CATEGORY]: {
    schema: keyNames.KEY_ITEM_CATEGORY,
    getValueView: (value: any) => {
      return PRODUCT_ITEM_INVENTORY_TYPE_OPTIONS?.find((v: any) => v.value === value)?.label ?? '';
    },
  },
  [keyNames.KEY_ITEM_TYPE]: {
    schema: keyNames.KEY_ITEM_TYPE,
    getValueView: (value: any) => {
      return PRODUCT_ITEM_TYPE_OPTIONS?.find((v: any) => v.value === value)?.label ?? '';
    },
  },
  [keyNames.KEY_ITEM_COUNTRY_ORIGIN]: {
    schema: keyNames.KEY_ITEM_COUNTRY_ORIGIN,
    component: components.CountryOriginView,
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_ITEM_COUNTRY_ORIGIN]: value?.value,
      };
    },
    getChangedValue: (value: any) => {
      return value?.value;
    },
    getValueEdit: (value: any) => {
      return { value: value, label: '' };
    },
  },
  [keyNames.KEY_ITEM_ORIGIN_BARCODE]: {
    component: components.ItemBarCodeView,
    schema: keyNames.KEY_ITEM_ORIGIN_BARCODE,
  },
  [keyNames.KEY_ITEM_RETURNABLE]: {
    schema: ``,
    component: commonComponents.Switch,
  },
  [keyNames.KEY_ITEM_VENDORS]: {
    schema: `vendors {
      id
      code
      name
    }`,
    component: components.CustomerView,
    componentProps: {
      single: false,
    },
    getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        vendors:
          value && value?.length > 0
            ? value?.map((item: any) => ({ linkId: item.id, linkName: item.name }))
            : [],
      };
    },
    getChangedValue: (value: any) => {
      return value;
    },
  },
  [keyNames.KEY_ITEM_MANUFACTURERS]: {
    schema: `manufacturers {
      id
      code
      name
    }`,
    component: components.CustomerView,
    componentProps: {
      single: true,
      category: 'account',
      type: 'vendor',
    },
    getValueView: (value: any) => {
      return {
        id: '',
        name: value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '',
      };
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        manufacturers: value ? [{ id: value.id, name: value.name }] : [],
      };
      // return {
      //   manufacturers:
      //     value && value?.length > 0
      //       ? value?.map((item: any) => ({ id: item.id, name: item.name }))
      //       : [],
      // };
    },
    getChangedValue: (value: any) => {
      return [value];
    },
  },
  [keyNames.KEY_ITEM_MANUFACTURE_DATE]: {
    component: commonComponents.DateView,
  },
  [keyNames.KEY_ITEM_EXPIRATION_DATE]: {
    component: commonComponents.DateView,
  },
  [keyNames.KEY_ITEM_BEST_BEFORE_DATE]: {
    component: commonComponents.DateView,
  },
  [keyNames.KEY_ITEM_UNIT_PRICES]: {
    schema: `prices {
      amount
      currency
    }
    basePrices {
      amount
      currency
    }
    costPrices {
      amount
      currency
    }`,
  },
  [keyNames.KEY_ITEM_BASE_PRICES]: {
    schema: `basePrices {
      amount
      currency
    }`,
  },
  [keyNames.KEY_ITEM_COST_PRICES]: {
    schema: `costPrices {
      amount
      currency
    }`,
  },
  [keyNames.KEY_ITEM_BEST_PRICES]: {
    schema: `bestPrices {
      amount
      currency
    }`,
  },
  [keyNames.KEY_ITEM_PURCHASE_PRICES]: {
    schema: `purchasePrices {
      amount
      currency
    }`,
  },
  [keyNames.KEY_ITEM_DESCRIPTION]: {
    ...commonConfig?.default?.[keyNames.KEY_ITEM_DESCRIPTION],
    showFullRow: true,
  },
  [keyNames.KEY_ITEM_IMAGE]: {
    schema: `images {
      id
      name
      orgName
    }`,
  },
  // [keyNames.KEY_ITEM_PREPAID_AMOUNT]: {
  //   schema: `prepaidAmount {
  //     amount
  //     currency
  //   }`,
  // },
  [keyNames.KEY_ITEM_BRAND]: {},
  [keyNames.KEY_ITEM_ASSIGN_TO]: {
    component: commonComponents?.AssignRepView,
    componentProps: {
      placement: 'top',
    },
    schema: `assignTo {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => ({ ...item, value: item.id, label: item.name }))
        : [];
    },
    getMutationValue: (value: any) => {
      return {
        assignTo:
          value && value?.length > 0
            ? value?.map((item: any) => ({ user: { id: item.id, name: item.name }, group: null }))
            : [],
      };
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK]: {
    schema: keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
    component: commonComponents.TextView,
    componentProps: {
      type: 'decimal',
      digits: 0,
    },
    getValueView: (value: any) => {
      return numberFormat(value, { noOfDecimal: 0 });
    },
    getMutationValue: (value: any) => {
      return parseInt(value);
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_SKU]: {
    schema: keyNames.KEY_ITEM_INVENTORY_SKU,
    component: components.ItemSkuView,
  },
  [keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT]: {
    schema: keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
    component: commonComponents.TextView,
    componentProps: {
      type: 'decimal',
      digits: 0,
    },
    getValueView: (value: any) => {
      return numberFormat(value, { noOfDecimal: 0 });
    },
    getMutationValue: (value: any) => {
      return parseInt(value);
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND]: {
    schema: keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND,
    component: commonComponents.TextView,
    componentProps: {
      type: 'decimal',
      digits: 0,
    },
    getValueView: (value: any) => {
      return numberFormat(value, { noOfDecimal: 0 });
    },
    getMutationValue: (value: any) => {
      return parseInt(value);
    },
  },
};
export default ViewConfig;

export const InventoryConfig: IFieldConfig = {
  ...commonConfig?.default,
  [keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK]: {
    schema: keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
    component: commonComponents.TextView,
    componentProps: {
      type: 'decimal',
      digits: 0,
    },
    getValueView: (value: any) => {
      return numberFormat(value, { noOfDecimal: 0 });
    },
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
    getMutationValue: (value: any) => {
      return parseInt(value);
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_SKU]: {
    schema: keyNames.KEY_ITEM_INVENTORY_SKU,
    component: components.ItemSkuView,
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT]: {
    schema: keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
    component: commonComponents.TextView,
    componentProps: {
      type: 'decimal',
      digits: 0,
    },
    getValueView: (value: any) => {
      return numberFormat(value, { noOfDecimal: 0 });
    },
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_RESERVE_METHOD]: {
    schema: ``,
    getValueView: (value: any) => {
      return value?.label;
    },
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KET_ITEM_INVENTORY_TRACEABILITY]: {
    schema: ``,
    component: commonComponents.Switch,
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
    getRecoilStateValue: (value: any) => {
      return value || false;
    },
  },
  [keyNames.KET_ITEM_INVENTORY_TRACEABILITY_NO]: {
    schema: ``,
  },
  [keyNames.KEY_ITEM_INVENTORY_TRACKING_TYPE]: {
    schema: ``,
    component: components.TrackingTypeView,
    getValueEdit: (value: any) => {
      return value ?? '';
    },
    getMutationValue: (value: any) => {
      return value ?? '';
    },
    getChangedValue: (value: any) => {
      return value ?? '';
    },
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_MANUFACTURE_DATE]: {
    schema: ``,
    component: commonComponents.DateView,
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_EXPIRATION_DATE]: {
    schema: ``,
    component: commonComponents.DateView,
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_BATCH_NO]: {
    schema: ``,
    component: commonComponents.TextView,
    getValue: (value: any) => {
      return value?.[keyNames.KEY_ITEM_INVENTORY_BATCH_NO] ?? '';
    },
    getExtraMutationParam: (viewData: any) => {
      return {
        target: { id: viewData?.target?.id, name: viewData?.target?.name },
        type: viewData?.type,
      };
    },
  },
  [keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND]: {
    schema: keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND,
  },
};
