import React, { useEffect, useState } from 'react';
import UploaderImage from '@base/components/form/image-uploader';
import styled from '@emotion/styled';
import { Save, Trash2 } from 'react-feather';
import classnames from 'classnames';
import { generateUUID } from '@base/utils/helpers';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { gql } from 'graphql-request';
import { toast } from 'react-toastify';
import { MENU_PRODUCT_COMPONENT, MENU_PRODUCT_ITEM } from '@base/config/menus';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';
import ImageView from './image';
import { isString } from 'lodash';
import { Button } from '@base/components/form';
import { useQueryClient } from '@tanstack/react-query';

const Container = styled.div`
  padding-top: 15px;
  .justify-content-space-between {
    justify-content: space-between;
  }
`;

const ImageWrap = styled.div`
  // position: relative;
  // border: 1px solid $border-color;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-img-actions {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: rgba($color: #000, $alpha: 0.6);

    .btn {
      flex-grow: 1;
      color: $white;
    }
  }

  .remove-cover {
    display: none;
    position: absolute;
    background: #101010bf;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;

    svg {
      width: 16px;
    }
  }

  &:hover {
    .remove-cover {
      display: flex;
    }
  }
`;

const MainImage = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  width: 100%;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SubWarpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ThumbList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;

  .edit-product-pic-thumb-list-item {
    display: inline-block;
    margin: 0 0.25rem;
    &.active {
      .edit-product-pic-thumb {
        border-width: 2px;
        border-color: var(--primary-color);
      }
    }
  }

  .edit-product-pic-thumb {
    width: 150px;
    height: 150px;
    padding: 0;
    background: none;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.no-pic {
      background-color: $light;
    }
  }

  .sub-warpper-upload-image {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
  }
`;

const IMAGE_TYPE_NONE = 'IMAGE_TYPE_NONE';
const IMAGE_TYPE_PRIMARY = 'IMAGE_TYPE_PRIMARY';
const IMAGE_TYPE_ADDITIONAL = 'IMAGE_TYPE_ADDITIONAL';

const ITEM_UNIT_UPDATE_IMAGES = gql`
  mutation m($item: UpdateData!) {
    product_updateItem(item: $item) {
      id
    }
  }
`;

const COMPONENT_UPDATE_IMAGES = gql`
  mutation m($component: UpdateData!) {
    product_updateComponent(component: $component) {
      id
    }
  }
`;

const Edit: React.FC = (props: any) => {
  const { value, onSave: callbackOnSave, data: updateData, menuSource, onClose } = props;

  const queryClient = useQueryClient();

  const [mainImage, setMainImage] = useState<any>(null);
  const [images, setImages] = useState<any[]>([]);
  const [error, setError] = useState(true);

  const [curFileIndex, setCurFileIndex] = useState<number>(-1);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState<number>(-1);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [needUpload, setNeedUpload] = useState<any[]>([]);

  useEffect(() => {
    if (value && value?.length > 0) {
      setError(false);
      setMainImage({ ...value?.[0], idLocal: generateUUID() });

      const _images = value.slice(1);
      let subImages: any[] = [];
      _images?.map((image: any) => {
        subImages.push({ ...image, idLocal: generateUUID() });
      });
      setImages(subImages);
    }
  }, [value]);

  const handlerOnChangeMainImage = (images: any) => {
    let newImage: any = images?.[0] ?? null;
    newImage.idLocal = generateUUID();
    setMainImage(newImage);
    setError(false);
  };

  const handlerOnChangeSubImages = (subImages: any) => {
    let newImages: any[] = [];
    subImages?.map((item: any) => {
      let newImage: any = item;
      newImage.idLocal = generateUUID();
      newImages.push(newImage);
    });
    setImages(images.concat(newImages));
  };

  const handlerRemove = (idLocal: string) => {
    if (mainImage?.idLocal === idLocal) {
      setMainImage(null);
      setError(true);
    } else {
      let _newImages = [...images];
      const _imageIndex = images?.findIndex((e: any) => e.idLocal === idLocal);
      if (_imageIndex >= 0) {
        _newImages.splice(_imageIndex, 1);
      }
      setImages(_newImages);
    }
  };

  const mUpdateItemUnitImages: any = useMutationPost<BaseResponse<string>>(
    ITEM_UNIT_UPDATE_IMAGES,
    'product_updateItem',
    {
      onSuccess: (res: any) => {
        toast.success('Updated successfully!');
        setTimeout(() => {
          queryClient.invalidateQueries(['product_item']);
        }, 500);
        onClose && onClose();
      },
    },
  );

  const mUpdateComponentImages: any = useMutationPost<BaseResponse<string>>(
    COMPONENT_UPDATE_IMAGES,
    'product_updateComponent',
    {
      onSuccess: (res: any) => {
        toast.success('Updated successfully!');
      },
    },
  );

  // upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onMutate: (data: any) => {},
      onSuccess: (data: any, variables: any, context: any) => {},
      onError: (error: any, variables: any, context: any) => {
        toast.error('Uploaded failed: ' + JSON.parse(error).message);
      },
    },
    // (pEvent: ProgressEvent, partsNumber: number, partIndex: number, uploadId?: string) =>
    // uploadProgressHandler(pEvent, partsNumber, partIndex, uploadId),
  );

  // upload success
  useEffect(() => {
    if (mUpload.isSuccess) {
      const newUploadedFiles = [...uploadedFiles];
      const selectedFiles = needUpload;
      const newUpload = {
        objectId: mUpload.data.id, //upload id
        objectUrl: mUpload.data.url, //download url
        name: selectedFiles[curFileIndex]?.image?.name,
        size: selectedFiles[curFileIndex]?.image?.size,
        contentType: selectedFiles[curFileIndex]?.image?.type,
        type: selectedFiles[curFileIndex]?.type,
      };
      newUploadedFiles.push(newUpload);
      setUploadedFiles(newUploadedFiles);

      // next file uploading
      setLastUploadedFileIndex(curFileIndex);
    }
  }, [mUpload.isSuccess]);

  useEffect(() => {
    if (needUpload?.length > 0) {
      if (curFileIndex === -1) {
        setCurFileIndex(lastUploadedFileIndex === -1 ? 0 : lastUploadedFileIndex + 1);
      }
    }
  }, [needUpload]);

  useEffect(() => {
    if (curFileIndex !== -1) {
      mUpload.mutate(needUpload[curFileIndex]?.image);
    }
  }, [curFileIndex]);

  // next file - last file
  useEffect(() => {
    if (lastUploadedFileIndex === -1) {
      return;
    }
    const selectedFiles = needUpload;
    const isLastFile = lastUploadedFileIndex === selectedFiles.length - 1;
    if (isLastFile) {
      setCurFileIndex(-1);

      // start save to db
      let imagesData: any[] = [];

      if (isString(mainImage?.id)) {
        delete mainImage.idLocal;
        imagesData.unshift({ ...mainImage, type: IMAGE_TYPE_PRIMARY });
      }

      images?.map((image: any) => {
        if (isString(image?.id)) {
          delete image.idLocal;
          imagesData.push({ ...image, type: IMAGE_TYPE_ADDITIONAL });
        }
      });

      uploadedFiles?.map((image: any) => {
        if (image?.type === IMAGE_TYPE_PRIMARY) {
          imagesData.unshift({
            id: image?.objectId,
            name: image?.objectUrl,
            orgName: image?.name,
            type: IMAGE_TYPE_PRIMARY,
          });
        } else {
          imagesData.push({
            id: image?.objectId,
            name: image?.objectUrl,
            orgName: image?.name,
            type: IMAGE_TYPE_ADDITIONAL,
          });
        }
      });

      if (menuSource === MENU_PRODUCT_ITEM) {
        mUpdateItemUnitImages.mutate({
          item: {
            id: updateData.id,
            images: imagesData,
          },
        });
      }
    } else {
      // next uploaded
      const nextFileIndex = curFileIndex + 1;
      setCurFileIndex(nextFileIndex);
    }
  }, [lastUploadedFileIndex]);

  const onSave = () => {
    if (!mainImage || mainImage === null) {
      setError(true);
    } else {
      // console.log('onSave', mainImage, images);

      let uploadImages: any[] = [];
      if (!isString(mainImage?.id)) {
        uploadImages.push({ type: IMAGE_TYPE_PRIMARY, image: mainImage });
      }
      images?.map((image: any) => {
        if (!isString(image?.id)) {
          uploadImages.push({ type: IMAGE_TYPE_ADDITIONAL, image: image });
        }
      });
      setNeedUpload(uploadImages);
    }
  };

  const RenderImageWrapper = (image: any, className = '') => {
    return (
      <ImageWrap className={classnames('product-img-wrap', className)}>
        {image ? (
          <div className="image-container">
            <div
              className="remove-cover"
              onClick={() => {
                handlerRemove(image?.idLocal);
              }}
            >
              <Trash2 />
            </div>
            {isString(image?.id) ? (
              <ImageView className="rounded" imgSrc={image?.url ?? ''} />
            ) : (
              <img src={window.URL.createObjectURL(image) ?? ''} className="rounded" alt="" />
            )}
          </div>
        ) : (
          <div className="wd-300 pd-t-100">
            <UploaderImage
              type="img"
              onChange={handlerOnChangeMainImage}
              value={image ? [image] : []}
              preview={false}
            />
          </div>
        )}
      </ImageWrap>
    );
  };

  return (
    <Container className="image-edit">
      <div className="form-group">
        <div className="d-flex justify-content-space-between">
          <label className="form-item-title d-block">
            Main Image<span className="tx-danger mg-l-5">*</span>
          </label>
          <Button
            className="btn btn-link"
            color={'null'}
            onClick={onSave}
            disabled={error}
            loading={mUpload.isLoading || mUpdateItemUnitImages.isLoading}
          >
            <Save /> Save
          </Button>
        </div>
        <MainImage className="mg-b-20">{RenderImageWrapper(mainImage ?? null)}</MainImage>
      </div>
      <div className="form-group">
        <label className="form-item-title d-block">Additional Image</label>
        <SubWarpper>
          <ThumbList className="edit-product-pic-thumb-list">
            {images?.length > 0 &&
              images?.map((image: any, index: number) => {
                return (
                  <li key={index} className={classnames('edit-product-pic-thumb-list-item', {})}>
                    {RenderImageWrapper(image ?? null, 'edit-product-pic-thumb')}
                  </li>
                );
              })}
            <li
              key={images?.length + 1}
              className={classnames('edit-product-pic-thumb-list-item', {})}
            >
              <div className="sub-warpper-upload-image">
                <UploaderImage
                  type="img"
                  isSingle={false}
                  preview={false}
                  onChange={handlerOnChangeSubImages}
                />
              </div>
            </li>
          </ThumbList>
        </SubWarpper>
        {/* <div className="tx-orange">You can change the order by drag and drop.</div> */}
      </div>
    </Container>
  );
};

export default Edit;
