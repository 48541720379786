import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { deviceAtom, pageDataByMenuAtom } from '@base/recoil/atoms/app';
import { MENU_PRODUCT_TREE } from '@base/config/menus';
import ProductTree from '@product/group/containers/product-tree';
import { PAGING_ITEMS_PER_PAGE } from '@customer/customer/config/constants';

const PageBody: React.FC<any> = (props) => {
  const {
    isSplitMode,
    category, //router category
    isFetching,
    fields,
    itemsList,
    paging,
    //isPreviousData,
    selectedIds,
    onChecked,
  } = props;

  const { t } = useTranslation();
  //state
  const [pageData, setPageData] = useRecoilState(pageDataByMenuAtom(MENU_PRODUCT_TREE));
  let { listType } = pageData;

  const navigate = useNavigate();

  const pagingProps = {
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: paging?.itemPerPage || PAGING_ITEMS_PER_PAGE,
    nextPage: paging?.nextPage || null,
    previousPage: paging?.previousPage || null,
    onChange: (page: number, size: number) => {
      const newListFilter = {
        ...pageData,
        filter: {
          ...pageData.filter,
          paging: { page, size },
        },
      };
      setPageData(newListFilter);
    },
  };

  const nodeSelect = (node: any) => {
    if (node && node?.nodeType != 'GROUP') {
      return navigate(`/product/group/${node?.nodeType?.toLowerCase()}/${node?.id}`);
    }
  };

  // body
  const ListBodyMemo = useMemo(() => {
    return (
      <div className="pos-relative pd-x-15">
        <ProductTree treeId="product-group-list" onSelect={nodeSelect} />
        {/* <ListPagination isSplitMode={isSplitMode} type={'full'} {...pagingProps} /> */}
      </div>
    );
  }, [itemsList, isSplitMode, listType]);

  //main
  return <>{ListBodyMemo}</>;
};

export default PageBody;
