import { generateUUID } from '@base/utils/helpers';
import * as keyNames from '@product/item/config/key-names';

export const summaryLayout = {
  name: 'basic',
  keyName: 'basic',
  dataType: 'section',
  children: [
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_NAME,
      languageKey: `Item Name`,
      dataType: 'text',
      showInView: true,
      showInList: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_UNIT,
      languageKey: `Base Unit`,
      dataType: 'base_unit',
      showInView: true,
      userPermission: { isEdit: false, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_UNIT_VALUE,
      languageKey: `Unit Name`,
      dataType: 'unit_value',
      showInView: true,
      userPermission: { isEdit: false, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_ATTR_VALUES,
      languageKey: `Attributes`,
      dataType: 'attr_values',
      showInView: true,
      userPermission: { isEdit: false, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_PRODUCT,
      languageKey: `Product Name`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_CODE,
      languageKey: `Item ID`,
      dataType: 'item_code',
      showInView: true,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_TYPE,
      languageKey: `Item Type`,
      dataType: 'item_type',
      showInView: true,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_CATEGORY,
      languageKey: `Inventory Type`,
      dataType: 'item_category',
      showInView: true,
      showInList: true,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_VIEW_ITEM_PREPAID_TYPE,
    //   languageKey: `Prepaid Type`,
    //   dataType: 'item_prepaid_type',
    //   showInView: true,
    //   userPermission: { isEdit: false, isShow: true, isSortable: true },
    // },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_SALES_COMMISSION,
    //   languageKey: `Sales Commission`,
    //   dataType: 'sales_commission',
    //   showInView: true,
    //   userPermission: { isEdit: true, isShow: true, isSortable: true },
    // },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_SKU,
      languageKey: `SKU`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_ACTIVE,
      languageKey: `Active`,
      dataType: 'switch',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_IMAGE,
      languageKey: `crm_product_item_${keyNames.KEY_ITEM_IMAGE}`,
      dataType: 'images',
      showInView: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_BASE_PRICES,
      languageKey: `crm_product_item_${keyNames.KEY_ITEM_BASE_PRICES}`,
      dataType: 'base_prices',
      showInView: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_COST_PRICES,
      languageKey: `crm_product_item_${keyNames.KEY_ITEM_COST_PRICES}`,
      dataType: 'cost_prices',
      showInView: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_BEST_PRICES,
      languageKey: `crm_product_item_${keyNames.KEY_ITEM_BEST_PRICES}`,
      dataType: 'best_prices',
      showInView: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_PURCHASE_PRICES,
      languageKey: `crm_product_item_${keyNames.KEY_ITEM_PURCHASE_PRICES}`,
      dataType: 'purchase_prices',
      showInView: true,
      userPermission: { isEdit: true, isShow: false, isSortable: true },
    },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_PREPAID_AMOUNT,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_PREPAID_AMOUNT}`,
    //   dataType: 'prepaid_amount',
    //   showInView: false,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_PREPAID_PER_ITEM,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_PREPAID_PER_ITEM}`,
    //   dataType: 'prepaid_per_item',
    //   showInView: false,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_PREPAID_DISCOUNT,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_PREPAID_DISCOUNT}`,
    //   dataType: 'prepaid_discount',
    //   showInView: false,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_MATCH_ITEM_UNIT_ID,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_MATCH_ITEM_UNIT_ID}`,
    //   dataType: 'match_item_unit',
    //   showInView: false,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
      languageKey: `Open Stock`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND,
      languageKey: `Stock On Hand`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
      languageKey: `Replenishment Point`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
  ],
};

export const detailLayout = {
  name: 'more',
  keyName: 'more',
  dataType: 'section',
  children: [
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_DIMENSION,
      languageKey: `Dimension`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_WEIGHT,
      languageKey: `Weight`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_VIEW_ITEM_WARRANTY,
      languageKey: `Warranty Period`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_COUNTRY_ORIGIN,
      languageKey: `Country Origin`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_BRAND,
      languageKey: `Brand`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_VENDORS,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_VENDORS}`,
    //   dataType: 'text',
    //   showInView: true,
    //   userPermission: { isEdit: true, isShow: true, isSortable: true },
    // },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_MANUFACTURERS,
      languageKey: `Manufacturer`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_MANUFACTURE_DATE,
      languageKey: `Manufacture Date`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_EXPIRATION_DATE,
      languageKey: `Experation Date`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_BEST_BEFORE_DATE,
      languageKey: `Best Before Date`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_RETURNABLE,
    //   languageKey: `Returnable Item`,
    //   dataType: 'text',
    //   showInView: true,
    //   userPermission: { isEdit: true, isShow: true, isSortable: true },
    // },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_ORIGIN_BARCODE,
      languageKey: `Original Barcode`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_DESCRIPTION,
      languageKey: `Description`,
      dataType: 'text',
      showInView: true,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_USE_SUBSCRIBE,
    //   languageKey: `crm_product_item_${keyNames.KEY_USE_SUBSCRIBE}`,
    //   dataType: 'switch',
    //   showInView: true,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
    // {
    //   id: generateUUID(),
    //   keyName: keyNames.KEY_ITEM_SUB_TPL,
    //   languageKey: `crm_product_item_${keyNames.KEY_ITEM_SUB_TPL}`,
    //   dataType: 'select',
    //   showInView: true,
    //   userPermission: { isEdit: true, isShow: false, isSortable: true },
    // },
  ],
};

export const inventoryLayout = {
  name: 'inventory',
  keyName: 'inventory',
  dataType: 'section',
  children: [
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
      languageKey: `Open Stock`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_SKU,
      languageKey: `SKU`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
      languageKey: `Replenish Point`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_RESERVE_METHOD,
      languageKey: `Batch Stock Reserve Method`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KET_ITEM_INVENTORY_TRACEABILITY,
      languageKey: `Traceability`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_TRACKING_TYPE,
      languageKey: `Tracking Type`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: false, isShow: true, isSortable: true },
    },
    {
      id: generateUUID(),
      keyName: keyNames.KEY_ITEM_INVENTORY_BATCH_NO,
      languageKey: `Lot(Batch) No`,
      dataType: 'text',
      showInView: true,
      showInList: false,
      userPermission: { isEdit: true, isShow: true, isSortable: true },
    },
  ],
};

export const listLayout = [
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_IMAGE,
    languageKey: `Images`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_NAME,
    languageKey: `Item Name`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_INVENTORY_SKU,
    languageKey: `SKU`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_PRODUCT,
    languageKey: `Product Name`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_CODE,
    languageKey: `Item ID`,
    dataType: 'item_code',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_TYPE,
    languageKey: `Item Type`,
    dataType: 'item_type',
    showInView: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_CATEGORY,
    languageKey: `Inventory Type`,
    dataType: 'item_category',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_UNIT_VALUE,
    languageKey: `Unit Name`,
    dataType: 'unit_value',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_UNIT_VAL_QTY,
    languageKey: `Unit Qty`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_ATTR_VALUES,
    languageKey: `Attributes`,
    dataType: 'attr_values',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: false, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_UNIT_PRICES,
    languageKey: `Rate`,
    dataType: 'item_price',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_ACTIVE,
    languageKey: `Active`,
    dataType: 'switch',
    showInView: true,
    showInList: true,
    defaultViewInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_ASSIGN_TO,
    languageKey: `Assigned Rep`,
    dataType: 'assign_to',
    showInView: true,
    showInList: true,
    defaultViewInList: false,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  // {
  //   id: generateUUID(),
  //   keyName: keyNames.KET_ITEM_INVENTORY_TRACEABILITY_NO,
  //   languageKey: `Traceability No`,
  //   dataType: 'text',
  //   showInView: true,
  //   showInList: true,
  //   userPermission: { isEdit: false, isShow: true, isSortable: true },
  // },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
    languageKey: `Opening Stock`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND,
    languageKey: `Stock on Hand`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
    languageKey: `Replenishment Point`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: false, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_DIMENSION,
    languageKey: `Dimension`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_VIEW_ITEM_WARRANTY,
    languageKey: `Warranty Period`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_COUNTRY_ORIGIN,
    languageKey: `Country of Origin`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_BRAND,
    languageKey: `Brand`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_MANUFACTURERS,
    languageKey: `Manufacturer`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_MANUFACTURE_DATE,
    languageKey: `Manufacture Date`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  // {
  //   id: generateUUID(),
  //   keyName: keyNames.KEY_ITEM_RETURNABLE,
  //   languageKey: `Returnable Item`,
  //   dataType: 'text',
  //   showInView: true,
  //   showInList: true,
  //   userPermission: { isEdit: true, isShow: true, isSortable: true },
  // },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_EXPIRATION_DATE,
    languageKey: `Experation Date`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: keyNames.KEY_ITEM_ORIGIN_BARCODE,
    languageKey: `Barcode`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: 'createdBy',
    languageKey: `Created By`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: 'createdAt',
    languageKey: `Created Time`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: 'updatedBy',
    languageKey: `product_product_field_more_updatedby`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
  {
    id: generateUUID(),
    keyName: 'updatedAt',
    languageKey: `product_product_field_more_updatedat`,
    dataType: 'text',
    showInView: true,
    showInList: true,
    userPermission: { isEdit: true, isShow: true, isSortable: true },
  },
];
