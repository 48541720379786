import React, { useState } from 'react';
import { CUSTOMER_CATEGORY_CONTACT } from '@customer/customer/config/constants';
import { CustomerCategory } from '@customer/customer/types/enums/customer-enums';
import { CUSTOMER_LIST_FOR_SELECT } from '@customer/customer/services/graphql';
import usePost from '@base/hooks/usePost';
import { AssignCustomerContainer } from '@base/containers';
import { IShortCustomer } from '@customer/customer/types/interface/customer';
import { ResponsePaging } from '@base/types/interfaces/response';

interface IRelatedCustomerProps {
  placement?: string;
  sourceId: string;
  sourceMenu?: string;
  menuTab?: string;
  menuType?: string;
  readOnly?: boolean;
}

/**
 *
 * @param {*} props
 * @returns
 */
const RelatedCustomer: React.FC<IRelatedCustomerProps> = (props: IRelatedCustomerProps) => {
  const {
    placement,
    sourceId,
    sourceMenu,
    menuTab, //on url
    menuType, //for filter 'account', 'contact'
    readOnly = true,
  } = props;

  // state
  const [isAssigning, setIsAssigning] = useState<boolean>(false);

  // get related customers
  const paramsGet = {
    filter: {
      filters: {
        category: CustomerCategory[CUSTOMER_CATEGORY_CONTACT],
        contactType: ['CONTACT_TYPE_EMPLOYEE'],
      },
      paging: {
        page: 1,
        size: 3,
      },
    },
  };
  const {
    data: customers,
    isLoading,
    refetch,
  } = usePost<ResponsePaging<IShortCustomer>>(
    ['customer_customersSelect'],
    CUSTOMER_LIST_FOR_SELECT,
    paramsGet,
  );

  // console.log('Related-customer', customers);

  //TODO: assign contact to customer
  const onAssignCustomerContact = (contact: any) => {
    //check exist before add
    const foundIdx = customers?.results?.findIndex((_ele: any) => _ele.id === contact.id) || -1;
    if (foundIdx > -1) return;
  };

  //delete customer contact
  const onDeleteCustomerContact = (contact: any, cb: any) => {
    //remove
  };

  return (
    <AssignCustomerContainer
      items={customers?.results || []}
      placement={placement}
      isLoading={isLoading}
      menuSourceName={sourceMenu}
      menuTab={menuTab}
      menuType={menuType}
      onAssign={onAssignCustomerContact}
      onDelete={onDeleteCustomerContact}
      readOnly={readOnly}
    />
  );
};

RelatedCustomer.defaultProps = {
  placement: 'auto',
  sourceId: '',
  sourceMenu: '',
  menuTab: '',
  menuType: '',
};

export default RelatedCustomer;
