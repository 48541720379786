import React, { useEffect, useMemo, useState } from 'react';
import { PRODUCT_MENUS } from '@base/config/route-menus';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, pageDataByMenuAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import { default as viewConfig } from '@product/item/config/view-field';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@product/item/config/display-layout';
import { useTranslation } from 'react-i18next';
import ViewLeft from '@product/item/layouts/desktop/layout1/view/view-left';
import ViewRight from '@product/item/layouts/desktop/layout1/view/view-right';
import { useNavigate, useParams } from 'react-router-dom';
import { summaryLayout, detailLayout } from '@product/item/utils/fake-layout';
import Title from '@base/containers/view-field/title';
import {
  KEY_ITEM_IMAGE,
  KEY_ITEM_RETURNABLE,
  KEY_ITEM_SALES_COMMISSION,
  KEY_ITEM_DESCRIPTION,
  KEY_VIEW_ITEM_PREPAID_TYPE,
  KEY_ITEM_TYPE,
  KEY_ITEM_CATEGORY,
} from '@product/item/config/key-names';

// TABS
// import { TABS } from './tabs';
import { ITab } from '@base/types/interfaces/app';
import Timeline from '@base/containers/timeline';
// import Transaction from '@demo-page/product/desktop/theme1/view-new/transaction';
import AssociatedItems from '@product/item/containers/associated-items';
// import StockMovement from '@demo-page/product/desktop/theme1/view-new/stockMovement';
// import Subscription from '@product/item/containers/tab-subscription';
// import Purchase from '@demo-page/product/desktop/theme1/view-new/purchase';
import Notes from '@base/containers/note';
// import { default as InventoryInfo } from '@product/item/containers/tab-inventory';
import {
  PRODUCT_ITEM_CATEGORY,
  PRODUCT_ITEM_CATEGORY_INVENTORY,
  PRODUCT_ITEM_CATEGORY_NON_INVENTORY,
  PRODUCT_ITEM_TYPE,
  PRODUCT_ITEM_TYPE_BUNDLE,
  PRODUCT_ITEM_TYPE_ENUM_BUNDLE,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import TabDetail from '@product/item/containers/tab-detail';
import Loading from '@base/components/loading';
import NotFound from '@base/components/errors/notFound';
import {
  MENU_DISCOUNT,
  MENU_PRICELIST,
  MENU_PRODUCT,
  MENU_PRODUCT_COMPONENT,
  MENU_PRODUCT_ITEM,
  MENU_PRODUCT_PRODUCT,
  MENU_PRODUCT_UNIT,
} from '@base/config/menus';
import { productWriteOptionAtom } from '@product/main/recoil/atom/write-atom';
import { SpanLang } from '@base/components';

// Write Product
import WritePageProduct from '@product/product/pages/write';
// Write Item
import WritePageItem from '@product/item/pages/write';

import { PRODUCT_ADD_OPTIONS } from '@product/main/config';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { DELETE_ITEM } from '@product/item/services/graphql';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import PrintPreview from '@product/item/containers/print-preview';

interface IViewProps {
  id?: string;
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device,  init props
 */
const ViewPage: React.FC<IViewProps> = (props: IViewProps) => {
  const { t } = useTranslation();
  const { isSplitMode, id: requestId = '' } = props;

  // recoil
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_PRODUCT_ITEM));
  const pageData = useRecoilValue(pageDataByMenuAtom(MENU_PRODUCT_ITEM));
  const [writeOption, setWriteOption] = useRecoilState(productWriteOptionAtom);

  /** Define */
  const menu = MENU_PRODUCT;
  const category = 'item';

  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();

  const { activeId, activeTab } = pageData;
  let id = requestId ? requestId : activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';

  // state
  const [fieldTop, setFieldTop] = useState(null);
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [showPrint, setShowPrint] = useState(false);

  /** Get data */
  let layoutView: any = {
    data: [],
  };
  layoutView.data.push(summaryLayout);
  layoutView.data.push(detailLayout);

  const ignore: any[] = [];
  const viewSchema = buildViewSchema({
    sections: layoutView?.data,
    configFields: viewConfig,
    ignore: ignore,
  });
  const {
    isLoading,
    error,
    data,
    isFetching: dataLoading,
    isPreviousData,
    refetch,
  } = useGetView<any>({ menu: 'product_item', schemas: viewSchema, params: { id: id } });

  useEffect(() => {
    if (!isLoading && data) {
      // *Add the 'Sales Commission' field to the item when the Supply Method of the product is 'Can be Sold'
      let hideFields: any[] = [];
      if (!data?.prod?.canBeSold) {
        hideFields.push(KEY_ITEM_SALES_COMMISSION);
      }
      // *Show the 'Prepaid Type' field, when the Item Type is 'Prepaid'
      if (data?.itemType?.keyName != PRODUCT_ITEM_TYPE[PRODUCT_ITEM_TYPE_PREPAID]) {
        hideFields.push(KEY_VIEW_ITEM_PREPAID_TYPE);
      }

      const layoutData = mergeLayoutData(layoutView, data, viewConfig, hideFields);

      const basicFields = layoutData?.layout?.data?.[0]?.children;
      const moreFields = layoutData?.layout?.data?.[1]?.children ?? [];
      const imagesField = basicFields?.find((field: any) => field?.keyName === KEY_ITEM_IMAGE);
      setFieldTop(imagesField);

      // detail tab
      let detailFields: any[] = [];
      moreFields?.map((field: any) => {
        let flag = true;
        let _field = { ...field };

        // Show 'Description' only for the Prepaid Type Item
        // if (
        //   field?.keyName === KEY_ITEM_DESCRIPTION &&
        //   data?.itemType?.keyName != PRODUCT_ITEM_TYPE[PRODUCT_ITEM_TYPE_PREPAID]
        // ) {
        //   flag = false;
        // }

        // Show the Returnable fileld automatically if item type is 'Inventory' and 'Supply Method- Can be Sold'is checked. (Only the Sales Return can be generated 'Sales Retun)
        // if (
        //   field?.keyName === KEY_ITEM_RETURNABLE &&
        //   !(
        //     data?.prod?.canBeSold &&
        //     data?.itemCategory?.keyName === PRODUCT_ITEM_CATEGORY[PRODUCT_ITEM_CATEGORY_INVENTORY]
        //   )
        // ) {
        //   flag = false;
        // }

        if (flag) {
          detailFields.push(_field);
        }
      });

      // tabs
      let _tabs: ITab[] = [
        {
          order: 1,
          default: true,
          label: 'Details',
          path: 'detail',
          tabComponent: () => (
            <TabDetail
              fields={detailFields}
              menuSource={MENU_PRODUCT_ITEM}
              menuSourceId={id}
              data={data}
            />
          ),
        },
        {
          order: 2,
          label: 'Timeline',
          path: 'timeline',
          tabComponent: () => <Timeline menuSource={MENU_PRODUCT_ITEM} menuSourceId={id} />,
        },
      ];

      // Transactions tab
      // if (
      //   data?.itemCategory?.keyName === PRODUCT_ITEM_CATEGORY[PRODUCT_ITEM_CATEGORY_NON_INVENTORY]
      // ) {
      //   _tabs.push({
      //     order: 5,
      //     label: 'Transactions',
      //     path: 'transaction',
      //     tabComponent: Transaction,
      //   });
      // }

      // SubscriptionTpl tab
      // if (data?.prod?.canBeSold) {
      //   _tabs.push({
      //     order: 6,
      //     label: 'Subscription',
      //     path: 'subscription',
      //     tabComponent: () => (
      //       <Subscription menuSource={MENU_PRODUCT_ITEM} menuSourceId={id} itemUnit={data} />
      //     ),
      //   });
      // }

      // Purchase tab
      // if (data?.prod?.type?.keyName === PRODUCT_TYPE_PURCHASE) {
      //   _tabs.push({
      //     order: 7,
      //     label: 'Purchase',
      //     path: 'purchase',
      //     tabComponent: Purchase,
      //   });
      // }

      // Associated Items tab
      if (data?.itemType === PRODUCT_ITEM_TYPE_ENUM_BUNDLE) {
        _tabs.push({
          order: 8,
          label: 'Associated Items',
          path: 'associated-items',
          tabComponent: () => <AssociatedItems itemId={id} />,
        });
      }

      // Notes tab
      _tabs.push({
        order: 9,
        label: 'Notes',
        path: 'note',
        tabComponent: Notes,
      });

      setTabs(_tabs);

      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = id;
      newLayoutData.menuSource = MENU_PRODUCT_ITEM;
      newLayoutData.data = data; // need to use for other tabs
      setViewData(newLayoutData);
    }
    return () => { };
  }, [data, isLoading]);

  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_ITEM,
    'product_deleteItem',
    {
      onMutate: () => {
        // setQuickLoading(true);
      },
      onSuccess: (data: any, variables: any, context: any) => {
        // toast.success('Delete items successfully!');
        // setQuickLoading(false);
        setTimeout(() => {
          queryClient.invalidateQueries(['product_items']);
        }, 500);
        return navigate(`/${menu}/${category}`);
      },
      onError: (error: any, variables: any, context: any) => {
        toast.error('Delete item failed: ' + JSON.parse(error).message);
        // setQuickLoading(false);
      },
    },
  );

  const View = useMemo(() => {
    const moreActions = [
      {
        icon: 'Trash',
        label: 'Delete',
        onClick: () => {
          mutationDelete.mutate({ ids: [id] });
        },
      },
    ];

    if (
      data?.[KEY_ITEM_CATEGORY]?.keyName === PRODUCT_ITEM_CATEGORY[PRODUCT_ITEM_CATEGORY_INVENTORY]
    ) {
      moreActions.push({ icon: 'Layers', label: 'Adjustment', onClick: () => { } });
      moreActions.push({ icon: 'Repeat', label: 'Transfer Order', onClick: () => { } });
    }

    let pageProps: IDestopLayout1ViewProps = {
      headerProps: {
        menu: category,
        menus: PRODUCT_MENUS,
        onMenuChange: (newCategory: string) => {
          return navigate(`/${menu}/${newCategory}`);
        },
        onPrint: () => setShowPrint(true),
        moreActions: moreActions,
        // addOptions: PRODUCT_ADD_OPTIONS,
        onNew: (newCategory: string | undefined) => {
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: newCategory === undefined ? MENU_PRODUCT_ITEM : newCategory,
          }));
        },
        splitViewMode: isSplitMode,
        componentLeft: (
          <>
            <Title
              value={data?.name}
              userPermission={{
                isEdit: true,
              }}
              onSave={(params: any) => { }}
              menuSourceId={id}
              menuSource={MENU_PRODUCT_ITEM}
              keyName={'name'}
            />
          </>
        ),
      },
      centerProps: {
        menuSource: MENU_PRODUCT_ITEM,
        menuSourceId: id,
        tabs: tabs,
        showTabs: 3,
        currentTab: currentTab,
      },
      componentLeft: (
        <ViewLeft id={id} category={MENU_PRODUCT_ITEM} data={data} fieldTop={fieldTop} />
      ),
      leftProps: {},
      componentRight: <ViewRight id={id} category={MENU_PRODUCT_ITEM} data={data} />,
      rightProps: {},
    };

    return (
      <>
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="view"
          {...pageProps}
        />

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_ITEM && (
          <WritePageItem
            title={<SpanLang keyLang={'Create Item'} />}
            size="xl"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            product={null}
            menuApi={MENU_PRODUCT_ITEM}
          />
        )}

        {writeOption.isOpenWrite && writeOption.writeType === MENU_PRODUCT_PRODUCT && (
          <WritePageProduct
            title={<SpanLang keyLang={'Create Product'} />}
            size="md"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            // onReload={refetch}
            menuApi={MENU_PRODUCT_PRODUCT}
          />
        )}

        {showPrint && (
          <PrintPreview
            title='Product Item Print Preview'
            isOpen={showPrint}
            onClose={() => setShowPrint(false)}
            data={viewData}
          />
        )}
      </>
    );
  }, [data, currentTab, fieldTop, tabs, writeOption, showPrint]);

  return <>{isLoading ? <Loading /> : data ? View : <NotFound />}</>;
};
export default ViewPage;
