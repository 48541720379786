import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MENU_PRODUCT, MENU_PRODUCT_TREE } from '@base/config/menus';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { categoryOptions } from '@product/main/config';

const PageToolbar: React.FC<any> = (props) => {
  const { category } = props;
  //recoil
  const navigate = useNavigate();

  return (
    <>
      <ListToolbarContainer
        categoryOptions={categoryOptions}
        categorySelected={category}
        onCategoryChange={(category: string) => {
          navigate(`/${MENU_PRODUCT}/${category}`);
        }}
        menu={MENU_PRODUCT_TREE}
      />
    </>
  );
};

export default PageToolbar;
