import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'react-feather';

const View: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <div className="dropdown d-flex">
      {value && value?.length > 0 ? (
        <div
          className="d-flex align-items-center"
          data-toggle={value?.length >= 2 ? 'dropdown' : ''}
        >
          <span className="mg-r-5">{value?.[0]}</span>
          {value?.length >= 2 && (
            <>
              <span className="badge badge-secondary mg-r-5">+ {value?.length - 1}</span>
              <ChevronDown />
            </>
          )}
        </div>
      ) : null}
      {value && value?.length >= 2 && (
        <div className="dropdown-menu pd-0">
          {value?.map((item: string, index: number) => {
            return (
              <div key={index} className="dropdown-item-list">
                {item}
              </div>
            );
          })}
          <div className="dropdown-footer">Total: {value?.length}</div>
        </div>
      )}
    </div>
  );
};

export default View;
