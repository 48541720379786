import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const MainPage = () => {
  return (
    <Routes>
      {/* default router */}
      <Route path="/" element={<Navigate replace to="/product/product" />} />
    </Routes>
  );
};

export default MainPage;
