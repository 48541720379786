import {
  PRODUCT_ITEM_TYPE_ENUM_GENERAL,
  PRODUCT_ITEM_TYPE_ENUM_PREPAID,
  PRODUCT_ITEM_TYPE_GENERAL,
  PRODUCT_ITEM_TYPE_PREPAID,
  PRODUCT_PREPAID_TYPE,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
  PRODUCT_TYPE_PRODUCED,
  PRODUCT_TYPE_PURCHASE,
} from '@product/main/config/constants';
import React, { useEffect, useState } from 'react';
import { moneyFormat, numberFormat } from '@base/utils/helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {
  data: any;
}

const ItemBaseInfo: React.FC<IProps> = (props: IProps) => {
  const { data } = props;
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState<any[]>([]);

  useEffect(() => {
    if (JSON.stringify(data?.attrValues) !== JSON.stringify(attributes)) {
      if (data?.attrValues?.length > 0) {
        let _attributes: any[] = [];
        data?.attrValues?.map((attrVal: any, index: number) => {
          const idx = _attributes?.findIndex((_ele) => _ele.id === attrVal?.attr?.id);
          let _values: any[] = [];
          let _flag = false;
          if (idx > -1) {
            _values = _attributes?.values as unknown as any[];
          } else {
            _flag = true;
          }
          if (_values && _values?.findIndex((_ele: any) => _ele?.id === attrVal?.id) <= -1) {
            _values.push({ id: attrVal?.id, name: attrVal?.name });
          }
          if (_flag) {
            _attributes.push({
              ...attrVal?.attr,
              values: _values,
            });
          }
        });
        setAttributes(_attributes);
      } else {
        setAttributes([]);
      }
    }
  }, [data?.attrValues]);

  return (
    <div className="view-aside-section pd-15">
      {/* Base Unit */}
      {data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <div className="d-flex pd-10">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Base Unit</div>
          <div className="mg-l-auto tx-15 tx-semibold">{data?.unit?.name ?? ''}</div>
        </div>
      )}
      {/* Unit Name */}
      {data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">Unit Name</div>
          <div className="mg-l-auto tx-15 tx-semibold">{data?.unitVal?.name ?? ''}</div>
        </div>
      )}
      {/* Unit Quantity */}
      {data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">Unit Qty</div>
          <div className="mg-l-auto tx-15 tx-semibold">{data?.unitVal?.qty ?? 0}</div>
        </div>
      )}
      {/* Base Price */}
      {data?.prod?.canBeSold && data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Base Price</div>
          <div className="mg-l-auto tx-15 tx-semibold">
            {data?.basePrices?.map((item: any, index: number) => {
              return (
                <div key={index} className="mg-l-auto tx-15 tx-semibold">
                  {`${item?.currency ?? ''}: ${moneyFormat(item?.amount)}`}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* Base Cost Price */}
      {(data?.prod?.canBeSold || data?.prod?.type === PRODUCT_TYPE_PRODUCED) &&
        data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
          <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-info">Base Cost</div>
            <div className="mg-l-auto tx-15 tx-semibold">
              {data?.costPrices?.map((item: any, index: number) => {
                return (
                  <div key={index} className="mg-l-auto tx-15 tx-semibold">
                    {`${item?.currency ?? ''}: ${moneyFormat(item?.amount)}`}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      {/* Best Price */}
      {/* {data?.prod?.canBeSold && data?.itemType != PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-danger">Best Price</div>
          <div className="mg-l-auto tx-15 tx-semibold">
            {data?.bestPrices?.map((item: any, index: number) => {
              return (
                <div key={index} className="mg-l-auto tx-15 tx-semibold">
                  {`${item?.currency ?? ''}: ${moneyFormat(item?.amount)}`}
                </div>
              );
            })}
          </div>
        </div>
      )} */}
      {/* Purchase Price */}
      {data?.prod?.type === PRODUCT_TYPE_PURCHASE && !data?.prod?.canBeSold && (
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-danger">
            Base Purchase Price
          </div>
          <div className="mg-l-auto tx-15 tx-semibold">
            {data?.purchasePrices?.map((item: any, index: number) => {
              return (
                <div key={index} className="mg-l-auto tx-15 tx-semibold">
                  {`${item?.currency ?? ''}: ${moneyFormat(item?.amount)}`}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* Attributes */}
      {data?.itemType === PRODUCT_ITEM_TYPE_ENUM_GENERAL && (
        <div className="pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="tx-12 tx-spacing-1 tx-uppercase tx-teal">Attributes</div>
          {attributes?.length > 0 &&
            attributes?.map((attr: any, index: number) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-wrap align-items-center justify-content-between pd-t-5"
                >
                  <span className="tx-15 tx-semibold mg-r-10">{attr?.name ?? ''}</span>
                  <div>
                    {attr?.values?.length > 0 &&
                      attr?.values?.map((val: any, i: number) => {
                        return (
                          <span
                            key={i}
                            className="badge badge-light mg-l-5 mg-b-2 pd-x-7 tx-13 tx-normal bd rounded-30"
                          >
                            {val?.name ?? ''}
                          </span>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {/* Prepaid Item */}
      {data?.itemType === PRODUCT_ITEM_TYPE_ENUM_PREPAID && (
        <>
          <div className="d-flex pd-10">
            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Prepaid Type</div>
            <div className="mg-l-auto tx-15 tx-semibold">
              {t(data?.prepaidType?.languageKey ?? '') as any}
            </div>
          </div>
          {data?.prepaidType?.keyName === PRODUCT_PREPAID_TYPE[PRODUCT_PREPAID_TYPE_PER_ITEM] && (
            <>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Qty to Use</div>
                <div className="mg-l-auto tx-15 tx-semibold">
                  {numberFormat(data?.prepaidPerItem, { noOfDecimal: 0 })}
                </div>
              </div>
              <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
                <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
                  Matching Item
                </div>
                <div className="mg-l-auto tx-15 tx-semibold">
                  {/* {data?.matchItemUnit?.name ?? ''} */}
                  <Link to={`/product/item/${data?.matchItemUnit?.id}`} target="_blank">
                    {data?.matchItemUnit?.name ?? ''}
                  </Link>
                </div>
              </div>
            </>
          )}
          <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-info">Prepaid Amount</div>
            <div className="mg-l-auto tx-15 tx-semibold">
              {data?.prepaidAmount?.map((item: any, index: number) => {
                return (
                  <div key={index} className="mg-l-auto tx-15 tx-semibold">
                    {`${item?.currency ?? ''}: ${moneyFormat(item?.value)}`}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-orange">Discount</div>
            <div className="mg-l-auto tx-15 tx-semibold">{`${data?.prepaidDiscount ?? ''} %`}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default ItemBaseInfo;
