import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, Edit2 } from 'react-feather';
import ImageGallery from 'react-image-gallery';
import { MiModal } from '@base/components';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import Edit from './edit';
import { SRC_NO_IMAGE } from '@base/config/constant';
import ImageView from './image';

const Container = styled.div`
  position: relative;
  .images-view {
    .image-gallery-thumbnails-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .image-gallery-thumbnails {
        max-width: calc(100% - 72px);
        .image-gallery-thumbnails-container {
          margin: 0;
          padding: 0;
          white-space: nowrap;
          .image-gallery-thumbnail {
            width: auto;
            &.active {
              border-radius: 10px;
            }
            &:hover {
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
`;

const MainImage = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  width: 100%;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SubImage = styled.div`
  width: 100px;
  height: 100px;
  padding: 0;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 5px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.no-pic {
    background-color: $light;
  }
`;

const LeftNav = styled.button`
  position: absolute;
  z-index: 1;
  bottom: -80px;
`;

const RightNav = styled.button`
  position: absolute;
  z-index: 1;
  bottom: -80px;
  right: 0px;
`;

const EditButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const View: React.FC<any> = (props: any) => {
  const { value } = props;

  const [images, setImages] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (value && value?.length > 0) {
      setImages(
        value?.map((image: any) => ({
          original: image?.url ?? '',
          thumbnail: image?.url ?? '',
        })),
      );
    }
    return () => {
      setImages([]);
    };
  }, [value]);

  return (
    <Container>
      {images?.length > 0 ? (
        <ImageGallery
          additionalClass="images-view"
          thumbnailPosition="bottom"
          showFullscreenButton={false}
          showPlayButton={false}
          showNav={true}
          items={images}
          renderItem={(item: any) => (
            <MainImage className="mg-b-20">
              {/* <img key={item.original} className={'original rounded'} src={item.original} /> */}
              <ImageView key={item.original} className="original rounded" imgSrc={item?.original} />
            </MainImage>
          )}
          renderThumbInner={(item: any) => (
            <SubImage>
              {/* <img key={item.thumbnail} className={'thumbnail'} src={item.thumbnail} /> */}
              <ImageView key={item.thumbnail} className="thumbnail" imgSrc={item?.thumbnail} />
            </SubImage>
          )}
          renderLeftNav={(onClick: any, disabled: boolean) => (
            <LeftNav type="button" className="btn btn-xs btn-white btn-icon" onClick={onClick}>
              <ChevronLeft />
              <span className="sr-only">이전</span>
            </LeftNav>
          )}
          renderRightNav={(onClick: any, disabled: boolean) => (
            <RightNav type="button" className="btn btn-xs btn-white btn-icon" onClick={onClick}>
              <ChevronRight />
              <span className="sr-only">이전</span>
            </RightNav>
          )}
        />
      ) : (
        <div>
          <MainImage className="mg-b-20">
            <img key={'no-images'} className={'original rounded mg-t-20'} src={SRC_NO_IMAGE} />
          </MainImage>
        </div>
      )}

      <EditButton type="button" className="btn btn-link" onClick={() => setIsEdit(true)}>
        <Edit2 className="mg-r-5" />
        Edit
      </EditButton>

      {isEdit && (
        <MiModal
          onClose={() => {
            setIsEdit(false);
          }}
        >
          <MiModal.Body>
            <Edit
              {...props}
              onClose={() => {
                setIsEdit(false);
              }}
            />
          </MiModal.Body>
        </MiModal>
      )}
    </Container>
  );
};

export default View;
