import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Plus, Copy } from 'react-feather';
import { Button, Input, Switch } from '@base/components/form';
import CodeGenerator from '@base/containers/code-generator';
import { WarrantyPeriod } from '@product/product/components';
import {
  KEY_ITEM_CODE,
  KEY_ITEM_NAME,
  KEY_ITEM_ACTIVE,
  KEY_ITEM_DES,
  KEY_ITEM_WARRANTY,
  //prepaid
  KEY_ITEM_MATCH_ITEM,
  KEY_ITEM_PREPAID_DISCOUNT,
  KEY_ITEM_PREPAID_PER_ITEM,
  KEY_ITEM_PREPAID_AMOUNT,
} from '@product/item/config/key-names';
import {
  PRODUCT_PREPAID_TYPE_AMOUNT,
  PRODUCT_PREPAID_TYPE_PER_ITEM,
} from '@product/main/config/constants';
import ProductItemAutoComplete from '@product/item/containers/item-auto-complete';
import BaseCurrency from './base-currency';

const RowItem: React.FC<any> = (props: any) => {
  const {
    prodType,
    type,
    rowIndex = 0,
    removable,
    value,
    defaultValue,
    onChange,
    onRemove,
    onCopy,
  } = props;

  // state
  const [itemValue, setItemValue] = useState<any>(value);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  // item value change
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(itemValue)) {
        setItemValue(value);
      }
    } else {
      setItemValue(_.cloneDeep(defaultValue));
    }
    return () => {};
  }, [value]);

  // value change
  const handleValueChange = (keyName: string, value: any) => {
    const newItemValue = { ...itemValue };
    newItemValue[keyName] = value;
    setItemValue(newItemValue);
    // callback
    onChange && onChange(newItemValue, rowIndex);
  };

  // copy first amount to all
  const handleCopyAll = (keyName: string) => {
    onCopy && onCopy(keyName);
  };

  // // console.log('...item value...', itemValue);
  //render
  return (
    <>
      <tr key={rowIndex}>
        <td>
          <input
            type="text"
            className="form-control ht-38"
            style={{ minWidth: '150px' }}
            value={itemValue?.[KEY_ITEM_NAME] || ''}
            onChange={(e) => handleValueChange(KEY_ITEM_NAME, e.target.value)}
          />
        </td>
        <td>
          <div className="input-group wd-200">
            <CodeGenerator
              menu="item"
              value={itemValue?.code}
              index={rowIndex}
              key={rowIndex}
              onChange={(newCode: string) => {
                handleValueChange(KEY_ITEM_CODE, newCode);
              }}
            />
          </div>
        </td>
        {type === PRODUCT_PREPAID_TYPE_AMOUNT && (
          <td>
            {/* Prepaid Amount */}
            <BaseCurrency
              rowIndex={rowIndex}
              removable={removable}
              value={itemValue?.[KEY_ITEM_PREPAID_AMOUNT] || []}
              onChange={(value: any) => handleValueChange(KEY_ITEM_PREPAID_AMOUNT, value)}
              onCopyAll={() => {
                handleCopyAll(KEY_ITEM_PREPAID_AMOUNT);
              }}
            />
          </td>
        )}
        {type === PRODUCT_PREPAID_TYPE_PER_ITEM && (
          <>
            {/* Matching Item */}
            <td>
              <ProductItemAutoComplete
                type="general"
                single={true}
                showAllOption={false}
                value={itemValue?.[KEY_ITEM_MATCH_ITEM] ?? null}
                onChange={(newItem: any) => handleValueChange(KEY_ITEM_MATCH_ITEM, newItem)}
              />
            </td>
            <td>
              {/* Number of use */}
              <input
                type="number"
                className="form-control ht-38 wd-100"
                value={itemValue?.[KEY_ITEM_PREPAID_PER_ITEM]}
                onChange={(e) => handleValueChange(KEY_ITEM_PREPAID_PER_ITEM, e.target.value)}
              />
            </td>
            <td>
              {/* Prepaid Amount */}
              <BaseCurrency
                rowIndex={rowIndex}
                removable={removable}
                value={itemValue?.[KEY_ITEM_PREPAID_AMOUNT] || []}
                onChange={(value: any) => handleValueChange(KEY_ITEM_PREPAID_AMOUNT, value)}
                onCopyAll={() => {
                  handleCopyAll(KEY_ITEM_PREPAID_AMOUNT);
                }}
              />
            </td>
          </>
        )}
        <td>
          <div className="d-flex">
            <div className="input-group wd-100">
              <input
                type="number"
                className="form-control ht-38"
                value={itemValue?.[KEY_ITEM_PREPAID_DISCOUNT] || '0'}
                onChange={(e) => handleValueChange(KEY_ITEM_PREPAID_DISCOUNT, e.target.value)}
              />
              <div className="input-group-append">
                <span className="input-group-text lh-1">%</span>
              </div>
            </div>
          </div>
          {rowIndex === 0 && removable && (
            <button
              type="button"
              className="btn btn-link text-nowrap"
              onClick={() => handleCopyAll(KEY_ITEM_PREPAID_DISCOUNT)}
            >
              <Copy className="mg-r-5" />
              Copy All
            </button>
          )}
        </td>
        {/* <td>
          <div className="pd-t-10">
            <Switch
              defaultChecked={itemValue?.[KEY_ITEM_ACTIVE] || 'n'}
              onChange={(e: any) =>
                handleValueChange(KEY_ITEM_ACTIVE, e?.target?.checked ? 'y' : 'n')
              }
            />
          </div>
        </td> */}
        {/* <td>
          <button
            type="button"
            className={classNames('btn btn-link tx-nowrap', showMoreInfo ? 'opened' : '')}
            onClick={() => setShowMoreInfo(!showMoreInfo)}
          >
            <Plus className="mg-r-5" />
            {showMoreInfo ? 'Cancel' : 'More Info'}
          </button>
        </td> */}
        {removable && (
          <td>
            <Button
              classSpanIcon="mg-r-5"
              color="icon"
              icon="Minus"
              className="tx-danger"
              onClick={onRemove}
            />
          </td>
        )}
      </tr>
      {/* {showMoreInfo && (
        <tr>
          <td colSpan={9} className="more-info-cell">
            <div id="itemMoreInfo1" className="product-item-more-info">
              <div className="form-row mg-0">
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Warranty Period</label>
                    <WarrantyPeriod
                      value={itemValue?.[KEY_ITEM_WARRANTY]}
                      onChange={(value: any) => handleValueChange(KEY_ITEM_WARRANTY, value)}
                    />
                  </div>
                </div>
                <div className="form-col col-lg-6">
                  <div className="form-group">
                    <label className="form-item-title">Description</label>
                    <textarea
                      cols={10}
                      rows={3}
                      className="form-control"
                      value={itemValue?.[KEY_ITEM_DES] || ''}
                      onChange={(e) => handleValueChange(KEY_ITEM_DES, e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Button
                classSpanIcon="mg-r-5"
                color="link"
                icon="X"
                className="mg-l-auto"
                onClick={() => setShowMoreInfo(false)}
              >
                Close more info
              </Button>
            </div>
          </td>
        </tr>
      )} */}
    </>
  );
};

export default RowItem;
