import React, { useEffect, useState } from 'react';
import ItemBaseInfo from '@product/item/containers/item-base-info';
import { default as ImagesViewField } from '@product/item/containers/view-field/images';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import Button from '@base/components/form/button';
import Icon from '@base/assets/icons/svg-icons';
import { ViewFields } from '@base/components/hook-form/view';
import * as keyNames from '@product/item/config/key-names';
import {
  PRODUCT_ITEM_CATEGORY,
  PRODUCT_ITEM_CATEGORY_INVENTORY,
} from '@product/main/config/constants';
import { inArray } from 'jquery';

interface IViewLeftProps {
  id: string;
  category: string;
  data?: any;
  fieldTop?: any;
}

const inventoryKeys: string[] = [
  keyNames.KEY_ITEM_INVENTORY_SKU,
  keyNames.KEY_ITEM_INVENTORY_OPEN_STOCK,
  keyNames.KEY_ITEM_INVENTORY_REPLENISHMENT_POINT,
  keyNames.KEY_ITEM_INVENTORY_STOCK_ON_HAND,
];

const ViewLeft: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { category, id, data, fieldTop } = props;

  const viewData = useRecoilValue(viewDataByMenuAtom(category));

  const [ignoreFields, setIgnoreFields] = useState<string[]>(['images']);
  const [summaryFields, setSummaryField] = useState<any[]>([]);

  useEffect(() => {
    const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
    const prodData =
      basicFields?.find((_field: any) => _field?.keyName === keyNames.KEY_ITEM_PRODUCT)?.data ??
      null;
    const itemTypeData =
      basicFields?.find((_field: any) => _field?.keyName === keyNames.KEY_ITEM_TYPE)?.data ?? null;
    const inventoryTypeData =
      basicFields?.find((_field: any) => _field?.keyName === keyNames.KEY_ITEM_CATEGORY)?.data ??
      null;

    const fields: any[] = [];
    basicFields?.map((field: any) => {
      if (!inventoryKeys.includes(field.keyName)) {
        fields.push(field);
      } else {
        if (inventoryTypeData === PRODUCT_ITEM_CATEGORY[PRODUCT_ITEM_CATEGORY_INVENTORY]) {
          fields.push(field);
        }
      }
    });
    setSummaryField(fields);
  }, [viewData]);

  const handlerOnSaveImage = (images: any[]) => {
    // // console.log('handlerOnSaveImage >>> images', images);
  };

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 110px)' }}>
      {fieldTop && (
        <div className="view-aside-section pd-15">
          <div className="d-flex align-items-center justify-content-center">
            <div className="w-100">
              <ImagesViewField
                {...fieldTop}
                value={fieldTop?.data ?? ''}
                onSave={handlerOnSaveImage}
                data={data}
                menuSource={category}
              />
            </div>
          </div>
        </div>
      )}
      <ItemBaseInfo data={data} />
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          <button type="button" className="view-aside-section-move mg-r-5">
            {Icon('move')}
          </button>
          <span className="view-aside-section-header-title">Summary</span>
          <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#abmSummary"
          />
        </div>
        <div id="abmSummary" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={summaryFields}
              ignoreFields={ignoreFields}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
              metadata={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewLeft;
